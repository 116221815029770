import clsx from 'clsx';
import { HTMLAttributes, ReactNode } from 'react';

export enum TypographyVariantEnum {
  'HEADER-1' = 'header-1',
  'HEADER-2' = 'header-2',
  'HEADER-3' = 'header-3',
  SUBTITLE = 'subtitle',
  'SUBTITLE-2' = 'subtitle-2',
  'BODY-1' = 'body-1',
  'BODY-2' = 'body-2',
  CAPTION = 'caption',
  'CAPTION-2' = 'caption-2',
  PREHEADER = 'preheader'
}

export enum TypographyAlignEnum {
  INHERIT = 'inherit',
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify',
}

export enum TypographyColorEnum {
  'INITIAL' = 'initial',
  'INHERIT' = 'inherit',
  'PRIMARY' = 'primary',
  'SECONDARY' = 'secondary',
  'TEXT-PRIMARY' = 'text-primary',
  'TEXT-SECONDARY' = 'text-secondary',
  'SUCCESS' = 'success',
  'ERROR' = 'error',
  'HIGHLIGHT' = 'highlight',
  'GRADIENT' = 'gradient',
  'GRADIENT-SECONDARY' = 'gradient-secondary',
  'WARNING' = 'warning'
}

export enum TypographyDisplayEnum {
  BLOCK = 'block',
  INLINE = 'inline',
}

export interface TypographyInterface extends HTMLAttributes<HTMLElement> {
  /** Applies the theme typography styles. */
  variant?: Lowercase<keyof typeof TypographyVariantEnum>;
  /** Set the text-align on the component. */
  align?: Lowercase<keyof typeof TypographyAlignEnum>;
  /** The color of the component. It supports those theme colors that make sense for this component. */
  color?: Lowercase<keyof typeof TypographyColorEnum> | null;
  /** Controls the display type */
  display?: Lowercase<keyof typeof TypographyDisplayEnum>;
  /** If true, font weight is bold */
  bold?: boolean;
  /** If true, font style is italic */
  italic?: boolean;
  /** If true, text decoration is underline */
  underline?: boolean;
  /** If true, text decoration is strikethrough  */
  strikethrough?: boolean;
  /** If true, the text will have a bottom margin. */
  paragraph?: boolean;
  /** The content of the component. */
  children?: ReactNode;
  /** font-weight: 600 */
  semiBold?: boolean;
  /** font-weight: 500 */
  mediumBold?: boolean;
  lightBold?: boolean;
}

/** Use typography to present your design and content as clearly and efficiently as possible. */
export const Typography = ({
  variant = TypographyVariantEnum['BODY-1'],
  align = TypographyAlignEnum.INHERIT,
  color = TypographyColorEnum.INHERIT,
  display = TypographyDisplayEnum.BLOCK,
  bold,
  semiBold,
  mediumBold,
  lightBold,
  italic,
  underline,
  strikethrough,
  paragraph = false,
  children,
  className: classNameProp,
  ...props
}:TypographyInterface) => {
  const className = clsx(
    {
      'text-title dark:text-white':
        color === TypographyColorEnum.INITIAL || color === TypographyColorEnum['TEXT-PRIMARY'],
      'text-current': color === TypographyColorEnum.INHERIT,
      'text-primary dark:text-white': color === TypographyColorEnum.PRIMARY,
      'text-secondary': color === TypographyColorEnum.SECONDARY,
      'text-inputBg dark:text-grey2': color === TypographyColorEnum['TEXT-SECONDARY'],
      'text-success': color === TypographyColorEnum.SUCCESS,
      'text-error': color === TypographyColorEnum.ERROR,
      'text-primary dark:text-primary': color === TypographyColorEnum.HIGHLIGHT,
      'text-transparent bg-clip-text bg-gradient-to-r to-GradStart from-GradEnd': color === TypographyColorEnum.GRADIENT,
      'text-transparent bg-clip-text bg-gradient-to-r to-GradStartSecondary from-GradEndSecondary': color === TypographyColorEnum['GRADIENT-SECONDARY'],
      'text-text-tertiary': color === TypographyColorEnum.WARNING,
    },
    {
      'text-4xl lg2:text-3xl md:text-5xl xl:text-5xl sm:text-3xl': variant === TypographyVariantEnum['HEADER-1'],
      'text-3xl md:text-4xl': variant === TypographyVariantEnum['HEADER-2'],
      'text-2xl md:text-3xl sm:text-xl tablet:text-2xl desktop:text-2xl': variant === TypographyVariantEnum['HEADER-3'],
      'text-xl': variant === TypographyVariantEnum.SUBTITLE,
      'text-lg': variant === TypographyVariantEnum['SUBTITLE-2'],
      'text-base md:text-lg': variant === TypographyVariantEnum['BODY-1'],
      'text-sm md:text-base': variant === TypographyVariantEnum['BODY-2'],
      'text-2sm md:text-sm': variant === TypographyVariantEnum.CAPTION,
      'text-2xs md:text-2sm': variant === TypographyVariantEnum['CAPTION-2'],
      'text-2sm uppercase tracking-widest': variant === TypographyVariantEnum.PREHEADER,
    },
    {
      italic,
      underline,
      'line-through': strikethrough && !underline,
      'font-bold': bold,
      'font-normal': !bold && variant !== TypographyVariantEnum.CAPTION,
      'font-medium': !bold && variant === TypographyVariantEnum.CAPTION,
      'text-left': align === TypographyAlignEnum.LEFT,
      'text-center': align === TypographyAlignEnum.CENTER,
      'text-right': align === TypographyAlignEnum.RIGHT,
      'text-justify': align === TypographyAlignEnum.JUSTIFY,
      block: display === TypographyDisplayEnum.BLOCK,
      'mb-5': display === TypographyDisplayEnum.BLOCK && paragraph,
      '!font-semibold': semiBold,
      '!font-medium': mediumBold,
      '!font-light': lightBold,
    },
    classNameProp,
  );

  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};