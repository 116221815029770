import clsx from 'clsx';
import {
  PaginationItem,
  PaginationItemColorEnum,
  PaginationItemShapeEnum,
  PaginationItemSizeEnum,
  PaginationItemVariantEnum,
} from 'common/otto-ui/pagination/pagination-item';
import { HTMLAttributes, useEffect, useMemo, useState } from 'react';

export interface PaginationInterface extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  /** The current page. */
  page?: number;
  /** The total number of pages. */
  count?: number;
  /** Number of always visible pages at the beginning and end. */
  boundaryCount?: number;
  /** Number of always visible pages before and after the current page. */
  siblingCount?: number;
  /** If true, show the first-page button. */
  showFirstButton?: boolean;
  /** If true, show the last-page button. */
  showLastButton?: boolean;
  /** If true, hide the next-page button. */
  hideNextButton?: boolean;
  /** If true, hide the previous-page button. */
  hidePrevButton?: boolean;
  /** The active color. */
  color?: Lowercase<keyof typeof PaginationItemColorEnum>;
  /** The shape of the pagination items. */
  shape?: Lowercase<keyof typeof PaginationItemShapeEnum>;
  /** The size of the pagination component. */
  size?: Lowercase<keyof typeof PaginationItemSizeEnum>;
  /** The variant to use. */
  variant?: Lowercase<keyof typeof PaginationItemVariantEnum>;
  /**
   * Callback fired when the page is changed.
   * function(page: number) => void
   * page: The page selected.
   */
  onChange?: (page: number) => void;
  children?: never;
}

/** The DataTablePagination component enables the user to select a specific page from a range of pages. */
export const Pagination = ({
  page = 1,
  count = 1,
  boundaryCount = 1,
  siblingCount = 1,
  showFirstButton,
  showLastButton,
  hideNextButton,
  hidePrevButton,
  color = PaginationItemColorEnum.PRIMARY,
  shape = PaginationItemShapeEnum.SQUARE,
  size = PaginationItemSizeEnum.MEDIUM,
  variant = PaginationItemVariantEnum.OUTLINED,
  className,
  onChange,
  ...props
}: PaginationInterface) => {
  const validPageNumber = (pageNumber: number | null | undefined): number =>
    pageNumber ? Math.max(1, Math.min(count, pageNumber)) : 1;

  const [currentPage, setCurrentPage] = useState<number>(validPageNumber(page));

  useEffect(() => {
    setCurrentPage(validPageNumber(page));
  }, [page, count]);

  const handleFirstButtonClick = () => {
    setCurrentPage(1);
    if (onChange) {
      onChange(1);
    }
  };

  const handlePrevButtonClick = () => {
    const newPage = validPageNumber(currentPage - 1);
    setCurrentPage(newPage);
    if (onChange) {
      onChange(newPage);
    }
  };

  const handleNextButtonClick = () => {
    const newPage = validPageNumber(currentPage + 1);
    setCurrentPage(newPage);
    if (onChange) {
      onChange(newPage);
    }
  };

  const handleLastButtonClick = () => {
    setCurrentPage(count);
    if (onChange) {
      onChange(count);
    }
  };

  const handlePageClick = (pageNumber: number) => {
    if (pageNumber) {
      setCurrentPage(pageNumber);
      if (onChange) {
        onChange(pageNumber);
      }
    }
  };

  const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
  };

  const items = useMemo(() => {
    const startPages = range(1, Math.min(boundaryCount, count));
    const endPages = range(Math.max(count - boundaryCount + 1, boundaryCount + 1), count);
    const siblingsStart = Math.max(
      Math.min(currentPage - siblingCount, count - boundaryCount - siblingCount * 2 - 1),
      boundaryCount + 2,
    );
    const siblingsEnd = Math.min(
      Math.max(currentPage + siblingCount, boundaryCount + siblingCount * 2 + 2),
      endPages.length > 0 ? endPages[0] - 2 : count - 1,
    );

    const ellipsStart =
      siblingsStart > boundaryCount + 2 ? [0] : boundaryCount + 1 < count - boundaryCount ? [boundaryCount + 1] : [];
    const ellipsEnd =
      siblingsEnd < count - boundaryCount - 1
        ? [0]
        : count - boundaryCount > boundaryCount
        ? [count - boundaryCount]
        : [];

    return [...startPages, ...ellipsStart, ...range(siblingsStart, siblingsEnd), ...ellipsEnd, ...endPages];
  }, [count, boundaryCount, siblingCount, currentPage]);

  return (
    <div className={clsx('flex flex-row items-center', className)} {...props}>
      {showFirstButton && (
        <PaginationItem
          type="first"
          color={color}
          shape={shape}
          variant={variant}
          size={size}
          onClick={handleFirstButtonClick}
        />
      )}
      {!hidePrevButton && (
        <PaginationItem
          type="prev"
          color={color}
          shape={shape}
          variant={variant}
          size={size}
          onClick={handlePrevButtonClick}
        />
      )}
      {items.map((value, index) => (
        <PaginationItem
          key={`pagination-item-${index}`}
          type={value ? 'page' : 'ellipse'}
          color={color}
          shape={shape}
          variant={variant}
          size={size}
          page={value}
          selected={value === currentPage}
          onClick={() => handlePageClick(value)}
        />
      ))}
      {!hideNextButton && (
        <PaginationItem
          type="next"
          color={color}
          shape={shape}
          variant={variant}
          size={size}
          onClick={handleNextButtonClick}
        />
      )}
      {showLastButton && (
        <PaginationItem
          type="last"
          color={color}
          shape={shape}
          variant={variant}
          size={size}
          onClick={handleLastButtonClick}
        />
      )}
    </div>
  );
};
