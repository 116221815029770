import { IconProps } from 'common/otto-ui/icons/icon.type';
import { useTheme } from 'common/roq-hooks/use-theme';
import React from 'react';

export const UnhappyIcon: React.FC<IconProps> = ({ height = 74, width = 74, color = 'black', darkColor = 'white' }) => {
  const { isDark } = useTheme();
  return (
    <svg width={width} height={height} viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0042 0C16.5907 0 0 16.582 0 36.996C0 57.41 16.5902 74.0002 37.0042 74.0002C57.4183 74.0002 74 57.4095 74 36.996C74 16.5825 57.4178 0 37.0042 0ZM37.0042 3.3892C55.5865 3.3892 70.6111 18.4138 70.6111 36.996C70.6111 55.5782 55.586 70.611 37.0042 70.611C18.4225 70.611 3.38894 55.5777 3.38894 36.996C3.38894 18.4142 18.422 3.3892 37.0042 3.3892Z"
        fill={isDark ? darkColor : color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7344 21.7683C19.2167 21.7683 16.3286 24.6567 16.3286 28.1744C16.3286 31.6866 19.2167 34.5718 22.7344 34.5718C26.2521 34.5718 29.1318 31.6829 29.1318 28.1744C29.1318 24.6604 26.2521 21.7683 22.7344 21.7683ZM22.7344 25.1575C24.4196 25.1575 25.7429 26.4856 25.7429 28.1744C25.7429 29.8517 24.4196 31.1826 22.7344 31.1826C21.0493 31.1826 19.7176 29.848 19.7176 28.1744C19.7176 26.4893 21.0493 25.1575 22.7344 25.1575Z"
        fill={isDark ? darkColor : color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.274 21.7683C47.7563 21.7683 44.8682 24.6567 44.8682 28.1744C44.8682 31.6866 47.7563 34.5718 51.274 34.5718C54.7917 34.5718 57.6714 31.6829 57.6714 28.1744C57.6714 24.6604 54.7917 21.7683 51.274 21.7683ZM51.274 25.1575C52.9591 25.1575 54.2824 26.4856 54.2824 28.1744C54.2824 29.8517 52.9591 31.1826 51.274 31.1826C49.5889 31.1826 48.2571 29.848 48.2571 28.1744C48.2571 26.4893 49.5889 25.1575 51.274 25.1575Z"
        fill={isDark ? darkColor : color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0045 45.1824C30.0077 45.1824 23.9553 49.1999 21.7909 55.0137C21.7087 55.2232 21.6691 55.447 21.6743 55.672C21.6795 55.897 21.7295 56.1186 21.8213 56.3241C21.9132 56.5295 22.045 56.7147 22.2091 56.8686C22.3733 57.0226 22.5664 57.1423 22.7773 57.2208C22.9882 57.2993 23.2126 57.3351 23.4375 57.3259C23.6624 57.3168 23.8831 57.2629 24.087 57.1675C24.2908 57.0721 24.4736 56.9371 24.6247 56.7703C24.7757 56.6035 24.8921 56.4083 24.9669 56.1961C26.5817 51.8586 31.3078 48.5716 37.0045 48.5716C42.7012 48.5716 47.4195 51.8571 49.043 56.1985C49.1187 56.41 49.2356 56.6042 49.387 56.7701C49.5384 56.9359 49.7213 57.07 49.925 57.1646C50.1287 57.2591 50.3492 57.3122 50.5736 57.3208C50.798 57.3294 51.0218 57.2934 51.2322 57.2148C51.4425 57.1361 51.6351 57.0164 51.7988 56.8627C51.9625 56.7089 52.094 56.5241 52.1857 56.3191C52.2774 56.1141 52.3274 55.8929 52.3328 55.6684C52.3383 55.4439 52.299 55.2206 52.2174 55.0114C50.0447 49.2013 44.0014 45.1824 37.0045 45.1824Z"
        fill={isDark ? darkColor : color}
      />
    </svg>
  );
};
