import { QueryOptions } from '@apollo/client';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { AdminsInterface } from 'slices/admins';
import { requestGql } from 'utils/request-gql';

export interface FetchAdminsActionRequestInterface extends QueryOptions {
  variables: any;
}

export interface FetchAdminsActionResponseInterface {
  data: AdminsInterface[];
}

export const fetchAdminsAction = createAsyncThunk(
  'admins/fetch',
  async (request: FetchAdminsActionRequestInterface, thunkApi): Promise<AdminsInterface[] | SerializedError> =>
    await requestGql<AdminsInterface[]>(request, thunkApi, 'users'),
);
