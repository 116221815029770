import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { authApi } from "api/auth.api";
import { UserInterface } from "common/interfaces";
import { StoreInterface } from "configuration/redux/store";
import { UserTypeEnum } from "enums";

export interface CoachInterface {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
  fullName: string;
}

export interface UserCoachStateInterface {
  coach: CoachInterface;
}

const initialState: UserCoachStateInterface = {
  coach: null
};

const slice = createSlice({
  initialState,
  name: 'userCoach',
  reducers: {
    updateUserCoachAction: (state, action: PayloadAction<UserInterface>) => {
      updateCoach(state, action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload: { user } }) => {
        updateCoach(state, user);
      });
  }
});

const updateCoach = (state, user) => {
  if (user.type === UserTypeEnum.CUSTOMER && user.customerMetaInfo?.coachMatches?.data.length) {
    const { id, firstName, lastName, avatar } = user.customerMetaInfo.coachMatches.data[0].coachMetaInfo.user;
    state.coach = {
      id,
      firstName,
      lastName,
      fullName: `${firstName} ${lastName}`,
      avatar
    };
  }
};

export default slice.reducer;
export const { updateUserCoachAction } = slice.actions;
export const userCoachSelector = createSelector(
  (state: StoreInterface) => state,
  (values) => values.userCoach
);
