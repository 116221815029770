import React from 'react';

interface Props {
  color?: string;
}

export const AvatarDefaultIcon = ({ color = 'currentColor' }: Props): JSX.Element => (
  <svg className="w-full h-full" viewBox="0 0 56 56" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 56 56 C 56 56 55.847 0 55.89 0 C 55.933 0 0 0 0 0 C 0 0 0 56 0 56 L 56 56 Z M 27.987 11.989 C 29.765 11.989 31.505 12.516 32.985 13.504 C 34.464 14.492 35.616 15.895 36.298 17.538 C 36.979 19.18 37.157 20.987 36.809 22.731 C 36.462 24.475 35.605 26.076 34.348 27.333 C 33.089 28.59 31.486 29.446 29.741 29.792 C 27.997 30.14 26.188 29.961 24.544 29.281 C 22.901 28.601 21.495 27.449 20.506 25.971 C 19.519 24.493 18.991 22.755 18.991 20.977 C 18.991 18.594 19.938 16.307 21.625 14.622 C 23.313 12.936 25.6 11.989 27.987 11.989 Z M 12.009 45.805 C 12.043 43.182 13.109 40.678 14.977 38.836 C 16.846 36.993 19.365 35.959 21.99 35.958 L 33.983 35.958 C 36.608 35.959 39.127 36.993 40.996 38.836 C 42.864 40.678 43.93 43.182 43.964 45.805 C 39.581 49.752 33.888 51.937 27.987 51.937 C 22.085 51.937 16.392 49.752 12.009 45.805 Z"
      fill="#E2E8F0"
    />
  </svg>
);
