import { MutationOptions, QueryOptions } from '@apollo/client';
import { SerializedError } from '@reduxjs/toolkit';
import { apolloClient } from 'configuration/apollo/apollo-client';

export async function requestGql<T>(
  request: QueryOptions | MutationOptions,
  thunkApi?: any | Record<string, any>,
  responseKey?: string,
): Promise<T | SerializedError> {
  try {
    if ('query' in request && responseKey) {
      const {
        data: { [responseKey]: response },
      } = await apolloClient().query(request);
      return response;
    } else if ('mutation' in request && responseKey) {
      const {
        data: { [responseKey]: response },
      } = await apolloClient().mutate(request);
      return response;
    } else if ('query' in request && !responseKey) {
      return apolloClient().query(request);
    } else if ('mutation' in request && !responseKey) {
      return apolloClient().mutate(request);
    }
  } catch (error) {
    if (thunkApi) {
      return thunkApi.rejectWithValue(error);
    }
    throw new Error((error as Error)?.message);
  }
}
