import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const FilterIcon: React.FC<IconProps> = ({ color = 'currentColor', height = 22, width = 22 }) => (
  <svg width={width} height={height} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" fill={color} rx="1" height="2" />
    <rect x="2.40002" y="5" width="11.2" fill={color} rx="1" height="2" />
    <rect x="4.80005" y="10" width="6.4" fill={color} rx="1" height="2" />
  </svg>
);
