import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const BinIcon: React.FC<IconProps> = ({ color = 'currentColor', height = 16, width = 14 }) => (
  <svg width={width} height={height} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g stroke={color} strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round">
      <path d="M1 4H2.33333H13" />
      <path d="M4.33398 4.00016V2.66683C4.33398 2.31321 4.47446 1.97407 4.72451 1.72402C4.97456 1.47397 5.3137 1.3335 5.66732 1.3335H8.33398C8.68761 1.3335 9.02674 1.47397 9.27679 1.72402C9.52684 1.97407 9.66732 2.31321 9.66732 2.66683V4.00016M11.6673 4.00016V13.3335C11.6673 13.6871 11.5268 14.0263 11.2768 14.2763C11.0267 14.5264 10.6876 14.6668 10.334 14.6668H3.66732C3.3137 14.6668 2.97456 14.5264 2.72451 14.2763C2.47446 14.0263 2.33398 13.6871 2.33398 13.3335V4.00016H11.6673Z" />
      <path d="M5.66602 7.3335V11.3335" />
      <path d="M8.33398 7.3335V11.3335" />
    </g>
  </svg>
);
