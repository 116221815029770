import { useTheme } from "@otto-finance/ui";
import { HTMLAttributes, ReactNode } from "react";
import { Column, Row } from "../ui";

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  color?: string;
  padding?: string;
  borderRadius?: string;
  children: ReactNode;
}

interface CardHeaderProps extends HTMLAttributes<HTMLDivElement> {
  leading: string | ReactNode;
  trailing?: string | ReactNode;
  children?: ReactNode;
}

export const Card = ({
  color = 'white',
  padding = '22px',
  borderRadius = '34px',
  children,
  ...rest
}: CardProps) => {
  const [css] = useTheme();
  return (
    <div
      {...rest}
      className={css({
        display: 'grid',
        gridTemplateRows: 'max-content 1fr',
        backgroundColor: color,
        borderRadius: borderRadius,
        padding: padding,
      })}>
      {children}
    </div>
  );
};

export const CardHeader = ({
  leading,
  trailing,
  children,
  ...rest
}: CardHeaderProps) => {
  return (
    <Row
      {...rest}
      crossalign="center"
      align="between">
      <Column>
        {leading}
        {children && <div>{children}</div>}
      </Column>
      <div>
        {trailing}
      </div>
    </Row>
  );
};
