import clsx from 'clsx';
import { HTMLAttributes } from 'react';

export enum DividerOrientationEnum {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum DividerColorEnum {
  LIGHT = 'light',
  NORMAL = 'normal',
  DARK = 'dark',
}

export enum DividerThicknessEnum {
  '1PX' = '1px',
  '2PX' = '2px',
  '3PX' = '3px',
}

export enum DividerTypeEnum {
  SOLID = 'solid',
  DASHED = 'dashed',
}

export interface DividerInterface extends HTMLAttributes<HTMLDivElement> {
  /** The divider orientation. */
  orientation?: Lowercase<keyof typeof DividerOrientationEnum>;
  /** The divider color. */
  color?: Lowercase<keyof typeof DividerColorEnum>;
  /** The divider thickness. */
  thickness?: Lowercase<keyof typeof DividerThicknessEnum>;
  /** The divider line type. */
  type?: Lowercase<keyof typeof DividerTypeEnum>;
  children?: never;
  dividerClassName?: string;
}

/** A divider is a thin line that groups content in lists and layouts. */
export const Divider = ({
  orientation = DividerOrientationEnum.HORIZONTAL,
  color = DividerColorEnum.NORMAL,
  thickness = DividerThicknessEnum['1PX'],
  className,
  dividerClassName,
  type = DividerTypeEnum.SOLID,
  ...props
}) => {
  const classes = {
    wrapper: clsx('self-stretch', className),
    divider: clsx(
      dividerClassName,
      orientation === DividerOrientationEnum.HORIZONTAL && {
        'w-full min-w-full': true,
        'border-b': thickness === DividerThicknessEnum['1PX'],
        'border-b-2': thickness === DividerThicknessEnum['2PX'],
        'border-b-3': thickness === DividerThicknessEnum['3PX'],
      },
      orientation === DividerOrientationEnum.VERTICAL && {
        'h-full min-h-full': true,
        'border-r': thickness === DividerThicknessEnum['1PX'],
        'border-r-2': thickness === DividerThicknessEnum['2PX'],
        'border-r-3': thickness === DividerThicknessEnum['3PX'],
      },
      {
        'border-label dark:border-grey1': color === DividerColorEnum.LIGHT,
        'border-line dark:bg-white': color === DividerColorEnum.NORMAL,
        'border-inputBg dark:border-title': color === DividerColorEnum.DARK,
      },
      {
        'border-solid': type === DividerTypeEnum.SOLID,
        'border-dashed': type === DividerTypeEnum.DASHED,
      }, dividerClassName
    ),
  };

  return (
    <div className={classes.wrapper} {...props}>
      <div className={classes.divider} />
    </div>
  );
};
