import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { investmentWatchlistApi } from 'api/investment-watchlist.api';
import { FinanceSecurityProfiles, FinanceStockPriceChange, InvestmentWatchlistItem } from 'common/interfaces';
import { StoreInterface } from 'configuration/redux/store';
import omit from 'lodash/omit';

export interface InvestmentsWatchlistStateInterface {
  items: InvestmentWatchlistItem[];
}

const initialState: InvestmentsWatchlistStateInterface = {
  items: []
};

const slice = createSlice({
  initialState,
  name: 'investments',
  reducers: {
    setPrices: (state, { payload }: PayloadAction<FinanceStockPriceChange[]>) => {
      state.items = state.items.map(item => {
          payload.forEach(p => {
            if (item.symbol === p.symbol) {
              item.priceChange = p['1D'];
            }
          });
          return item;
      });
    },
    setProfiles: (state, { payload }: PayloadAction<FinanceSecurityProfiles[]>) => {
      state.items.forEach(item => {
        if (!item.profile) {
          payload.forEach(p => {
            if (item.symbol === p.symbol) {
              item.profile = omit(p, ['description']);
            }
          });
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(investmentWatchlistApi.endpoints.investmentWatchlist.matchFulfilled, (state, { payload }) => {
        state.items = payload || [];
      })
      .addMatcher(investmentWatchlistApi.endpoints.investmentWatchlistAdd.matchFulfilled, (state, { meta: { arg: { originalArgs }}}) => {
        const inStoreIndex = state.items.findIndex(f => f.symbol === originalArgs.symbol);
        if (inStoreIndex === -1) {
          state.items.push({ ...omit(originalArgs, ['userId'])});
        } else {
          state.items[inStoreIndex].tags = originalArgs.tags;
        }
      })
      .addMatcher(investmentWatchlistApi.endpoints.investmentWatchlistRemove.matchFulfilled, (state, { meta: { arg: { originalArgs }}}) => {
        state.items = state.items.filter(f => f.symbol !== originalArgs.symbol);
      });
  }
});

export default slice.reducer;

export const investmentsWatchlistSelector = createSelector(
  (state: StoreInterface) => state,
  (values) => values.investmentsWatchlist,
);

export const investmentsWatchlistActions = slice.actions;
