import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const InsuranceIcon: React.FC<IconProps> = ({ color = 'currentColor', height = 55, width = 55 }) => (
  <svg width={width} height={height} viewBox="0 0 29 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.9991 3.00562C27.7716 2.82036 27.4733 2.74714 27.1858 2.80572C26.3781 2.97145 25.454 3.05541 24.4387 3.05541C23.5314 3.05541 22.5846 2.98707 21.6246 2.85258C18.6713 2.43789 16.0464 1.44984 14.9119 0.329266C14.733 0.12741 14.4718 0 14.1809 0C14.063 0 13.9463 0.0214792 13.8367 0.0627289C13.8111 0.0724922 13.7857 0.0829877 13.7606 0.0949477C13.6451 0.149622 13.5436 0.225287 13.4601 0.316086C12.3322 1.44228 9.69809 2.43642 6.73347 2.85258C5.77326 2.98731 4.82671 3.05541 3.91922 3.05566C2.90384 3.05566 1.97974 2.97169 1.17281 2.80596C0.885528 2.74689 0.586772 2.82036 0.359288 3.00586C0.132048 3.19137 0 3.46913 0 3.76252V21.1533C0 26.7301 4.51038 31.2065 13.7884 34.8382C13.908 34.8905 14.0398 34.9193 14.1784 34.9193H14.1809C14.3205 34.9193 14.453 34.89 14.5731 34.837C23.8487 31.2058 28.3584 26.7294 28.3584 21.1533V3.76252C28.3584 3.46889 28.2266 3.19088 27.9991 3.00562ZM26.4057 21.1528C26.4057 25.7735 22.4052 29.6159 14.1794 32.8929C5.95339 29.6156 1.9529 25.7733 1.9529 21.1528V4.90262C3.46474 5.07397 5.22847 5.03516 7.00489 4.78595C9.95364 4.37223 12.5934 3.43154 14.1794 2.25971C15.765 3.43154 18.4047 4.37199 21.3537 4.78619C23.1301 5.0354 24.8941 5.07397 26.406 4.90262V21.1528H26.4057Z"
      fill={color}
    />
    <path
      d="M18.1915 22.3089H13.1791C14.2884 20.6091 14.6521 19.3272 14.5811 18.2661H16.4478C16.987 18.2661 17.4241 17.829 17.4241 17.2898C17.4241 16.7506 16.987 16.3135 16.4478 16.3135H13.914C13.7136 15.9876 13.4815 15.6735 13.2338 15.3611C12.6665 14.6452 12.3189 14.1719 12.3189 13.4958C12.3189 11.9637 13.0636 11.219 14.596 11.219C14.6009 11.219 14.6055 11.2175 14.6104 11.2175C16.1754 11.2295 16.8457 11.9115 16.8457 13.4931C16.8457 14.0323 17.2828 14.4694 17.822 14.4694C18.3612 14.4694 18.7983 14.0323 18.7983 13.4931C18.7983 10.8448 17.2174 9.26367 14.5694 9.26367C14.5489 9.26367 14.5298 9.26855 14.5098 9.26953C11.9526 9.30565 10.3665 10.9163 10.3665 13.4956C10.3665 14.7301 10.9511 15.6059 11.4991 16.313H9.64039C9.10121 16.313 8.66406 16.7501 8.66406 17.2893C8.66406 17.8285 9.10121 18.2656 9.64039 18.2656H12.6052C12.7285 19.1907 12.2709 20.4519 10.5193 22.6476C10.1991 23.0492 10.2503 23.623 10.6204 23.9671C10.6255 23.9728 10.6314 23.9781 10.6367 23.9833C10.6499 23.9947 10.6599 24.0086 10.6736 24.0194C10.6848 24.0284 10.6975 24.0343 10.709 24.0428C10.8764 24.1773 11.0858 24.261 11.317 24.261H18.192C18.7312 24.261 19.1684 23.8239 19.1684 23.2847C19.1684 22.7455 18.7307 22.3089 18.1915 22.3089Z"
      fill={color}
    />
  </svg>
);
