export class ModalManager {
  readonly modals: Element[];

  constructor() {
    this.modals = [];
  }

  add(modal: Element): void {
    if (!modal || this.modals.includes(modal)) {
      return;
    }
    this.modals.push(modal);
  }

  remove(modal: Element): void {
    if (!modal) {
      return;
    }

    const index = this.modals.indexOf(modal);
    if (index === -1) {
      return;
    }

    this.modals.splice(index, 1);
  }

  isTopModal(modal: Element): boolean {
    if (!modal || this.modals.length === 0) {
      return false;
    }

    return this.modals[this.modals.length - 1] === modal;
  }
}
