import { gql } from 'graphql-request';

export const TiersDocument = gql`
  query Tiers {
    tiers {
      data {
        id
        name
      }
      totalCount
    }
  }
`;
