import { gql } from 'graphql-request';

export const BenefitsDocument = gql`
  query Benefits {
    benefits {
      id
      name
      link
      logo
    }
  }
`;
