import { Button, LabelSmall, Modal, useSnackbar, useTheme } from "@otto-finance/ui";
import { useInvestmentAsset } from "common/roq-hooks";
import { HoldingTitle } from "components/add-holdings/holding-title";
import { memo, useCallback, useMemo } from "react";
import { BuySellButton } from "./buy-sell-button";
import { WealthProviderEnum } from "enums";
import { useDeleteHoldingMutation } from "api/holdings.api";
import { useConfirmAlert } from "common/otto-ui/confirm-alert";
import { useToast } from "common/otto-ui/toast";
import useTranslation from "next-translate/useTranslation";
import { captureException, formatMoney } from "utils";
import { COUNTRIES } from "configuration/data";
import { HoldingTransactions } from "./holding-transactions";
import dynamic from "next/dynamic";
import { useAnalytics } from "use-analytics";
import { TRACK_EVENT } from "enums";
import { securityMaps } from "slices/investments/utils/get-accounts-allocations";
import { HoldingFormItem } from "./holding-form-item";
import { Tabs } from "common/otto-ui/tabs-v2";
import { useMobile } from "common/roq-hooks/use-mobile";

const HoldingHistorical = dynamic(() => import('./holding-historical').then((c) => c.HoldingHistorical), { ssr: false });
// const HistoricalStockPrice = dynamic(() => import('./historical-stock-price').then((c) => c.HistoricalStockPrice), { ssr: false });

export type ViewHoldingProps = {
  /** aCCOUNT ID */
  accountId: string;
  /** Holding ID */
  holdingId: string;
  onClose: () => void;
};

export const ViewHolding = memo(({ accountId, holdingId, onClose }: ViewHoldingProps): JSX.Element => {
  const [css, theme] = useTheme();
  const account = useInvestmentAsset(accountId);
  const { t } = useTranslation('common');
  const [deleteHolding, { isLoading: isDeleting }] = useDeleteHoldingMutation();
  const { open: confirm } = useConfirmAlert();
  const { toast } = useToast();
  const { enqueue } = useSnackbar();
  const holding = useMemo(() => account.holdings.find(f => f.id === holdingId), [account, holdingId]);
  const { track } = useAnalytics();
  const isMobile = useMobile();
  const onDelete = useCallback(async () => {
    if (await confirm()) {
      await deleteHolding({ account: accountId, holding: holdingId })
        .unwrap()
        .then(() => {
          void track(TRACK_EVENT.INVEST_HOLDING_DELETED);
          enqueue({ message: t('investments:holding.deleted', { symbol: holding?.security?.symbol }) });
          onClose();
        })
        .catch(err => {
          captureException(err.message);
          toast({ message: t('errors.request'), variant: 'error' });
        });
    }
  }, []);
  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size={isMobile ? '400px' : 'auto'}
      overrides={{
        Close: {
          style: () => ({
            display: "none"
          })
        }
      }}>
      {(account && holding) ? (
        <div className={css({
          minHeight: '500px',
          display: 'grid',
          gridTemplateRows: 'max-content 1fr'
        })}>
          <div className={css({
            padding: theme.sizing.scale600,
            display: 'flex',
            borderBottom: `solid 1px ${theme.colors.grayScale100}`,
            justifyContent: 'space-between',
            flexDirection: 'column',
            gap: theme.sizing.scale700,
            [theme.mediaQuery.medium]: {
              minWidth: '700px',
              flexDirection: 'row'
            },
          })}>
            <HoldingTitle
              data={{
                companyName: holding.security.name,
                symbol: holding.security.symbol,
                image: holding.security.iconUrl
              }}
              color={account?.color}
            />
            {account.vendor === WealthProviderEnum.Otto && (
              <div>
                <BuySellButton accountId={accountId} security={holding.security} />
              </div>
            )}
          </div>

          <Tabs
            orientation={isMobile ? 'horizontal' : 'vertical'}
            items={[
              {
                id: 'holdings',
                title: 'Holdings',
                content: (
                  <div className={css({
                    display: 'grid',
                    alignContent: 'space-between',
                    gap: theme.sizing.scale800,
                    maxWidth: '750px'
                  })}>
                    <div>
                      <div className={css({ display: 'grid' })}>
                        <HoldingFormItem label="Account">
                          <LabelSmall>{account.name}</LabelSmall>
                        </HoldingFormItem>
                        <HoldingFormItem label="Shares">
                          <LabelSmall>{holding.amount}</LabelSmall>
                        </HoldingFormItem>
                        <HoldingFormItem label="Value">
                          <LabelSmall>{formatMoney(holding.balance.convertedAmount, holding.balance.convertedCurrency)}</LabelSmall>
                        </HoldingFormItem>
                        <HoldingFormItem label="Latest valuation">
                          <LabelSmall>{formatMoney(holding.security.convertedPrice, account.convertedCurrency)}/share</LabelSmall>
                        </HoldingFormItem>
                        {holding.security.country && holding.security.country.length > 0 ? (
                          <HoldingFormItem label={`Countries (${holding.security.country.length})`}>
                            <LabelSmall>
                              {holding.security.country
                                .map(country => country.value && country.value.length > 2 ? country.value : COUNTRIES[country.value] || '-')
                                .join(', ')}
                            </LabelSmall>
                          </HoldingFormItem>
                        ) : null}
                        {holding.security.sector && holding.security.sector.length > 0 ? (
                          <HoldingFormItem label={`Sectors (${holding.security.sector.length})`}>
                            <LabelSmall>{holding.security.sector.map(sector => securityMaps.sector[sector.value] || '-').join(', ')}</LabelSmall>
                          </HoldingFormItem>
                        ) : null}
                      </div>
    
                    </div>
                    <div className={css({ textAlign: 'right' })}>
                      {account.vendor === WealthProviderEnum.Otto && (
                        <Button onClick={onDelete} isLoading={isDeleting} kind="tertiary" size="mini">Delete holding</Button>
                      )}
                    </div>
                  </div>
                )
              },
              {
                id: 'transactions',
                title: 'Transactions',
                content: <HoldingTransactions accountId={account.id} holdingId={holding.id} />
              },
              {
                id: 'holding-history',
                title: <>Holding&nbsp;History</>,
                content: <HoldingHistorical holding={holding} />
              },
              // {
              //   id: 'market-price',
              //   title: 'Market Price',
              //   disabled: true,
              //   content: <HistoricalStockPrice symbol={holding.security.symbol} />
              // }
            ]} />
        </div>
      ) : 'Not Found'}
    </Modal>
  );
});
