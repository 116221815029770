import clsx from 'clsx';
import { AlertIcon, AlertIconSeverityEnum } from 'common/otto-ui/alert';
import { Portal } from 'common/otto-ui/portal';
import {
  AnimationEvent,
  CSSProperties,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useState,
} from 'react';

export const SNACKBAR_DEFAULT_AUTO_HIDE_DURATION = 3000;
export const SNACKBAR_DEFAULT_WIDTH = 275;

type SnackbarPlacementVerticalType = 'top' | 'bottom';
type SnackbarPlacementHorizontalType = 'start' | 'center' | 'end';
export type SnackbarPlacementType = `${SnackbarPlacementVerticalType}-${SnackbarPlacementHorizontalType}`;

export enum SnackbarVariantEnum {
  DEFAULT = 'default',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface SnackbarInterface extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  /** If true, Snackbar is open. */
  open?: boolean;
  /** The position of the Snackbar. */
  placement?: SnackbarPlacementType;
  /** The number of milliseconds to wait before dismissing after user interaction. */
  closeAfter?: number;
  /** The width of the Snackbar. */
  width?: number;
  /** The variant to use. */
  variant?: Lowercase<keyof typeof SnackbarVariantEnum>;
  /** The icon displayed before the children. */
  icon?: ReactNode;
  /** Displayed above the content (strings only) */
  titleString?: string;
  /** Displayed above the content */
  title?: ReactNode;
  /** The content of the Snackbar. Unless provided, children props will be used to show content. */
  message?: ReactNode;
  /** The action to display. It renders after the message, at the end of the conent. */
  action?: ReactNode;
  /** The content of the Snackbar. Unless provided, message props will be used to show content. */
  children?: ReactNode;
  /** If true, placement props will be ignored. Useful to place component into the current view hierarchy */
  disablePlacement?: boolean;
  /**
   * Callback fired when the component requests to be closed.
   * function() => void;
   */
  onClose?: () => void;
  /**
   * Callback fired after the component has been opened.
   * function() => void;
   */
  onOpened?: () => void;
  /**
   * Callback fired after the component has been closed.
   * function() => void;
   */
  onClosed?: () => void;
}

/** Snackbars provide brief messages about app processes. The component is also known as a toast. */
export const Snackbar = ({
  open,
  placement = 'bottom-center',
  closeAfter = SNACKBAR_DEFAULT_AUTO_HIDE_DURATION,
  width = SNACKBAR_DEFAULT_WIDTH,
  variant = SnackbarVariantEnum.DEFAULT,
  icon,
  title,
  titleString,
  message,
  action,
  disablePlacement,
  style = {},
  className,
  children,
  onClose,
  onOpened,
  onClosed,
  onAnimationEnd,
  ...props
}:SnackbarInterface) => {
  const [autoHideDuration, setAutoHideDuration] = useState<number>(closeAfter);
  const [shouldRender, setRender] = useState<boolean>(open);

  const classes = {
    frame: 'z-50 fixed inset-2 pointer-events-none',
    layout: clsx('w-full h-full flex flex-row', {
      'justify-start': placement && placement.endsWith('start'),
      'justify-center': placement && placement.endsWith('center'),
      'justify-end': placement && placement.endsWith('end'),
      'items-start': placement && placement.startsWith('top'),
      'items-end': placement && placement.startsWith('bottom'),
    }),
    wrapper: clsx(
      'px-5 py-3 rounded-lg flex flex-row space-x-4 text-sm shadow-general pointer-events-auto overflow-hidden',
      {
        'flex-grow': !width,
        'bg-title dark:bg-offWhite dark:text-title': variant === SnackbarVariantEnum.DEFAULT,
        'text-title': variant === SnackbarVariantEnum.WARNING,
        'text-offWhite': variant !== SnackbarVariantEnum.WARNING,
        'bg-primary': variant === SnackbarVariantEnum.INFO,
        'bg-success': variant === SnackbarVariantEnum.SUCCESS,
        'bg-amber-300': variant === SnackbarVariantEnum.WARNING,
        'bg-error': variant === SnackbarVariantEnum.ERROR,
      },
      open && {
        'animate-slide-in-down': placement === 'top-center',
        'animate-slide-in-up': placement === 'bottom-center',
        'animate-slide-in-right': placement && placement.endsWith('start'),
        'animate-slide-in-left': placement && placement.endsWith('end'),
      },
      !open && {
        'animate-slide-out-up': placement === 'top-center',
        'animate-slide-out-down': placement === 'bottom-center',
        'animate-slide-out-left': placement && placement.endsWith('start'),
        'animate-slide-out-right': placement && placement.endsWith('end'),
      },
      className,
    ),
    body: clsx('flex-1', {
      'flex flex-col space-y-2': Boolean(title || titleString),
    }),
    icon: clsx('w-5 h-5 text-current', {
      'mt-1': Boolean(title || titleString),
    }),
    action: clsx('flex flex-row justify-end items-center space-x-2 cursor-pointer', {
      'text-primary-light dark:text-primary': variant === SnackbarVariantEnum.DEFAULT,
    }),
  };

  const styles: CSSProperties = {
    ...style,
    ...(width && { width }),
  };

  const handleMouseEnter = () => {
    setAutoHideDuration(0);
  };

  const handleMouseLeave = () => {
    setAutoHideDuration(closeAfter);
  };

  const handleAnimationEnd = (event: AnimationEvent<HTMLDivElement>) => {
    if (onAnimationEnd) {
      onAnimationEnd(event);
    }

    if (!open) {
      setRender(false);
      setAutoHideDuration(0);
      if (onClosed) {
        onClosed();
      }
    } else if (onOpened) {
      onOpened();
    }
  };

  useEffect(() => {
    if (open) {
      setRender(true);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setAutoHideDuration(closeAfter);
    }
  }, [open, closeAfter]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (autoHideDuration > 0 && open) {
      timer = setTimeout(() => {
        if (onClose) {
          onClose();
        }
      }, autoHideDuration);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [open, autoHideDuration]);

  const view = (
    <div
      className={classes.wrapper}
      style={styles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onAnimationEnd={handleAnimationEnd}
      {...props}
    >
      {(icon || variant !== SnackbarVariantEnum.DEFAULT) && (
        <div className={classes.icon}>
          {icon ?? <AlertIcon severity={variant as AlertIconSeverityEnum} size={18} />}
        </div>
      )}
      {title || titleString ? (
        <div className={classes.body}>
          <div className="text-base font-bold">{title || titleString}</div>
          {message && <div>{message}</div>}
        </div>
      ) : (
        <div className={classes.body}>{message ?? children}</div>
      )}
      {Boolean(action) && <div className={classes.action}>{action}</div>}
    </div>
  );

  return shouldRender ? (
    disablePlacement ? (
      view
    ) : (
      <Portal>
        <div className={classes.frame}>
          <div className={classes.layout}>{view}</div>
        </div>
      </Portal>
    )
  ) : null;
};
