import { caching } from 'configuration/data';
import { GetEventsDocument } from 'configuration/graphql/documents';
import { isAfter } from 'date-fns';
import { SpeakerInterface } from 'slices/events';

import { API_TAGS, baseApi } from './base.api';

type AssetModel = {
  contentType?: string;
  description?: string;
  entryId: string;
  fileName?: string;
  fileUrl?: string;
  slug?: string;
  title?: string;
};

type EventTopicModel = {
  entryId: string;
  icon?: string;
  image?: AssetModel;
  slug?: string;
  title?: string;
};

export interface CompanyInterface {
  logo: {
    fileUrl: string;
  };
  name: string;
}

export interface EventInterface {
  webinarId: string;
  entryId: string;
  title: string;
  date: string;
  description: string;
  registration: {
    joinLink: string;
    registered: string;
  };
  slug: string;
  thumbnail: AssetModel;
  topics: EventTopicModel[];
  companies: CompanyInterface[];
  categories: {
    title: string;
    slug: string;
  }[];
  video?: {
    fileName: string;
    url: string;
  };
  host?: {
    avatar: {
      fileUrl: string;
    };
    name: string;
    role: string;
  };
  speakers?: SpeakerInterface[];
  banner?: {
    contentType: string;
    description: string;
    fileName: string;
    fileUrl: string;
    title: string;
  };
}

interface EventsResponse {
  data: EventInterface[];
  totalCount: number;
}

type ContentOrderArgType = {
  order: string;
  sort: string;
};

interface GetEventsArgs {
  categoriesIn?: string[];
  category?: string;
  entryId?: string;
  entryIds?: string[];
  limit?: number;
  offset?: number;
  order?: ContentOrderArgType;
  topic?: string;
  topicsAll?: string[];
  topicsIn?: string[];
}

export const eventsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    events: builder.query<EventsResponse, GetEventsArgs>({
      query: (arg) => ({
        variables: {
          offset: 0,
          limit: 5,
          order: {
            sort: 'DATE',
            order: 'DESC'
          },
          ...arg
        },
        document: GetEventsDocument
      }),
      transformResponse: (response: { fetchWebinars; }) => response.fetchWebinars,
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.EVENTS],
    }),
    upcomingEvents: builder.query<EventsResponse, GetEventsArgs>({
      query: (arg) => ({
        variables: {
          offset: 0,
          limit: 20,
          order: {
            sort: 'DATE',
            order: 'DESC'
          },
          ...arg
        },
        document: GetEventsDocument
      }),
      transformResponse: (response: { fetchWebinars: EventsResponse; }) => {
        const { data, totalCount } = response.fetchWebinars;
        return {
          totalCount,
          data: totalCount ? data.filter(f => isAfter(new Date(f.date), new Date())).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()) : []
        };
      },
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.EVENTS],
    }),
    event: builder.query<EventInterface, { entryId: string; }>({
      query: args => ({
        variables: args,
        document: GetEventsDocument
      }),
      transformResponse: ({ fetchWebinars }: { fetchWebinars: EventsResponse; }) => fetchWebinars?.totalCount ? fetchWebinars.data[0] : null,
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.EVENT],
    }),
  }),
});

export const {
  useEventsQuery,
  useEventQuery,
  useUpcomingEventsQuery
} = eventsApi;
