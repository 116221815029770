import clsx from 'clsx';
import { AvatarDefaultIcon } from 'common/otto-ui/avatar/avatar-default-icon';
import React from 'react';

interface Props {
  src?: string;
  grayscale?: boolean;
  className?: string;
  title?: string;
}

export const AvatarExpert: React.FC<Props> = ({ src, grayscale = true, className, title }) => {
  const classes = {
    avatar: clsx(
      'bg-cover bg-center rounded-full overflow-hidden w-[120px] h-[120px]',
      {
        // eslint-disable-next-line object-shorthand
        'grayscale': grayscale
      },
      className),
  };
  return (
    <div>
      {!src ? (
        <div className={classes.avatar} title={title}>
          <AvatarDefaultIcon />
        </div>
      ) : (
        <div className={classes.avatar} style={{ backgroundImage: `url(${src})`}} />
      )}
    </div>
  );
};
