import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const CalendarTimerIcon: React.FC<IconProps> = ({ color = "currentColor", size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color} stroke={color} strokeWidth="0.4">
    <path d="M16.435 6.06738H14.3803V5.48032C14.3803 5.21348 14.1668 5 13.8999 5C13.6331 5 13.4196 5.21348 13.4196 5.48032V6.06738H8.58973V5.48032C8.58973 5.21348 8.37625 5 8.10941 5C7.84256 5 7.62909 5.21348 7.62909 5.48032V6.06738H5.57438C4.6938 6.06738 4 6.78786 4 7.64176V16.4476C4 17.3282 4.72048 18.022 5.57438 18.022H16.4616C17.3422 18.022 18.036 17.3015 18.036 16.4476V7.64176C18.0093 6.78786 17.3155 6.06738 16.435 6.06738ZM17.0487 16.4743C17.0487 16.7945 16.7819 17.0614 16.4616 17.0614H5.5477C5.22749 17.0614 4.96064 16.7945 4.96064 16.4743V10.2035H17.0487V16.4743ZM17.0487 9.21615H4.93396V7.66845C4.93396 7.34823 5.2008 7.08139 5.52101 7.08139H7.57572V7.66845C7.57572 7.93529 7.78919 8.14877 8.05604 8.14877C8.32288 8.14877 8.53636 7.93529 8.53636 7.66845V7.0547H13.3396V7.64176C13.3396 7.90861 13.553 8.12208 13.8199 8.12208C14.0867 8.12208 14.3002 7.90861 14.3002 7.64176V7.0547H16.3549C16.6751 7.0547 16.942 7.32155 16.942 7.64176V9.21615H17.0487Z"/>
    <path d="M7.41621 13.1643H8.21674C8.4569 13.1643 8.64369 12.9775 8.64369 12.7373V12.257C8.64369 12.0169 8.4569 11.8301 8.21674 11.8301H7.41621C7.17605 11.8301 6.98926 12.0169 6.98926 12.257V12.7373C6.98926 12.9775 7.17605 13.1643 7.41621 13.1643Z"/>
    <path d="M10.591 13.1643H11.3915C11.6317 13.1643 11.8185 12.9775 11.8185 12.7373V12.257C11.8185 12.0169 11.6317 11.8301 11.3915 11.8301H10.591C10.3509 11.8301 10.1641 12.0169 10.1641 12.257V12.7373C10.1641 12.9775 10.3509 13.1643 10.591 13.1643Z"/>
    <path d="M13.7668 13.1643H14.5673C14.8075 13.1643 14.9943 12.9775 14.9943 12.7373V12.257C14.9943 12.0169 14.8075 11.8301 14.5673 11.8301H13.7668C13.5266 11.8301 13.3398 12.0169 13.3398 12.257V12.7373C13.3398 12.9775 13.5266 13.1643 13.7668 13.1643Z"/>
    <path d="M7.41621 15.4592H8.21674C8.4569 15.4592 8.64369 15.2724 8.64369 15.0323V14.552C8.64369 14.3118 8.4569 14.125 8.21674 14.125H7.41621C7.17605 14.125 6.98926 14.3118 6.98926 14.552V15.0323C6.98926 15.2724 7.17605 15.4592 7.41621 15.4592Z"/>
    <path d="M10.591 15.4592H11.3915C11.6317 15.4592 11.8185 15.2724 11.8185 15.0323V14.552C11.8185 14.3118 11.6317 14.125 11.3915 14.125H10.591C10.3509 14.125 10.1641 14.3118 10.1641 14.552V15.0323C10.1641 15.2724 10.3509 15.4592 10.591 15.4592Z"/>
    <path d="M13.7668 15.4592H14.5673C14.8075 15.4592 14.9943 15.2724 14.9943 15.0323V14.552C14.9943 14.3118 14.8075 14.125 14.5673 14.125H13.7668C13.5266 14.125 13.3398 14.3118 13.3398 14.552V15.0323C13.3398 15.2724 13.5266 15.4592 13.7668 15.4592Z"/>
    <path d="M15.1347 20.7969C15.9246 21.3247 16.8532 21.6064 17.8032 21.6064C19.0771 21.6064 20.2988 21.1004 21.1996 20.1996C22.1004 19.2988 22.6064 18.0771 22.6064 16.8032C22.6064 15.8532 22.3247 14.9246 21.7969 14.1347C21.2691 13.3448 20.519 12.7292 19.6413 12.3656C18.7636 12.0021 17.7979 11.907 16.8661 12.0923C15.9344 12.2776 15.0786 12.7351 14.4068 13.4068C13.7351 14.0786 13.2776 14.9344 13.0923 15.8661C12.907 16.7979 13.0021 17.7636 13.3656 18.6413C13.7292 19.519 14.3448 20.2691 15.1347 20.7969Z" fill="white"/>
    <path d="M17.8032 21.6064C16.8532 21.6064 15.9246 21.3247 15.1347 20.7969C14.3448 20.2691 13.7292 19.519 13.3656 18.6413C13.0021 17.7636 12.907 16.7979 13.0923 15.8661C13.2776 14.9344 13.7351 14.0786 14.4068 13.4068C15.0786 12.7351 15.9344 12.2776 16.8661 12.0923C17.7979 11.907 18.7636 12.0021 19.6413 12.3656C20.519 12.7292 21.2691 13.3448 21.7969 14.1347C22.3247 14.9246 22.6064 15.8532 22.6064 16.8032C22.6064 18.0771 22.1004 19.2988 21.1996 20.1996C20.2988 21.1004 19.0771 21.6064 17.8032 21.6064ZM17.8032 12.9606C17.0432 12.9606 16.3003 13.186 15.6684 13.6082C15.0365 14.0305 14.544 14.6306 14.2531 15.3327C13.9623 16.0349 13.8862 16.8075 14.0345 17.5529C14.1827 18.2982 14.5487 18.9829 15.0861 19.5203C15.6235 20.0577 16.3082 20.4237 17.0536 20.5719C17.7989 20.7202 18.5716 20.6441 19.2737 20.3533C19.9758 20.0624 20.576 19.5699 20.9982 18.938C21.4204 18.3061 21.6458 17.5632 21.6458 16.8032C21.6458 15.7841 21.2409 14.8067 20.5203 14.0861C19.7997 13.3655 18.8223 12.9606 17.8032 12.9606Z"/>
    <path d="M18.7642 18.2431C18.701 18.2435 18.6383 18.2314 18.5798 18.2074C18.5213 18.1835 18.4681 18.1483 18.4232 18.1038L17.4625 17.1432C17.418 17.0983 17.3828 17.0451 17.3589 16.9865C17.335 16.928 17.3229 16.8654 17.3233 16.8021V15.3612C17.3233 15.2338 17.3739 15.1116 17.4639 15.0215C17.554 14.9315 17.6762 14.8809 17.8036 14.8809C17.931 14.8809 18.0531 14.9315 18.1432 15.0215C18.2333 15.1116 18.2839 15.2338 18.2839 15.3612V16.6052L19.1052 17.4218C19.1503 17.4664 19.186 17.5195 19.2104 17.5781C19.2348 17.6366 19.2473 17.6994 19.2473 17.7628C19.2473 17.8262 19.2348 17.889 19.2104 17.9475C19.186 18.006 19.1503 18.0592 19.1052 18.1038C19.0604 18.1483 19.0071 18.1835 18.9486 18.2074C18.8901 18.2314 18.8274 18.2435 18.7642 18.2431Z" strokeWidth="0.5"/>
    </g>
  </svg>
);
