import { createSlice } from '@reduxjs/toolkit';
import { createAccessTokenAction } from 'slices/platform/actions';

interface PlatformStateErrorInterface {
  message: string;
}

export interface PlatformStateInterface {
  isLoading: boolean;
  error: PlatformStateErrorInterface;
  accessToken: string;
}

export interface PlatformAccessTokenInterface {
  accessToken: string;
}

const platformInitialState: PlatformStateInterface = {
  accessToken: null,
  error: null,
  isLoading: false,
};

export const platformSlice = createSlice({
  name: 'platform',
  initialState: platformInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAccessTokenAction.pending as any, (state = platformInitialState) => {
      state.isLoading = true;
    }).addCase(createAccessTokenAction.rejected as any, (state = platformInitialState, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }).addCase(createAccessTokenAction.fulfilled as any, (state = platformInitialState, action) => {
      state.isLoading = false;
      state.error = null;
      state.accessToken = action.payload.accessToken;
    });
  },
});

export default platformSlice.reducer;

export { createAccessTokenAction };
