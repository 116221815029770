import { FormControl, Input, InputProps } from "@otto-finance/ui";
import { CURRENCY_SYMBOL } from "enums";
import { useField } from 'formik';
import { ChangeEvent, useCallback } from "react";
import { formatMoney } from "utils";

export const FormCurrencyField = ({ label, name, caption, symbol = CURRENCY_SYMBOL.GBP, ...props }: InputProps & { label: string; caption?: string; symbol?: string; }) => {
  const [field, meta, helper] = useField({ name });
  const _onChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = Number(e.target.value.replace(/[^\d]/g, ''));
    const amount = formatMoney(value, symbol, 0);
    helper.setValue(amount);
  },[]);
  return (
    <div>
      <FormControl
        label={`${label}${props.required ? '*' : ''}`}
        error={meta.touched && meta.error ? meta.error : null}
        caption={caption}>
        <Input
          {...props}
          type="text"
          onChange={_onChange}
          onBlur={field.onBlur}
          name={name}
          value={field.value ?? ''}
        />
      </FormControl>
    </div>
  );
};
