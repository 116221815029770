import { ButtonIcon } from 'common/otto-ui/button';
import { CheckBox } from 'common/otto-ui/check-box';
import { IconsSort } from 'common/otto-ui/table/icons';
import { MapDataToTableConfigItemInterface } from 'common/otto-ui/table/map-data-to-table.mapper';
import { SyntheticEvent } from 'react';

export interface TableHeadInterface {
  data: MapDataToTableConfigItemInterface[];
  selectionMode?: boolean;
  onSelectItems?: (event: SyntheticEvent<HTMLInputElement>) => void;
  selected?: boolean;
  children?: never;
  editMode?: boolean;
  deleteMode?: boolean;
  onChangeOrder?: (sort: string) => void;
  sort: string;
  order: string;
}

export const TableHead = ({
  data,
  onSelectItems,
  selected,
  selectionMode,
  editMode,
  deleteMode,
  onChangeOrder,
  sort,
  order,
}:TableHeadInterface) => {
  const columns = data.map(({ label, dataTarget, type, disableSorting }, i) => (
    <th
      key={`table-head-${i}`}
      className="border-b border-solid border-line px-2 bg-offWhite whitespace-nowrap text-left text-inputBg text-sm tracking-wide dark:bg-background-dark-background2"
    >
      <div className="flex items-center">
        <div>{label}</div>
        {type !== 'id' && type !== 'entity' && type !== 'entityGroup' && !disableSorting && (
          <ButtonIcon size="small" onClick={() => onChangeOrder(dataTarget)}>
            <IconsSort
              sortAsc={dataTarget === sort && order === 'ASC'}
              sortDesc={dataTarget === sort && order === 'DESC'}
            />
          </ButtonIcon>
        )}
      </div>
    </th>
  ));

  return (
    <thead className="w-full">
      <tr className="h-18 w-full">
        {selectionMode && (
          <th className="border-b border-solid border-line px-8 bg-offWhite sticky left-0 dark:bg-background-dark-background2 shadow-general dark:shadow-general-lg z-10">
            <div className="flex align-items justify-center">
              <CheckBox checked={selected} onChange={onSelectItems} size="small" />
            </div>
          </th>
        )}
        {columns}
        {(editMode || deleteMode) && (
          <th className="border-b border-solid border-line px-8 bg-offWhite sticky right-0 dark:bg-background-dark-background2 shadow-general dark:shadow-general-lg" />
        )}
      </tr>
    </thead>
  );
};
