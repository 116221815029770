export enum VIDEO_TILE_SIZE {
  SM = 'sm',
  MD = 'md',
  XL = 'xl',
}

export enum VIDEO_TYPE {
  VIDEO = 'video',
  SCREEN = 'screenVideo'
}

export enum ROOM_STATE {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  CREATING = 'CREATING',
  JOINING = 'JOINING',
  JOINED = 'JOINED',
  LEAVING = 'LEAVING',
  ERROR = 'ERROR',
  HAIRCHECK = 'HAIRCHECK',
  LEFT = 'LEFT',
  ENDED = 'ENDED'
}

export enum ROOM_EVENT {
  LOADING = "loading",
  LOADED = "loaded",
  JOINED = "joined-meeting",
  JOINING = "joining-meeting",
  P_UPDATED = "participant-updated",
  P_JOINED = "participant-joined",
  P_LEFT = "participant-left",
  CAM_ERROR = "camera-error",
  CAM_STARTED = "started-camera",
  ERROR = "error",
  LEFT_MEETING = "left-meeting",
  TRANSCRIPTION_STARTED = "transcription-started",
  TRANSCRIPTION_STOPPED = "transcription-stopped",
  TRANSCRIPTION_ERROR = "transcription-error",
  APP_MESSAGE = "app-message"
}
