import { Placement } from '@popperjs/core/lib/enums';
import { Portal } from 'common/otto-ui/portal';
import { useClickOutside } from 'common/roq-hooks/use-click-outside';
import { cloneElement, isValidElement, ReactNode, useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import styles from './dropdown.module.scss';

export const Dropdown: React.FC<{ content: ReactNode; open?: boolean; placement?: Placement; onClose: () => void;  children?: ReactNode; }> = ({ content, open = false, placement, children, onClose }) => {
  const [triggerElement, setTriggerElement] = useState(null);
  const onCloseHandle = useCallback(() => {
    onClose();
  }, []);
  return (
    <>
      {isValidElement(children) && cloneElement<any>(children, {
        ref: setTriggerElement,
        style: {
          ...(open && {
            pointerEvents: 'none'
          })
        }
      })}
      {open ? <Portal>
        <DropdownContent trigger={triggerElement} onClose={onCloseHandle} placement={placement}>{content}</DropdownContent>
      </Portal> : null}
    </>
  );
};

const DropdownContent: React.FC<{ trigger: Element; placement?: Placement; onClose: () => void; children?: ReactNode; }> = ({ trigger, placement, children, onClose }) => {
  const wrapEl = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles: pStyles, attributes } = usePopper(trigger, popperElement, {
    placement: placement || 'auto',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });
  useClickOutside(wrapEl, onClose);
  return (
    <div ref={wrapEl}>
      <div
        className={styles.root}
        ref={setPopperElement}
        style={pStyles.popper}
        {...attributes.popper}
      >
        {children}
      </div>
    </div>
  );
};
