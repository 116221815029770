import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const FilterChevronDownIcon: React.FC<IconProps> = ({ color = '#A1A7A2', height = 4, width = 6 }) => (
  <svg width={width} height={height} viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.55209 4.02664C3.31428 4.28612 2.90523 4.28611 2.66742 4.02664L0.472682 1.63189C0.119969 1.24704 0.392981 0.626503 0.915015 0.626503L5.30449 0.626502C5.82653 0.626502 6.09954 1.24704 5.74683 1.63189L3.55209 4.02664Z"
      fill={color}
    />
  </svg>
);
