import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const GroceryIcon: React.FC<IconProps> = ({ color = 'currentColor', height = 21, width = 17 }) => (
  <svg width={width} height={height} viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 1C0.5 0.723858 0.723858 0.5 1 0.5H2.69694C4.01519 0.5 5.15431 1.42088 5.43052 2.70987L5.48989 2.98694H19.123C19.96 2.98694 20.5663 3.78512 20.3418 4.59147L19.0027 9.40188C18.666 10.6111 17.5647 11.4478 16.3094 11.4478H7.19653C7.25738 12.3869 8.03801 13.126 8.98835 13.126H18.0533C18.3294 13.126 18.5533 13.3499 18.5533 13.626C18.5533 13.9021 18.3294 14.126 18.0533 14.126H17.0385C17.5127 14.386 17.8343 14.8899 17.8343 15.4689C17.8343 16.3141 17.1491 16.9993 16.3039 16.9993C15.4586 16.9993 14.7734 16.3141 14.7734 15.4689C14.7734 14.8899 15.095 14.386 15.5692 14.126H9.38614C9.8604 14.386 10.1819 14.8899 10.1819 15.4689C10.1819 16.3141 9.49675 16.9993 8.65152 16.9993C7.80629 16.9993 7.12109 16.3141 7.12109 15.4689C7.12109 14.7851 7.56954 14.2061 8.18838 14.0098C7.05332 13.6714 6.21868 12.631 6.1933 11.3874L6.18551 11.0058L5.1297 6.07864L4.59678 3.5917L4.45272 2.9194C4.27531 2.09148 3.54365 1.5 2.69694 1.5H1C0.723858 1.5 0.5 1.27614 0.5 1ZM7.08864 10.4478H16.3094C17.1157 10.4478 17.8231 9.91039 18.0393 9.13369L19.3785 4.32328C19.4255 4.15425 19.2984 3.98694 19.123 3.98694H5.70418L6.1075 5.86911L7.08864 10.4478ZM8.65152 15.9993C8.94446 15.9993 9.18194 15.7618 9.18194 15.4689C9.18194 15.176 8.94446 14.9385 8.65152 14.9385C8.35857 14.9385 8.12109 15.176 8.12109 15.4689C8.12109 15.7618 8.35857 15.9993 8.65152 15.9993ZM16.8343 15.4689C16.8343 15.7618 16.5968 15.9993 16.3039 15.9993C16.0109 15.9993 15.7734 15.7618 15.7734 15.4689C15.7734 15.176 16.0109 14.9385 16.3039 14.9385C16.5968 14.9385 16.8343 15.176 16.8343 15.4689Z"
      fill={color}
    />
  </svg>
);
