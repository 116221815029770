/* eslint react/display-name: "off" */
/* @eslint react/display-name: "off" */

import clsx from 'clsx';
import { ProgressCircular } from 'common/otto-ui/progress';
import { useRipple } from 'common/roq-hooks/use-ripple/use-ripple.hook';
import {
  ButtonHTMLAttributes,
  ForwardedRef,
  forwardRef,
  MouseEventHandler,
  ReactEventHandler,
  ReactNode,
} from 'react';

export enum ButtonTypeEnum {
  SUBMIT = 'submit',
  RESET = 'reset',
  BUTTON = 'button',
}

export enum ButtonVariantEnum {
  FILLED = 'filled',
  TEXT = 'text',
  OUTLINED = 'outlined',
  ICON = 'icon',
  GRADIENT = 'gradient',
  FLOATING = 'floating',
  FLAT = 'flat'
}

export enum ButtonSizeEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XL = 'xl',
}

export enum ButtonColorEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ERROR = 'error'
}

export interface ButtonInterface extends ButtonHTMLAttributes<HTMLButtonElement> {
  ref?: ForwardedRef<HTMLButtonElement>;
  /** The default behavior of the button */
  type?: Lowercase<keyof typeof ButtonTypeEnum>;
  /** The variant to use */
  variant?: Lowercase<keyof typeof ButtonVariantEnum>;
  /** The size of the button */
  size?: Lowercase<keyof typeof ButtonSizeEnum>;
  /** The color of the component */
  color?: Lowercase<keyof typeof ButtonColorEnum>;
  /** Element placed before the children */
  startIcon?: ReactNode;
  /** Element placed after the children */
  endIcon?: ReactNode;
  /** If true, the button will take up the full width of its container */
  fullWidth?: boolean;
  /** If true, no elevation is used */
  disableElevation?: boolean;
  /** If true, no text uppercase */
  disableUppercased?: boolean;
  /** The content of the button */
  children?: ReactNode;
  /** The onClick props specifies some script to run when the element is clicked */
  onClick?: ReactEventHandler<HTMLButtonElement>;
  /** Is the button in a loading state */
  isLoading?: boolean;
  /** Border radius */
  radius?: 'sm' | 'md' | 'lg' | 'full' | string;
  /** Button padding */
  spacing?: 'sm' | 'md' | 'lg' | 'full' | string; 
  bold?: boolean;
  rounded?: boolean;
}

/** Buttons allow users to take actions, and make choices, with a single tap */
export const Button = forwardRef<HTMLButtonElement, ButtonInterface>(
  (
    {
      type = ButtonTypeEnum.BUTTON,
      variant = ButtonVariantEnum.FILLED,
      size = ButtonSizeEnum.MEDIUM,
      color = ButtonColorEnum.PRIMARY,
      startIcon,
      endIcon,
      fullWidth,
      disableElevation,
      disableUppercased = true,
      disabled,
      className,
      children,
      onMouseDown,
      onClick,
      isLoading,
      radius,
      spacing = 0,
      bold,
      rounded,
      ...props
    },
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const classes = {
      button: clsx(
        { [className]: className },
        'z-0 relative box-border font-semibold focus:outline-none overflow-hidden transition duration-150 select-none cursor-pointer pointer-events-auto disabled:cursor-not-allowed',
        {
          'w-full': fullWidth,
          'font-bold': bold,
          uppercase: !disableUppercased,
          'h-7.5 px-2 text-2sm': size === ButtonSizeEnum.SMALL,
          'h-9 px-4 text-sm': size === ButtonSizeEnum.MEDIUM,
          'h-10.5 px-5.5 text-base-sm': size === ButtonSizeEnum.LARGE,
          'h-15 min-w-15': size === ButtonSizeEnum.XL,
          'cursor-not-allowed opacity-50': disabled,
          [`px-${spacing}`]: true,
        },
        variant === ButtonVariantEnum.FILLED && {
          'shadow-general hover:shadow-general-lg active:shadow-general-lg': !disableElevation && !disabled,
          'text-opacity-80 text-title bg-line bg-opacity-30': disabled,
          'text-offWhite bg-primary hover:bg-primary-message active:bg-primary focus:bg-primary dark:focus:bg-primary dark:text-title':
            color === ButtonColorEnum.PRIMARY && !disabled,
          'bg-line hover:bg-inputBg active:bg-inputBg dark:text-title focus:bg-inputBg':
            color === ButtonColorEnum.SECONDARY && !disabled,
        },
        variant === ButtonVariantEnum.TEXT && {
          'text-opacity-30': disabled,
          'text-primary dark:active:bg-body dark:hover:bg-body dark:focus:bg-body': color === ButtonColorEnum.PRIMARY && !disabled,
          'text-title dark:bg-black-500 dark:text-offWhite hover:bg-background-light-background8 dark:hover:bg-background5 dark:bg-transparent':
            color === ButtonColorEnum.SECONDARY && !disabled,
        },
        variant === ButtonVariantEnum.OUTLINED && {
          'border border-solid border-black dark:border-white': true,
          'border-error text-error': color === ButtonColorEnum.ERROR,
        },
        variant === ButtonVariantEnum.ICON && {
          'dark:text-secondary-light rounded-full w-9 h-9 p-0': true,
        },
        (variant === ButtonVariantEnum.GRADIENT || variant === ButtonVariantEnum.FLOATING) && {
          'rounded-full !text-black bg-gradient-to-r to-GradStart from-GradEnd': true,
          'hover:to-GradEnd hover:from-GradStart': !disabled,
          'to-GradStartDisabled from-GradEndDisabled': disabled,
        },
        variant === ButtonVariantEnum.FLAT && {
          'bg-background-light-background2 text-white dark:bg-background-dark-background6': color === ButtonColorEnum.PRIMARY,
          'bg-background-light-background10 hover:bg-background-light-background7 dark:bg-background-dark-background4 dark:hover:bg-background-dark-background6': color === ButtonColorEnum.SECONDARY,
        },
        radius && `rounded-${radius}`,
        {
          'rounded-full': rounded
        }
      ),
      wrapper: clsx('flex flex-row flex-nowrap whitespace-nowrap justify-center items-center space-x-3', {
        'px-2 space-x-1': size === ButtonSizeEnum.SMALL,
        'px-4': size === ButtonSizeEnum.MEDIUM,
        '!p-0': variant === ButtonVariantEnum.ICON
      }),
      icon: clsx({
        'opacity-30': disabled,
      }),
    };

    const { addRipple, ripples } = useRipple();
    const handleMouseDown: MouseEventHandler<HTMLButtonElement> = (event) => {
      addRipple(event);
      if (onMouseDown) {
        onMouseDown(event);
      }
    };

    return (
      <button
        ref={ref}
        className={classes.button}
        type={type}
        onMouseDown={handleMouseDown}
        onClick={onClick}
        disabled={disabled}
        {...props}
      >
        {ripples}
        <div className={classes.wrapper}>
          {startIcon && <div className={classes.icon}>{startIcon}</div>}
          <div style={{ opacity: isLoading ? 0 : 1 }}>{children}</div>
          {isLoading && (
            <div className="absolute">
              <ProgressCircular size={18} color="white" />
            </div>
          )}
          {endIcon && <div className={classes.icon}>{endIcon}</div>}
        </div>
      </button>
    );
  },
);

Button.displayName = 'Button';
