import { MutationOptions } from '@apollo/client';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { requestGql } from 'utils/request-gql';

interface UpdateAdminRequestInterface extends MutationOptions {
  variables: {
    id: string;
    user: { firstName?: string; lastName?: string; status?: string };
  };
}

export const updateAdminAction = createAsyncThunk(
  'admin/update',
  async (request: UpdateAdminRequestInterface, thunkApi): Promise<UpdateAdminRequestInterface | SerializedError> =>
    await requestGql<UpdateAdminRequestInterface>(request, thunkApi, 'updateUser'),
);
