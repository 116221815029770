import clsx from 'clsx';
import { Portal } from 'common/otto-ui/portal';
import { useKeypress } from 'common/roq-hooks';
import { KeyEnum } from 'enums';
import { domAnimation, LazyMotion, motion } from 'framer-motion';
import { HTMLAttributes, MouseEventHandler, ReactNode, useCallback, useEffect } from 'react';

export enum DrawerPositionEnum {
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom',
}

export enum DrawerSizeEnum {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  AUTO = 'auto',
  FULL = 'full',
}

/**
 * Get animation props
 * @param p DrawerPositionEnum
 */
const getAnimation = (p: string) => {
  let initial = {};
  let animate = {};
  switch (p) {
    case DrawerPositionEnum.BOTTOM:
      initial = { y: '50%' };
      animate = { y: '0%' };
      break;
    case DrawerPositionEnum.RIGHT:
      initial = { x: '50%' };
      animate = { x: '0%' };
      break;
    case DrawerPositionEnum.LEFT:
      initial = { x: '-50%' };
      animate = { x: '0%' };
      break;
    case DrawerPositionEnum.TOP:
      initial = { y: '-50%' };
      animate = { y: '0%' };
      break;
  }
  return { initial, animate };
};

export interface DrawerInterface extends HTMLAttributes<HTMLDivElement> {
  /** If true, the drawer is open. */
  open?: boolean;
  /** Side from which the drawer will appear. */
  position?: Lowercase<keyof typeof DrawerPositionEnum>;
  /** The size of the drawer. */
  size?: Lowercase<keyof typeof DrawerSizeEnum>;
  /** The contents of the drawer. */
  children?: ReactNode;
  /**
   * Callback fired when the component requests to be closed.
   * function() => void;
   */
  onClose?: () => void;
}

/**
 * Navigation drawers provide access to destinations in your app. Side sheets are surfaces containing
 * supplementary content that are anchored to the left or right edge of the screen.
 */
export const Drawer = ({
  open,
  position = DrawerPositionEnum.LEFT,
  size = DrawerSizeEnum.AUTO,
  className,
  children,
  onClose,
  ...props
}) => {
  const pressed = useKeypress(KeyEnum.Escape);
  const isHorizontal = position === DrawerPositionEnum.LEFT || position === DrawerPositionEnum.RIGHT;
  const classes = {
    wrapper: clsx('z-[200] fixed left-0 top-0 w-screen h-screen flex items-stretch', {
      'flex-col': !isHorizontal,
      'flex-row': isHorizontal,
      'justify-start': position === DrawerPositionEnum.LEFT || position === DrawerPositionEnum.TOP,
      'justify-end': position === DrawerPositionEnum.RIGHT || position === DrawerPositionEnum.BOTTOM,
    }),
    backdrop: 'z-0 absolute left-0 top-0 w-full h-full bg-title dark:bg-grey2 opacity-60 dark:opacity-40',
    drawer: clsx(
      'z-10 shadow-general bg-white dark:bg-body text-title dark:text-offWhite',
      {
        'h-full': size === DrawerSizeEnum.FULL && position === DrawerPositionEnum.RIGHT,
      },
      isHorizontal && {
        'w-auto': size === DrawerSizeEnum.AUTO && Boolean(children),
        'w-48': size === DrawerSizeEnum.XS,
        'w-60': size === DrawerSizeEnum.SM,
        'w-72': !Boolean(children) || size === DrawerSizeEnum.MD,
        'w-96': size === DrawerSizeEnum.LG,
      },
      !isHorizontal && {
        'h-auto': size === DrawerSizeEnum.AUTO && Boolean(children),
        'h-48': size === DrawerSizeEnum.XS,
        'h-60': size === DrawerSizeEnum.SM,
        'h-72': !Boolean(children) || size === DrawerSizeEnum.MD,
        'h-96': size === DrawerSizeEnum.LG,
        'h-full': size === DrawerSizeEnum.FULL,
      },
      className,
    ),
  };

  const handleClose: MouseEventHandler = useCallback(() => {
    onClose();
  }, []);

  useEffect(() => {
    if (open && pressed) {
      onClose();
    }
  }, [pressed]);

  return open ? (
    <Portal>
      <LazyMotion features={domAnimation}>
        <div className={classes.wrapper}>
          <div className={classes.backdrop} onClick={handleClose} />
          <motion.div {...getAnimation(position)} transition={{ type: 'easeOut' }}>
            <div className={classes.drawer} {...props}>
              {children}
            </div>
          </motion.div>
        </div>
      </LazyMotion>
    </Portal>
  ) : null;
};
