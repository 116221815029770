import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const BankIcon: React.FC<IconProps> = ({ height = 41, width = 40, color = "currentColor" }) => (
  <svg width={width} height={height} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <path d="M39 40.5H1C0.7 40.5 0.4 40.35 0.2 40.1C-2.83122e-07 39.85 -0.0499999 39.5 0.0500001 39.2L2.55 31.2C2.7 30.8 3.05 30.5 3.5 30.5H36.5C36.95 30.5 37.3 30.8 37.45 31.2L39.95 39.2C40.05 39.5 40 39.85 39.8 40.1C39.6 40.35 39.3 40.5 39 40.5ZM2.35 38.5H37.65L35.75 32.5H4.25L2.35 38.5Z" />
      <path d="M7 31.5C6.45 31.5 6 31.05 6 30.5V13.5C6 12.95 6.45 12.5 7 12.5C7.55 12.5 8 12.95 8 13.5V30.5C8 31.05 7.55 31.5 7 31.5Z" />
      <path d="M13.5 31.5C12.95 31.5 12.5 31.05 12.5 30.5V13.5C12.5 12.95 12.95 12.5 13.5 12.5C14.05 12.5 14.5 12.95 14.5 13.5V30.5C14.5 31.05 14.05 31.5 13.5 31.5Z" />
      <path d="M20 31.5C19.45 31.5 19 31.05 19 30.5V13.5C19 12.95 19.45 12.5 20 12.5C20.55 12.5 21 12.95 21 13.5V30.5C21 31.05 20.55 31.5 20 31.5Z" />
      <path d="M26.5 31.5C25.95 31.5 25.5 31.05 25.5 30.5V13.5C25.5 12.95 25.95 12.5 26.5 12.5C27.05 12.5 27.5 12.95 27.5 13.5V30.5C27.5 31.05 27.05 31.5 26.5 31.5Z" />
      <path d="M33 31.5C32.45 31.5 32 31.05 32 30.5V13.5C32 12.95 32.45 12.5 33 12.5C33.55 12.5 34 12.95 34 13.5V30.5C34 31.05 33.55 31.5 33 31.5Z" />
      <path d="M37 14.5H3C2.45 14.5 2 14.05 2 13.5V11.3C2 10.95 2.2 10.65 2.5 10.45L19.5 0.15C19.8 -0.05 20.2 -0.05 20.55 0.15L37.55 10.45C37.85 10.65 38.05 10.95 38.05 11.3V13.5C38 14.05 37.55 14.5 37 14.5ZM4 12.5H36V11.85L20 2.15L4 11.85V12.5Z" />
      <path d="M37 36.5H3C2.45 36.5 2 36.05 2 35.5C2 34.95 2.45 34.5 3 34.5H37C37.55 34.5 38 34.95 38 35.5C38 36.05 37.55 36.5 37 36.5Z" />
    </g>
  </svg>
);
