import clsx from 'clsx';
import omit from 'lodash/omit';
import { InputHTMLAttributes, ReactNode } from 'react';

export enum RadioColorEnum {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum RadioLabelPositionEnum {
  AFTER = 'after',
  BEFORE = 'before',
  ABOVE = 'above',
  BELOW = 'below',
}

export interface RadioInterface extends InputHTMLAttributes<HTMLInputElement> {
  /** Color of radio */
  color?: Lowercase<keyof typeof RadioColorEnum>;
  /** Label for component */
  label?: ReactNode;
  /** Position of label element for radio */
  labelPosition?: Lowercase<keyof typeof RadioLabelPositionEnum>;
  /** Set full width of parent container */
  fullWidth?: boolean;
  children?: never;
}

/** Radio control with one value selection */
export const Radio = ({
  color = RadioColorEnum.DEFAULT,
  label,
  labelPosition = RadioLabelPositionEnum.AFTER,
  fullWidth,
  checked,
  disabled,
  className,
  ...props
}: RadioInterface) => {
  const classes = {
    wrapper: clsx('flex-row justify-start items-center', {
      'flex ': fullWidth,
      'inline-flex': !fullWidth,
      'opacity-30': disabled,
    }),
    label: clsx(
      'flex justify-start items-center text-base text-body dark:text-offWhite cursor-pointer',
      label && className,
      {
        'flex-row': labelPosition === RadioLabelPositionEnum.AFTER,
        'flex-row-reverse': labelPosition === RadioLabelPositionEnum.BEFORE,
        'flex-col': labelPosition === RadioLabelPositionEnum.BELOW,
        'flex-col-reverse': labelPosition === RadioLabelPositionEnum.ABOVE,
      },
      {
        'pointer-events-none': disabled,
        'pointer-events-auto': !disabled,
      },
    ),
    button: clsx('z-0 relative p-3 rounded-full group cursor-pointer overflow-hidden', !label && className, {
      'pointer-events-none': disabled,
      'pointer-events-auto': !disabled,
    }),
    radio: clsx('w-4.5 h-4.5 p-px border-2 border-solid rounded-full overflow-hidden', {
      'border-line group-hover:border-placeholder': color === RadioColorEnum.DEFAULT,
      'border-primary dark:border-primary-light group-hover:border-primary-dark dark:group-hover:border-primary-normal':
        color === RadioColorEnum.PRIMARY,
      'border-secondary dark:border-secondary-lightest group-hover:border-secondary-dark dark:group-hover:border-secondary-light':
        color === RadioColorEnum.SECONDARY,
    }),
    check: clsx(
      'w-full h-full rounded-full transition-transform duration-150 transform',
      {
        'bg-line group-hover:bg-placeholder': color === RadioColorEnum.DEFAULT,
        'bg-primary dark:bg-primary-light group-hover:bg-primary-dark dark:group-hover:bg-primary-normal':
          color === RadioColorEnum.PRIMARY,
        'bg-secondary dark:bg-secondary-lightest group-hover:bg-secondary-dark dark:group-hover:bg-secondary-light':
          color === RadioColorEnum.SECONDARY,
      },
      {
        'scale-0': !checked,
        'scale-100': checked,
      },
    ),
    control: clsx('z-10 absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer'),
  };

  const radio = (
    <div className={classes.button}>
      {/* {ripples} */}
      <div className={classes.radio}>
        <div className={classes.check} />
      </div>
      <input type="radio" className={classes.control} disabled={disabled} checked={checked} {...omit(props, 'type')} />
    </div>
  );

  const radioWithLabel = label ? (
    <label className={classes.label}>
      {radio}
      <span>{label}</span>
    </label>
  ) : (
    radio
  );

  return <div className={classes.wrapper}>{radioWithLabel}</div>;
};
