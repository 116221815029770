import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const CalculatorIcon: React.FC<IconProps> = ({ color = 'currentColor', height = 16, width = 12 }) => (
  <svg width={width} height={height} viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6667 0H1.33333C0.6 0 0 0.6 0 1.33333V14.6667C0 15.4 0.6 16 1.33333 16H10.6667C11.4 16 12 15.4 12 14.6667V1.33333C12 0.6 11.4 0 10.6667 0ZM11.3333 14.6667C11.3333 15.0333 11.0333 15.3333 10.6667 15.3333H1.33333C0.966667 15.3333 0.666667 15.0333 0.666667 14.6667V1.33333C0.666667 0.966667 0.966667 0.666667 1.33333 0.666667H10.6667C11.0333 0.666667 11.3333 0.966667 11.3333 1.33333V14.6667Z"
      fill={color}
    />
    <path
      d="M9.99935 1.6333H1.99935C1.79935 1.6333 1.66602 1.76663 1.66602 1.96663V5.29997C1.66602 5.49997 1.79935 5.6333 1.99935 5.6333H9.99935C10.1993 5.6333 10.3327 5.49997 10.3327 5.29997V1.96663C10.3327 1.76663 10.1993 1.6333 9.99935 1.6333ZM9.66602 4.96663H2.33268V2.29997H9.66602V4.96663Z"
      fill={color}
    />
    <path
      d="M10 7.99984H9V6.99984C9 6.79984 8.86667 6.6665 8.66667 6.6665C8.46667 6.6665 8.33333 6.79984 8.33333 6.99984V7.99984H7.33333C7.13333 7.99984 7 8.13317 7 8.33317C7 8.53317 7.13333 8.6665 7.33333 8.6665H8.33333V9.6665C8.33333 9.8665 8.46667 9.99984 8.66667 9.99984C8.86667 9.99984 9 9.8665 9 9.6665V8.6665H10C10.2 8.6665 10.3333 8.53317 10.3333 8.33317C10.3333 8.13317 10.2 7.99984 10 7.99984Z"
      fill={color}
    />
    <path
      d="M4.66602 8H1.99935C1.79935 8 1.66602 8.13333 1.66602 8.33333C1.66602 8.53333 1.79935 8.66667 1.99935 8.66667H4.66602C4.86602 8.66667 4.99935 8.53333 4.99935 8.33333C4.99935 8.13333 4.86602 8 4.66602 8Z"
      fill={color}
    />
    <path
      d="M4.49974 11.6C4.36641 11.4667 4.16641 11.4667 4.03307 11.6L3.33307 12.3L2.63307 11.6C2.49974 11.4667 2.29974 11.4667 2.16641 11.6C2.03307 11.7333 2.03307 11.9333 2.16641 12.0667L2.86641 12.7667L2.16641 13.4667C2.03307 13.6 2.03307 13.8 2.16641 13.9333C2.23307 14 2.33307 14.0333 2.39974 14.0333C2.49974 14.0333 2.56641 14 2.63307 13.9333L3.33307 13.2333L4.03307 13.9333C4.09974 14 4.19974 14.0333 4.26641 14.0333C4.36641 14.0333 4.43307 14 4.49974 13.9333C4.63307 13.8 4.63307 13.6 4.49974 13.4667L3.79974 12.7667L4.49974 12.0667C4.63307 11.9333 4.63307 11.7333 4.49974 11.6Z"
      fill={color}
    />
    <path
      d="M10 12.4331H7.33333C7.13333 12.4331 7 12.5664 7 12.7664C7 12.9664 7.13333 13.0998 7.33333 13.0998H10C10.2 13.0998 10.3333 12.9664 10.3333 12.7664C10.3333 12.5998 10.2 12.4331 10 12.4331Z"
      fill={color}
    />
    <path
      d="M8.83268 12.0002C9.03268 12.0002 9.16602 11.8668 9.16602 11.6668C9.16602 11.4668 9.03268 11.3335 8.83268 11.3335H8.49935C8.29935 11.3335 8.16602 11.4668 8.16602 11.6668C8.16602 11.8668 8.29935 12.0002 8.49935 12.0002H8.83268Z"
      fill={color}
    />
    <path
      d="M8.83268 13.5332H8.49935C8.29935 13.5332 8.16602 13.6665 8.16602 13.8665C8.16602 14.0665 8.29935 14.1999 8.49935 14.1999H8.83268C9.03268 14.1999 9.16602 14.0665 9.16602 13.8665C9.16602 13.6999 9.03268 13.5332 8.83268 13.5332Z"
      fill={color}
    />
  </svg>
);
