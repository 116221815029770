import { IconProps } from 'common/otto-ui/icons/icon.type';
import { useTheme } from 'common/roq-hooks/use-theme';
import React from 'react';

export const ChevronRightIcon: React.FC<IconProps> = ({
  color = 'black',
  height = 14,
  width = 8,
  darkColor = 'white',
}) => {
  const { isDark } = useTheme();
  return (
    <svg width={width} height={height} viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.66663 13L7.66663 7L1.66663 1"
        stroke={isDark ? darkColor : color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
