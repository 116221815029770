import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FolderModel, UserSimpleInterface } from 'common/interfaces';
import { documentsApi } from 'api/documents.api';
import { arrayToTree } from 'utils/arrays';
import { findDeepBy } from 'utils/find-deep-by';

export interface DocumentsStateInterface {
  folders: FolderModel[];
  folder: { [folderId: string]: FolderModel; };
  owner: { [userId: string]: UserSimpleInterface; };
}

const initialState: DocumentsStateInterface = {
  folders: [],
  folder: {},
  owner: {}
};

const slice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    updateDocumentOwners: (state, { payload }: PayloadAction<UserSimpleInterface[]>) => {
      payload.forEach(u => {
        state.owner[u.id] = u;
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(documentsApi.endpoints.folders.matchFulfilled, (state, { payload }) => {
        const folders = payload.map(i => {
          const tree = i.path.split('.');
          return {
            ...i,
            children: [],
            parentId: tree.length > 1 ? tree[tree.length - 2] : null,
            breadcrumb: tree.map(t => payload.find(f => f.id === t))
          };
        }).sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

        folders.forEach(f => {
          state.folder[f.id] = f;
        });
        state.folders = [
          {
            id: null,
            userId: '',
            name: 'My documents',
            path: '',
            parentId: null,
            createdAt: null,
            updatedAt: null,
            children: arrayToTree(folders),
            breadcrumb: []
          }
        ];
      })
      .addMatcher(documentsApi.endpoints.documents.matchFulfilled, (state, { payload }) => {
        payload.data.forEach(doc => {
          if (!state.owner[doc.userId]) {
            state.owner[doc.userId] = null;
          }
        });
      });
  }
});

const documentsState = ({ documents }: { documents: DocumentsStateInterface; }) => documents;

export const { updateDocumentOwners } = slice.actions;

export const foldersSelector = createSelector(documentsState, v => v.folders);
export const documentOwnerSelector = createSelector(documentsState, v => v.owner);
export const documentOwnerByIdSelector = (userId: string) => createSelector(documentOwnerSelector, v => v[userId]);
export const folderSelector = (folderId: string) => createSelector(documentsState, v => v.folder[folderId]);
export const folderTreeSelector = (folderId: string) => createSelector(foldersSelector, v => findDeepBy(v, 'children', folderId, 'id'));

export default slice.reducer;
