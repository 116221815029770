import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

interface CustomProps extends IconProps {
  outline?: boolean;
  strokeWidth?: string;
}

export const SparkleIcon: React.FC<CustomProps> = ({
  height = 7,
  width = 8,
  color = 'currentColor',
  outline,
  strokeWidth = '4',
}) => {
  if (outline) {
    return (
      <svg width={width} height={height} viewBox="0 0 90 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g stroke={color} strokeWidth={strokeWidth}>
          <path d="M47.0788 33.5468C47.7725 33.9261 47.7725 34.9223 47.0788 35.3016L33.2699 42.8525L25.7191 56.6613C25.3398 57.355 24.3436 57.355 23.9643 56.6613L16.4135 42.8525L2.60456 35.3016C1.91092 34.9223 1.91093 33.9261 2.60457 33.5468L16.4135 25.996L23.9643 12.1871C24.3436 11.4934 25.3398 11.4934 25.7191 12.1871L33.2699 25.996L47.0788 33.5468Z" />
          <path d="M69.9905 82.448C70.4269 82.6866 70.4269 83.3134 69.9905 83.552L61.3026 88.3026L56.552 96.9905C56.3134 97.4269 55.6866 97.4269 55.448 96.9905L50.6974 88.3026L42.0095 83.552C41.5731 83.3134 41.5731 82.6866 42.0095 82.448L50.6974 77.6974L55.448 69.0095C55.6866 68.5731 56.3134 68.5731 56.552 69.0095L61.3026 77.6974L69.9905 82.448Z" />
          <path d="M86.9905 16.448C87.4269 16.6866 87.4269 17.3134 86.9905 17.552L78.3026 22.3026L73.552 30.9905C73.3134 31.4269 72.6866 31.4269 72.448 30.9905L67.6974 22.3026L59.0095 17.552C58.5731 17.3134 58.5731 16.6866 59.0095 16.448L67.6974 11.6974L72.448 3.00951C72.6866 2.57311 73.3134 2.57311 73.552 3.00951L78.3026 11.6974L86.9905 16.448Z" />
        </g>
      </svg>
    );
  }
  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g fill={color}>
        <path d="M16.2492 2.5355L14.6733 3.39722L13.8116 4.97309L12.9499 3.39722L11.374 2.5355L12.9499 1.67377L13.8116 0.0979004L14.6733 1.67377L16.2492 2.5355Z" />
        <path d="M9.46092 5.67577L6.72502 7.1718L5.229 9.9077L3.73298 7.1718L0.99707 5.67577L3.73298 4.17975L5.229 1.44385L6.72502 4.17975L9.46092 5.67577Z" />
        <path d="M13.1086 14.2577L11.5327 15.1194L10.671 16.6953L9.80927 15.1194L8.2334 14.2577L9.80927 13.3959L10.671 11.8201L11.5327 13.3959L13.1086 14.2577Z" />
      </g>
    </svg>
  );
};
