import { caching } from 'configuration/data';
import { DeleteRoadmapDocument, GetRoadmapDocument,SaveRoadmapDocument } from 'configuration/graphql/documents';
import { RoadmapInterface } from 'views/roadmap/interfaces';
import { RoadmapInputInterface } from 'views/roadmap/interfaces/roadmap.interface';

import { API_TAGS, baseApi } from './base.api';

export interface SaveRoadmapResponse extends RoadmapInterface {
  id: string;
}

export const roadmapApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoadmap: builder.query<RoadmapInterface, { userId: string }>({
      query: (args) => ({
        variables: args,
        document: GetRoadmapDocument
      }),
      transformResponse: (response: { getRoadmap; }) => response.getRoadmap,
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.ROADMAP]
    }),
    saveRoadmap: builder.mutation<SaveRoadmapResponse, RoadmapInputInterface>({
      query: args => ({
        variables: { roadmap: args },
        document: SaveRoadmapDocument
      }),
      transformResponse: ({ saveRoadmap: res }: {  saveRoadmap: SaveRoadmapResponse }) => res,
    }),
    deleteRoadmap: builder.mutation<null, { userId: string }>({
      query: args => ({
        variables: args,
        document: DeleteRoadmapDocument
      }),
    }),
    
  }),
});

export const { 
  useGetRoadmapQuery,
  useSaveRoadmapMutation,
  useLazyGetRoadmapQuery,
  useDeleteRoadmapMutation
} = roadmapApi;
