import { Link } from 'common/otto-ui/link';
import { MapDataToTableConfigItemInterface } from 'common/otto-ui/table/map-data-to-table.mapper';
import { format, parseISO } from 'date-fns';
import { ReactNode } from 'react';
import { isValidDate } from 'utils/check-date-is-valid';

export const tableMapFieldToComponent = (
  key: MapDataToTableConfigItemInterface,
  item: any | Record<string, unknown>,
): ReactNode => {
  const { dataTarget, type, link, getCellData, render } = key;

  if (!item[dataTarget] && !render) {
    return item[dataTarget];
  }

  if (type) {
    switch (type) {
      case 'url':
        return (
          <Link href={item[dataTarget]} target="_blank">
            {getCellData ? getCellData(item[dataTarget]) : item[dataTarget]}
          </Link>
        );
      case 'color':
        return (
          <div className="flex items-center text-sm text-grey2 font-semibold tracking-wide dark:text-inputBg">
            <div
              className="rounded-sm w-4.5 h-4.5 border border-solid border-line mr-2"
              style={{ backgroundColor: item[dataTarget].toString() }}
            />
            {item[dataTarget].toUpperCase()}
          </div>
        );
      case 'tel':
        return (
          <Link href={`tel:${item[dataTarget]}`} className="whitespace-nowrap">
            {item[dataTarget]}
          </Link>
        );
      case 'date': {
        const date = parseISO(item[dataTarget]);
        const valueToDisplay = isValidDate(date) ? format(date, 'dd/MM/yyyy') : 'Invalid Date';
        return <div className="text-sm tracking-wider font-normal text-title dark:text-white">{valueToDisplay}</div>;
      }
      case 'timestamp': {
        const date = parseISO(item[dataTarget]);
        const valueToDisplay = isValidDate(date)
          ? format(parseISO(item[dataTarget]), 'dd/MM/yyyy, HH-mm')
          : 'Invalid Date';
        return (
          <div className="text-sm tracking-wider font-normal whitespace-nowrap text-title dark:text-white">
            {valueToDisplay}
          </div>
        );
      }
      case 'boolean':
        return (
          <div className="text-sm tracking-wide font-normal text-title dark:text-white">
            {item[dataTarget].toString()}
          </div>
        );
      case 'email':
        return (
          <div className="text-sm tracking-wide font-semibold text-title dark:text-white">
            {getCellData ? getCellData(item[dataTarget]) : item[dataTarget]}
          </div>
        );
      case 'text':
        return (
          <div className="text-sm tracking-wide font-normal w-60 text-title dark:text-white">
            {getCellData ? getCellData(item[dataTarget]) : item[dataTarget]}
          </div>
        );
      case 'integer':
      case 'decimal':
        return <div className="text-sm tracking-wider font-normal text-title dark:text-white">{item[dataTarget]}</div>;
      case 'id':
        return (
          <div className="text-sm tracking-wider font-normal text-title dark:text-white">
            {item[dataTarget].slice(-6)}
          </div>
        );
      case 'entity':
        const entityData = getCellData ? getCellData(item[dataTarget]) : item[dataTarget]?.id.slice(-6);
        return (
          <Link href={`${link}/edit/${item[dataTarget]?.id}`} target="_blank">
            {entityData}
          </Link>
        );
      case 'entityGroup':
        const entityGroupIds = item[dataTarget]?.data.map((entity: any) => entity.id);
        return item[dataTarget].totalCount > 0 ? (
          <Link href={`${link}/?ids=${encodeURIComponent(entityGroupIds)}`} target="_blank">
            {item[dataTarget]?.totalCount}
          </Link>
        ) : (
          <div className="text-sm tracking-wide font-normal text-title dark:text-white">
            {item[dataTarget]?.totalCount}
          </div>
        );
      case 'renderCustom':
        return render(item);
      case 'varchar': {
        const value = item?.[key.label.toLowerCase()]?.toLowerCase();
        if (value) return value[0].toUpperCase() + value.substring(1); // Capitalize first letter

        return (
          <div className="text-sm tracking-wide font-normal text-title dark:text-white">
            {getCellData ? getCellData(item[dataTarget]) : item[dataTarget]}
          </div>
        );
      }
      default:
        return (
          <div className="text-sm tracking-wide font-normal text-title dark:text-white">
            {getCellData ? getCellData(item[dataTarget]) : item[dataTarget]}
          </div>
        );
    }
  }

  return (
    <div className="text-sm tracking-wide font-normal text-title dark:text-white">
      {getCellData ? getCellData(item[dataTarget]) : item[dataTarget].toString()}
    </div>
  );
};
