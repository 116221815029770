import { Popover, StatefulMenu } from "@otto-finance/ui";
import { useDisclosure } from "common/roq-hooks";
import { ReactNode, memo, useCallback } from "react";

// TODO: import "MenuItems" from @otto-finance/ui
type Item = {
  id: string ;
  label: string;
  disabled?: boolean;
}

interface DropdownDrops {
  children: ReactNode;
  options: Item[];
  onChange: (id: string) => void;
}

export const Dropdown = memo(({ options, children, onChange }: DropdownDrops) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const onSelect = useCallback(async ({ item }: { item: Item; }) => {
    onChange(item.id);
    onClose();
  }, []);
  return (
    <Popover
      isOpen={isOpen}
      onClick={onToggle}
      onClickOutside={onClose}
      content={(
        <StatefulMenu
          onItemSelect={onSelect}
          items={
            options.map(t => ({
              id: t.id,
              label: t.label,
              disabled: t.disabled
            }))}
        />
      )}
    >
      <div>{children}</div>
    </Popover>
  );
});
