import { camelize, decamelize } from 'humps';
import { capitalizeFirstLetter } from 'utils/capitalize-first-letter';

export const capitalizeString = (title: string): string | null => {
  /**
   * Takes any kind of string and capitalises the first letter of each word e.g.
   *
   * Camelcase: 'homeImprovements' -> 'Home Improvements'
   * Snakecase: 'office_maintenance' -> 'Office Maintenance'
   * All Capitlised: 'ATM' -> 'ATM'
   *
   */
  if (title) {
    if (title.toUpperCase() === title) return title;
    const camelizedTitle = camelize(title);
    const formattedTitle = decamelize(camelizedTitle, { separator: ' ' });
    return formattedTitle.split(' ').map(capitalizeFirstLetter).join(' ');
  }
  return null;
};
