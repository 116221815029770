import { gql } from 'graphql-request';
import { userModel } from './user.document';

export const CommunityConnectDocument = gql`
  mutation DiscourseConnect($sig: String!, $sso: String!) {
    discourseConnect(sig: $sig, sso: $sso)
  }
`;

export const ValidateTokenDocument = gql`
  query ValidateToken($input: InvitationTokenValidationDto!) {
    validateToken(input: $input) {
      message
      userId
    }
  }
`;

export const TokenRefreshDocument = gql`
  query TokenRefresh {
    tokenRefresh {
      accessToken
      email
      id
      refreshToken
    }
  }
`;

export const AcceptInviteDocument = gql`
  mutation AcceptInvite($input: InvitationAcceptDto!) {
    acceptInvite(input: $input) {
      accessToken
      refreshToken
      calendarConnected
      user {
        ${userModel}
        termsSettings {
          termsAndConditions
          privacyPolicy
          assetConsent
          coachingConsent
        }
      }
    }
  }
`;

export const LoginDocument = gql`
  mutation Login($data: AuthLoginDto!) {
    login(input: $data) {
      accessToken
      refreshToken
      calendarConnected
      user {
        ${userModel}
        createdAt
      }
    }
  }
`;

export const LoginGuestDocument = gql`
  mutation LoginGuest($token: String!) {
    loginGuest(token: $token) {
      accessToken
      refreshToken
      guest
      user {
        ${userModel}
        createdAt
      }
    }
  }
`;

export const SignupDocument = gql`
  mutation Signup($input: InvitationInviteDto!) {
    createAndInviteCoach(input: $input) {
      id
      firstName
      lastName
      email
      coachMetaInfoId
    }
  }
`;

export const ResetPasswordDocument = gql`
  mutation ResetPassword($data: AuthRestorePasswordDto!) {
    restorePassword(input: $data)
  }
`;

export const ChangePasswordDocument = gql`
  mutation ChangePassword($data: AuthChangePasswordDto!) {
    changePassword(input: $data)
  }
`;

export const ForgotPasswordDocument = gql`
  mutation ChangePassword($data: AuthForgotPasswordDto!) {
    forgotPassword(input: $data)
  }
`;

export const LogoutDocument = gql`
  mutation Logout {
    logout
  }
`;
