import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ROUTE_ENUM } from 'configuration/data/routers';
import store from 'configuration/redux/store';
import Router from 'next/router';
import { logoutOnExpiredAction } from 'slices/auth-management/auth.slice';
import { hasTokenExpired } from 'utils';

let clientSingleton;

export function apolloClient(): any {
  if (!clientSingleton) {
    const httpLink = createHttpLink({
      uri: `${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql`,
    });

    const authLink = setContext((_, { headers }) => {
      const { auth } = store.getState();
      const token = auth ? auth.accessToken : null;
      const timezone = auth?.userData?.timezone || null;
      if (token) {
        if (hasTokenExpired(token)) {
          void Router.push(ROUTE_ENUM.LOGIN);
          store.dispatch(logoutOnExpiredAction());
        }
      }
      return {
        headers: {
          ...headers,
          locale: Router.locale,
          timezone: timezone ? timezone : 'UTC',
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    const errorLink = onError(({ graphQLErrors }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          const statusCode = err.extensions?.response?.statusCode || err.extensions?.exception?.status;
          switch (statusCode) {
            case 401: {
              void Router.push(ROUTE_ENUM.LOGIN);
              // TODO: This action is overridden by pending auth actions that use the same error reducer.
              // This logic will change when we remove the Apollo client.
              store.dispatch(logoutOnExpiredAction());
            }
          }
        }
      }
    });

    clientSingleton = new ApolloClient({
      link: from([authLink, errorLink, httpLink]),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only', // We don't need to use Apollo's cache as we have the Redux Store
        },
      },
    });
  }

  return clientSingleton;
}
