import clsx from 'clsx';
import { Card, Typography } from 'common/otto-ui';
import { IconProps } from 'common/otto-ui/icons';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

export enum TabVariant {
  standard = 'standard',
  menu = 'menu',
}

interface Props {
  data: {
    label: string;
    icon?: React.FC<IconProps> | null;
    onClick: () => void;
  }[];
  defaultIndex?: number;
  variant?: TabVariant;
  isOpen?: boolean;
  onClose?: () => void;
}

const classes = {
  textWrapper: clsx('flex'),
  item: clsx('cursor-pointer relative flex flex-col items-center'),
  bar: clsx(
    'relative bg-background-light-background8 rounded-md h-1 mt-2 cursor-pointer',
    'dark:bg-background-dark-background4',
  ),
  barOverlay: clsx('h-full absolute w-[7rem] rounded-full bg-background-light-background2', 'dark:bg-white'),
  menuItemContainer: (isSelected: boolean) =>
    clsx('flex items-center space-x-3', isSelected ? 'text-primary' : 'primary'),
  menuBackdrop: (isOpen: boolean) =>
    clsx(isOpen ? 'fixed' : 'hidden', 'z-1 fixed left-0 top-0 w-full h-screen opacity-60'),
  menuCard: (isOpen: boolean) => clsx(isOpen ? 'absolute' : 'hidden', 'z-100 left-0 shadow-shadow1'),
};

const ICON_SIZE = 20;

export const TextTab: React.FC<Props> = ({
  data,
  defaultIndex = 0,
  variant = TabVariant.standard,
  isOpen = false,
  onClose,
}) => {
  const [selectedTab, setSelectedTab] = useState(defaultIndex);
  useEffect(() => {
    setSelectedTab(defaultIndex);
  }, [defaultIndex]);

  if (variant === TabVariant.menu) {
    return (
      <>
        <div className={classes.menuBackdrop(isOpen)} onClick={() => onClose && onClose()} />
        <Card className={classes.menuCard(isOpen)}>
          <div className="space-y-8">
            {data.map((item, key) => {
              const Icon = item.icon || null;
              return (
                <div
                  key={key}
                  onClick={() => {
                    setSelectedTab(key);
                    item.onClick();
                  }}
                  className={classes.menuItemContainer(selectedTab === key)}
                >
                  {Icon && <Icon width={ICON_SIZE} height={ICON_SIZE} />}
                  <Typography className="!font-medium">{item.label}</Typography>
                </div>
              );
            })}
          </div>
        </Card>
      </>
    );
  }

  return (
    <div>
      <div className={classes.textWrapper}>
        {data.map((item, key) => (
          <div
            key={key}
            className={classes.item}
            onClick={() => {
              setSelectedTab(key);
              item.onClick();
            }}
            style={{
              width: `7rem`,
            }}
          >
            <div className="w-[7rem] text-center">
              <Typography variant="caption" className="!font-light">
                {item.label}
              </Typography>
            </div>
          </div>
        ))}
      </div>
      <div
        className={classes.bar}
        style={{
          width: `${data.length * 7}rem`,
        }}
      >
        <motion.div
          className={classes.barOverlay}
          animate={{ left: `${selectedTab * 7}rem` }}
          transition={{ duration: 0.2 }}
        />
      </div>
    </div>
  );
};
