import { useCallback, useState } from 'react';

interface DisclosureInterface {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
}

export const useDisclosure = (defaultIsOpen = false): DisclosureInterface => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);
  const onToggle = () => setIsOpen(s => !s);

  return {
    isOpen,
    onOpen,
    onClose,
    onToggle,
  };
};
