/**
 * Mapping between financial goals and their respective icons and names
 */
export const FINANCIAL_GOAL_ITEMS = {
  buy_a_house: {
    title: 'Buy a House',
    icon: 'Home',
  },
  buy_to_let: {
    title: 'Buy to Let',
    icon: 'Juggle',
  },
  buy_a_car: {
    title: 'Buy a Car',
    icon: 'Car',
  },
  go_to_school: {
    title: 'Go to School',
    icon: 'University',
  },
  have_a_child: {
    title: 'Have a child',
    icon: 'Pram',
  },
  travel: {
    title: 'Travel',
    icon: 'Ship',
  },
  eliminate_debt: {
    title: 'Eliminate Debt',
    icon: 'Tax',
  },
  get_married: {
    title: 'Get Married',
    icon: 'Marriage',
  },
  sabbatical: {
    title: 'Sabbatical',
    icon: 'TimeOff',
  },
  invest_in_business: {
    title: 'Invest in Business',
    icon: 'Flowers',
  },
  move_out: {
    title: 'Move Out',
    icon: 'MovingVan',
  },
  relocate: {
    title: 'Relocate',
    icon: 'Suitcase',
  },
  retire: {
    title: 'Retire',
    icon: 'Holiday',
  },
  donate_to_charity: {
    title: 'Donate to Charity',
    icon: 'Save',
  },
  dream_purchase: {
    title: 'Dream Purchase',
    icon: 'Dreaming',
  },
  user_defined: {
    title: 'Other',
    icon: 'Other',
  },
};