
import { PayloadAction } from "@reduxjs/toolkit";
import { useAppDispatch } from "configuration/redux/store";
import { confirmAlertActions, ConfirmAlertOptiomsInterface } from "slices/confirm-alert";
import { confirmationAlertAction } from "slices/confirm-alert/actions";

interface UseConfirmAlert {
  open: (args?: ConfirmAlertOptiomsInterface) => Promise<boolean>;
  confirm: () => void;
  decline: () => void;
}

export const useConfirmAlert = (): UseConfirmAlert => {
  const dispatch = useAppDispatch();
  const open = async (args?: ConfirmAlertOptiomsInterface) => {
    await dispatch(confirmAlertActions.open(args));
    const action: unknown = await dispatch(confirmationAlertAction());
    return (action as PayloadAction<boolean>).payload;
  };
  const confirm = () => dispatch(confirmAlertActions.confirm());
  const decline = () => dispatch(confirmAlertActions.decline());
  return { open, confirm, decline };
};
