import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const MortgageIcon: React.FC<IconProps> = ({ color = 'currentColor', width = 17, height = 18 }) => (
  <svg width={width} height={height} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g stroke={color} strokeWidth="0.688696" strokeLinejoin="round">
      <path d="M2.71511 10.9102H1V17.3895H2.71511V10.9102Z" />
      <path d="M2.71484 11.9584L3.80235 11.3181C4.04581 11.1752 4.32297 11.0998 4.60528 11.0996H4.97625C5.20051 11.0997 5.4222 11.1474 5.62672 11.2394L6.09933 11.4528C6.21148 11.5035 6.32916 11.541 6.44998 11.5646L7.51716 11.7704C7.61682 11.7894 7.71807 11.7987 7.81952 11.7984H8.32771C8.53732 11.7967 8.74519 11.8365 8.93933 11.9156C9.13348 11.9946 9.31005 12.1113 9.45886 12.259C9.60767 12.4066 9.72579 12.5822 9.80639 12.7758C9.88699 12.9693 9.92848 13.1768 9.92848 13.3864V13.3864" />
    </g>
    <g strokeWidth="0.688696" strokeLinecap="round" strokeLinejoin="round">
      <path
        d="M5.3828 14.3397L5.42345 14.3041C6.11216 13.7139 6.98876 13.3887 7.89575 13.3869H10.8C11.4723 13.3894 12.1332 13.2141 12.7159 12.8787L14.7714 11.6819C14.9209 11.5946 15.0977 11.5668 15.2668 11.604C15.4358 11.6412 15.5846 11.7407 15.6836 11.8826V11.8826C15.7831 12.0259 15.8246 12.2016 15.7996 12.3742C15.7746 12.5468 15.685 12.7035 15.549 12.8126L11.8367 15.8007C11.7489 15.8714 11.645 15.9193 11.5343 15.9405L7.57306 16.7027C7.5308 16.7067 7.48827 16.7067 7.44601 16.7027H2.71484"
        stroke={color}
      />
      <path d="M4.65979 10.2337V6.97367H3.28516L9.15973 1L14.8031 6.97367H13.4056V11.3262" stroke={color} />
      <path
        d="M7.72359 7.29093C8.13616 7.29093 8.47061 6.95647 8.47061 6.5439C8.47061 6.13133 8.13616 5.79688 7.72359 5.79688C7.31102 5.79688 6.97656 6.13133 6.97656 6.5439C6.97656 6.95647 7.31102 7.29093 7.72359 7.29093Z"
        stroke={color}
      />
      <path
        d="M10.583 10.1523C10.9955 10.1523 11.33 9.8178 11.33 9.40523C11.33 8.99266 10.9955 8.6582 10.583 8.6582C10.1704 8.6582 9.83594 8.99266 9.83594 9.40523C9.83594 9.8178 10.1704 10.1523 10.583 10.1523Z"
        stroke={color}
      />
      <path d="M7.125 9.99669L11.1777 5.94141" stroke={color} />
    </g>
  </svg>
);
