import clsx from 'clsx';
import { ButtonHTMLAttributes, ReactNode } from 'react';

export enum FloatingActionButtonColorEnum {
  'PRIMARY' = 'primary',
  'SECONDARY' = 'secondary',
  'GREY' = 'grey',
}

export enum FloatingActionButtonSizeEnum {
  'SMALL' = 'small',
  'MEDIUM' = 'medium',
  'LARGE' = 'large',
}

export interface FloatingActionButtonInterface extends ButtonHTMLAttributes<HTMLButtonElement> {
  /** Background color of component */
  color?: Lowercase<keyof typeof FloatingActionButtonColorEnum>;
  /** Label of component */
  label?: string;
  /** Size of a component */
  size?: Lowercase<keyof typeof FloatingActionButtonSizeEnum>;
  /** Icon inside a button*/
  icon?: ReactNode;
}

/** Floating action button used where button position on screen shoud be fixed */
export const FloatingActionButton = ({
  className,
  type,
  disabled,
  color = FloatingActionButtonColorEnum.PRIMARY,
  label,
  size = FloatingActionButtonSizeEnum.LARGE,
  onClick,
  icon,
  ...props
}: FloatingActionButtonInterface) => {
  const mainClassName = clsx(
    `rounded-full flex flex-row items-center shadow-lg cursor-pointer h-8
    focus:outline-none relative overflow-hidden box-content`,
    {
      'bg-primary dark:bg-primary-lightest': color === FloatingActionButtonColorEnum.PRIMARY,
      'bg-secondary ': color === FloatingActionButtonColorEnum.SECONDARY,
      'bg-placeholder dark:bg-grey2 text-body dark:text-offWhite': color === FloatingActionButtonColorEnum.GREY,
      'text-offWhite dark:text-body': color !== FloatingActionButtonColorEnum.GREY,
      'hover:opacity-80': !disabled,
      'p-1': size === FloatingActionButtonSizeEnum.SMALL,
      'p-2': size === FloatingActionButtonSizeEnum.MEDIUM,
      'p-3': size === FloatingActionButtonSizeEnum.LARGE,
      'px-8': label,
      'opacity-70': disabled,
      'w-8': !label,
      'justify-center': icon && !label,
    },
    className ?? 'm-2',
  );

  return (
    <button type={type} onClick={onClick} className={mainClassName} disabled={disabled} {...props}>
      {icon}
      {label && <p className="px-1 text-lg font-bold tracking-wider">{label}</p>}
    </button>
  );
};
