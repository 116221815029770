import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const DoubleChevronRightIcon: React.FC<IconProps> = ({ height = 14, width = 14, color = 'currentColor' }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g stroke={color} strokeWidth="2" strokeLinecap="round">
      <path d="M1 12.25L6.58819 6.86139C6.81118 6.64637 6.80606 6.28766 6.57704 6.07908L1 0.999998" />
      <path d="M7 12.25L12.5882 6.86139C12.8112 6.64637 12.8061 6.28766 12.577 6.07908L7 0.999998" />
    </g>
  </svg>
);
