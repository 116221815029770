import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const SunIcon: React.FC<IconProps> = ({ color = 'currentColor', size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g
      stroke={color}
      strokeWidth="0.965802"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M7.50066 10.9897C9.5009 10.9897 11.1224 9.36821 11.1224 7.36797C11.1224 5.36773 9.5009 3.74622 7.50066 3.74622C5.50042 3.74622 3.87891 5.36773 3.87891 7.36797C3.87891 9.36821 5.50042 10.9897 7.50066 10.9897Z" />
      <path d="M7.5 1.81455V0.6073" />
      <path d="M3.57241 3.44106L2.71875 2.5874" />
      <path d="M1.94749 7.36792H0.740234" />
      <path d="M3.57241 11.2947L2.71875 12.1483" />
      <path d="M7.5 12.9213V14.1285" />
      <path d="M11.4277 11.2947L12.2814 12.1483" />
      <path d="M13.0527 7.36792H14.26" />
      <path d="M11.4277 3.44106L12.2814 2.5874" />
    </g>
  </svg>
);

