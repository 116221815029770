import clsx from 'clsx';
import { AvatarDefaultIcon } from 'common/otto-ui/avatar/avatar-default-icon';
import React, { useState } from 'react';
import { Image } from 'components';

interface Props {
  size?: string;
  opacity?: string;
  src?: string;
  gradientBorder?: boolean;
  alt?: string;
}

export const CoachAvatar = ({ size, opacity, src, gradientBorder = true, alt = '' }: Props): JSX.Element => {
  const classes = {
    wrapper: clsx(
      'relative',
      size ?? 'w-20 h-20',
      opacity ?? 'opacity-30',
      gradientBorder && 'bg-gradient-to-r to-GradStart from-GradEnd',
      'p-2px rounded-full w-20 h-20 flex items-center justify-center',
    ),
    innerWrapper: clsx(
      'flex rounded-full items-center bg-white w-full h-full overflow-hidden',
      'dark:bg-background-dark-background3',
    ),
    image: clsx('!object-cover overflow-hidden w-full h-full'),
  };

  const [imageError, setImageError] = useState(false);
  const image = src ? <Image src={src} alt={alt} fill style={{ objectFit: 'cover' }} circle className={classes.image} onError={() => setImageError(true)} /> : null;

  return (
    <div className={classes.wrapper}>
      <div className={classes.innerWrapper}>{imageError || !image ? <AvatarDefaultIcon /> : image}</div>
    </div>
  );
};
