import { IconProps } from 'common/otto-ui/icons/icon.type';

export const BookIcon = ({ size = 24, color = 'currentColor' }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M4 19.5263C4 18.8633 4.26339 18.2274 4.73223 17.7585C5.20107 17.2897 5.83696 17.0263 6.5 17.0263H20" />
      <path d="M6.5 2.02631H20V22.0263H6.5C5.83696 22.0263 5.20107 21.7629 4.73223 21.2941C4.26339 20.8252 4 20.1893 4 19.5263V4.52631C4 3.86326 4.26339 3.22738 4.73223 2.75854C5.20107 2.2897 5.83696 2.02631 6.5 2.02631V2.02631Z" />
    </g>
  </svg>
);
