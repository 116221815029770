import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const MoonIcon: React.FC<IconProps> = ({ color = 'currentColor', size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1136 7.75232C11.1521 7.97288 10.1503 7.94559 9.20215 7.673C8.25405 7.40041 7.39073 6.89146 6.69316 6.19389C5.99559 5.49633 5.48664 4.633 5.21406 3.6849C4.94147 2.7368 4.91417 1.73499 5.13473 0.773455L5.13466 0.773438C4.12235 1.00696 3.191 1.5072 2.43732 2.22222C1.68363 2.93725 1.13509 3.84099 0.848639 4.83961C0.562191 5.83824 0.54828 6.89533 0.808353 7.90115C1.06842 8.90697 1.593 9.82483 2.32761 10.5594C3.06221 11.294 3.98008 11.8186 4.98589 12.0787C5.9917 12.3388 7.0488 12.3249 8.04743 12.0384C9.04605 11.752 9.94979 11.2034 10.6648 10.4497C11.3798 9.69607 11.8801 8.76472 12.1136 7.75241L12.1136 7.75232Z"
      stroke={color}
      strokeWidth="0.82331"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
