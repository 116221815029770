interface OptionProps {
  currency?: string;
  maximumFractionDigits?: number;
  locale?: string;
}

export const currencyFormatter = (value: number, options?: OptionProps): string => {
  const currency = options?.currency || 'GBP';
  const maximumFractionDigits = options?.maximumFractionDigits || 0;
  const locale = options?.locale || 'en-GB';

  if (isNaN(value)) {
    return '';
  }

  try {
    const currencyFormat = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits,
    });

    return currencyFormat.format(value);
  } catch {
    /**
     * For new currencies (such as Crypto) that are not supported by the browser
     * we simply prefix them to the value e.g. SHIB 1000
     */
    return `${currency} ${value}`;
  }
};
