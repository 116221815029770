import { MutationOptions } from "@apollo/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestGql } from "utils/request-gql";
  
export interface RedeemAccessTokenVariablesInterface {
  code: string;
  userId: string;
}

export interface RedeemAccessTokenActionRequestInterface extends MutationOptions  {
  variables: RedeemAccessTokenVariablesInterface;
}
  export const redeemAccessTokenAction = createAsyncThunk(
    'calendar/redeem-token',
    async (
      request: RedeemAccessTokenActionRequestInterface,
      thunkApi,
    ): Promise<any> =>
      await requestGql<any>(request, thunkApi, 'redeemAccessToken'),
  );
  