const primaryColor1 = '#53DADA';
const primaryColor2 = '#61D47F';
const primaryColorGradient = [primaryColor1, primaryColor2];

const secondaryColor = '#FF2D6B';
const secondaryColorGradient = [secondaryColor, secondaryColor];

const graphLinePositive = 'hsl(157, 60%, 59%)';
const graphLineNegative = 'hsl(342, 100%, 59%)';

const currentColor = 'currentColor';

export const getLightColor = (pos = 0, alpha = 1) => `hsla(${pos}, 76%, 90%, ${alpha})`;

export const ColorTheme = {
  mainLayoutColor: '#F2F3F7',
  accentDefault: 'hsl(153.79deg 82.08% 58.43%)',
  primaryColorGradient,
  primaryColor1,
  primaryColor2,
  secondaryColor,
  graphLinePositive,
  graphLineNegative,
  currentColor,
  red: '#FF2D6C',
  red2: '#f9d4d4',
  redHsl: `342deg 100% 59%`,
  darkBlue2: '#21252c',
  lightGreen: '#4bbc7d',
  green2: '#D8F1E7',
  blue1: '#e9eaef',
  blue1Hover: '#dee0e9',
  blue2: '#D4DDF9',
  blue3: '#4F67B1',
  blue4: '#B0B1E0',
  blue5: '#0547ff',
  light: {
    base: '#252926',
    background7: '#CDD3CF',
  },
  dark: {
    base: '#c9d1d9',
    background7: '#A7AAB1'
  },
  secondaryColorGradient,
  dropShadow: '0px 100px 80px rgb(0 0 0 / 7%), 0px 41.7776px 33.4221px rgb(0 0 0 / 5%), 0px 22.3363px 17.869px rgb(0 0 0 / 4%), 0px 12.5216px 10.0172px rgb(0 0 0 / 4%), 0px 6.6501px 5.32008px rgb(0 0 0 / 3%), 0px 2.76726px 2.21381px rgb(0 0 0 / 2%)',
  purpleLinearBackground: 'linear-gradient(246deg, rgba(103, 18, 255, 0.25) 34.71%, rgba(211, 185, 254, 0.33) 91.6%)',
  purpleLinearBackground2: 'linear-gradient(261deg, rgb(31 114 251 / 7%) 34.71%, rgb(207 207 207 / 15%) 91.6%)',
  conicGradientPurple: 'conic-gradient(from 2.2rad at 50% 50%, #E2494930, #9F15150A)',
  background50: '#F9FAF9',
  background10: '#f7fafd',
  gray50: '#E7E9EE',
  gray2: '#e6e6e6',
  blueGray2: '#e6e9f3',
  linearGradient3: 'linear-gradient(90deg, #F3FAF7 0%, #FEE 65%, #EDEFFA 100%)',
  linearGradient4: 'linear-gradient(90deg, #3EE568, #6640FF)'
};
/**
 * Get HSL color
 * @param c HSL color values ie. `342deg 100% 59%`
 * @param o Opacity percentage ie `70`, defaults to `100`
 * @returns 
 */
export const getColorHsla = (c: string, o = 100): string => `hsl(${ColorTheme.redHsl} / ${o}%)`;
