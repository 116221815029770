import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const EventsIcon = ({ color = 'currentColor', size = 24 }: IconProps): JSX.Element => (
  <svg width={size} height={size} viewBox="0,0,256,256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <g transform="scale(2,2)">
        <path d="M34,1c-1.7,0 -3,1.3 -3,3v7h-27c-1.7,0 -3,1.3 -3,3c0,1.7 1.3,3 3,3h110c3.9,0 7,3.1 7,7v90c0,3.9 -3.1,7 -7,7h-100c-3.9,0 -7,-3.1 -7,-7v-70c0,-3.9 3.1,-7 7,-7h90c1.7,0 3,-1.3 3,-3c0,-1.7 -1.3,-3 -3,-3h-90c-7.2,0 -13,5.8 -13,13v70c0,7.2 5.8,13 13,13h100c7.2,0 13,-5.8 13,-13v-90c0,-7.2 -5.8,-13 -13,-13h-17v-7c0,-1.7 -1.3,-3 -3,-3c-1.7,0 -3,1.3 -3,3v7h-54v-7c0,-1.7 -1.3,-3 -3,-3zM29,51c-6.1,0 -11,4.9 -11,11c0,6.1 4.9,11 11,11c6.1,0 11,-4.9 11,-11c0,-6.1 -4.9,-11 -11,-11zM64,51c-6.1,0 -11,4.9 -11,11c0,6.1 4.9,11 11,11c6.1,0 11,-4.9 11,-11c0,-6.1 -4.9,-11 -11,-11zM29,57c2.8,0 5,2.2 5,5c0,2.8 -2.2,5 -5,5c-2.8,0 -5,-2.2 -5,-5c0,-2.8 2.2,-5 5,-5zM64,57c2.8,0 5,2.2 5,5c0,2.8 -2.2,5 -5,5c-2.8,0 -5,-2.2 -5,-5c0,-2.8 2.2,-5 5,-5zM64,86c-6.1,0 -11,4.9 -11,11c0,6.1 4.9,11 11,11c6.1,0 11,-4.9 11,-11c0,-6.1 -4.9,-11 -11,-11zM99,86c-6.1,0 -11,4.9 -11,11c0,6.1 4.9,11 11,11c6.1,0 11,-4.9 11,-11c0,-6.1 -4.9,-11 -11,-11zM64,92c2.8,0 5,2.2 5,5c0,2.8 -2.2,5 -5,5c-2.8,0 -5,-2.2 -5,-5c0,-2.8 2.2,-5 5,-5zM99,92c2.8,0 5,2.2 5,5c0,2.8 -2.2,5 -5,5c-2.8,0 -5,-2.2 -5,-5c0,-2.8 2.2,-5 5,-5z">
        </path>
      </g>
    </g>
  </svg>
);
