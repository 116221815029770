import { IconProps } from 'common/otto-ui/icons/icon.type';

export const GridIcon = ({ size = 24, color = 'currentColor' }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M21 14H14V21H21V14Z" />
      <path d="M10 14H3V21H10V14Z" />
      <path d="M21 3H14V10H21V3Z" />
      <path d="M10 3H3V10H10V3Z" />
    </g>
  </svg>
);
