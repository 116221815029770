import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';



export const LevelUpIcon:React.FC<IconProps> = ({ color = 'black', height = 55, width = 55}) => (
  <div>
    <svg width={width} height={height} viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M54.1666 54.9995H0.833332C0.372721 54.9995 0 54.6263 0 54.1661V0.832882C0 0.495968 0.202636 0.192014 0.514322 0.0630262C0.826821 -0.0647405 1.18489 0.00483942 1.42252 0.24369L54.7558 53.5769C54.9942 53.8154 55.0658 54.1734 54.9364 54.4851C54.8079 54.7964 54.5035 54.9995 54.1666 54.9995ZM1.66666 53.3328H52.1549L1.66666 2.84459V53.3328Z"
        fill={color}
      />
      <path
        d="M32.9598 45.5822H10.2515C9.79093 45.5822 9.41821 45.209 9.41821 44.7488V22.0405C9.41821 21.7036 9.62085 21.3997 9.93253 21.2707C10.2442 21.1429 10.6023 21.2125 10.8407 21.4513L33.549 44.1596C33.7875 44.3981 33.8591 44.7561 33.7297 45.0678C33.6011 45.3791 33.2968 45.5822 32.9598 45.5822ZM11.0849 43.9155H30.9481L11.0849 24.0522V43.9155Z"
        fill={color}
      />
      <path
        d="M4.61344 13.0472H0.833332C0.372721 13.0472 0 12.674 0 12.2138C0 11.7536 0.372721 11.3805 0.833332 11.3805H4.61344C5.07405 11.3805 5.44677 11.7536 5.44677 12.2138C5.44677 12.674 5.07405 13.0472 4.61344 13.0472Z"
        fill={color}
      />
      <path
        d="M6.17593 19.0424H0.833332C0.372721 19.0424 0 18.6693 0 18.2091C0 17.7489 0.372721 17.3757 0.833332 17.3757H6.17593C6.63655 17.3757 7.00927 17.7489 7.00927 18.2091C7.00927 18.6693 6.63655 19.0424 6.17593 19.0424Z"
        fill={color}
      />
      <path
        d="M4.30094 25.0381H0.833332C0.372721 25.0381 0 24.665 0 24.2048C0 23.7446 0.372721 23.3715 0.833332 23.3715H4.30094C4.76155 23.3715 5.13427 23.7446 5.13427 24.2048C5.13427 24.665 4.76155 25.0381 4.30094 25.0381Z"
        fill={color}
      />
      <path
        d="M6.69677 31.0334H0.833332C0.372721 31.0334 0 30.6602 0 30.2C0 29.7398 0.372721 29.3667 0.833332 29.3667H6.69677C7.15738 29.3667 7.5301 29.7398 7.5301 30.2C7.5301 30.6602 7.15738 31.0334 6.69677 31.0334Z"
        fill={color}
      />
      <path
        d="M4.0926 37.0286H0.833332C0.372721 37.0286 0 36.6555 0 36.1953C0 35.7351 0.372721 35.3619 0.833332 35.3619H4.0926C4.55322 35.3619 4.92594 35.7351 4.92594 36.1953C4.92594 36.6555 4.55322 37.0286 4.0926 37.0286Z"
        fill={color}
      />
      <path
        d="M6.12385 43.024H0.833332C0.372721 43.024 0 42.6508 0 42.1906C0 41.7304 0.372721 41.3573 0.833332 41.3573H6.12385C6.58446 41.3573 6.95718 41.7304 6.95718 42.1906C6.95718 42.6508 6.58446 43.024 6.12385 43.024Z"
        fill={color}
      />
      <path
        d="M6.69677 49.0196H0.833332C0.372721 49.0196 0 48.6464 0 48.1862C0 47.726 0.372721 47.3529 0.833332 47.3529H6.69677C7.15738 47.3529 7.5301 47.726 7.5301 48.1862C7.5301 48.6464 7.15738 49.0196 6.69677 49.0196Z"
        fill={color}
      />
      <path
        d="M21.5295 4.62958C31.7564 14.266 41.9239 24.3358 50.2292 33.0374C50.2971 33.1108 50.3485 33.1991 50.3789 33.2944L53.2874 41.5577C53.4638 42.0443 52.8967 42.612 52.4106 42.4354L44.6261 39.5026C44.5386 39.4697 44.458 39.418 44.3909 39.3526C34.439 29.3932 24.2502 19.4135 15.6698 10.9448C14.4691 9.15958 15.0199 6.91665 16.2472 5.69995C17.9093 4.16534 19.6436 3.73258 21.5295 4.62958ZM17.2096 6.6633C16.4772 7.38933 16.0509 9.01291 16.7819 10.1527L20.9949 14.2844L25.2079 10.0671C23.7188 8.65132 22.2609 7.17319 20.7597 5.78559C19.5114 5.09911 18.2956 5.67 17.2096 6.6633ZM26.1916 11.0305L21.9787 15.2477C29.5061 22.5874 36.9651 29.9712 44.3909 37.4046L48.5397 33.2515C41.0987 25.1726 32.9496 17.5561 26.1916 11.0305ZM49.3311 34.4075L45.3961 38.3251L51.5124 40.6371L49.3311 34.4075Z"
        fill={color}
      />
    </svg>
  </div>
);
