import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const ConfettiSpringIcon: React.FC<IconProps> = ({ color = '#53DADA', height = 26, width = 11 }) => (
  <svg width={width} height={height} viewBox="0 0 11 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.18396 1.54934C5.18396 1.54934 10.0552 4.13904 9.63352 6.75002C9.21255 9.35674 4.25649 8.3455 3.77581 10.3758C3.29514 12.4061 8.49618 14.7525 7.62273 16.8421C6.74927 18.9317 1.33821 17.7916 0.857516 19.8219C0.376825 21.8522 4.46055 24.759 4.46055 24.759"
      stroke={color}
      strokeWidth="1.43038"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
