import { IconProps } from 'common/otto-ui/icons/icon.type';
import { useTheme } from 'common/roq-hooks/use-theme';
import React from 'react';

export const LiferaftIcon: React.FC<IconProps> = ({
  color = '#252926',
  height = 18,
  width = 18,
  darkColor = 'white',
}) => {
  const { isDark } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.1983 5.00452C16.1264 4.98055 16.0545 5.00452 16.0065 5.05248L13.9685 6.4671C13.8726 6.53902 13.8486 6.65891 13.8966 6.77879C14.3042 7.52207 14.496 8.33727 14.496 9.17645C14.496 10.711 13.8246 12.1735 12.6738 13.1805C12.5779 13.2525 12.5539 13.3963 12.6258 13.4922L13.9925 15.5542C14.0404 15.6022 14.0884 15.6501 14.1603 15.6501C14.1843 15.6501 14.1843 15.6501 14.2083 15.6501C14.2562 15.6501 14.3042 15.6262 14.3521 15.6022C16.3182 14.0437 17.4451 11.694 17.4451 9.17645C17.4451 7.76183 17.0855 6.34721 16.3662 5.12441C16.3182 5.07645 16.2702 5.0285 16.1983 5.00452ZM14.2322 15.0747L13.1293 13.3963C14.3042 12.3174 14.9755 10.7829 14.9755 9.17645C14.9755 8.31329 14.7837 7.49809 14.4241 6.73084L16.0545 5.57996C16.6299 6.68289 16.9416 7.90569 16.9416 9.17645C16.9416 11.4782 15.9586 13.5881 14.2322 15.0747Z"
        fill={isDark ? darkColor : color}
        stroke={isDark ? darkColor : color}
        strokeWidth="0.4"
      />
      <path
        d="M5.79384 1.7193C5.72191 1.74327 5.69793 1.79123 5.67395 1.86316C5.64998 1.93509 5.64998 2.00702 5.69793 2.05497L6.92074 4.18889C6.96869 4.26082 7.04062 4.30877 7.13652 4.30877C7.1605 4.30877 7.20845 4.30877 7.23243 4.28479C7.66401 4.11696 8.09559 3.99707 8.55114 3.92514C8.67103 3.90117 8.76693 3.80526 8.76693 3.68538V1.23977C8.76693 1.16784 8.74296 1.11988 8.695 1.07193C8.64705 1.02398 8.57512 1 8.50319 1C7.54413 1.09591 6.63302 1.33567 5.79384 1.7193ZM8.26342 3.51754C7.90378 3.58947 7.5681 3.6614 7.23243 3.80526L6.22541 2.07895C6.87278 1.8152 7.5681 1.62339 8.26342 1.52748V3.51754Z"
        fill={isDark ? darkColor : color}
        stroke={isDark ? darkColor : color}
        strokeWidth="0.4"
      />
      <path
        d="M9.22397 17.4015C10.5667 17.4015 11.9093 17.0658 13.0842 16.4424C13.1322 16.4184 13.1801 16.3465 13.2041 16.2985C13.2281 16.2266 13.2041 16.1547 13.1801 16.1067L11.8134 14.0447C11.7415 13.9488 11.6216 13.9009 11.5017 13.9488C10.7825 14.3085 10.0152 14.4763 9.2 14.4763C6.29883 14.4763 3.92514 12.1026 3.92514 9.20147C3.92514 7.57106 4.69239 6.01258 6.01111 5.00557C6.10701 4.93364 6.13099 4.78978 6.08304 4.69387L4.86023 2.55995C4.83625 2.512 4.76433 2.46405 4.6924 2.44007C4.62047 2.41609 4.54854 2.44007 4.50058 2.48802C2.31871 4.02253 1 6.54007 1 9.20147C1.02398 13.7091 4.6924 17.4015 9.22397 17.4015ZM4.57251 3.01551L5.57953 4.74182C4.26082 5.84475 3.46959 7.47515 3.46959 9.17749C3.46959 12.3424 6.05906 14.9319 9.22397 14.9319C10.0392 14.9319 10.8064 14.764 11.5257 14.4523L12.6286 16.1307C11.5737 16.6582 10.3988 16.9219 9.22397 16.9219C4.95614 16.9219 1.50351 13.4453 1.50351 9.20147C1.50351 6.75586 2.63041 4.47808 4.57251 3.01551Z"
        fill={isDark ? darkColor : color}
        stroke={isDark ? darkColor : color}
        strokeWidth="0.4"
      />
      <path
        d="M9.96491 1C9.89298 1 9.82105 1.02398 9.7731 1.07193C9.72515 1.11988 9.70117 1.16784 9.70117 1.23977V3.70936C9.70117 3.82924 9.79708 3.92514 9.91696 3.94912C11.1398 4.11696 12.2906 4.71637 13.1298 5.62748C13.1778 5.67544 13.2497 5.69941 13.2977 5.69941C13.3456 5.69941 13.3936 5.67544 13.4415 5.65146L15.4795 4.23684C15.5275 4.18889 15.5754 4.14093 15.5754 4.069C15.5754 3.99707 15.5754 3.92514 15.5275 3.87719C14.1368 2.24678 12.0988 1.19181 9.96491 1ZM13.3216 5.17193C12.4825 4.30877 11.3556 3.73333 10.1807 3.51754V1.52749C12.0029 1.76725 13.7292 2.65439 14.976 4.04503L13.3216 5.17193Z"
        fill={isDark ? darkColor : color}
        stroke={isDark ? darkColor : color}
        strokeWidth="0.4"
      />
    </svg>
  );
};
