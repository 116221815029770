import clsx from 'clsx';
import { InputHTMLAttributes } from 'react';

export enum SwitchTextPositionEnum {
  AFTER = 'after',
  BEFORE = 'before',
  ABOVE = 'above',
  BELOW = 'below',
}
export enum SwitchSizeEnum {
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}
export interface SwitchInterface extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  /** Size of switch */
  size?: Lowercase<keyof typeof SwitchSizeEnum>;
  color?: string;
  /** Label for component */
  label?: string;
  /** Text position of label for switch */
  textPosition?: Lowercase<keyof typeof SwitchTextPositionEnum>;
}

/** Switch controlled component with checked state, like checkbox */
export const Switch = ({
  className,
  size = SwitchSizeEnum.NORMAL,
  disabled,
  name,
  textPosition = SwitchTextPositionEnum.AFTER,
  checked,
  label,
  id,
  onClick,
  onChange,
}: SwitchInterface) => {
  const trackClassName = clsx('flex items-center rounded-full text-defaultBg dark:text-body p-0.5', {
    'w-12 h-6': size === SwitchSizeEnum.NORMAL,
    'w-16 h-8': size === SwitchSizeEnum.LARGE,
    'bg-primary dark:bg-blue-300 hover:bg-primary-dark dark:hover:bg-primary-normal': checked,
    'bg-line dark:bg-grey2': !checked,
    'hover:bg-placeholder dark:hover:bg-placeholder': !checked,
    'opacity-50': disabled,
  });

  const dotClassName = clsx('transition-all ease-in transform-gpu rounded-full bg-defaultBg dark:bg-label', {
    'w-5 h-full': size === SwitchSizeEnum.NORMAL,
    'w-7 h-full': size === SwitchSizeEnum.LARGE,
    'translate-x-8': checked && size === SwitchSizeEnum.LARGE,
    'translate-x-6': checked && size === SwitchSizeEnum.NORMAL,
  });

  const labelClassName = clsx({
    'ml-2': textPosition === SwitchTextPositionEnum.AFTER,
    'mr-2': textPosition === SwitchTextPositionEnum.BEFORE,
    'mt-2': textPosition === SwitchTextPositionEnum.BELOW,
    'mb-2': textPosition === SwitchTextPositionEnum.ABOVE,
    'text-body dark:text-defaultBg': !disabled,
    'text-grey2 dark:text-line': disabled,
  });

  const wrapperClassName = clsx('inline-flex items-center my-2 outline-none', {
    'flex-row': textPosition === SwitchTextPositionEnum.AFTER,
    'flex-row-reverse': textPosition === SwitchTextPositionEnum.BEFORE,
    'flex-col': textPosition === SwitchTextPositionEnum.BELOW,
    'flex-col-reverse': textPosition === SwitchTextPositionEnum.ABOVE,
    'cursor-pointer': !disabled,
    [className]: className,
  });

  return (
    <label className={wrapperClassName} htmlFor={id} tabIndex={0}>
      <div className={trackClassName}>
        <div className={dotClassName} />
      </div>
      <p className={labelClassName}>{label}</p>
      <input
        type="checkbox"
        name={name}
        className="w-0 h-0 opacity-0"
        id={id}
        disabled={disabled}
        checked={checked}
        onClick={onClick}
        onChange={onChange}
      />
    </label>
  );
};
