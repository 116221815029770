import clsx from 'clsx';
import { Fragment, HTMLAttributes, ReactNode } from 'react';

export interface SelectOptionGroupInterface extends HTMLAttributes<HTMLDivElement> {
  label?: ReactNode;
  children?: ReactNode;
}

export const SelectOptionGroup = ({
  label,
  className,
  children,
  ...props
}: SelectOptionGroupInterface) => (
  <Fragment>
    {label && (
      <div
        className={clsx('h-12 px-3 py-4 text-base text-title dark:text-offWhite font-semibold', className)}
        {...props}
      >
        {label}
      </div>
    )}
    {children}
  </Fragment>
);
