import { Modal, ModalBody, ModalButton, ModalHeader, ModalFooter } from "@otto-finance/ui";
import { ReactNode } from "react";

import { IconProps } from "../icons/icon.type";

interface DialogDrawerProps {
  children: ReactNode;
  isOpen: boolean;
  title: string;
  acceptLabel?: string;
  acceptDisabled?: boolean;
  acceptLoading?: boolean;
  icon?: React.FC<IconProps>;
  noButton?: boolean;
  onAccept?: () => void;
  onClose?: () => void;
}

export const DialogDrawer: React.FC<DialogDrawerProps> = ({
  isOpen,
  title,
  children,
  acceptLabel = 'Save',
  acceptDisabled = false,
  icon: Icon,
  acceptLoading,
  noButton,
  onClose,
  onAccept
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalHeader>{title}</ModalHeader>
    <ModalBody>{children}</ModalBody>
    {!noButton && (
      <ModalFooter>
        <ModalButton onClick={onAccept} shape="pill" isLoading={acceptLoading} disabled={acceptDisabled || acceptLoading}>{acceptLabel}</ModalButton>
      </ModalFooter>
    )}
  </Modal>
);
