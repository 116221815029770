import { LabelLarge, Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, ParagraphMedium, useTheme } from '@otto-finance/ui';
import { useSelector } from 'react-redux';
import { selectConfirmAlert } from 'slices/confirm-alert';
import { memo } from 'react';

import { useConfirmAlert } from './use-confirm-alert';

export const ConfirmAlert = memo(() => {
  const { isOpen, options } = useSelector(selectConfirmAlert);
  const { confirm, decline } = useConfirmAlert();
  const [css] = useTheme();
  return (
    <Modal
      isOpen={isOpen}
      closeable={false}
      onClose={decline}
      role="alertdialog"
      size="400px">
      <ModalHeader>
        <LabelLarge>{options.title}</LabelLarge>
      </ModalHeader>
      <ModalBody className={css({ minWidth: '320px' })}>
        <ParagraphMedium>{options.msg}</ParagraphMedium>
        {options.body && options.body}
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={decline} kind="tertiary" shape="pill">{options.declineLabel}</ModalButton>
        <ModalButton onClick={confirm} shape="pill">{options.confirmLabel}</ModalButton>
      </ModalFooter>
    </Modal>
  );
});

