import clsx from 'clsx';
import { RadioColorEnum, RadioInterface, RadioLabelPositionEnum } from 'common/otto-ui/radio/radio';
import { TextFieldStatusEnum } from 'common/otto-ui/text-field';
import { Typography } from 'common/otto-ui/typography';
import {
  Children,
  cloneElement,
  InputHTMLAttributes,
  isValidElement,
  ReactNode,
  ReactText,
} from 'react';

export interface RadioGroupInterface extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  value?: ReactText;
  helperText?: ReactNode;
  color?: Lowercase<keyof typeof RadioColorEnum>;
  status?: Lowercase<keyof typeof TextFieldStatusEnum>;
  radioLabelPosition?: Lowercase<keyof typeof RadioLabelPositionEnum>;
  children?: ReactNode;
}

export const RadioGroup = ({
  id,
  label,
  value,
  helperText,
  color,
  status,
  radioLabelPosition,
  className,
  children,
  ...props
}: RadioGroupInterface) => {
  const classes = {
    wrapper: clsx('flex flex-col justify-start items-start', className),
    helperText: clsx('text-xs font-medium tracking-wide leading-none mt-2', {
      'text-grey2 dark:placeholder': status === TextFieldStatusEnum.DEFAULT,
      'text-error': status === TextFieldStatusEnum.ERROR,
      'text-success': status === TextFieldStatusEnum.SUCCESS,
    }),
  };
  return (
    <div id={id} className={classes.wrapper}>
      {label && <Typography semiBold>{label}</Typography>}
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          const { value: childValue } = child.props || {};
          return cloneElement<RadioInterface>(child, {
            key: child.key ?? childValue,
            ...props,
            color,
            labelPosition: radioLabelPosition,
            checked: childValue === value,
            fullWidth: true,
          });
        }
        return child;
      })}
      {helperText && <p className={classes.helperText}>{helperText}</p>}
    </div>
  );
};
