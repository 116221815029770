import { Translate } from 'next-translate';
import getT from 'next-translate/getT';
import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';

interface TranslateContextInterface {
  t: Translate;
}

const TranslateContext = createContext<TranslateContextInterface>(null);

export const TranslateProvider: React.FC<{ ns: string, locale?: string; children?: ReactNode; }> = ({ ns, locale, children }) => {
  const t = useRef<Translate>(null);
  const [isReady, setReady] = useState(false);
  useEffect(() => {
    void getT(locale, ns)
      .then(_t => {
        t.current = _t;
        setReady(true);
      });
  }, []);
  return (
    <TranslateContext.Provider value={{ t: t.current }}>
      {isReady ? children : null}
    </TranslateContext.Provider>
  );
};

export const useTranslate = (): TranslateContextInterface => {
  const context = useContext(TranslateContext);
  return context;
};
