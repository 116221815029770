import { FormDatePickerField, FormTextField } from "components/form";

export const HoldingFormFields = () => (
  <>
    <FormTextField name="amount" type="number" step="any" label="Amount" min={0} required />
    <div>
      <FormDatePickerField name="date" label="Date" maxDate={new Date()} clearable />
    </div>
  </>
);
