import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const InfoIcon: React.FC<IconProps> = ({ height = 22, width = 22, color = 'currentColor' }) => (
  <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.5" cy="7.5" r="6.96429" stroke={color} strokeWidth="1.07143" />
    <path d="M7.49998 4.61527C7.18136 4.61527 6.92306 4.35697 6.92306 4.03835C6.92306 3.71972 7.18136 3.46143 7.49998 3.46143C7.81861 3.46143 8.0769 3.71972 8.0769 4.03835C8.0769 4.35697 7.81861 4.61527 7.49998 4.61527Z" fill={color} />
    <path d="M7.49998 5.76929C7.81729 5.76929 8.0769 6.02257 8.0769 6.33214V10.9757C8.0769 11.2852 7.81729 11.5385 7.49998 11.5385C7.18267 11.5385 6.92306 11.2852 6.92306 10.9757V6.33214C6.92306 6.0085 7.18267 5.76929 7.49998 5.76929Z" fill={color} />
  </svg>
);
