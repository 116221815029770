import { IconProps } from 'common/otto-ui/icons/icon.type';
import { ColorTheme } from 'configuration/ui';
import React from 'react';

export const SearchIcon: React.FC<IconProps> = ({ width = 16, height = 16, color = ColorTheme.currentColor }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.4395 12.8732C7.8696 12.8733 9.2588 12.3961 10.387 11.5173L14.6436 15.7739C14.9615 16.0809 15.4682 16.0721 15.7753 15.7542C16.0749 15.444 16.0749 14.9522 15.7753 14.6421L11.5188 10.3856C13.6994 7.57857 13.1916 3.5353 10.3846 1.3547C7.57756 -0.825908 3.53433 -0.31812 1.35372 2.48891C-0.826884 5.29593 -0.319097 9.3392 2.48793 11.5198C3.61801 12.3977 5.00846 12.874 6.4395 12.8732ZM3.01943 3.01795C4.9083 1.12904 7.97076 1.12901 9.85967 3.01788C11.7486 4.90675 11.7486 7.96921 9.85974 9.85812C7.97087 11.747 4.90841 11.7471 3.0195 9.85819C3.01947 9.85815 3.01947 9.85815 3.01943 9.85812C1.13056 7.98301 1.11943 4.93171 2.99454 3.04284C3.00282 3.03452 3.01111 3.02623 3.01943 3.01795Z"
      fill={color}
    />
  </svg>
);
