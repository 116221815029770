import { RefObject, SyntheticEvent, useCallback, useEffect } from "react";

export const useClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: (event: SyntheticEvent<HTMLElement, Event>) => void,
  isOff?: boolean,
): void => {
  useEffect(
    () => {
      if (!isOff) {
        document.addEventListener("mousedown", listener);
      }
      return () => {
        if (!isOff) {
          document.removeEventListener("mousedown", listener);
        }
      };
    },
    [ref, isOff]
  );
  const listener = useCallback((event) => {
    if (!ref.current || ref.current.contains(event.target)) {
      return;
    }
    handler(event);
  }, [handler]);
};
