import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const ActionsIcon: React.FC<IconProps> = ({ color = '#CDD3CF', height = 22, width = 22 }) => (
  <svg width={width} height={height} viewBox="0 0 3 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="1.5" cy="1.5" r="1.5" fill={color} />
    <circle cx="1.5" cy="15.5" r="1.5" fill={color} />
    <circle cx="1.5" cy="8.5" r="1.5" fill={color} />
  </svg>
);
