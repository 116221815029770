import { IconProps } from 'common/otto-ui/icons/icon.type';
import { useTheme } from 'common/roq-hooks/use-theme';
import React from 'react';

export const ProtectionLockIcon: React.FC<IconProps> = ({
  color = '#252926',
  height = 18,
  width = 13,
  darkColor = 'white',
}) => {
  const { isDark } = useTheme();
  return (
    <svg width={width} height={height} viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5 1C3.92522 1 1.83046 3.09476 1.83046 5.66955V8.41931H1.65143C1.01657 8.41931 0.5 8.93588 0.5 9.57091V15.7048C0.5 16.3397 1.01657 16.8563 1.65143 16.8563H11.3486C11.9834 16.8563 12.5 16.3397 12.5 15.7048V9.57091C12.5 8.93588 11.9834 8.41931 11.3486 8.41931H11.1695V5.66955C11.1695 3.09476 9.07478 1 6.5 1ZM2.4647 5.66955C2.4647 3.44441 4.27487 1.63425 6.5 1.63425C8.72513 1.63425 10.5353 3.44441 10.5353 5.66955V8.41931H2.4647V5.66955ZM11.8658 9.57091V15.7048C11.8658 15.9901 11.6338 16.222 11.3486 16.222H1.65143C1.36621 16.222 1.13425 15.9901 1.13425 15.7048V9.57091C1.13425 9.2857 1.36621 9.05356 1.65143 9.05356H2.14758H10.8524H11.3486C11.6338 9.05356 11.8658 9.2857 11.8658 9.57091Z"
        fill={isDark ? darkColor : color}
        stroke={isDark ? darkColor : color}
        strokeWidth="0.405418"
      />
      <path
        d="M6.50214 10.5693C5.64368 10.5693 4.94531 11.2678 4.94531 12.1263C4.94531 12.6153 5.17014 13.0633 5.55541 13.3567V14.3894C5.55541 14.5645 5.69724 14.7065 5.87254 14.7065H7.13204C7.30734 14.7065 7.44917 14.5645 7.44917 14.3894V13.3567C7.83413 13.0631 8.05896 12.6153 8.05896 12.1263C8.05896 11.2678 7.36059 10.5693 6.50214 10.5693ZM6.97008 12.916C6.87377 12.9732 6.81492 13.0768 6.81492 13.1887V14.0723H6.18966V13.1887C6.18966 13.0768 6.1305 12.9732 6.0345 12.916C5.74959 12.7469 5.57956 12.4518 5.57956 12.1263C5.57956 11.6175 5.9933 11.2036 6.50214 11.2036C7.01097 11.2036 7.42471 11.6175 7.42471 12.1263C7.42471 12.4517 7.25468 12.7469 6.97008 12.916Z"
        fill={isDark ? darkColor : color}
        stroke={isDark ? darkColor : color}
        strokeWidth="0.405418"
      />
    </svg>
  );
};
