import { QueryOptions } from "@apollo/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestGql } from "utils/request-gql";

export interface FetchSurveyVariablesInterface {
    name: string;
  }
  
  export interface FetchSurveyActionRequestInterface extends QueryOptions {
    variables: FetchSurveyVariablesInterface;
  }
  
  export const fetchSurveyAction = createAsyncThunk(
    'survey/fetch',
    async (
      request: FetchSurveyActionRequestInterface,
      thunkApi,
    ): Promise<any> =>
      await requestGql<any>(request, thunkApi, 'configuration'),
  );
  