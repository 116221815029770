import { createSelector, createSlice } from '@reduxjs/toolkit';
import { TierEnum } from 'configuration/data';
import { StoreInterface } from 'configuration/redux/store';
import { UserTypeEnum } from 'enums';
import {
  fetchSessionsAction,
  fetchSurveyAction,
  fetchSurveyDetailsAction,
  startSessionAction,
  stopSessionAction,
  submitSurveyResultAction,
} from 'slices/survey/actions';

interface ProgressBar {
  value: number;
  label: string;
}

export interface SurveyAnswerResultInterface {
  answer?: any;
  answers?: any;
}

export interface SurveyBirthDateResultInterface {
  day: number;
  month: number;
  year: number;
}

interface OnboardingDetails {
  data: {
    result: ProgressBar[] | string[] | SurveyAnswerResultInterface | SurveyBirthDateResultInterface | Record<string, unknown>;
    elementTitle: string;
    elementName: string;
    createdAt: string;
    sectionName: string;
  }[];
}

export interface SurveyConfigurationInterface {
  config: {
    name: string;
    title: string;
    sections: {
      name: string;
      title: string;
      pages: {
        name: string;
        elements: any[];
      }[];
    }[];
  };
  id: string;
  name: string;
}

export interface SurveySessionInterface {
  id: number;
  configuration: {
    id: string;
    name: string;
  };
  isCompleted: boolean;
}
export interface SurveyStateInterface {
  isLoading: boolean;
  error: any;
  surveyPage: number;
  sessionId: string | null;
  isCompleted: boolean;
  configuration: SurveyConfigurationInterface;
  detailsLoading: boolean;
  detailsError: any | null;
  result: null | OnboardingDetails;
  sessions: SurveySessionInterface[];
  forms: {
    id: string;
    role: UserTypeEnum;
    tier: TierEnum[];
    open: false,
  }[];
}

const initialState: SurveyStateInterface = {
  isLoading: false,
  error: null,
  surveyPage: 0,
  sessionId: null,
  isCompleted: false,
  configuration: null,
  detailsLoading: false,
  detailsError: null,
  result: null,
  sessions: [],
  forms: [
    {
      id: 'dC0qBVFQ',
      role: UserTypeEnum.CUSTOMER,
      tier: [TierEnum.LITE],
      open: false
    },
    {
      id: 'p8DQYTYY',
      role: UserTypeEnum.CUSTOMER,
      tier: Object.values(TierEnum).filter(f => f !== TierEnum.LITE),
      open: false
    }
  ]
};
export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    IncrementPageAction: (state) => {
      state.surveyPage = state.surveyPage + 1;
    },
    resetPageAction: (state) => {
      state.surveyPage = 0;
    },
    restartSessionAction: (state, { payload }) => {
      if (payload) {
        state.sessionId = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSurveyDetailsAction.fulfilled as any, (state, { payload }) => {
      state.result = payload;
      state.detailsLoading = false;
      state.detailsError = null;
    }).addCase(fetchSurveyDetailsAction.pending as any, (state) => {
      state.detailsLoading = true;
    }).addCase(fetchSurveyDetailsAction.rejected as any, (state, { payload }) => {
      state.detailsError = payload;
      state.detailsLoading = false;
    }).addCase(fetchSurveyAction.fulfilled as any, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.configuration = payload;
    }).addCase(fetchSurveyAction.pending as any, (state) => {
      state.isLoading = true;
    }).addCase(fetchSurveyAction.rejected as any, (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    }).addCase(startSessionAction.fulfilled as any, (state, { payload }) => {
      state.sessionId = payload.id;
      state.isCompleted = payload.isCompleted;
    }).addCase(submitSurveyResultAction.pending as any, (state) => {
      state.isLoading = true;
    }).addCase(submitSurveyResultAction.fulfilled as any, (state) => {
      state.isLoading = false;
    }).addCase(stopSessionAction.fulfilled as any, (state, { payload }) => {
      state.isCompleted = payload.isCompleted;
    }).addCase(fetchSessionsAction.fulfilled as any, (state, { payload }) => {
      const { data } = payload;
      state.sessions = data.sessions.data;
    });
  },
});

export default surveySlice.reducer;
export const { IncrementPageAction, resetPageAction, restartSessionAction } = surveySlice.actions;
export { fetchSurveyAction, startSessionAction, submitSurveyResultAction, stopSessionAction };
export const surveySelector = createSelector(
  (state: StoreInterface) => state,
  (values) => values.survey,
);
