import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const OttoIcon = ({ color = 'currentColor', height = 29, width = 88 }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 88 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <path d="M26.5703 14.502C26.5703 15.6973 26.4121 16.8516 26.0957 17.9648C25.791 19.0664 25.3574 20.1035 24.7949 21.0762C24.2324 22.0371 23.5527 22.916 22.7559 23.7129C21.959 24.5098 21.0801 25.1953 20.1191 25.7695C19.1582 26.332 18.1211 26.7656 17.0078 27.0703C15.8945 27.3867 14.7402 27.5449 13.5449 27.5449C12.3496 27.5449 11.1953 27.3867 10.082 27.0703C8.98047 26.7656 7.94336 26.332 6.9707 25.7695C6.00977 25.1953 5.13086 24.5098 4.33398 23.7129C3.53711 22.916 2.85156 22.0371 2.27734 21.0762C1.71484 20.1035 1.27539 19.0664 0.958984 17.9648C0.654297 16.8516 0.501953 15.6973 0.501953 14.502C0.501953 13.3066 0.654297 12.1523 0.958984 11.0391C1.27539 9.92578 1.71484 8.88867 2.27734 7.92773C2.85156 6.9668 3.53711 6.08789 4.33398 5.29102C5.13086 4.49414 6.00977 3.81445 6.9707 3.25195C7.94336 2.68945 8.98047 2.25586 10.082 1.95117C11.1953 1.63477 12.3496 1.47656 13.5449 1.47656C14.7402 1.47656 15.8945 1.63477 17.0078 1.95117C18.1211 2.25586 19.1582 2.68945 20.1191 3.25195C21.0801 3.81445 21.959 4.49414 22.7559 5.29102C23.5527 6.08789 24.2324 6.9668 24.7949 7.92773C25.3574 8.88867 25.791 9.92578 26.0957 11.0391C26.4121 12.1523 26.5703 13.3066 26.5703 14.502ZM21.5605 14.502C21.5605 13.4004 21.3496 12.3633 20.9277 11.3906C20.5059 10.4062 19.9316 9.55664 19.2051 8.8418C18.4902 8.11523 17.6406 7.54102 16.6562 7.11914C15.6836 6.69727 14.6465 6.48633 13.5449 6.48633C12.4316 6.48633 11.3887 6.69727 10.416 7.11914C9.44336 7.54102 8.59375 8.11523 7.86719 8.8418C7.14062 9.55664 6.56641 10.4062 6.14453 11.3906C5.72266 12.3633 5.51172 13.4004 5.51172 14.502C5.51172 15.6035 5.72266 16.6406 6.14453 17.6133C6.56641 18.5742 7.14062 19.418 7.86719 20.1445C8.59375 20.8711 9.44336 21.4453 10.416 21.8672C11.3887 22.2891 12.4316 22.5 13.5449 22.5C14.6465 22.5 15.6836 22.2891 16.6562 21.8672C17.6406 21.4453 18.4902 20.8711 19.2051 20.1445C19.9316 19.418 20.5059 18.5742 20.9277 17.6133C21.3496 16.6406 21.5605 15.6035 21.5605 14.502Z" />
      <path d="M38.8223 27C37.6621 27 36.5723 26.7832 35.5527 26.3496C34.5332 25.9043 33.6367 25.3008 32.8633 24.5391C32.1016 23.7656 31.498 22.8691 31.0527 21.8496C30.6191 20.8301 30.4023 19.7402 30.4023 18.5801V12.9902H28.0645V8.20898H30.4023V0.685547H35.1836V8.20898H42.4609V12.9902H35.1836V18.5801C35.1836 19.084 35.2773 19.5586 35.4648 20.0039C35.6523 20.4375 35.9102 20.8184 36.2383 21.1465C36.5664 21.4746 36.9531 21.7383 37.3984 21.9375C37.8438 22.125 38.3184 22.2188 38.8223 22.2188H42.4609V27H38.8223Z" />
      <path d="M54.6074 27C53.4473 27 52.3574 26.7832 51.3379 26.3496C50.3184 25.9043 49.4219 25.3008 48.6484 24.5391C47.8867 23.7656 47.2832 22.8691 46.8379 21.8496C46.4043 20.8301 46.1875 19.7402 46.1875 18.5801V12.9902H43.8496V8.20898H46.1875V0.685547H50.9688V8.20898H58.2461V12.9902H50.9688V18.5801C50.9688 19.084 51.0625 19.5586 51.25 20.0039C51.4375 20.4375 51.6953 20.8184 52.0234 21.1465C52.3516 21.4746 52.7383 21.7383 53.1836 21.9375C53.6289 22.125 54.1035 22.2188 54.6074 22.2188H58.2461V27H54.6074Z" />
      <path d="M79.1465 17.4199C79.1465 18.8262 78.8945 20.1387 78.3906 21.3574C77.8867 22.5645 77.2012 23.6133 76.334 24.5039C75.4668 25.3828 74.4473 26.0801 73.2754 26.5957C72.1152 27.0996 70.8672 27.3516 69.5312 27.3516C68.207 27.3516 66.959 27.0996 65.7871 26.5957C64.627 26.0801 63.6074 25.3828 62.7285 24.5039C61.8613 23.6133 61.1758 22.5645 60.6719 21.3574C60.168 20.1387 59.916 18.8262 59.916 17.4199C59.916 15.9902 60.168 14.666 60.6719 13.4473C61.1758 12.2285 61.8613 11.1797 62.7285 10.3008C63.6074 9.41016 64.627 8.71289 65.7871 8.20898C66.959 7.70508 68.207 7.45312 69.5312 7.45312C70.8672 7.45312 72.1152 7.69336 73.2754 8.17383C74.4473 8.64258 75.4668 9.31641 76.334 10.1953C77.2012 11.0625 77.8867 12.1113 78.3906 13.3418C78.8945 14.5605 79.1465 15.9199 79.1465 17.4199ZM74.3125 17.4199C74.3125 16.6465 74.1836 15.9492 73.9258 15.3281C73.6797 14.6953 73.3398 14.1562 72.9062 13.7109C72.4727 13.2539 71.9629 12.9082 71.377 12.6738C70.8027 12.4277 70.1875 12.3047 69.5312 12.3047C68.875 12.3047 68.2539 12.4277 67.668 12.6738C67.0938 12.9082 66.5898 13.2539 66.1562 13.7109C65.7344 14.1562 65.4004 14.6953 65.1543 15.3281C64.9082 15.9492 64.7852 16.6465 64.7852 17.4199C64.7852 18.1465 64.9082 18.8203 65.1543 19.4414C65.4004 20.0625 65.7344 20.6016 66.1562 21.0586C66.5898 21.5156 67.0938 21.8789 67.668 22.1484C68.2539 22.4062 68.875 22.5352 69.5312 22.5352C70.1875 22.5352 70.8027 22.4121 71.377 22.166C71.9629 21.9199 72.4727 21.5742 72.9062 21.1289C73.3398 20.6836 73.6797 20.1445 73.9258 19.5117C74.1836 18.8789 74.3125 18.1816 74.3125 17.4199Z" />
      <path d="M87.707 25.207C87.707 25.6289 87.6309 26.0273 87.4785 26.4023C87.3262 26.7656 87.1152 27.082 86.8457 27.3516C86.5762 27.6211 86.2598 27.832 85.8965 27.9844C85.5332 28.1484 85.1406 28.2305 84.7188 28.2305C84.3086 28.2305 83.916 28.1484 83.541 27.9844C83.1777 27.832 82.8555 27.6211 82.5742 27.3516C82.3047 27.082 82.0879 26.7656 81.9238 26.4023C81.7715 26.0273 81.6953 25.6289 81.6953 25.207C81.6953 24.7969 81.7715 24.4102 81.9238 24.0469C82.0879 23.6836 82.3047 23.3672 82.5742 23.0977C82.8555 22.8281 83.1777 22.6172 83.541 22.4648C83.916 22.3008 84.3086 22.2188 84.7188 22.2188C85.1406 22.2188 85.5332 22.3008 85.8965 22.4648C86.2598 22.6172 86.5762 22.8281 86.8457 23.0977C87.1152 23.3672 87.3262 23.6836 87.4785 24.0469C87.6309 24.4102 87.707 24.7969 87.707 25.207Z" />
    </g>
  </svg>
);
