import { useRightClick } from 'common/roq-hooks/use-right-click';
import { createElement, HTMLAttributes, SyntheticEvent } from 'react';

export interface RightClickListenerInterface extends HTMLAttributes<HTMLDivElement> {
  onRightClick: (event: SyntheticEvent<HTMLElement, Event>) => void;
}

export const RightClickListener = ({
  onRightClick,
  children,
  ...rest
}: RightClickListenerInterface) => {
  const { containerRef: ref } = useRightClick<HTMLSpanElement>(onRightClick)

  return createElement('div', { ...rest, ref }, children);
};
