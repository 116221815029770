import { IconProps } from 'common/otto-ui/icons/icon.type';

export const PhoneOffIcon = ({ size = 24 }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
    <g stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M5.1914 12.8101C3.50725 10.2131 2.45576 7.25726 2.1214 4.1801C2.09641 3.90356 2.12927 3.62486 2.2179 3.36172C2.30652 3.09859 2.44897 2.85679 2.63616 2.65172C2.82336 2.44665 3.0512 2.28281 3.30519 2.17062C3.55917 2.05843 3.83374 2.00036 4.1114 2.0001H7.1114C7.5967 1.99532 8.06719 2.16718 8.43516 2.48363C8.80313 2.80008 9.04348 3.23954 9.1114 3.7201C9.23802 4.68016 9.47285 5.62282 9.8114 6.5301C9.94594 6.88802 9.97506 7.27701 9.8953 7.65098C9.81555 8.02494 9.63026 8.36821 9.3614 8.6401L8.0914 9.9101M10.6814 13.3101C11.6963 14.3258 12.8432 15.2004 14.0914 15.9101L15.3614 14.6401C15.6333 14.3712 15.9766 14.1859 16.3505 14.1062C16.7245 14.0264 17.1135 14.0556 17.4714 14.1901C18.3787 14.5286 19.3213 14.7635 20.2814 14.8901C20.762 14.958 21.2014 15.1984 21.5179 15.5663C21.8343 15.9343 22.0062 16.4048 22.0014 16.8901V19.8901C22.0025 20.1686 21.9455 20.4443 21.8339 20.6994C21.7223 20.9546 21.5587 21.1837 21.3535 21.372C21.1483 21.5602 20.906 21.7036 20.6421 21.7928C20.3783 21.882 20.0988 21.9152 19.8214 21.8901C16.7442 21.5557 13.7884 20.5042 11.1914 18.8201C9.98667 18.0552 8.86993 17.1597 7.8614 16.1501L10.6814 13.3101Z" />
      <path d="M23 1L1 23" />
    </g>
  </svg>
);
