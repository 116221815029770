import { QueryOptions } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestGql } from 'utils/request-gql';

export interface ListCategoriesVariablesInterface {
    limit?: number;
    offset?: number;
    order: { sort: string; order: string }[];
}

export interface ListCategoriesActionRequestInterface extends QueryOptions {
    variables: ListCategoriesVariablesInterface;
}

export const listCategoriesAction = createAsyncThunk(
    'events/listCategories',
    async (request: ListCategoriesActionRequestInterface, thunkApi) => requestGql(request, thunkApi, 'listWebinarCategories'),
);
