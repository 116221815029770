/**
 * Calulates percentage change from 2 numbers.
 * @param f First value
 * @param s Second value
 * @returns 
 */
export const calcPercentageChange = (f: number, s: number): number => {
  if (f < s) {
    return f === 0 ? 100 : (s - f) / f * 100.0;
  }
  if (f > s) {
    return -(f - s) / f * 100.0;
  }
  return 0;
};
