import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { accountsApi, balancesApi } from 'api';
import { InvestmentHoldingInterface } from 'common/interfaces';
import { SecurityTypeEnum } from 'common/interfaces/investment.interface';
import { AccountPolicyEnum, AccountSubtypeEnum } from 'configuration/assets/account-types';
import { StoreInterface } from 'configuration/redux/store';
import { CURRENCY, WealthProviderEnum, AccountTypeEnum } from 'enums';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import { buildBalanceGraphData, calculateConnectionExpiry } from 'utils';
import { weightedAverage } from 'utils/arrays';

const REAUTH_DAYS = 14;

const INVESTMENT_TYPES = [
  AccountTypeEnum.INVESTMENT,
  AccountTypeEnum.PENSION,
  // AccountTypeEnum.SAVINGS
];

export type MonthlyComparisonDataType = {
  [key: string]: string | number;
};

export interface AnalysisInterface {
  spending: {
    monthlyComparisonData: MonthlyComparisonDataType[];
  };
  income: {
    monthlyComparisonData: MonthlyComparisonDataType[];
  };
}

export interface TransactionCategoryInterface {
  name: string | null;
  group: string | null;
}

export interface TransactionCounterpartyInterface {
  id: string;
  shortName: string;
  longName: string;
  logo?: string;
}

export interface AssetsTransactionInterface {
  id: string;
  accountId: string;
  amount: number;
  convertedAmount: number;
  currency: string;
  convertedCurrency: string;
  direction: 'IN' | 'OUT';
  categoryId: string;
  holdingId?: string;
  categoryIdConfirmed: boolean;
  date: string;
  fullDescription: string;
  cleanDescription: string;
  notes: string;
  counterpartyId: string;
  projectId: string | null;
  category: TransactionCategoryInterface;
  counterparty: TransactionCounterpartyInterface;
  status: TransactionStateEnum;
  matching?: {
    id: string;
  };
}

export enum AssetStatusEnum {
  pending = 'pending',
  fulfilled = 'fulfilled',
}

export enum TransactionStateEnum {
  POSTED = 'POSTED',
  PENDING = 'PENDING',
}

export enum ProviderStatusEnum {
  AVAILABLE = 'AVAILABLE',
  PARTIALLY_AVAILABLE = 'PARTIALLY_AVAILABLE',
  TEMPORARILY_UNAVAILABLE = 'TEMPORARILY_UNAVAILABLE',
  PERMANENTLY_UNAVAILABLE = 'PERMANENTLY_UNAVAILABLE',
}

// See: https://docs.moneyhubenterprise.com/docs/connection-status
export enum ErrorActionEnum {
  RECONSENT = 'RECONSENT',
  /**
   * If there's a problem with the connection e.g. requires MFA we will
   * have to refresh the connection
   */
  REFRESH = 'REFRESH',
  /**
   * If there's a credentials error we need to reauthenticate
   */
  REAUTH = 'REAUTH',
  RESYNC = 'RESYNC',
  NONE = 'NONE',
}

interface ValueInterface {
  value: number;
  currency: string;
}

export interface FinanceHoldings {
  accountId: string;
  dateOfValuation: string;
  sedol: string;
  quantity: number;
  description: string;
  total: ValueInterface;
  unitPrice: ValueInterface;
  currency: string | null;
  ticker: string;
  securityType: string;
  marketSector: string;
}

export interface FinanceHoldingSecurity {
  symbol: string;
  companyName: string;
  image: string;
  currency: string;
  price: number;
  convertedPrice?: number;
  convertedCurrency?: string;
  exchangeShortName?: string;
  sector?: string;
  industry?: string;
  changes?: number;
  assetType?: SecurityTypeEnum;
}

interface AssetEntityInterface {
  name: string;
  iconUrl: string | null;
  iconBase64: string | null;
}

export interface AssetsInterface {
  id: string;
  dateAdded: string;
  alias?: string;
  dateModified: string;
  name: string;
  nameOriginal?: string;
  type: AccountTypeEnum;
  errored: boolean;
  subtype?: AccountSubtypeEnum;
  policy?: AccountPolicyEnum;
  providerName?: string;
  providerId?: string;
  providerReference?: string;
  provider?: WealthProviderEnum; // crypto
  providerType?: 'wallet' | 'fiat'; // crypto
  connectionId?: string;
  balance: number;
  currency: CURRENCY;
  transactionData?: {
    count: number;
    earliestDate: string;
    lastDate: string;
  };
  accountHolderName?: string;
  purpose?: string;
  details?: {
    sortCodeAccountNumber: string;
  };
  connection?: {
    singleSyncOnly: boolean;
    id: string;
    name: string;
    type: string;
    providerConnectionId: string;
    expiresAt: string;
    createdAt: string;
    updatedAt: string;
    errored: boolean;
    errorAction: ErrorActionEnum;
    actions: string[];
  };
  convertedCurrency: string;
  convertedBalance: number;
  /**
   * Where the asset is stored. OTTO indicates
   * this is a manual asset.
   */
  vendor?: WealthProviderEnum;
  /**
   * The status of the asset. Used for optimistically rendering
   * the assets in the UI
   */
  status?: AssetStatusEnum;
  /**
   * The unix timestamp of when the asset was added to
   * the state in seconds
   */
  timestamp?: number;
  performance?: {
    total?: {
      openingBalance?: number;
      openingDate?: string;
      currentBalance?: number;
      currentDate?: string;
      currency?: string;
      contributions?: number;
      withdrawals?: number;
      nonContributionGrowth?: number;
      growthRate?: number;
      annualisedGrowthRate?: number;
    };
    monthly?: AssetMonthPerformance[];
  };
  entity: AssetEntityInterface;
  holdings: InvestmentHoldingInterface[];
}

type AssetMonthPerformance = {
  period?: string;
  openingBalance?: number;
  nonContributionGrowth?: number;
  aer?: number;
  avg?: number;
};

export interface InvestmentAccountInterface {
  /**
   * Total balance amounts
   */
  total: number;
  /**
   * Total return amount
   */
  totalRreturn: number;
  /**
   * Growth Rate in percentage
   */
  growthRate: number;
  accounts: number;
  growthRateMonths: {
    amount: number;
    period: string;
    total: number;
  }[];
}

export interface HistoricalBalanceSerie {
  id: string;
  color: string;
  data: { x: string; y: number }[];
}

export interface AssetsStateInterface {
  isLoadingAssets: boolean;
  isLoadingCrypto: boolean;
  loadingAccountsFinished: boolean;
  totalAssets: number;
  assets: AssetsInterface[];
  cryptoAssets: AssetsInterface[];
  holdingAssets: FinanceHoldings[];
  networth: number | null;
  investmentAssets: AssetsInterface[];
  investmentAccounts: {
    total: number;
    hasLoaded: boolean;
    isa: InvestmentAccountInterface;
    gia: InvestmentAccountInterface;
    pension: InvestmentAccountInterface;
    currency?: string;
  };
  historicalBalances: { [accountId: string]: HistoricalBalanceSerie[] };
}

export const assetsInitialState: AssetsStateInterface = {
  isLoadingAssets: false,
  isLoadingCrypto: false,
  loadingAccountsFinished: false,
  totalAssets: 0,
  holdingAssets: [],
  assets: [],
  cryptoAssets: [],
  networth: null,
  investmentAssets: [],
  investmentAccounts: {
    total: 0,
    isa: null,
    gia: null,
    pension: null,
    hasLoaded: false,
    currency: null,
  },
  historicalBalances: {},
};

export const assetsSlice = createSlice({
  name: 'assets',
  initialState: assetsInitialState,
  reducers: {
    onDeleteAccount: (state, { payload }: PayloadAction<AssetsInterface>) => {
      if (payload.type === AccountTypeEnum.CRYPTO) {
        state.cryptoAssets = state.cryptoAssets.filter((f) => f.id !== payload.id);
      } else {
        state.assets = state.assets.filter((f) => f.id !== payload.id);
        setInvestments(state);
      }
      if (state.historicalBalances[payload.id]) {
        delete state.historicalBalances[payload.id];
      }
      state.totalAssets = state.assets.length + state.cryptoAssets.length;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(balancesApi.endpoints.netWorth.matchFulfilled, (state, { payload }) => {
        if (payload?.amount) {
          state.networth = payload.amount?.value;
        }
      })
      .addMatcher(accountsApi.endpoints.fetchAccounts.matchFulfilled, (state, { payload }) => {
        state.assets = payload.map((a) => {
          if (a.connection && a.connection?.expiresAt) {
            const daysToExpire = calculateConnectionExpiry(a.connection.expiresAt);
            if (daysToExpire.daysToExpire < REAUTH_DAYS) {
              a.connection.errored = true;
              a.connection.errorAction = ErrorActionEnum.REAUTH;
            }
          }
          if (a?.connection?.errored) {
            a.connection.errorAction = ErrorActionEnum.REAUTH;
          }
          return a;
        });
        state.totalAssets = state.assets.length + state.cryptoAssets.length;
        setInvestments(state);
        state.investmentAccounts.hasLoaded = true;
        state.isLoadingAssets = false;
        state.loadingAccountsFinished = true;
      })
      .addMatcher(accountsApi.endpoints.fetchAccounts.matchPending, (state) => {
        state.isLoadingAssets = true;
      })
      .addMatcher(accountsApi.endpoints.fetchAccounts.matchRejected, (state) => {
        state.isLoadingAssets = false;
        state.investmentAccounts.hasLoaded = true;
      })
      .addMatcher(accountsApi.endpoints.fetchCryptoAccounts.matchFulfilled, (state, { payload }) => {
        state.cryptoAssets = payload.map((p) => ({ ...p, vendor: WealthProviderEnum.Otto }));
        state.totalAssets = state.assets.length + state.cryptoAssets.length;
        state.loadingAccountsFinished = true;
      })
      .addMatcher(accountsApi.endpoints.fetchHistoricalBalances.matchFulfilled, (state, { payload }) => {
        payload.forEach((b) => {
          state.historicalBalances[b.accountSummary.accountId] = buildBalanceGraphData(b.balanceHistory);
        });
      })
      .addMatcher(accountsApi.endpoints.updateAccount.matchFulfilled, (state, { payload }) => {
        const assetIndex = state.assets.findIndex((f) => f.id === payload.id);
        if (assetIndex !== -1) {
          state.assets[assetIndex] = {
            ...state.assets[assetIndex],
            ...(payload.account as Partial<AssetsInterface>),
          };
        }
      });
  },
});

const getInvestmentGroupData = (assets: AssetsInterface[]) => ({
  total: sumBy(assets, (a) => a.convertedBalance),
  totalRreturn: sumBy(assets, (a) => a?.performance?.total?.nonContributionGrowth),
  growthRate: getAverageGrowthRate(assets),
  growthRateMonths: getMonthlyAverageGrowth(assets),
  accounts: assets.length,
  currency: assets[0]?.convertedCurrency ? assets[0]?.convertedCurrency : assets[0]?.currency,
});

const getAverageGrowthRate = (assets: AssetsInterface[]) => {
  const t = assets.reduce<[number[], number[]]>(
    (acc, curr) => {
      acc[0].push(curr?.performance?.total?.growthRate || 0);
      acc[1].push(curr?.performance?.total?.nonContributionGrowth || 0);
      return acc;
    },
    [[0], [0]],
  );
  return weightedAverage(t);
};

const getMonthlyAverageGrowth = (assets: AssetsInterface[]) => {
  const months = assets.reduce<AssetMonthPerformance[]>((acc, curr) => {
    acc = acc.concat(curr?.performance?.monthly || []);
    return acc;
  }, []);
  return Object.values<AssetMonthPerformance[]>(groupBy(months, (m) => m.period))
    .reduce<{ amount: number; period: string; total: number }[]>((acc, curr) => {
      acc.push({
        total: curr.reduce((a, c) => a + (c.openingBalance + c.nonContributionGrowth), 0),
        period: curr[0].period,
        amount: weightedAverage(
          curr.reduce<[number[], number[]]>(
            (a, c) => {
              a[0].push(c?.aer || 0);
              a[1].push(c?.nonContributionGrowth || 0);
              return a;
            },
            [[0], [0]],
          ),
        ),
      });
      return acc;
    }, [])
    .sort((a, b) => new Date(a.period).valueOf() - new Date(b.period).valueOf());
};

const setInvestments = (state: AssetsStateInterface) => {
  state.investmentAssets = state.assets.filter((f) => INVESTMENT_TYPES.includes(f.type));
  state.investmentAccounts = {
    total: 0,
    isa: getInvestmentGroupData(state.investmentAssets.filter((f) => f.policy !== null)),
    gia: getInvestmentGroupData(
      state.investmentAssets.filter((f) => f.type !== AccountTypeEnum.PENSION && f.policy === null),
    ),
    pension: getInvestmentGroupData(
      state.investmentAssets.filter((f) => f.type === AccountTypeEnum.PENSION && f.policy === null),
    ),
    hasLoaded: false,
    // currency: state.investmentAccounts[0]?.convertedCurrency ? state.investmentAccounts[0]?.convertedCurrency : state.investmentAccounts[0]?.currency
  };
  state.investmentAccounts.total =
    state.investmentAccounts.isa?.total + state.investmentAccounts.gia?.total + state.investmentAccounts.pension?.total;
};

export default assetsSlice.reducer;

export const selectAssetById =
  (id: string) =>
  (state: StoreInterface): AssetsInterface =>
    state.assets.assets.find((f) => f.id === id);

export const assetsSelector = createSelector(
  (state: StoreInterface) => state,
  (values) => values.assets,
);

export const assetActions = assetsSlice.actions;
