import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const PlayIcon: React.FC<IconProps> = ({ size = 89, color = 'white' }) => {
  const [customSize, setCustomSize] = React.useState(size);

  return (
    <div
      onMouseDown={() => {
        // Gives us the "click down" effect
        setCustomSize(size * 0.99);
      }}
      onMouseUp={() => {
        setCustomSize(size);
      }}
      onMouseLeave={() => {
        setCustomSize(size);
      }}
    >
      <svg width={customSize} height={customSize} viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
        <svg width={customSize} height={customSize} viewBox="0 0 89 89" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="44.5" cy="44.5" r="44.5" fill="white" fillOpacity="0.4" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.5 82.2576C65.3529 82.2576 82.2576 65.3529 82.2576 44.5C82.2576 23.6471 65.3529 6.74243 44.5 6.74243C23.6471 6.74243 6.74243 23.6471 6.74243 44.5C6.74243 65.3529 23.6471 82.2576 44.5 82.2576ZM62.0303 46.1614C63.8283 45.1233 63.8283 42.5282 62.0303 41.4901L38.7689 28.0602C36.971 27.0221 34.7235 28.3197 34.7235 30.3958L34.7235 57.2557C34.7235 59.3318 36.971 60.6294 38.7689 59.5914L62.0303 46.1614Z"
            fill={color}
          />
        </svg>
      </svg>
    </div>
  );
};
