import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const ElectronicIcon: React.FC<IconProps> = ({ height = 20, width = 20, color = 'currentColor' }) => (
  <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.49902 5.22754L5.98586 6.74071C4.63204 8.09453 4.63204 10.2895 5.98586 11.6433V11.6433C6.19414 11.8516 6.19414 12.1893 5.98586 12.3976L5.4791 12.9043C4.99094 13.3925 4.99094 14.1839 5.4791 14.6721L7.24686 16.4399C7.73502 16.928 8.52647 16.928 9.01463 16.4399L9.52139 15.9331C9.72967 15.7248 10.0674 15.7248 10.2756 15.9331V15.9331C11.6295 17.2869 13.8244 17.2869 15.1782 15.9331L16.7656 14.3458M7.49902 5.22754L7.77719 4.94937C8.55824 4.16832 9.82457 4.16832 10.6056 4.94937L16.9696 11.3133C17.7506 12.0944 17.7506 13.3607 16.9696 14.1418L16.7656 14.3458M7.49902 5.22754L16.7656 14.3458"
      stroke={color}
      strokeWidth="0.9"
    />
    <path
      d="M4.27633 17.0056C4.1006 17.1814 4.1006 17.4663 4.27633 17.642C4.45207 17.8177 4.737 17.8177 4.91273 17.642L4.27633 17.0056ZM15.8729 3.14632C16.0486 2.97059 16.0486 2.68566 15.8729 2.50993C15.6971 2.33419 15.4122 2.33419 15.2365 2.50993L15.8729 3.14632ZM19.762 7.03541C19.9377 6.85967 19.9377 6.57475 19.762 6.39901C19.5862 6.22328 19.3013 6.22328 19.1256 6.39901L19.762 7.03541ZM6.0441 15.2378L4.27633 17.0056L4.91273 17.642L6.6805 15.8742L6.0441 15.2378ZM12.3374 6.68186L15.8729 3.14632L15.2365 2.50993L11.701 6.04546L12.3374 6.68186ZM16.2264 10.5709L19.762 7.03541L19.1256 6.39901L15.59 9.93455L16.2264 10.5709Z"
      fill={color}
    />
  </svg>
);
