import { caching } from 'configuration/data';
import { GetCustomerProfileDocument, UpdateCustomerProfileDocument } from 'configuration/graphql/documents';

import { API_TAGS, baseApi } from './base.api';
import { CustomerProfileInterface } from 'common/interfaces';

export const customerProfileApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<CustomerProfileInterface | null, { userId: string }>({
      query: (arg) => ({
        variables: arg,
        document: GetCustomerProfileDocument,
      }),
      transformResponse: (res: { getCustomerProfile: CustomerProfileInterface }) => res?.getCustomerProfile ?? null,
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.CUSTOMER_PROFILE],
    }),
    saveProfile: builder.mutation<boolean, { data: Partial<CustomerProfileInterface>; userId: string }>({
      query: ({ data, userId }) => ({
        variables: { data, userId },
        document: UpdateCustomerProfileDocument,
      }),
      transformResponse: (res: { updateCustomerProfile }) => res.updateCustomerProfile,
      invalidatesTags: [API_TAGS.CUSTOMER_PROFILE],
    }),
  }),
});

export const { useGetProfileQuery, useSaveProfileMutation } = customerProfileApi;
