import { BasePlacement, VariationPlacement } from '@popperjs/core';
import clsx from 'clsx';
import { Portal } from 'common/otto-ui/portal';
import {
  AnimationEventHandler,
  Children,
  cloneElement,
  CSSProperties,
  Fragment,
  HTMLAttributes,
  isValidElement,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { usePopper } from 'react-popper';

export interface TooltipInterface extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  /** Tooltip title. Zero-length titles string are never displayed. */
  title: ReactNode;
  /** If true, the tooltip is shown. */
  open?: boolean;
  /** If true, adds an arrow to the tooltip. */
  arrow?: boolean;
  /** Tooltip placement. */
  position?: BasePlacement | VariationPlacement;
  /** The width of the tooltip */
  width?: number | false;
  /** Tooltip reference element. */
  children: ReactElement;
  /**
   * Callback fired when the component requests to be open.
   * function() => void
   */
  onOpen?: () => void;
  /**
   * Callback fired when the component requests to be closed.
   * function() => void
   */
  onClose?: () => void;
}

/** Tooltips display informative text when users hover over. */
export const Tooltip = ({
  title,
  open,
  arrow,
  position = 'bottom',
  width = 300,
  className,
  children,
  onOpen,
  onClose,
  onAnimationEnd,
  ...props
}:TooltipInterface) => {
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(open);
  const [shouldRender, setRender] = useState<boolean>(open);
  const [anchorElement, setAnchorElement] = useState<HTMLElement>(null);
  const [tooltipElement, setTooltipElement] = useState<HTMLElement>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement>(null);

  const alwaysVisible = open && (!onOpen || !onClose);
  const isLeft = position.startsWith('left');
  const isRight = position.startsWith('right');
  const isTop = position.startsWith('top');
  const isBottom = position.startsWith('bottom');

  const classes = {
    wrapper: clsx(
      'z-50 relative',
      !alwaysVisible && {
        'animate-fade-zoom-in': tooltipVisible,
        'animate-fade-zoom-out': !tooltipVisible,
      },
      className,
    ),
    tooltip: 'p-3 text-sm bg-grey2 rounded text-offWhite flex flex-row justify-center items-center dark:bg-background-dark-screen dark:text-line',
    arrow: clsx('w-0 h-0 border-4 border-solid border-transparent', {
      'border-b-0 border-t-6 border-t-grey2 dark:border-t-background-dark-screen': isTop,
      'border-t-0 border-b-6 border-b-grey2 dark:border-b-background-dark-screen -mt-1.5': isBottom,
      'border-r-0 border-l-6 border-l-grey2 dark:border-l-background-dark-screen left-full': isLeft,
      'border-l-0 border-r-6 border-r-grey2 dark:border-r-background-dark-screen -ml-1.5': isRight,
    }),
  };

  const { styles: popperStyle, attributes } = usePopper(anchorElement, tooltipElement, {
    placement: position,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 12],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      },
      {
        name: 'arrow',
        enabled: !!arrow,
        options: {
          element: arrowElement,
          padding: 8,
        },
      },
    ],
  });

  const tooltipStyles: CSSProperties = {
    ...popperStyle.popper,
    fontSize: 12,
    zIndex: 200,
    minWidth: '2rem',
  };
  if (width) {
    tooltipStyles.maxWidth = width;
  }

  const handleAnimationEnd: AnimationEventHandler<HTMLDivElement> = (event) => {
    if (!tooltipVisible) {
      setRender(false);
    }
    if (onAnimationEnd) {
      onAnimationEnd(event);
    }
  };

  const showTooltip = () => {
    if (onOpen) {
      onOpen();
    } else {
      setTooltipVisible(true);
    }
  };

  const hideTooltip = () => {
    if (onClose) {
      onClose();
    } else {
      setTooltipVisible(false);
    }
  };

  useEffect(() => {
    setTooltipVisible(open);
  }, [open]);

  useEffect(() => {
    if (tooltipVisible) {
      setRender(true);
    }
  }, [tooltipVisible]);

  const slides: ReactElement[] = Children.toArray(children)
    .filter((child) => isValidElement(child))
    .map((child) => child as ReactElement);
  const [child] = slides;
  const { onMouseEnter, onMouseMove, onMouseLeave, ...childProps } = child.props || {};
  const childChildren = [child.props.children];
  if (child.props.disabled || alwaysVisible) {
    childChildren.push(
      <div
        key="tooltip-child"
        className="absolute left-0 top-0 w-full h-full pointer-events-auto opacity-0"
        ref={setAnchorElement}
        onMouseEnter={showTooltip}
        onMouseMove={showTooltip}
        onMouseLeave={hideTooltip}
      />,
    );
  }
  

  return child ? (
    <Fragment>
      {cloneElement(child, {
        ...childProps,
        onMouseEnter: (e: any) => {
          if (onMouseEnter) {
            onMouseEnter(e);
          }
          setAnchorElement(e.currentTarget);
          showTooltip();
        },
        onMouseMove: (e: any) => {
          if (onMouseMove) {
            onMouseMove(e);
          }
          showTooltip();
        },
        onMouseLeave: (e: any) => {
          if (onMouseLeave) {
            onMouseLeave(e);
          }
          hideTooltip();
        },
        children: childChildren,
      })}
      {shouldRender && Boolean(title) && Boolean(anchorElement) && (
        <Portal>
          <div ref={setTooltipElement} style={tooltipStyles} {...attributes.popper}>
            <div className={classes.wrapper} onAnimationEnd={handleAnimationEnd} {...props}>
              {arrow && (isRight || isBottom) && (
                <div ref={setArrowElement} className={classes.arrow} style={popperStyle.arrow} />
              )}
              <div className={classes.tooltip}>{title}</div>
              {arrow && (isLeft || isTop) && (
                <div ref={setArrowElement} className={classes.arrow} style={popperStyle.arrow} />
              )}
            </div>
          </div>
        </Portal>
      )}
    </Fragment>
  ) : null;
};
