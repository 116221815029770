import { gql } from 'graphql-request';

export const commonUserFields = `
  id
  type
  firstName
  lastName
  avatar
  avatarImageIdentifier
  status
  email
  lastLogin
  timezone
`;

const userProfile = `
  userProfile {
    id
    userId
    hasChildren
    hasPartner
    birthday
    isMarried
    currency
    country
    hasSharedFinancialInformation
    requestedFinancialInformation
  }
`;

const subscription = `
  subscription {
    couponId
    createdAt
    currentPlan
    currentProduct
    expiresAt
    hasExpired
    id
    isActive
    promotionCode
    status
    totalPaid
    updatedAt
  }
  subscriptionId
  `;

const benefit = `
  benefit {
    sessions
    expiresAt
    benefit {
      id
      name
      logo
      link
    }
  }
`;

const tier = `
  tier {
    id
    tier
    name
    features {
      full
      limited
    }
  }
`;

const customerMetaInfo = `
customerMetaInfo {
  id
  customerCompany {
    id
    name
    logoUrl
  }
  coachMatches(isActive: true) {
    data {
      id
      conversationId
      coachMetaInfo {
        id
        user {
          id
          firstName
          lastName
          avatar
          avatarImageIdentifier
        }
      }
    }
  }
}
`;

export const userModel = `
  ${commonUserFields}
  channel
  ${customerMetaInfo}
  ${tier}
  ${benefit}
  ${userProfile}
  ${subscription}
`;

export const UpdateUserDocument = gql`
  mutation UpdateUser($id: String!, $user: UserUpdateDto!) {
    updateUser(id: $id, user: $user) {
      ${userModel}
    }
  }
`;

export const CurrentUserDocument = gql`
  query GetUser($email: String, $id: String) {
    user(email: $email, id: $id) {
      ${userModel}
      createdAt
      partnerId
      coachMetaInfoId
      termsSettings {
        termsAndConditions
        privacyPolicy
        assetConsent
        coachingConsent
      }
    }
  }
`;

export const CoachDocument = gql`
  query GetUser($email: String, $id: String) {
    user(email: $email, id: $id) {
      ${commonUserFields}
      ${userProfile}
      coachMetaInfoId
    }
  }
`;

export const CreateUserDocument = gql`
  mutation CreateUser($input: InvitationInviteDto!){
    createAndInviteUser(input: $input){
      ${commonUserFields}
    }
  }
`;

export const CreateAndInviteCustomerDocument = gql`
  mutation createAndInviteCustomer($input: CustomerInviteDto!){
    createAndInviteCustomer(input: $input){
      ${commonUserFields}
    }
  }
`;

export const SaveAvatarDocument = gql`
  mutation SaveAvatar($fileId: String!, $status: StatusEnum!, $userId: String) {
    profilePictureUpdateFileStatus(fileId: $fileId, status: $status, userId: $userId) {
      id
      url
    }
  }
`;

export const DeleteAvatarDocument = gql`
  mutation DeleteAvatar($fileId: String!, $userId: String!) {
    deleteProfilePicture(fileId: $fileId, userId: $userId)
  }
`;

export const DeleteAccountDocument = gql`
  mutation DeleteAccount($reason: String!) {
    deleteAccount(reason: $reason)
  }
`;

export const GetUserDocument = gql`
  query GetUser($email: String, $id: String) {
    user(email: $email, id: $id) {
      ${commonUserFields}
      channel
      coachMetaInfoId
      ${userProfile}
      customerMetaInfo {
        coachMatches(isActive: true) {
          data {
            conversationId
          }
        }
      }
    }
  }
`;

export const GetUserProfileDocument = gql`
  query GetUserProfile($userId: String!) {
    getCustomerProfile(userId: $userId) {
      id
      userId
      hasChildren
      hasPartner
      birthday
      isMarried
      currency
      country
      hasSharedFinancialInformation
      requestedFinancialInformation
    }
  }
`;

export const GetUsersDocument = gql`
  query GetUsers(
    $limit: Float,
    $offset: Float,
    $order: UserOrderArgType,
    $ids: [String!],
    $search: UserSearchArgType,
    $multiColumnTextSearch: String,
    $type: String,
    $couponCode: String,
    $status: UserStatus,
    $coachIds: [String!],
    $companyIds: [String!],
    $lastLogin: DateRangeArgType,
    $tierIds: [String!],
    $channels: [UserChannelEnum!],
    $source: [String!],
    $hasSharedFinancialInformation: Boolean,
    $partnerIds: [String!],
    $partnerId: String,
  ) {
    users(
      limit: $limit,
      offset: $offset,
      order: $order,
      ids: $ids,
      search: $search,
      type: $type,
      couponCode: $couponCode,
      multiColumnTextSearch: $multiColumnTextSearch,
      status: $status,
      coachIds: $coachIds,
      companyIds: $companyIds,
      lastLogin: $lastLogin,
      tierIds: $tierIds,
      channels: $channels,
      source: $source,
      hasSharedFinancialInformation: $hasSharedFinancialInformation,
      partnerIds: $partnerIds,
      partnerId: $partnerId
    ) {
      data {
        id
        type
        firstName
        lastName
        status
        email
        avatar
        actionsOpen
        lastAppointment
        nextAppointment
        partnerId
        userProfile {
          hasSharedFinancialInformation
        }
        customerMetaInfo {
          id
          customerCompany {
            id
            name
          }
          coachMatches(isActive: true) {
            data {
              id
              conversationId
              coachMetaInfo {
                id
                user {
                  id
                  firstName
                  lastName
                  avatar
                  avatarImageIdentifier
                }
              }
            }
          }
        }
        tier {
          id
          name
          features {
            full
            limited
          }
        }
        completedAppointmentsCount
        lastLogin
        actionsCompleted
        channel
        createdAt
        couponCode {
          code
        }
      }
      totalCount
    }
  }
`;

export const GetTotalUsersDocument = gql`
  query GetTotalUsers(
    $coachIds: [String!],
    $coachAssigned: DateRangeArgType,
    $order: UserOrderArgType,
    $limit: Float
  ) {
    users(
      coachIds: $coachIds,
      coachAssigned: $coachAssigned,
      order: $order,
      limit: $limit
    ) {
      totalCount
    }
  }
`;

export const SimpleUsersDocument = gql`
  query SimpleUsers($ids: [String!], $multiColumnTextSearch: String, $limit: Float) {
    users(ids: $ids, multiColumnTextSearch: $multiColumnTextSearch, limit: $limit) {
      data {
        id
        firstName
        lastName
        avatar
        email
        status
        createdAt
      }
      totalCount
    }
  }
`;

export const CreateMultipleUsersDocument = gql`
mutation createAndInviteMultipleCustomers($csv: Upload!, $preview: Boolean!) {
  createAndInviteMultipleCustomers(csv: $csv, preview: $preview)
  {
    totalCount 
    data {
      email
      firstName
      lastName
    }
  }
}`;

// A more minimal version of the standard GetUsersDocument query
export const GetChatUsersDocument = gql`
  query GetUsers(
    $limit: Float!
    $offset: Float!
    $order: UserOrderArgType
    $ids: [String!]
    $search: UserSearchArgType
    $multiColumnTextSearch: String
    $type: String
    $status: UserStatus
    $coachIds: [String!]
    $companyIds: [String!]
    $lastLogin: DateRangeArgType
    $tierIds: [String!]
    $channels: [UserChannelEnum!]
    $source: [String!]
  ) {
    users(
      limit: $limit
      offset: $offset
      order: $order
      ids: $ids
      search: $search
      type: $type
      multiColumnTextSearch: $multiColumnTextSearch
      status: $status
      coachIds: $coachIds
      companyIds: $companyIds
      lastLogin: $lastLogin
      tierIds: $tierIds 
      channels: $channels
      source: $source
    ) {
      data {
        id
        type
        firstName
        lastName
        avatar
      }
      totalCount
    }
  }
`;

export const UserSubscriptionDocument = gql`
  query UserSubscription($returnUrl: String!, $userId: ID) {
    manageUserSubscription(userId: $userId, returnUrl: $returnUrl)
  }
`;

export const GetCoachesDocument = gql`
  query GetCoaches(
    $limit: Float,
    $offset: Float,
    $order: UserOrderArgType,
    $ids: [String!],
    $search: UserSearchArgType,
    $multiColumnTextSearch: String,
    $type: String,
    $status: UserStatus,
    $partnerIds: [String!],
    $partnerId: String,
    $lastLogin: DateRangeArgType,
  ) {
    users(
      limit: $limit
      offset: $offset
      order: $order
      ids: $ids
      search: $search
      type: $type
      multiColumnTextSearch: $multiColumnTextSearch
      status: $status,
      partnerIds: $partnerIds,
      partnerId: $partnerId,
      lastLogin: $lastLogin
    ) {
      data {
        ${commonUserFields}
        coachMetaInfoId
        createdAt
        partnerId
        coachMetaInfo {
              about
              autoAssignEnabled
              information
              coachExpertises {
                data {
                  id
                  name
                  icon
                }
              }
            }

      }
      totalCount
    }
  }
`;

export const UserBenefitDocument = gql`
  query UserBenefit($id: String!) {
    userBenefit(id: $id) {
      id
      sessions
      expiresAt
      benefit {
        id
        name
        logo
        link
      }
    }
  }
`;

export const UpdateUserChannelDocument = gql`
  mutation UpdateUserChannel($benefit: ID, $channel: UserChannelEnum!, $expiresAt: DateTime, $sessions: Int, $userId: ID!) {
    updateUserChannel(benefit: $benefit, channel: $channel, expiresAt: $expiresAt, sessions: $sessions, userId: $userId) {
      ${userModel}
    }
  }
`;

export const CreateCoachMatchDocument = gql`
  mutation CreateCoachMatch($coachMatch: CoachMatchCreateDto!) {
    createCoachMatch(coachMatch: $coachMatch){
      id
    }
  }
`;

export const UpdateCoachMatchDocument = gql`
  mutation CreateCoachMatch($id: String!, $coachMatch: CoachMatchUpdateDto!) {
    updateCoachMatch(id: $id, coachMatch: $coachMatch){
      id
    }
  }
`;

export const CreateCustomerMetaInfoDocument = gql`
  mutation CreateCustomerMetaInfo($info: CustomerMetaInfoCreateDto!){
    createCustomerMetaInfo(customerMetaInfo: $info){
      id
    }
  }
`;

export const UpdateCustomerMetaInfoDocument = gql`
  mutation UpdateCustomerMetaInfo($companyId: String, $id: String!){
    updateCustomerMetaInfo(customerMetaInfo: { customerCompanyId: $companyId }, id: $id){
      id
    }
  }
`;

export const ResendInvitationsDocument = gql`
  mutation ResendInvitations($input: InvitationResendManyDto!) {
    resendInvitations(input: $input) {
      message
    }
  }
`;

export const ApplyCouponDocument = gql`
  mutation ApplyCoupon($code: String!) {
    applyCoupon(code: $code)
  }
`;

export const RequestFinancialInformationDocument = gql`
  mutation RequestFinancialInformation($userId: String!) {
    requestFinancialInformation(userId: $userId)
  }
`;
