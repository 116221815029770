import { CheckIcon } from '@otto-finance/ui';
import clsx from 'clsx';
import { InputHTMLAttributes, ReactNode, useState } from 'react';

export enum CheckBoxTextPositionEnum {
  AFTER = 'after',
  BEFORE = 'before',
  ABOVE = 'above',
  BELOW = 'below',
}

export enum CheckBoxSizeEnum {
  LARGE = 'large',
  SMALL = 'small',
  NORMAL = 'normal',
}

export interface CheckBoxInterface extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  /** Size of component */
  size?: Lowercase<keyof typeof CheckBoxSizeEnum>;
  color?: string;
  /** Label of the component */
  label?: string;
  /** Label component */
  labelComponent?: ReactNode;
  /** Classes will be added to hover state of component */
  hoveredClassName?: string;
  /** Text position of label for checkbox */
  textPosition?: Lowercase<keyof typeof CheckBoxTextPositionEnum>;
  indeterminate?: string;
}

/** Checkbox control */
export const CheckBox = ({
  className,
  size = CheckBoxSizeEnum.NORMAL,
  disabled,
  name,
  textPosition = CheckBoxTextPositionEnum.AFTER,
  checked,
  hoveredClassName,
  label,
  id,
  onClick,
  onChange,
  labelComponent,
  ...props
}: CheckBoxInterface) => {
  const [focused, setFocus] = useState<boolean>(false);

  const dotClassName = clsx('flex justify-center items-center rounded text-defaultBg dark:text-body', {
    'w-8 h-8 border-4': size === CheckBoxSizeEnum.LARGE,
    'w-6 h-6 border-3': size === CheckBoxSizeEnum.NORMAL,
    'w-3.5 h-3.5 border-2 box-content': size === CheckBoxSizeEnum.SMALL,
    'bg-primary dark:bg-blue-300 hover:bg-primary-dark dark:hover:bg-primary-normal': checked,
    'border-primary dark:border-blue-300 hover:border-primary-dark dark:hover:border-primary-normal': checked,
    'ring-blue-200 dark:ring-grey1 ring-8': checked && focused,
    'border-line bg-offWhite dark:border-grey2 dark:bg-body': !checked,
    'ring-label dark:ring-grey1 ring-8': !checked && !disabled && focused,
    'hover:border-placeholder dark:hover:border-placeholder': !checked && !disabled,
    'opacity-50 ': disabled,
  });

  const paragraphClassName = clsx({
    'ml-2': textPosition === CheckBoxTextPositionEnum.AFTER,
    'mr-2': textPosition === CheckBoxTextPositionEnum.BEFORE,
    'mt-2': textPosition === CheckBoxTextPositionEnum.BELOW,
    'mb-2': textPosition === CheckBoxTextPositionEnum.ABOVE,
    [hoveredClassName]: hoveredClassName,
    'text-body dark:text-defaultBg': !disabled && !hoveredClassName,
    'text-grey2 dark:text-line': disabled,
  });

  const labelClassName = clsx('inline-flex items-center my-2 outline-none', {
    'flex-row': textPosition === CheckBoxTextPositionEnum.AFTER,
    'flex-row-reverse': textPosition === CheckBoxTextPositionEnum.BEFORE,
    'flex-col': textPosition === CheckBoxTextPositionEnum.BELOW,
    'flex-col-reverse': textPosition === CheckBoxTextPositionEnum.ABOVE,
    'cursor-pointer': !disabled,
    [className]: className,
  });

  return (
    <label className={labelClassName} htmlFor={id}>
      <div className={dotClassName}>{checked && <CheckIcon />}</div>

      <p className={paragraphClassName}>{label || labelComponent}</p>
      <input
        type="checkbox"
        name={name}
        className="w-0 h-0 opacity-0"
        id={id}
        disabled={disabled}
        checked={checked}
        onClick={onClick}
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        {...props}
      />
    </label>
  );
};
