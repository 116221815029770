import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { saveState } from "configuration/redux/local-store";
import { StoreInterface } from "configuration/redux/store";
import { fetchAuthUrlAction, fetchOwnerAction, redeemAccessTokenAction } from 'slices/calendar/actions';

export interface CalendarStateInterface {
  isLoading: boolean;
  error: any;
  redeemError: any;
  authUrl: string;
  externalUserId: string | null;
  calendarConnected: boolean;
}
const initialState: CalendarStateInterface = {
  isLoading: true,
  error: null,
  redeemError: null,
  authUrl: '',
  externalUserId: null,
  calendarConnected: false
};
export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    updateCalendarConnectedStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.calendarConnected = payload;
      saveState({ calendar: state });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(redeemAccessTokenAction.fulfilled as any, (state) => {
      state.redeemError = null;
    })
      .addCase(redeemAccessTokenAction.pending as any, (state) => {
        state.isLoading = true;
      }).addCase(redeemAccessTokenAction.rejected as any, (state, { payload }) => {
        state.redeemError = payload;
        state.isLoading = false;
      }).addCase(fetchOwnerAction.fulfilled as any, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.externalUserId = payload.externalUserId;
        state.calendarConnected = true;
        saveState({ calendar: state });
      }).addCase(fetchOwnerAction.rejected as any, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      }).addCase(fetchAuthUrlAction.fulfilled as any, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.authUrl = payload;
      }).addCase(fetchAuthUrlAction.pending as any, (state) => {
        state.isLoading = true;
      }).addCase(fetchAuthUrlAction.rejected as any, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      });
  }
});

export default calendarSlice.reducer;
export { fetchAuthUrlAction, redeemAccessTokenAction, fetchOwnerAction };
export const { updateCalendarConnectedStatus } = calendarSlice.actions;

const selectCalendar = (state: StoreInterface) => state.calendar;

export const isCalendarConnectedSelector = createSelector(selectCalendar, v => v.calendarConnected);