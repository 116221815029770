import { Button, ChevronLeftIcon, ChevronRightIcon, LabelSmall, Select, useTheme } from '@otto-finance/ui';
import { Table } from '@tanstack/react-table';
import { Row } from '../ui';

export const Paginator = <T extends unknown>({ table }: { table: Table<T>; }) => {
  const [css, theme] = useTheme();
  return (
    <Row crossalign='center'
      className={css({
        padding: theme.sizing.scale600
      })}>

      {
        table.getCanPreviousPage() && (
          <Button
            onClick={() => table.setPageIndex(0)}
            size='mini' kind="secondary"
          >
            First
          </Button>
        )
      }

      {table.getCanPreviousPage() && (
        <Button onClick={() => table.previousPage()} size='mini' shape='circle' kind="secondary">
          <ChevronLeftIcon size="16px" />
        </Button>
      )}
      {
        table.getCanNextPage() && (
          <Button onClick={() => table.nextPage()} size='mini' shape='circle' kind="secondary">
            <ChevronRightIcon size="16px" />
          </Button>
        )
      }

      {
        table.getCanNextPage() && (
          <Button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            size='mini' kind="secondary"
          >
            Last
          </Button>
        )
      }

      <Row crossalign='center' >
        <LabelSmall>Page</LabelSmall>
        <LabelSmall>{table.getState().pagination.pageIndex + 1} of {table.getPageCount()}</LabelSmall>
      </Row>

      <div className={css({ width: '120px'})}>
        <Select
          clearable={false}
          size='mini'
          autoFocus={false}
          searchable={false}
          value={[{ id: table.getState().pagination.pageSize }]}
          options={[10, 20, 30, 40, 50].map(p => ({ id: p, label: `${p} per page` }))}
          onChange={(params) => {
            table.setPageSize(Number(params.option.id));
          }}
        />
      </div>
    </Row>
  );
};
