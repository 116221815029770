import { gql } from 'graphql-request';

const commonFields = `
  investment {
    excludeRiskAssessment
    risk
    watchlist {
      symbol
      idea
      tags
    }
  }
`;

export const InvestmentsWatchlistAddDocument = gql`
  mutation AddSymbolToWatchlist(
    $userId: String!
    $symbol: String!
    $idea: String
    $tags: [String!]
    ) {
    addSymbolToWatchlist(
    userId: $userId
    symbol: $symbol
    idea: $idea
    tags: $tags
  ) {
    ${commonFields}
  }
  }
`;

export const InvestmentsWatchlistRemoveDocument = gql`
  mutation RemoveSymbolFromWatchlist(
  $userId: String!
  $symbol: String!
){
  removeSymbolFromWatchlist(
    userId: $userId
    symbol: $symbol
  ) {
    ${commonFields}
   }
}
`;
