module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  pages: {
    '*': ['common', 'validations', 'settings'],
    '/': ['dashboard', 'action', 'session', 'models','coach'],
    'rgx:^/welcome*': ['welcome'],
    '/wealth': ['wealth','dashboard', 'action', 'session', 'assetSuccess', 'infoIcon'],
    '/wealth/assets': ['wealth','assetSuccess'],
    '/wealth/transactions': ['wealth'],
    '/wealth/cashflow': ['infoIcon'],
    '/wealth/investment-analysis': ['investment-analysis'],
    '/verify-email': ['verifyEmail'],
    '/users': ['users', 'dashboard'],
    '/terms-condition-privacy-policy': ['termsConditionPrivacyPolicy'],
    '/sessions': ['session', 'users'],
    '/session/*': ['session'],
    '/session-room': ['session'],
    '/restore-password': ['restorePass'], 
    '/register': ['register'],
    '/adviser': ['messageCenter', 'bookSession', 'coach'],
    '/messages': ['messageCenter'],
    '/dev-messages': ['messageCenter'],
    '/dev-messages/multiple': ['messageCenter'],
    '/invalid-email': ['invalid-email'],
    '/get-started': ['register'],
    '/forgot-password': ['forgotPass'],
    '/connect-calendar': ['connect-calendar'],
    '/connect-calendar/connecting': ['connect-calendar'],
    '/coaches': ['coaches'],
    '/coach-profile': ['coachProfile'],
    '/calendar': ['session'],
    '/book-session': ['bookSession'],
    '/admins': ['admins'],
    '/actions': ['action'],
    '/account-activate': ['accountActivate'],
    '/roadmap': ['roadmap', 'validations'],
    '/survey': ['survey'],
    '/settings/account': ['account', 'wealth'],
    '/learn': ['learn'],
    '/learn/article/*': ['learn'],
    '/learn/topic/*': ['learn'],
    '/rules': ['ottomate'],
    '/models': ['models'],
    '/models/[slug]': ['models'],
    '/wealth/assets/add': ['add-asset'],
    '/wealth/investments': ['investments'],
    '/wealth/transactions': ['transactions'],
    '/confirm-session': ['bookSession']
  },
};
