export enum AssetCategoryEnum {
  CASH = 'cash',
  CRYPTO = 'crypto',
  PENSION = 'pension',
  CARD = 'card',
  PROPERTY = 'property',
  INVESTMENT = 'investment',
  LOAN = 'loan',
  INSURANCE = 'insurance',
  OTHER = 'other'
}
