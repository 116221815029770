import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { Appointment, AppointmentNote, AppointmentRoom, CoachMetaInfoInterface } from 'common/interfaces';
import { ROOM_STATE } from 'components/session-meeting/types';
import { StoreInterface } from 'configuration/redux/store';
import { fetchSessionRoomAction, fetchSessionsAction } from 'slices/session/actions';

export interface SessionInterface {
  id: string;
  firstName: string;
  nextAppointment: Date;
  appointment: {
    joinUrl: string;
    title: string;
  };
  customerMetaInfo: CoachMetaInfoInterface;
}

interface RoomOptions {
  isMinimal?: boolean;
  enableNotes?: boolean;
  haircheckTitle?: boolean;
}

type RoomParticipants = {
  remote: {
    id: string;
    name: string;
    avatar?: string;
  };
  local: {
    id: string;
    name: string;
    avatar?: string;
  };
};

export interface SessionStateInterface extends EntityState<SessionInterface, string> {
  data: Appointment;
  participants: RoomParticipants;
  currentSession: {
    isActive: boolean;
    id: string;
    room: AppointmentRoom;
    recordingUrl: string;
  };
  roomStatus: ROOM_STATE;
  isLoading: boolean;
  error: Error;
  totalCount: number;
  limit: number;
  offset: number;
  pageCount: number;
  currentPage: number;
  currentEntity: SessionInterface;
  order: {
    sort: string;
    order: string;
  };
  sessionRoom: {
    isLoading: boolean;
    error: string;
    room: SessionRoomResponseInterface;
  };
  roomOptions: RoomOptions;
}

interface SessionRoomInterface {
  id: string;
  name: string;
  url: string;
}

export interface SessionRoomResponseInterface {
  room: SessionRoomInterface;
  token: string;
}

const entityAdapter = createEntityAdapter<SessionInterface>();

const initialState: SessionStateInterface = {
  data: null,
  participants: {
    remote: null,
    local: null
  },
  currentSession: {
    isActive: false,
    id: null,
    room: null,
    recordingUrl: null,
  },
  roomOptions: {
    isMinimal: true,
    enableNotes: true,
    haircheckTitle: true
  },
  roomStatus: ROOM_STATE.IDLE,
  isLoading: false,
  error: null,
  totalCount: 0,
  limit: 10,
  offset: 0,
  currentPage: 1,
  pageCount: 0,
  currentEntity: null,
  order: {
    sort: null,
    order: null,
  },
  sessionRoom: {
    isLoading: false,
    error: null,
    room: null
  },
  ...entityAdapter.getInitialState()
};

const sessionSlice = createSlice({
  initialState,
  name: 'session',
  reducers: {
    setCurrentSession: (state, { payload: { id, room, recordingUrl, options } }: PayloadAction<{ id: string; room: AppointmentRoom; recordingUrl?: string; options?: Partial<RoomOptions>; }>) => {
      state.currentSession = {
        isActive: true,
        id,
        room,
        recordingUrl
      };
      if (options) {
        state.roomOptions = { ...state.roomOptions, ...options };
      }
    },
    endCurrentSession: (state) => {
      state.currentSession = {
        isActive: false,
        id: null,
        room: null,
        recordingUrl: null
      };
    },
    setSessionData: (state, { payload }: PayloadAction<{ data: Appointment; userId: string; }>) => {
      state.data = payload.data;
      const _p = payload.data.users.map(u => ({ id: u.id, name: `${u.firstName} ${u.lastName}`, avatar: u.avatar }));
      state.participants = {
        local: _p.find(f => f.id === payload.userId),
        remote: _p.find(f => f.id !== payload.userId)
      };
    },
    setSessionParticipants: (state, { payload }: PayloadAction<RoomParticipants>) => {
      state.participants = payload;
    },
    addSessionNote: (state, { payload }: PayloadAction<AppointmentNote>) => {
      state.data.appointmentNotes.data = [
        payload,
        ...state.data.appointmentNotes.data
      ];
      state.data.appointmentNotes.totalCount += 1;
    },
    updateRoomStatus: (state, { payload }: PayloadAction<ROOM_STATE>) => {
      state.roomStatus = payload;
    },
    clearSessionsErrorAction: (state) => {
      state.error = null;
    },
    setSessionsPaginationAction: (state, { payload }) => {
      const { offset, limit } = payload;
      state.offset = offset;
      state.limit = limit;
      state.currentPage = Math.ceil(offset / limit + 1);
    },
    clearCurrentSessionsAction: (state) => {
      state.currentEntity = null;
    },
    setSessionsOrderAction: (state, { payload }) => {
      const { sort } = payload;
      const { order, sort: sortInState } = state.order;
      state.order.sort = sort;
      if (!sortInState) {
        state.order.sort = sort;
        state.order.order = 'ASC';
      }
      if (sortInState && sortInState === sort && order === 'ASC') {
        state.order.order = 'DESC';
      }
      if (sortInState && sortInState === sort && order === 'DESC') {
        state.order.sort = null;
        state.order.order = null;
      }
      if (sortInState && sortInState !== sort) {
        state.order.sort = sort;
        state.order.order = 'ASC';
      }
    },
  },
  extraReducers: (builder) => builder
    .addCase(fetchSessionsAction.fulfilled as any, (state, { payload: { data, totalCount } }) => {
      const { limit } = state;
      entityAdapter.setAll(state, data);
      state.totalCount = totalCount;
      state.isLoading = false;
      state.error = null;
      state.pageCount = Math.ceil(totalCount / limit);
    })
    .addCase(fetchSessionsAction.pending as any, (state) => {
      state.isLoading = true;
    })
    .addCase(fetchSessionsAction.rejected as any, (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    })
    .addCase(fetchSessionRoomAction.fulfilled as any, (state, { payload }) => {
      state.sessionRoom.room = payload;
      state.sessionRoom.isLoading = false;
      state.sessionRoom.error = null;
    })
    .addCase(fetchSessionRoomAction.pending as any, (state) => {
      state.sessionRoom.isLoading = true;
    })
    .addCase(fetchSessionRoomAction.rejected as any, (state, { payload }) => {
      state.sessionRoom.error = payload.message;
      state.sessionRoom.isLoading = false;
    })
});

export default sessionSlice.reducer;
export const {
  setSessionsPaginationAction,
  setSessionsOrderAction,
  clearSessionsErrorAction,
  clearCurrentSessionsAction,
  setSessionData,
  endCurrentSession,
  setCurrentSession,
  addSessionNote,
  updateRoomStatus,
  setSessionParticipants
} = sessionSlice.actions;
export { fetchSessionsAction };

export const sessionSelector = createSelector(
  (state: StoreInterface) => state.session,
  (values) => values,
);

const session = (state: StoreInterface) => state.session;
const sessionData = (state: StoreInterface) => state.session.data;

export const sessionNotesSelector = createSelector(sessionData, v => v.appointmentNotes);
export const sessionParticipantsSelector = createSelector(session, v => v.participants);
export const currentSessionSelector = createSelector(session, v => v.currentSession);
export const sessionDataSelector = createSelector(session, v => v.data);
export const sessionRoomStatusSelector = createSelector(session, v => v.roomStatus);
export const sessionRoomOptionsSelector = createSelector(session, v => v.roomOptions);
