import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const DragIcon: React.FC<IconProps> = ({ color = 'currentColor', height = 17, width = 12 }) => (
  <svg width={width} height={height} viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g ry="1.5" fill={color}>
      <ellipse cx="2.0309" cy="1.5" rx="1.52699" />
      <ellipse cx="2.0309" cy="8.5" rx="1.52699" />
      <ellipse cx="2.0309" cy="15.5" rx="1.52699" />
      <ellipse cx="10.1754" cy="1.5" rx="1.52699" />
      <ellipse cx="10.1754" cy="8.5" rx="1.52699" />
      <ellipse cx="10.1754" cy="15.5" rx="1.52699" />
    </g>
  </svg>
);
