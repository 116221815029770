import { currencyMap } from 'configuration/data/currencies';
import currency from 'currency.js';
import { CURRENCY_SYMBOL } from 'enums';
/**
 * Takes a number and adds a comma before every 3 digits
 * e.g. 1000 -> 1,000
 */
export const formatMoney = (n: number, symbol = CURRENCY_SYMBOL.GBP.toString(), precision?: number): string => {
  if (n === undefined) return '0';

  const currencyObj = currencyMap[symbol];
  return currency(n, {
    symbol: currencyObj?.symbol || CURRENCY_SYMBOL.GBP,
    precision: precision ?? currencyObj?.decimal_digits ?? 2,
  }).format();
};
