import { InvestmentWatchlistItem } from 'common/interfaces';
import { caching } from 'configuration/data';
import { FinanceProfileDocument, InvestmentsWatchlistAddDocument, InvestmentsWatchlistRemoveDocument } from 'configuration/graphql/documents';

import { API_TAGS, baseApi } from './base.api';

export const investmentWatchlistApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    investmentWatchlist: builder.query<InvestmentWatchlistItem[], { userId: string; }>({
      query: (variables) => ({
        variables,
        document: FinanceProfileDocument
      }),
      transformResponse: ({ fetchFinanceProfile }: { fetchFinanceProfile: { investment: { watchlist: InvestmentWatchlistItem[] } } }) => fetchFinanceProfile?.investment?.watchlist || null,
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.INVESTMENT_WATCHLIST]
    }),
    investmentWatchlistAdd: builder.mutation<InvestmentWatchlistItem[], { userId: string; } & { symbol: string; tags: string[]; idea: string; }>({
      query: (variables) => ({
        variables,
        document: InvestmentsWatchlistAddDocument
      }),
      // invalidatesTags: [API_TAGS.INVESTMENT_WATCHLIST]
    }),
    investmentWatchlistRemove: builder.mutation<InvestmentWatchlistItem[], { userId: string; symbol: string; }>({
      query: (variables) => ({
        variables,
        document: InvestmentsWatchlistRemoveDocument
      }),
      // invalidatesTags: [API_TAGS.INVESTMENT_WATCHLIST]
    }),
  }),
});

export const {
  useInvestmentWatchlistQuery,
  useInvestmentWatchlistAddMutation,
  useInvestmentWatchlistRemoveMutation
} = investmentWatchlistApi;
