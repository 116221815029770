import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

interface Props extends IconProps {
  strokeColor?: string;
  iconHeight?: number;
  iconWidth?: number;
  filled?: boolean;
}

export const CheckIcon: React.FC<Props> = ({
  color,
  height = 6,
  width = 8,
  strokeColor = 'currentColor',
  iconHeight = 20,
  iconWidth = 20,
  filled,
  
}) =>
  filled ? (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="8.25" fill="#444945" stroke={strokeColor} strokeWidth="1.5" />
      <path
        d="M12.75 6.75L7.24997 12L4.5 9.375"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <div
      className={`${color} rounded-full flex items-center justify-center`}
      style={{ height: iconHeight, width: iconWidth }}
    >
      <svg width={width} height={height} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.58126 5.2882C2.66638 5.36856 2.77576 5.40869 2.88464 5.40869C2.99526 5.40869 3.10625 5.36718 3.19185 5.28471L3.19198 5.28458L7.68145 0.947844C7.68145 0.947843 7.68145 0.947842 7.68145 0.947841C7.85709 0.77818 7.86201 0.498267 7.69232 0.322581C7.52256 0.146747 7.24246 0.142197 7.06696 0.311709L7.06696 0.311713L2.88097 4.35522L1.05034 2.62909L1.05033 2.62908L2.68042 5.18315M2.58126 5.2882L2.68042 5.18315M2.58126 5.2882L2.58132 5.28825L2.68042 5.18315M2.58126 5.2882L0.443616 3.27259L0.542699 3.16747L2.68042 5.18315M0.42513 2.64756C0.257723 2.82517 0.265916 3.10508 0.443597 3.27257L0.53033 2.74656L0.425248 2.64744C0.425209 2.64748 0.42517 2.64752 0.42513 2.64756Z"
          fill={strokeColor}
          stroke={strokeColor}
          strokeWidth="0.288911"
        />
      </svg>
    </div>
  );
