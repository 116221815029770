import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { accountsApi } from 'api';
import { financeApi } from 'api/finance.api';
import {
  HistoricalBalance,
  InvestmentAccountInterface,
  InvestmentHolding,
  InvestmentMapType,
  InvestmentPortfolioType,
  SecurityClassEnum,
  SecurityClassStatus,
} from 'common/interfaces';
import { INVESTMENT_TYPES } from 'configuration/assets/account-types';
import { investmentColors } from 'configuration/data/investment-colors';
import { StoreInterface } from 'configuration/redux/store';
import { format } from 'date-fns';
import { DATE_FORMAT, DATE_RANGE, RISK_LEVEL, AccountTypeEnum } from 'enums';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { AssetsInterface } from 'slices/assets';
import { WealthProviderEnum } from 'enums';
import { calcPercentageChange, captureException } from 'utils';
import { getDatePeriodArray, getDateRange } from 'utils/dates';
import { getPercentage } from 'utils/numbers';
import { getRiskScore, INVESTMENT_ANALYSIS_VIEW, riskDistributions } from 'views/investment-analysis/data';

import { getAccountsAllocations, getAssetClassStatus } from './utils';
import { getInvestmentPortfolio } from 'utils/get-investment-portfolio';

interface InvestmentsTotals {
  netWorth: number;
  totalGrowth: number;
  totalGains: number;
  largestPosition: {
    value: number;
    name: string;
  };
  topGainer: {
    value: number;
    name: string;
  };
}

export interface InvestmentSeries {
  id?: string;
  name: string;
  data: [string, number][];
  color?: string;
}

export interface InvestmentsStateInterface {
  totals: InvestmentsTotals;
  filters: {
    date: DATE_RANGE;
    mapType: InvestmentMapType;
  };
  series: {
    [InvestmentMapType.LINE]: InvestmentSeries;
    [InvestmentMapType.LINE_STACKED]: InvestmentSeries[];
    [InvestmentMapType.HOLDINGS]: { [id: string]: InvestmentSeries };
  };
  accounts: InvestmentAccountInterface[];
  allocation: {
    sectors: any;
    countries: any;
  };
  portfolio: InvestmentPortfolioType;
  status: {
    isLoaded: boolean;
    isUpdating: boolean;
  };
  riskProfile: {
    score?: {
      id: RISK_LEVEL;
      percentage: number;
      value: number;
    };
    assetClassWarning?: { [ac: string]: SecurityClassStatus };
  };
  watchlist: {
    symbol: string;
    idea: string;
    tags: string[];
  }[];
}

const initialState: InvestmentsStateInterface = {
  status: {
    isLoaded: false,
    isUpdating: true,
  },
  totals: {
    netWorth: 0,
    totalGrowth: 0,
    totalGains: 0,
    largestPosition: null,
    topGainer: null,
  },
  filters: {
    date: DATE_RANGE.LAST_3_MONTHS,
    mapType: InvestmentMapType.LINE,
  },
  series: {
    [InvestmentMapType.LINE]: {
      name: '',
      data: [],
    },
    [InvestmentMapType.LINE_STACKED]: [],
    [InvestmentMapType.HOLDINGS]: {},
  },
  accounts: [],
  allocation: {
    sectors: [],
    countries: [],
  },
  portfolio: {
    isa: {
      total: 0,
      growth: 0,
      accounts: [],
    },
    other: {
      total: 0,
      growth: 0,
      accounts: [],
    },
    pension: {
      total: 0,
      growth: 0,
      accounts: [],
    },
  },
  riskProfile: {
    score: null,
    assetClassWarning: null,
  },
  watchlist: [],
};

const slice = createSlice({
  initialState,
  name: 'investments',
  reducers: {
    updateDate: (state, { payload }: PayloadAction<DATE_RANGE>) => {
      state.filters.date = payload;
      state.status.isUpdating = true;
    },
    updateMapType: (state, { payload }: PayloadAction<InvestmentMapType>) => {
      state.filters.mapType = payload;
    },
    generateInvestments: (state, { payload }: PayloadAction<HistoricalBalance[]>) => {
      const [from, to] = getDateRange(state.filters.date);
      const prefilledAccountSeriesDates = getDatePeriodArray(new Date(from), new Date(to)).reduce((acc, curr) => {
        acc[format(curr, DATE_FORMAT.DEFAULT)] = 0;
        return acc;
      }, {});

      if (payload.length) {
        // date series object
        const s: { [date: string]: number } = {};
        const holdingSeries: { [id: string]: InvestmentSeries } = {};
        const stackedSeries: InvestmentSeries[] = [];
        const accounts: InvestmentAccountInterface[] = JSON.parse(JSON.stringify(state.accounts));

        const totalGrowth = {
          startAmount: 0,
          finalAmount: 0,
          gains: 0,
        };

        payload.forEach((p) => {
          const account = accounts.find((f) => f.id === p.accountSummary.accountId);
          const accountSeries: { [date: string]: number } = prefilledAccountSeriesDates;

          totalGrowth.startAmount += p.growth.startAmount;
          totalGrowth.finalAmount += p.growth.finalAmount;
          totalGrowth.gains += p.growth.growthValue;

          // Create series data
          p.balanceHistory.forEach((b) => {
            s[b.date] = !s[b.date] ? b.convertedAmount : s[b.date] + b.convertedAmount;
            accountSeries[b.date] = b.convertedAmount;
          });

          if (p.holdings.length) {
            p.holdings.forEach((h) => {
              const holding = account.holdings.find((f) => f.id === h.id);
              if (holding) {
                holdingSeries[h.id] = {
                  name: holding.security.name,
                  data: h.balanceHistory
                    .slice()
                    .reverse()
                    .map((bh) => [bh.date, bh.convertedAmount]),
                  color: account.color,
                };
                h.balanceHistory.forEach((i) => {
                  s[i.date] = !s[i.date] ? i.convertedAmount : s[i.date] + i.convertedAmount;
                  accountSeries[i.date] = !accountSeries[i.date]
                    ? i.convertedAmount
                    : accountSeries[i.date] + i.convertedAmount;
                });

                holding.values = {
                  value: h.growth.finalAmount,
                  growthValue: h.growth.growthValue,
                  growthPercentage: h.growth.growthPercentage,
                  weight: 0,
                };
              }
            });
          }
          // MONEYHUB holdings is empty in balance history
          if (account.vendor === WealthProviderEnum.MoneyHub) {
            // We don't know what the contributions are for MH accounts.
            account.holdings.forEach((h) => {
              h.values = {
                value: h.balance.convertedAmount,
                growthValue: 0,
                growthPercentage: 0,
                weight: 0,
              };
            });
          }

          account.values = {
            value: p.growth.finalAmount,
            growthPercentage: p.growth.growthPercentage,
            growthValue: p.growth.growthValue,
            weight: 0,
          };

          stackedSeries.push({
            id: account.id,
            name: account.name,
            data: orderBy(
              Object.keys(accountSeries).map((k) => [k, accountSeries[k]]),
              (serie) => serie[0],
            ),
            color: account.color,
          });
        });

        const series: [string, number][] = orderBy(
          Object.keys(s).map((k) => [k, s[k]]),
          (serie) => serie[0],
        );

        const netWorth = series[series.length - 1][1];
        // calculate weights
        accounts.forEach((acc) => {
          acc.values.weight = getPercentage(netWorth, acc.values.value, 1);
          if (acc.holdings.length) {
            acc.holdings
              .filter((f) => f.values)
              .forEach((hol) => {
                hol.values.weight = getPercentage(netWorth, hol.values.value, 1);
              });
            acc.holdings = acc.holdings.sort((a, b) => b.values.weight - a.values.weight);
          }
        });

        const totals: InvestmentsTotals = {
          netWorth,
          totalGrowth: Number(calcPercentageChange(totalGrowth.startAmount, totalGrowth.finalAmount).toFixed(1)),
          totalGains: totalGrowth.gains,
          largestPosition: null,
          topGainer: null,
        };

        const holdings = accounts.map((a) => a.holdings).flat();
        if (holdings.length) {
          const largestPosition = orderBy<InvestmentHolding>(holdings, (h) => h.values.value, 'desc')[0];
          totals.largestPosition = {
            value: largestPosition.values.value,
            name: largestPosition.security.name,
          };
          const topGainer = orderBy<InvestmentHolding>(holdings, (h) => h.values.growthPercentage, 'desc')[0];
          totals.topGainer = {
            value: topGainer.values.growthPercentage,
            name: topGainer.security.name,
          };
        }

        // TODO: we may have other policies than ISA
        state.portfolio = getInvestmentPortfolio(accounts);

        state.accounts = accounts;
        state.series = {
          line: {
            name: 'Portfolio Value',
            data: series,
          },
          lineStacked: stackedSeries,
          holdings: holdingSeries,
        };
        state.totals = totals;
        state.status.isUpdating = false;
        if (!state.status.isLoaded) {
          state.status.isLoaded = true;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(accountsApi.endpoints.fetchAccounts.matchFulfilled, (state, { payload }) => {
        state.accounts = [...payload]
          .filter((f) => INVESTMENT_TYPES.includes(f.type))
          .map((a: AssetsInterface, i: number) => ({
            ...a,
            values: {
              value: 0,
              growthValue: 0,
              growthPercentage: 0,
              weight: 0,
            },
            holdings: a.holdings.map((h) => ({
              ...h,
              values: {
                value: 0,
                growthValue: 0,
                growthPercentage: 0,
                weight: 0,
              },
            })),
            color: investmentColors[i],
          }));

        if (!state.status.isLoaded) {
          state.status.isLoaded = true;
        }

        state.allocation = getAccountsAllocations(payload);
      })
      .addMatcher(financeApi.endpoints.financeProfile.matchFulfilled, (state, { payload }) => {
        if (!payload) {
          state.riskProfile.assetClassWarning = {};
          return;
        }
        try {
          state.riskProfile.score = getRiskScore(payload.risk);
          const assetClassWarnings = {};
          const accounts = state.accounts.filter((f) => !payload.excludeRiskAssessment.includes(f.id));
          const holdings = accounts.map((a) => a.holdings).flat();
          let totalAmount = holdings.reduce((a, c) => a + c.balance.convertedAmount, 0) || 0;
          const distributions = riskDistributions[INVESTMENT_ANALYSIS_VIEW.ASSET_CLASS][state.riskProfile.score.id];
          const totalCash = accounts
            .filter((f) => f.vendor === WealthProviderEnum.Otto)
            .reduce((a, c) => a + c.convertedBalance, 0);
          const cashStatus = getAssetClassStatus(distributions.CASH, totalCash / totalAmount);
          if (cashStatus !== SecurityClassStatus.BALANCED) {
            assetClassWarnings[SecurityClassEnum.CASH] = cashStatus;
          }
          totalAmount += totalCash;
          const groupedByClass = groupBy(holdings, (h) => h.security.class);
          Object.keys(SecurityClassEnum).forEach((k: string) => {
            if (k !== SecurityClassEnum.CASH) {
              const perc = groupedByClass[k]
                ? Number(groupedByClass[k].reduce((a, c) => a + c.balance.convertedAmount, 0) / totalAmount)
                : 0;
              const status = getAssetClassStatus(distributions[k], perc);
              if (status !== SecurityClassStatus.BALANCED) {
                assetClassWarnings[k] = status;
              }
            }
          });
          state.riskProfile.assetClassWarning = assetClassWarnings;
        } catch (error) {
          captureException(error);
        }
      });
  },
});


export default slice.reducer;

const selectInvestments = (state: StoreInterface) => state.investments;

export const investmentsSelector = createSelector(
  (state: StoreInterface) => state,
  (values) => values.investments,
);

export const investmentsAccountsSelector = createSelector(selectInvestments, (v) => v.accounts);
export const investmentsFiltersSelector = createSelector(selectInvestments, (v) => v.filters);

export const investmentsAllocationSelector = createSelector(selectInvestments, (v) => v.allocation);
export const investmentsPortfolioSelector = createSelector(selectInvestments, (v) => v.portfolio);
export const investmentsTotalsSelector = createSelector(selectInvestments, (v) => v.totals);
export const investmentsStatusSelector = createSelector(selectInvestments, (v) => v.status);
export const investmentsSeriesSelector = createSelector(selectInvestments, (v) => v.series);
export const investmentsRiskProfileSelector = createSelector(selectInvestments, (v) => v.riskProfile);

export const investmentsActions = slice.actions;
