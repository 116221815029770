import { baseApi } from "api";
import { useLazyFetchAccountsQuery } from "api/accounts.api";
import { API_TAGS } from "api/base.api";
import { useAppDispatch } from "configuration/redux/store";
import { useCallback } from "react";
import { useLoginHook } from "views/login/hooks";

export const useAccounts = (): { refetch: () => Promise<void>; } => {
  const { userData, currency } = useLoginHook();
  const dispatch = useAppDispatch();
  const [getAccounts] = useLazyFetchAccountsQuery();
  const refetch = useCallback(async () => {
    await getAccounts({ userId: userData.id, convertTo: currency })
      .unwrap()
      .then(() => {
        dispatch(baseApi.util.invalidateTags([
          API_TAGS.FINANCE_OVERVIEW,
          { type: API_TAGS.HISTORICAL_BALANCES, id: 'ALL' }
        ]));
      });
  }, []);
  return {
    refetch
  };
};
