import { IconProps } from 'common/otto-ui/icons/icon.type';
import { ColorTheme } from 'configuration/ui';
import React from 'react';

interface CustomIconProps extends IconProps {
  /**
   * Allocated primary color/gradient to this decrease icon
   */
  isPrimaryColor?: boolean;
}

export const DecreaseIcon: React.FC<CustomIconProps> = ({
  height = 21,
  width = 20,
  isPrimaryColor,
  color = ColorTheme.secondaryColor,
}) => (
  <svg width={width} height={height} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.44327 13.0357C7.69071 13.4643 8.30929 13.4643 8.55673 13.0357L15.2375 1.46428C15.4849 1.03571 15.1756 0.5 14.6808 0.5H1.31923C0.82436 0.5 0.515066 1.03571 0.762502 1.46429L7.44327 13.0357Z"
      fill={isPrimaryColor ? 'url(#paint0_linear_1956:315)' : color}
    />
    {isPrimaryColor ? (
      <defs>
        <linearGradient
          id="paint0_linear_1956:315"
          x1="-1"
          y1="9.00005"
          x2="16.208"
          y2="9.00005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={ColorTheme.primaryColorGradient[0]} />
          <stop offset="1" stopColor={ColorTheme.primaryColorGradient[1]} />
        </linearGradient>
      </defs>
    ) : null}
  </svg>
);
