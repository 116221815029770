import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const NoAudioIcon: React.FC<IconProps> = ({ color = 'currentColor', height = 12, width = 12 }) => (
  <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <path d="M6.30304 0.608337C5.13655 0.608337 4.1875 1.55739 4.1875 2.72387V6.31906C4.1875 7.48554 5.13655 8.43459 6.30304 8.43459C7.46952 8.43459 8.41857 7.48004 8.41857 6.30683V2.72387C8.41857 1.55739 7.46965 0.608337 6.30304 0.608337ZM7.68486 6.30683C7.68486 7.0755 7.06498 7.70088 6.30304 7.70088C5.54109 7.70088 4.92122 7.0809 4.92122 6.31906V2.72387C4.92122 1.96191 5.54109 1.34206 6.30304 1.34206C7.06498 1.34206 7.68486 1.96193 7.68486 2.72387V6.30683Z" />
      <path d="M6.67512 9.49695H5.94141V11.16H6.67512V9.49695Z" />
      <path d="M7.8214 10.7077H4.78872C4.58611 10.7077 4.42188 10.8719 4.42188 11.0746C4.42188 11.2772 4.58611 11.4414 4.78872 11.4414H7.8214C8.02402 11.4414 8.18825 11.2772 8.18825 11.0746C8.18825 10.8719 8.02402 10.7077 7.8214 10.7077Z" />
      <path d="M9.39822 4.50858C9.19561 4.50858 9.03138 4.67281 9.03138 4.87542V6.3184C9.03138 7.82202 7.80816 9.04536 6.30441 9.04536C4.80066 9.04536 3.57745 7.82202 3.57745 6.3184V4.87542C3.57745 4.67279 3.41322 4.50858 3.2106 4.50858C3.00798 4.50858 2.84375 4.67281 2.84375 4.87542V6.3184C2.84375 8.22666 4.39617 9.77906 6.30441 9.77906C8.21265 9.77906 9.76507 8.22664 9.76507 6.3184V4.87542C9.76507 4.67281 9.60086 4.50858 9.39822 4.50858Z" />
      <path d="M10.7699 1.55766C10.6266 1.41433 10.3945 1.41433 10.2512 1.55766L1.83796 9.97088C1.69464 10.1141 1.69464 10.3464 1.83796 10.4896C1.90951 10.5613 2.00342 10.5971 2.09733 10.5971C2.19112 10.5971 2.28516 10.5613 2.3567 10.4896L10.7699 2.0764C10.9132 1.9332 10.9132 1.70085 10.7699 1.55766Z" />
    </g>
  </svg>
);
