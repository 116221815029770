import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

interface CustomProps extends IconProps {
  outline?: boolean;
}

export const PlusIcon: React.FC<CustomProps> = ({ height = 22, width = 22, outline = true }) => (
  <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2732 14.944H11.9692V11.776H14.8972V10.208H11.9692V7.04H10.2732V10.208H7.29719V11.776H10.2732V14.944Z"
      fill="url(#paint0_linear_5:8661)"
    />
    {outline && <circle cx="11" cy="11" r="10.5" stroke="url(#paint1_linear_5:8661)" />}
    <defs>
      <linearGradient id="paint0_linear_5:8661" x1="3" y1="10" x2="18.4872" y2="10" gradientUnits="userSpaceOnUse">
        <stop stopColor="#53DADA" />
        <stop offset="1" stopColor="#61D57F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5:8661"
        x1="2.11287e-08"
        y1="11.0001"
        x2="21.032"
        y2="11.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#53DADA" />
        <stop offset="1" stopColor="#61D57F" />
      </linearGradient>
    </defs>
  </svg>
);
