import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const VideoIcon: React.FC<IconProps> = ({ color = 'currentColor', height = 12, width = 12 }) => (
  <svg width={width} height={height} viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g stroke={color} strokeWidth="0.731107">
      <path d="M10 3.66667L12 2V7L10 5.33333" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.365553 1.72602C0.365553 1.3592 0.662914 1.06184 1.02973 1.06184H9.26755C9.63436 1.06184 9.93173 1.3592 9.93173 1.72602V7.38952C9.93173 7.75633 9.63436 8.05369 9.26755 8.05369H1.02973C0.662915 8.05369 0.365553 7.75633 0.365553 7.38952V1.72602Z" />
    </g>
  </svg>
);
