import { Channel } from "pusher-js";
import { useContext, useEffect, useMemo } from "react";

import { PusherContext } from "./pusher.provider";

export const useChannel = <T extends Channel>(channelName: string): T => {
  /**
   * Subscribe to a Pusher channel
   * See: https://pusher.com/docs/channels/using_channels/channels/
   */
  const { subscribe, unsubscribe, getChannel } = useContext(PusherContext);
  const channel = useMemo((): T => {
    if (!channelName) return null;
    if (!getChannel(channelName)) {
      return subscribe(channelName);
    }
    return getChannel(channelName);
  }, [channelName]);
  useEffect(() => {
    return () => {
      unsubscribe(channelName);
    };
  }, [channelName]);
  return channel;
};
