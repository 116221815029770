import { IconProps } from 'common/otto-ui/icons/icon.type';
import { useTheme } from 'common/roq-hooks/use-theme';
import React from 'react';

export const SparkleCheckIcon: React.FC<IconProps> = ({
  height = 74,
  width = 74,
  color = 'black',
  darkColor = 'white',
}) => {
  const { isDark } = useTheme();
  const themeColor = isDark ? darkColor : color;
  return (
    <svg width={width} height={height} viewBox="0 0 96 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="48.5" cy="51.5" r="30" stroke={themeColor} strokeWidth="3" />
      <path
        d="M61.625 43.625L42.3749 62L32.75 52.8125"
        stroke={themeColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 16V20.5M5.5 25V20.5M5.5 20.5H10M5.5 20.5H1"
        stroke={themeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M87.5 2V8.5M87.5 15V8.5M87.5 8.5H94M87.5 8.5H81"
        stroke={themeColor}
        strokeWidth="2.16667"
        strokeLinecap="round"
      />
      <path d="M15 77V80M15 83V80M15 80H18M15 80H12" stroke={themeColor} strokeLinecap="round" />
      <path d="M80 87V91M80 95V91M80 91H84M80 91H76" stroke={themeColor} strokeWidth="1.33333" strokeLinecap="round" />
      <rect x="36" y="8" width="1.77267" height="1.77267" rx="0.886335" fill={themeColor} />
      <rect x="88.9985" y="28.3267" width="2.66674" height="2.66674" rx="1.33337" fill={themeColor} />
      <rect x="87" y="70" width="1.77267" height="1.77267" rx="0.886335" fill={themeColor} />
      <rect x="48" y="89" width="1.77267" height="1.77267" rx="0.886335" fill={themeColor} />
      <rect x="23" y="97" width="1" height="1" rx="0.5" fill={themeColor} />
      <rect x="8.00049" y="57.9985" width="2" height="2" rx="1" fill={themeColor} />
    </svg>
  );
};
