import { IconProps } from 'common/otto-ui/icons/icon.type';
import { ColorTheme } from 'configuration/ui';
import React from 'react';

export const CloseIcon: React.FC<IconProps> = ({ color = ColorTheme.currentColor, height = 32, width = 32 }) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
      <path d="M25 7L7 25" />
      <path d="M25 25L7 7" />
    </g>
  </svg>
);
