import { createApi } from '@reduxjs/toolkit/query/react';
import { caching } from 'configuration/data';
import { moneyhubBaseQuery } from 'configuration/redux/base-queries';

export interface FetchCategoriesResponseInterface {
    data: {
        categoryId?: string;
        group?: string;
        key: string;
    }[];
    meta: {
        correlationId: string;
    };
    links: {
        self: string;
    };
}

export interface FetchCategoriesReturnInterface {
    category: string;
}

export interface FetchCategoryGroupsReturnInterface {
    categoryGroup: string;
};

export const categoriesApi = createApi({
    reducerPath: 'categoriesApi',
    baseQuery: moneyhubBaseQuery,
    endpoints: (builder) => ({
        fetchCategories: builder.query<FetchCategoriesReturnInterface[], undefined>({
            query: () => `standard-categories`,
            transformResponse: (response: FetchCategoriesResponseInterface) =>
                response.data.map(({ key }) => ({ category: key })),
            keepUnusedDataFor: caching.day,
        }),
        fetchCategoryGroups: builder.query<FetchCategoryGroupsReturnInterface[], undefined>({
            query: () => `standard-category-groups`,
            transformResponse: (response: FetchCategoriesResponseInterface) =>
                response.data.map(({ key }) => ({ categoryGroup: key })),
            keepUnusedDataFor: caching.day,
        }),
    }),
});

export const { useFetchCategoriesQuery, useFetchCategoryGroupsQuery } = categoriesApi;
