import omit from "lodash/omit";
import { AuthStateInterface } from "slices/auth-management";
import { CalendarStateInterface } from "slices/calendar/calendar.slice";
import { FinanceAuthStateInterface } from "slices/finance-auth";
import { SessionStateInterface } from "slices/session";
import { UiInterface } from "slices/ui";

export const loadState = (reduxVersion: string): any => {
  /**
   * Loads the persisted state from localStorage but reset
   * the storage if we have a new redux version
   */
  try {
    if (reduxVersion !== localStorage.getItem('reduxVersion')) {
      localStorage.removeItem('state');
      localStorage.setItem('reduxVersion', reduxVersion);
      return undefined;
    } else {
      const serializedState = localStorage.getItem('state');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    }
  } catch (err) {
    return undefined;
  }
};

interface stateInterface {
  survey: Record<string, any>;
  ui: UiInterface;
  auth: Partial<AuthStateInterface>;
  financeAuth: Partial<FinanceAuthStateInterface>;
  session: Partial<SessionStateInterface>;
  calendar: Partial<CalendarStateInterface>;
}

export const clearState = (fields: string[]): any => {
  try {
    const oldState = JSON.parse(localStorage.getItem('state')) || {};
    localStorage.setItem('state', JSON.stringify(omit(oldState, fields)));
  } catch (err) {
    console?.error(err);
  }
};

export const saveState = (state: Partial<stateInterface>): any => {
  try {
    const oldState = JSON.parse(localStorage.getItem('state')) || {};
    localStorage.setItem('state', JSON.stringify({ ...oldState, ...state}));
  } catch (err) {
    console?.error(err);
  }
};
