import { Skeleton as S, theme } from "@otto-finance/ui";

type SkeletonProps = {
  width?: string;
  height?: string;
  circle?: boolean;
  border?: string;
  color?: string;
  animation?: boolean;
  rows?: number;
  opacity?: number;
};

export const Skeleton = ({
  circle,
  border = theme.sizing.scale200,
  color,
  opacity,
  ...props
}: SkeletonProps) => <S
    {...props}
    overrides={{
      Root: {
        style: {
          borderRadius: circle ? '50%' : border,
          ...(color && { backgroundColor: color }),
          ...(opacity && { opacity })
        },
      }
    }}
  />;

