import { gql } from 'graphql-request';

export const GetCoachMatchDocument = gql`
  query userCoach($userId: String!) {
    user(id: $userId) {
      customerMetaInfo {
        coachMatches {
          data {
            id
            isActive
            coachMetaInfo {
              user {
                firstName
                lastName
                status
                avatar
                avatarImageIdentifier
              }
              about
              coachExpertises {
                data {
                  name
                  icon
                }
              }
            }
          }
        }
      }
    }
  }
`;
