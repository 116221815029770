import { FormControl, Input, InputProps } from "@otto-finance/ui";
import { useField } from 'formik';

export const FormTextField = ({ label, name, caption, ...props }: InputProps & { label: string; caption?: string; }) => {
  const [field, meta] = useField({ name });
  return (
    <div>
      <FormControl
        label={`${label}${props.required ? '*' : ''}`}
        error={meta.touched && meta.error ? meta.error : null}
        caption={caption}>
        <Input
          {...props}
          onChange={field.onChange}
          onBlur={field.onBlur}
          name={name}
          value={field.value}
        />
      </FormControl>
    </div>
  );
};
