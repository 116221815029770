import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const NotesIcon: React.FC<IconProps> = ({ color = 'currentColor', height = 24, width = 24 }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color} stroke={color} strokeWidth="0.353615">
      <path d="M19.0824 4.84365C19.0074 4.56474 18.8284 4.33162 18.5781 4.18711L17.391 3.50084C16.8921 3.21479 16.2034 3.40093 15.915 3.8969L10.228 13.7278C10.2089 13.7611 10.1959 13.7973 10.1905 13.8354C10.1869 13.8616 9.89792 16.4782 10.4926 16.8243C10.5497 16.857 10.6211 16.8719 10.7037 16.8719C11.4709 16.8719 13.1919 15.5957 13.2104 15.5808C13.2407 15.557 13.2657 15.5279 13.2847 15.4946L18.9735 5.66314C19.1186 5.41396 19.1567 5.12256 19.0824 4.84365ZM10.8072 16.2766C10.7144 15.9692 10.6936 15.0593 10.7376 14.3665L12.4795 15.3727C11.9014 15.7616 11.1159 16.2005 10.8072 16.2766ZM12.919 14.9403L10.8911 13.7676L15.8413 5.21474L17.8704 6.38628L12.919 14.9403ZM18.4597 5.36639L18.1677 5.87188L16.1392 4.70033L16.4306 4.19484C16.5603 3.97183 16.8719 3.88798 17.0949 4.01584L18.2813 4.70152C18.3937 4.76634 18.4746 4.8716 18.5085 4.99708C18.5412 5.12316 18.5245 5.2534 18.4597 5.36639Z" />
      <path d="M14.6193 14.4313C14.4552 14.4313 14.3219 14.5645 14.3219 14.7287V17.6551C14.3219 18.1814 13.8938 18.609 13.3681 18.609H2.02905C1.50334 18.609 1.07516 18.1808 1.07516 17.6551V5.52579H3.59309C4.44766 5.52579 5.14167 4.83119 5.14167 3.97721V1.62936H13.3681C13.8938 1.62936 14.3219 2.05754 14.3219 2.58325V4.61888C14.3219 4.78302 14.4552 4.91623 14.6193 4.91623C14.7834 4.91623 14.9166 4.78302 14.9166 4.61888V2.58325C14.9166 1.72867 14.222 1.03467 13.3681 1.03467H4.84492C4.7682 1.03467 4.69446 1.0644 4.63915 1.11733L0.572051 5.01435C0.513177 5.07085 0.480469 5.14816 0.480469 5.22903V17.6557C0.480469 18.5103 1.17507 19.2043 2.02905 19.2043H13.3686C14.2226 19.2043 14.9172 18.5097 14.9172 17.6557V14.7292C14.9166 14.5645 14.784 14.4313 14.6193 14.4313ZM4.54757 2.02899V3.97721C4.54757 4.50351 4.11939 4.93109 3.59369 4.93109H1.51821L4.54757 2.02899Z" />
      <path d="M3.04735 16.0358C2.88321 16.0358 2.75 16.169 2.75 16.3331C2.75 16.4972 2.88321 16.6305 3.04735 16.6305H9.70077C9.64011 16.4479 9.59789 16.2463 9.56815 16.0358H3.04735Z" />
      <path d="M9.97195 12.9858H3.04735C2.88321 12.9858 2.75 13.1191 2.75 13.2832C2.75 13.4473 2.88321 13.5805 3.04735 13.5805H9.6633C9.68114 13.5306 9.68828 13.4765 9.71504 13.4301L9.97195 12.9858Z" />
      <path d="M11.7358 9.93616H3.04735C2.88321 9.93616 2.75 10.0694 2.75 10.2335C2.75 10.3976 2.88321 10.5308 3.04735 10.5308H11.3921L11.7358 9.93616Z" />
      <path d="M12.651 7.18284C12.651 7.01871 12.5178 6.8855 12.3537 6.8855H3.04735C2.88321 6.8855 2.75 7.01871 2.75 7.18284C2.75 7.34698 2.88321 7.48019 3.04735 7.48019H12.3537C12.5178 7.48019 12.651 7.34757 12.651 7.18284Z" />
      <path d="M12.3541 3.83691H6.25438C6.09024 3.83691 5.95703 3.97013 5.95703 4.13426C5.95703 4.2984 6.09024 4.43161 6.25438 4.43161H12.3541C12.5183 4.43161 12.6515 4.2984 12.6515 4.13426C12.6515 3.97013 12.5183 3.83691 12.3541 3.83691Z" />
    </g>
  </svg>
);
