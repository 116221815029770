import { styled } from "@otto-finance/ui";

export const Grid = styled<'div', { gap?: string; rows?: number; }>(
  'div',
  ({ $theme, gap, rows }) => ({
    display: 'grid',
    gridTemplateColumns: rows ? `repeat(${rows}, 1fr)` : '1fr', 
    gap: gap ?? $theme.sizing.scale400
  }),
);
