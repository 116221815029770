export const investmentColors = [
  '#5B91F5', 
  '#1E54B7', 
  '#87aade', 
  '#66D19E', 
  '#FFCF70', 
  '#957FCE', 
  '#CE7EB3',
  '#E85C4A', 
  '#FA9269', 
  '#FFCF70', 
  '#8BB956', 
  '#06C167', 
  '#56A9B9', 
  '#535FCF', 
  '#957FCE', 
  '#CE7EB3', 
  '#B18977',
  '#5B91F5', 
  '#1E54B7', 
  '#87aade', 
  '#66D19E', 
  '#FFCF70', 
  '#957FCE', 
  '#CE7EB3',
  '#E85C4A', 
  '#FA9269', 
  '#FFCF70', 
  '#8BB956', 
  '#06C167', 
  '#56A9B9', 
  '#535FCF', 
  '#957FCE', 
  '#CE7EB3', 
  '#B18977'
];
