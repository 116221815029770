
import { StoreInterface } from 'configuration/redux/store';
import { CURRENCY_SYMBOL, UserChannelEnum } from 'enums';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AuthStateInterface, AuthStateUserDataInterface } from 'slices/auth-management';

interface UseLoginInterface extends Partial<AuthStateInterface> {
  isBenefitSessionsExpired: boolean;
  userData: Partial<AuthStateUserDataInterface>;
  currency: string;
}

export const useLoginHook = (): UseLoginInterface => {
  const { 
    isLoading, 
    error, 
    accessToken,
    userData, 
    isAdmin, 
    isCoach, 
    isCustomer,
    isLogged,
    guest
  } = useSelector<StoreInterface, AuthStateInterface>(state => state.auth);

  const isBenefitSessionsExpired = useMemo(() => userData?.channel === UserChannelEnum.BENEFITS && userData?.benefit?.sessions === 0, [userData?.benefit]);

  return {
    isLoading,
    error,
    accessToken,
    userData,
    isAdmin,
    isCoach,
    isCustomer,
    isBenefitSessionsExpired,
    isLogged,
    currency: userData?.currency || CURRENCY_SYMBOL.GBP,
    guest
  };
};
