import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const ConfettiCircleIcon: React.FC<IconProps> = ({ color = '#53DADA', height = 10, width = 10 }) => (
  <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.24186 4.75754C9.24186 7.03853 7.33755 8.91509 4.95413 8.91509C2.57072 8.91509 0.666406 7.03853 0.666406 4.75754C0.666406 2.47656 2.57072 0.6 4.95413 0.6C7.33755 0.6 9.24186 2.47656 9.24186 4.75754Z"
      stroke={color}
      strokeWidth="1.2"
    />
  </svg>
);
