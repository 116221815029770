import { gql } from 'graphql-request';

const ruleModel = `
  id
  userId
  name
  disabled
  notes
  rule
  input
  dates {
    createdAt
    updatedAt
  }
  schedule {
    from
    to
    cron
    date
  }
  stages {
    name
    input
  }
`;
export const CreateRuleDocument = gql`
  mutation CreateRule($rule: RuleCreateDto!) {
    createRule(rule: $rule) {
      ${ruleModel}
    }
  }
`;

export const ListRulesDocument = gql`
  query ListRules($id: String, $limit: Float, $offset: Float, $order: [RuleOrderArgType!] = [], $userId: String) {
    listRules(id: $id, limit: $limit, offset: $offset, order: $order, userId: $userId) {
      data {
        ${ruleModel}
      }
      limit
      offset
      totalCount
    }
  }
`;

export const UpdateRuleDocument = gql`
  mutation UpdateRule($id: String!, $rule: RuleUpdateDto!) {
    updateRule(id: $id, rule: $rule) {
      ${ruleModel}
    }
  }
`;

export const DeleteRuleDocument = gql`
  mutation DeleteRule($id: String!) {
    deleteRule(id: $id)
  }
`;

export const RulesHistoryDocument = gql`
  query RulesHistory($ids: [String!], $limit: Float, $offset: Float, $order: [RulesHistoryOrderArgType!] = [], $ruleIds: [String!], $userId: String) {
    rulesHistory(ids: $ids, limit: $limit, offset: $offset, order: $order, ruleIds: $ruleIds, userId: $userId) {
      data {
        dates {
          createdAt
          updatedAt
        }
        error
        id
        result
        rule {
          ${ruleModel}
        }
        status
        type
        userId
      }
      limit
      offset
      totalCount
    }
  }
`;
