import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const ConfettiFlashIcon: React.FC<IconProps> = ({ color = '#53DADA', height = 19, width = 20 }) => (
  <svg width={width} height={height} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.90332 1.01672L7.84877 5.98252M13.374 7.84331L18.9858 4.68961M12.1197 13.1829L15.2124 18.397M6.48669 11.7138L1.38501 14.5808"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
