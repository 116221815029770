import { AssetsTransactionInterface, HistoricalBalanceSerie } from 'slices/assets';
import { ColorTheme } from 'configuration/ui';
import { format } from 'date-fns';
import { getDaysBetween, getMonthsBetween } from 'utils';

// TODO: Add weeks
type TimeframeType = 'month' | 'day' | '30-day';

interface Props {
  /**
   * The transaction data to generate the graph from
   */
  data: AssetsTransactionInterface[];
  /**
   * The number of months to generate the graph from
   */
  months?: number;
  /**
   * The type of time frame to generate the graph from
   */
  timeframePeriod?: TimeframeType;
  /**
   *
   */
  end?: Date;
}

export const buildTransactionGraphData = ({
  data,
  months = 3,
  timeframePeriod = 'month',
  end = new Date(),
}: Props): HistoricalBalanceSerie[] => {
  /**
   * Builds the graph data from user transactions based on their Income and Expenses
   * from today's date (if end date not provider)
   */

  if (!data) return [];

  const timeframe = () => {
    switch (timeframePeriod) {
      case 'day':
        return getDaysBetween({ end, months });
      default:
        return getMonthsBetween({ end, months });
    }
  };

  /**
   * Seperate the data into Positive and Negative arrays
   */
  const positiveData = data
    .filter((transaction) => transaction.direction === 'IN')
    .sort((a, b) => (new Date(a.date) >= new Date(b.date) ? 1 : -1));

  const negativeData = data
    .filter((transaction) => transaction.direction === 'OUT')
    .sort((a, b) => (new Date(a.date) >= new Date(b.date) ? 1 : -1));

  const calculateTimeframeValues = (dataPoints: AssetsTransactionInterface[]) => {
    /**
     * Calculates the values for each timeframe in unix
     * e.g. [{ x: 1644490352962, y: 100 }, { x: 1644490352962, y: 100 }]
     */
    const graphData: { x: string; y: number }[] = timeframe().map((tf) => ({ x: tf.toString(), y: 0 }));

    dataPoints.forEach((transaction) => {
      let transactionTfIndex: number | null = null;

      timeframe().forEach((tf, index) => {
        if (timeframePeriod === 'month') {
          const tfMonth = format(new Date(tf), 'MMMM yyyy');
          const trMonth = format(new Date(transaction.date), 'MMMM yyyy');
          if (tfMonth === trMonth) {
            transactionTfIndex = index;
          }
        }

        if (timeframePeriod === 'day') {
          const tfWeek = format(new Date(tf), 'dd MMMM yyyy');
          const trWeek = format(new Date(transaction.date), 'dd MMMM yyyy');
          if (tfWeek === trWeek) {
            transactionTfIndex = index;
          }
        }

        if (timeframePeriod === '30-day') {
          const tfPeriod = new Date(tf);
          const trPeriod = new Date(transaction.date);
          const nextPeriod = new Date(timeframe()[index + 1]);

          if (trPeriod >= tfPeriod && trPeriod <= nextPeriod) {
            transactionTfIndex = index + 1;
          } else if (trPeriod < tfPeriod && index === 0) {
            transactionTfIndex = index;
          } 
        };

      });

      if (transactionTfIndex !== null) {
        const currValue = graphData.find((point) => point.x === timeframe()[transactionTfIndex]?.toString());
        if (currValue) {
          currValue.y += transaction.amount;
        }
      }
    });
    return graphData;
  };

  const positive = positiveData.length ? calculateTimeframeValues(positiveData) : [];
  const negative = negativeData.length ? calculateTimeframeValues(negativeData) : [];

  return [
    {
      id: 'Income',
      color: ColorTheme.graphLinePositive,
      data: positive
    },
    {
      id: 'Expenses',
      color: ColorTheme.graphLineNegative,
      data: negative,
    },
  ];
};
