import clsx from 'clsx';
import { ButtonColorEnum, ButtonSizeEnum } from 'common/otto-ui/button/button';
import { useRipple } from 'common/roq-hooks/use-ripple/use-ripple.hook';
import { ButtonHTMLAttributes, MouseEventHandler, ReactNode } from 'react';

export interface ButtonIconInterface extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: Lowercase<keyof typeof ButtonSizeEnum>;
  color?: Lowercase<keyof typeof ButtonColorEnum>;
  children?: ReactNode;
}

export const ButtonIcon = ({
  type,
  size = ButtonSizeEnum.MEDIUM,
  color,
  disabled,
  className,
  children,
  onMouseDown,
  ...props
}: ButtonIconInterface) => {
  const classes = {
    button: clsx(
      'z-0 relative rounded-full font-semibold focus:outline-none overflow-hidden transition duration-150 cursor-pointer pointer-events-auto disabled:pointer-events-none',
      {
        'text-opacity-30 dark:text-opacity-30 text-title dark:text-offWhite': disabled,
        'text-primary text-primary-light hover:bg-primary dark:hover:bg-primary-normal hover:bg-opacity-10 dark:hover:bg-opacity-10 active:bg-primary dark:active:bg-primary-normal active:bg-opacity-10 dark:active:bg-opacity-10 focus:bg-primary dark:focus:bg-primary-normal focus:bg-opacity-20 dark:focus:bg-opacity-20':
          color === ButtonColorEnum.PRIMARY && !disabled,
        'text-secondary text-secondary-light hover:bg-secondary darK:hover:bg-secondary-light hover:bg-opacity-10 dark:hover:bg-opacity-10 active:bg-secondary dark:active:bg-secondary-light active:bg-opacity-10 dark:active:bg-opacity-10 focus:bg-secondary dark:focus:bg-secondary-light focus:bg-opacity-20 dark:focus:bg-opacity-20':
          color === ButtonColorEnum.SECONDARY && !disabled,
      },
      {
        'w-9 h-9 p-2 text-sm': size === ButtonSizeEnum.SMALL,
        'w-12 h-12 p-3 text-xl': size === ButtonSizeEnum.MEDIUM,
        'w-16 h-16 p-3.5 text-2xl': size === ButtonSizeEnum.LARGE,
      },
      className,
    ),
    icon: 'flex justify-center items-center w-full h-full',
  };

  const { addRipple, ripples } = useRipple();
  const handleMouseDown: MouseEventHandler<HTMLButtonElement> = (event) => {
    addRipple(event);
    if (onMouseDown) {
      onMouseDown(event);
    }
  };

  return (
    <button className={classes.button} type={type} onMouseDown={handleMouseDown} disabled={disabled} {...props}>
      {ripples}
      <div className={classes.icon}>{children}</div>
    </button>
  );
};
