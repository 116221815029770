import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const PlannerIcon = ({ color = 'currentColor', size = 24 }: IconProps): JSX.Element => (
  <svg width={size} height={size} viewBox="0,0,256,256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <g transform="scale(2,2)">
        <path d="M39,1c-1.66,0 -3,1.34 -3,3v13.21094c-11.39,0.49 -18.4,2.01914 -23,6.61914c-7.04,7.03 -7.03,19.99938 -7,45.85938v4.79102v4.33984c-0.02,23.19 -0.03016,34.82922 6.58984,41.44922c6.81,6.81 19.46016,6.78024 44.66016,6.74024c2.15,0 4.4,-0.00977 6.75,-0.00977c2.35,0 4.6,-0.00023 6.75,0.00977c1.93,0 3.78031,0.00976 5.57031,0.00976c21.53,0 32.79985,-0.46 39.08985,-6.75c6.62,-6.62 6.60984,-18.25922 6.58984,-41.44922v-4.33984v-4.79102c0.02,-25.86 0.03023,-38.82937 -7.00977,-45.85937c-4.6,-4.59 -11.6,-6.11914 -23,-6.61914v-13.21094c0,-1.66 -1.34,-3 -3,-3c-1.66,0 -3,1.34 -3,3v13.03906c-4.39,-0.07 -9.31109,-0.05883 -14.87109,-0.04883c-2.26,0.01 -4.62914,0.00977 -7.11914,0.00977c-2.49,0 -4.86086,0.00023 -7.13086,-0.00977c-5.56,-0.01 -10.48914,-0.02117 -14.86914,0.04883v-13.03906c0,-1.66 -1.34,-3 -3,-3zM48.99023,22.98242c2.47129,-0.00473 5.09164,0.00156 7.86914,0.00781c2.27,0.01 4.65062,0.00977 7.14063,0.00977c2.49,0 4.87063,0.00023 7.14063,-0.00977c22.21,-0.05 34.45915,-0.06992 39.61915,5.08008c1.95,1.95 3.18117,4.83969 3.95117,8.92969h-101.42188c0.77,-4.09 2.00117,-6.97969 3.95117,-8.92969c4.515,-4.50625 14.45098,-5.0548 31.75,-5.08789zM12.51953,43h102.95117c0.54,6.59 0.5393,15.29969 0.5293,26.67969v4.79102v4.34961c0.02,20.85 0.02992,32.33922 -4.83008,37.19922c-5.05,5.05 -16.87015,5.03024 -40.41015,4.99024c-2.15,-0.01 -4.40977,-0.00977 -6.75977,-0.00977c-2.35,0 -4.60977,-0.00023 -6.75977,0.00977c-23.54,0.04 -35.36016,0.05976 -40.41016,-4.99024c-4.86,-4.86 -4.85008,-16.34922 -4.83008,-37.19922v-4.33984v-4.79102c-0.01,-11.39 -0.02047,-20.09945 0.51953,-26.68945zM81,55c-7.17,0 -13,5.83 -13,13v10c0,7.17 5.83,13 13,13h10c7.17,0 13,-5.83 13,-13v-10c0,-7.17 -5.83,-13 -13,-13zM81,61h10c3.86,0 7,3.14 7,7v10c0,3.86 -3.14,7 -7,7h-10c-3.86,0 -7,-3.14 -7,-7v-10c0,-3.86 3.14,-7 7,-7z" />
      </g>
    </g>
  </svg>
);
