import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const ShareScreenIcon: React.FC<IconProps> = ({ color = 'currentColor', height = 13, width = 12 }) => (
  <svg width={width} height={height} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <path d="M11.0918 1.34219H1.61895C1.03766 1.34219 0.566406 1.81344 0.566406 2.39474V8.70997C0.566406 9.29127 1.03766 9.76252 1.61895 9.76252V8.70997V2.39474H11.0918V8.70997V9.76252C11.6731 9.76252 12.1443 9.29129 12.1443 8.70997V2.39474C12.1443 1.81344 11.6731 1.34219 11.0918 1.34219Z" />
      <path d="M9.21598 9.60782L6.86962 6.85514C6.58637 6.52283 6.12884 6.52362 5.84626 6.85514L3.4999 9.60782C2.93139 10.2748 3.18239 10.8108 4.0556 10.8108H8.66028C9.53499 10.8108 9.7823 10.2722 9.21598 9.60782ZM4.75469 9.75825L6.35794 7.87737L7.96119 9.75825H4.75469Z" />
    </g>
  </svg>
);
