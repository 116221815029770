import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const TicketIcon: React.FC<IconProps> = ({ color = 'currentColor', size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color} stroke={color} strokeWidth="0.4">
      <path d="M12.3576 5.42594C12.1238 5.1918 11.8075 5.05863 11.4767 5.05501L6.28792 5.00022C6.11795 4.99707 5.94909 5.02823 5.79144 5.09183C5.63379 5.15543 5.49058 5.25016 5.37037 5.37037C5.25016 5.49058 5.15543 5.63379 5.09183 5.79144C5.02823 5.94909 4.99707 6.11795 5.00022 6.28792L5.05501 11.4872C5.05863 11.8181 5.1918 12.1343 5.42594 12.3681L12.9203 19.8499C13.0378 19.9675 13.1772 20.0607 13.3307 20.1244C13.4842 20.188 13.6488 20.2207 13.815 20.2207C13.9811 20.2207 14.1457 20.188 14.2992 20.1244C14.4527 20.0607 14.5922 19.9675 14.7096 19.8499L19.8499 14.718C20.0867 14.4809 20.2197 14.1595 20.2197 13.8244C20.2197 13.4894 20.0867 13.168 19.8499 12.9309L12.3576 5.42594ZM19.2556 14.1111L14.1132 19.2535C14.074 19.2926 14.0275 19.3237 13.9764 19.3449C13.9252 19.3662 13.8704 19.3771 13.815 19.3771C13.7596 19.3771 13.7047 19.3662 13.6536 19.3449C13.6024 19.3237 13.5559 19.2926 13.5168 19.2535L6.02237 11.7675C5.94423 11.6898 5.8996 11.5847 5.89802 11.4746L5.84323 6.27527C5.84323 6.16348 5.88764 6.05627 5.96669 5.97722C6.04573 5.89818 6.15294 5.85377 6.26474 5.85377L11.464 5.90856C11.5742 5.91014 11.6793 5.95477 11.757 6.03291L19.2556 13.5168C19.2947 13.5559 19.3258 13.6024 19.3471 13.6536C19.3683 13.7047 19.3792 13.7596 19.3792 13.815C19.3792 13.8704 19.3683 13.9252 19.3471 13.9764C19.3258 14.0275 19.2947 14.074 19.2556 14.1132V14.1111Z" />
      <path d="M8.39498 8.39462C8.10561 8.68408 7.90857 9.05285 7.82878 9.45429C7.74898 9.85574 7.79001 10.2718 7.94667 10.65C8.10334 11.0281 8.3686 11.3513 8.70893 11.5786C9.04926 11.806 9.44936 11.9274 9.85866 11.9274C10.268 11.9274 10.6681 11.806 11.0084 11.5786C11.3487 11.3513 11.614 11.0281 11.7706 10.65C11.9273 10.2718 11.9683 9.85574 11.8885 9.45429C11.8087 9.05285 11.6117 8.68408 11.3223 8.39462C10.9339 8.00685 10.4075 7.78906 9.85866 7.78906C9.30981 7.78906 8.7834 8.00685 8.39498 8.39462ZM10.7133 10.7129C10.5124 10.9133 10.2483 11.0378 9.96588 11.0653C9.6835 11.0927 9.40033 11.0215 9.16459 10.8636C8.92885 10.7058 8.75512 10.4711 8.67299 10.1995C8.59087 9.92793 8.60543 9.63629 8.71419 9.37425C8.82295 9.11221 9.01918 8.89597 9.26947 8.76237C9.51976 8.62877 9.80862 8.58607 10.0869 8.64153C10.3651 8.697 10.6155 8.8472 10.7954 9.06657C10.9754 9.28593 11.0737 9.56088 11.0737 9.8446C11.0741 10.006 11.0425 10.1659 10.9806 10.3149C10.9187 10.464 10.8279 10.5993 10.7133 10.7129Z" />
    </g>
  </svg>
);
