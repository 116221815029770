import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const InvestmentIdeasIcon = ({ color = 'currentColor', size = 24 }: IconProps): JSX.Element => (
  <svg width={size} height={size} viewBox="0,0,256,256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color} fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt"
      strokeLinejoin="miter" strokeMiterlimit="10">
      <g transform="scale(2,2)">
        <path d="M14,1c-7.2,0 -13,5.8 -13,13v100c0,7.2 5.8,13 13,13h100c7.2,0 13,-5.8 13,-13v-100c0,-7.2 -5.8,-13 -13,-13zM14,7h100c3.9,0 7,3.1 7,7v15h-114v-15c0,-3.9 3.1,-7 7,-7zM18,15c-1.7,0 -3,1.3 -3,3c0,1.7 1.3,3 3,3h4c1.7,0 3,-1.3 3,-3c0,-1.7 -1.3,-3 -3,-3zM34,15c-1.7,0 -3,1.3 -3,3c0,1.7 1.3,3 3,3h4c1.7,0 3,-1.3 3,-3c0,-1.7 -1.3,-3 -3,-3zM7,35h114v79c0,3.9 -3.1,7 -7,7h-100c-3.9,0 -7,-3.1 -7,-7zM24,60c-1.7,0 -3,1.3 -3,3c0,1.7 1.3,3 3,3h37c1.7,0 3,-1.3 3,-3c0,-1.7 -1.3,-3 -3,-3zM77,60c-1.7,0 -3,1.3 -3,3v30c0,1.7 1.3,3 3,3h27c1.7,0 3,-1.3 3,-3v-30c0,-1.7 -1.3,-3 -3,-3zM80,66h21v24h-21zM24,75c-1.7,0 -3,1.3 -3,3c0,1.7 1.3,3 3,3h37c1.7,0 3,-1.3 3,-3c0,-1.7 -1.3,-3 -3,-3zM24,90c-1.7,0 -3,1.3 -3,3c0,1.7 1.3,3 3,3h16c1.7,0 3,-1.3 3,-3c0,-1.7 -1.3,-3 -3,-3zM56,90c-1.7,0 -3,1.3 -3,3c0,1.7 1.3,3 3,3h5c1.7,0 3,-1.3 3,-3c0,-1.7 -1.3,-3 -3,-3z" />
      </g>
    </g>
  </svg>
);
