import { SecurityClassEnum } from "common/interfaces";
import { RISK_LEVEL } from "enums";

import { distributionByRegion } from "./region-distributions";
import { maxBySector, maxBySectorStability } from "./sector-distributions";

const distributionByAssetType = {
  [RISK_LEVEL.CAUTIOUS]: {
    [SecurityClassEnum.OVERSEAS_EQUITY]: 0.05,
    [SecurityClassEnum.UK_EQUITY]: 0.15,
    [SecurityClassEnum.BONDS]: 0.5,
    [SecurityClassEnum.CASH]: 0.3,
  },
  [RISK_LEVEL.MODERATELY_CAUTIOUS]: {
    [SecurityClassEnum.OVERSEAS_EQUITY]: 0.1,
    [SecurityClassEnum.UK_EQUITY]: 0.25,
    [SecurityClassEnum.BONDS]: 0.45,
    [SecurityClassEnum.CASH]: 0.2,
  },
  [RISK_LEVEL.BALANCED]: {
    [SecurityClassEnum.OVERSEAS_EQUITY]: 0.15,
    [SecurityClassEnum.UK_EQUITY]: 0.35,
    [SecurityClassEnum.BONDS]: 0.4,
    [SecurityClassEnum.CASH]: 0.1,
  },
  [RISK_LEVEL.MODERATELY_ADVENTUROUS]: {
    [SecurityClassEnum.OVERSEAS_EQUITY]: 0.20,
    [SecurityClassEnum.UK_EQUITY]: 0.50,
    [SecurityClassEnum.BONDS]: 0.25,
    [SecurityClassEnum.CASH]: 0.05,
  },
  [RISK_LEVEL.ADVENTUROUS]: {
    [SecurityClassEnum.OVERSEAS_EQUITY]: 0.25,
    [SecurityClassEnum.UK_EQUITY]: 0.60,
    [SecurityClassEnum.BONDS]: 0.15,
    [SecurityClassEnum.CASH]: 0,
  },
};

export enum INVESTMENT_ANALYSIS_VIEW {
  ASSET_CLASS = 'ASSET_CLASS',
  SECTOR = 'SECTOR',
  REGION = 'REGION',
  SECTOR_STABILITY = 'SECTOR_STABILITY'
}

export const riskDistributions = {
  [INVESTMENT_ANALYSIS_VIEW.ASSET_CLASS]: distributionByAssetType,
  [INVESTMENT_ANALYSIS_VIEW.SECTOR]: maxBySector,
  [INVESTMENT_ANALYSIS_VIEW.SECTOR_STABILITY]: maxBySectorStability,
  [INVESTMENT_ANALYSIS_VIEW.REGION]: distributionByRegion,
};
