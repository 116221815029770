import { ChevronRightIcon, DeleteIcon, DownloadIcon } from '@otto-finance/ui';
import { EntityId } from '@reduxjs/toolkit';
import { Button } from 'common/otto-ui/button';
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'common/otto-ui/dialog';
import { Menu, MenuItem } from 'common/otto-ui/menu';
import { mapDataToTable, MapDataToTableConfigItemInterface } from 'common/otto-ui/table/map-data-to-table.mapper';
import { TableBody } from 'common/otto-ui/table/table-body';
import { TableHead } from 'common/otto-ui/table/table-head';
import { Typography } from 'common/otto-ui/typography';
import _noop from 'lodash/noop';
import { Dispatch, SetStateAction, SyntheticEvent, useEffect, useState } from 'react';

export interface TableInterface {
  /** The content of the component */
  data: any[];
  /** If true, checkbox selection will be activated */
  selectionMode?: boolean;
  /** The data identifiers */
  ids?: EntityId[];
  /** Selected rows ids */
  selectedItems?: EntityId[];
  /** Selection rows handler */
  onSelectItems?: Dispatch<SetStateAction<any[]>>;
  /** The table head configuration object */
  tableHeadConfig?: MapDataToTableConfigItemInterface[];
  children?: never;
  /** If true, will be activated edit mode (edit icon will be shown) */
  editMode?: boolean;
  /** If true, will be activated delete mode (delete icon will be shown) */
  deleteMode?: boolean;
  /** If true, will be activated add mode (add icon will be shown) */
  addMode?: boolean;
  /** The table title */
  entityName?: string;
  /** Callback used to add a table item */
  onAddEntity?: () => void;
  /** Callback used to edit a table item */
  onEditEntity?: (id: string) => void;
  /** Callback used to delete a table item */
  onDeleteEntity?: (id: string) => void;
  /** Callback used to change rows order */
  onChangeOrder?: (sort: string) => void;
  sort?: string;
  order?: string;
  isLoading?: boolean;
  onDeleteBulk?: (ids: EntityId[]) => void;
  onDownloadBulk?: (ids: EntityId[]) => void;
  /** Custom message if no data exists in the table */
  noDataMessage?: string;
}

/** Tables display sets of data */
export const Table = ({
  data,
  ids,
  selectedItems = [],
  onSelectItems,
  tableHeadConfig,
  selectionMode,
  editMode,
  deleteMode,
  addMode,
  onAddEntity,
  onEditEntity,
  onDeleteEntity,
  entityName,
  onChangeOrder = _noop,
  sort,
  order,
  isLoading,
  onDeleteBulk,
  onDownloadBulk,
  noDataMessage,
}: TableInterface) => {
  const { dataToBody, dataToHead } = mapDataToTable(data, tableHeadConfig);
  const [filteredDataToHead, setFilteredDataToHead] = useState(dataToHead);
  const [bulkActionsAnchor, setBulkActionsAnchor] = useState(null);
  const [isDeleteBulkMode, setDeleteBulkMode] = useState(false);
  useEffect(() => {
    setFilteredDataToHead(dataToHead);
  }, [data, tableHeadConfig]);

  const handleSetSelectedItem = (event: SyntheticEvent<HTMLInputElement>): void => {
    const id = event.currentTarget.dataset.rowId;
    if (selectedItems.includes(id)) {
      const items = selectedItems.filter((item) => item !== id);
      onSelectItems(items);
    } else {
      onSelectItems([...selectedItems, id]);
    }
  };

  const handleSelectItems = (event: SyntheticEvent<HTMLInputElement>): void => {
    if (event.currentTarget.checked) {
      onSelectItems(ids);
    } else {
      onSelectItems([]);
    }
  };

  const handleCloseBulkActions = () => {
    setBulkActionsAnchor(null);
  };

  const handleCloseDeleteBulkDialog = () => {
    setDeleteBulkMode(false);
  };

  const handleDeleteBulk = () => {
    if (onDeleteBulk) {
      onDeleteBulk(selectedItems);
    }
    onSelectItems([]);
    handleCloseDeleteBulkDialog();
  };

  const handleDownloadBulk = () => {
    if (onDownloadBulk) {
      onDownloadBulk(selectedItems);
    }
    onSelectItems([]);
  };

  return (
    <div className="w-full relative">
      <div className="w-full flex justify-between items-center mb-4.5">
        <div className="text-lg text-body font-bold tracking-wide dark:text-white">{entityName}</div>
        {selectedItems.length > 0 && (
          <>
            <Button
              variant="outlined"
              endIcon={<ChevronRightIcon />}
              onClick={(e) => setBulkActionsAnchor(e.currentTarget)}
            >
              Bulk actions
            </Button>
            <Menu anchor={bulkActionsAnchor} open={Boolean(bulkActionsAnchor)} onClose={handleCloseBulkActions}>
              <MenuItem onClick={handleDownloadBulk}>
                <DownloadIcon />
                <span>Download items</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseBulkActions();
                  setDeleteBulkMode(true);
                }}
              >
                <DeleteIcon />
                <span>Delete items</span>
              </MenuItem>
            </Menu>
          </>
        )}
        {selectedItems.length === 0 && addMode && (
          <Button onClick={onAddEntity} variant="filled" color="primary">
            Add new entry
          </Button>
        )}
      </div>
      <div>
        {data.length === 0 && !isLoading && (
          <div
            className="py-12 shadow-shadow1 rounded-lg bg-white"
            style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography mediumBold align="center">
              {noDataMessage || 'No items to display'}
            </Typography>
          </div>
        )}
      </div>
      {data.length > 0 && (
        <div className="w-full overflow-auto">
          <table className="w-full rounded-lg dark:bg-background-dark-background3">
            <TableHead
              data={filteredDataToHead}
              onSelectItems={handleSelectItems}
              selected={ids?.length === selectedItems?.length}
              selectionMode={selectionMode}
              editMode={editMode}
              deleteMode={deleteMode}
              onChangeOrder={onChangeOrder}
              sort={sort}
              order={order}
            />
            <TableBody
              data={dataToBody}
              headKeys={filteredDataToHead}
              selectedItems={selectedItems}
              onSetSelectedItems={handleSetSelectedItem}
              selectionMode={selectionMode}
              editMode={editMode}
              deleteMode={deleteMode}
              onEditEntity={onEditEntity}
              onDeleteEntity={onDeleteEntity}
              sort={sort}
            />
          </table>
        </div>
      )}
      <Dialog
        open={isDeleteBulkMode}
        onClose={handleCloseDeleteBulkDialog}
        maxWidth={'md'}
        closeIcon
        disableBackdropClick
      >
        <DialogTitle>Delete rows</DialogTitle>
        <DialogContent>Are you sure you want to delete these rows?</DialogContent>
        <DialogActions>
          <Button variant="filled" color="secondary" onClick={handleDeleteBulk} className="mr-2">
            Delete
          </Button>
          <Button variant="filled" onClick={handleCloseDeleteBulkDialog}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
