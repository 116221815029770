import {
  BinIcon,
  CalendarIcon,
  CreditCardIcon,
  DecreaseIcon,
  IconProps,
  IncreaseIcon,
  InsuranceIcon,
  InvestmentIcon,
  IsaIcon,
  PensionIcon,
  RealEstateAssetIcon,
  StockExchangeIcon,
  StockIcon,
} from 'common/otto-ui/icons';
import { AdventuresIcon } from 'common/otto-ui/icons/adventures-icon';
import { AscendingIcon } from 'common/otto-ui/icons/ascending-icon';
import { BankIcon } from 'common/otto-ui/icons/bank-icon';
import { BusinessIcon } from 'common/otto-ui/icons/business-icon';
import { CalculatorIcon } from 'common/otto-ui/icons/calculator-icon';
import { CarIcon } from 'common/otto-ui/icons/car-icon';
import { CashflowIcon } from 'common/otto-ui/icons/cashflow-icon';
import { CheckIcon } from 'common/otto-ui/icons/check-icon';
import { ChevronLeftIcon } from 'common/otto-ui/icons/chevron-left-icon';
import { CloseIcon } from 'common/otto-ui/icons/close-icon';
import { CoachIcon } from 'common/otto-ui/icons/coach-icon';
import { ConfidenceIcon } from 'common/otto-ui/icons/confidence-icon';
import { CryptoIcon } from 'common/otto-ui/icons/crypto-icon';
import { DescendingIcon } from 'common/otto-ui/icons/descending-icon';
import { DoubleChevronRightIcon } from 'common/otto-ui/icons/double-chevron-right-icon';
import { DreamingIcon } from 'common/otto-ui/icons/dreaming-icon';
import { FreedomIcon } from 'common/otto-ui/icons/freedom-icon';
import { FreedomTravelIcon } from 'common/otto-ui/icons/freedom-travel-icon';
import { GearCheckIcon } from 'common/otto-ui/icons/gear-check-icon';
import { GearIcon } from 'common/otto-ui/icons/gear-icon';
import { GroceryIcon } from 'common/otto-ui/icons/grocery-icon';
import { HealthIcon } from 'common/otto-ui/icons/health-icon';
import { HomeIcon } from 'common/otto-ui/icons/home-icon';
import { IncomeProtectionIcon } from 'common/otto-ui/icons/income-protection-icon';
import { LevelUpIcon } from 'common/otto-ui/icons/level-up-icon';
import { LiferaftIcon } from 'common/otto-ui/icons/liferaft-icon';
import { LifestyleIcon } from 'common/otto-ui/icons/lifestyle-icon';
import { LinkIcon } from 'common/otto-ui/icons/link-icon';
import { LockIcon } from 'common/otto-ui/icons/lock-icon';
import { MarriageIcon } from 'common/otto-ui/icons/marriage-icon';
import { MoneyBagIcon } from 'common/otto-ui/icons/money-bag-icon';
import { MoneyHeartIcon } from 'common/otto-ui/icons/money-heart-icon';
import { MovingVanIcon } from 'common/otto-ui/icons/moving-van-icon';
import { NftIcon } from 'common/otto-ui/icons/nft-icon';
import { OtherIcon } from 'common/otto-ui/icons/other-icon';
import { PiggyBankIcon } from 'common/otto-ui/icons/piggy-bank-icon';
import { PramIcon } from 'common/otto-ui/icons/pram-icon';
import { ProtectionLockIcon } from 'common/otto-ui/icons/protection-lock-icon';
import { QuestionIcon } from 'common/otto-ui/icons/question-icon';
import { RealEstateIcon } from 'common/otto-ui/icons/real-estate-icon';
import { SearchIcon } from 'common/otto-ui/icons/search-icon';
import { ShipIcon } from 'common/otto-ui/icons/ship-icon';
import { SparkleCheckIcon } from 'common/otto-ui/icons/sparkle-check-icon';
import { SpendingIcon } from 'common/otto-ui/icons/spending-icon';
import { StarIcon } from 'common/otto-ui/icons/star-icon';
import { TargetIcon } from 'common/otto-ui/icons/target-icon';
import { TaxEfficiencyIcon } from 'common/otto-ui/icons/tax-efficiency-icon';
import { TickIcon } from 'common/otto-ui/icons/tick-icon';
import { TimeOffIcon } from 'common/otto-ui/icons/time-off-icon';
import { TimerIcon } from 'common/otto-ui/icons/timer-icon';
import { TrophyIcon } from 'common/otto-ui/icons/trophy-icon';
import { UnhappyIcon } from 'common/otto-ui/icons/unhappy-icon';
import { UniversityIcon } from 'common/otto-ui/icons/university-icon';
import { WalletIcon } from 'common/otto-ui/icons/wallet-icon';
import { WorldIcon } from 'common/otto-ui/icons/world-icon';
export { SearchIcon } from 'common/otto-ui/icons/search-icon';
import { AtmIcon } from 'common/otto-ui/icons/atm-icon';
import { CableIcon } from 'common/otto-ui/icons/cable-icon';
import { ElectronicIcon } from 'common/otto-ui/icons/electronic-icon';
import { EntertainmentIcon } from 'common/otto-ui/icons/entertainment-icon';
import { GasolineIcon } from 'common/otto-ui/icons/gasoline-icon';
import { HealthCareIcon } from 'common/otto-ui/icons/healthcare-icon';
import { InterestIcon } from 'common/otto-ui/icons/interest-icon';
import { LoansIcon } from 'common/otto-ui/icons/loans-icon';
import { MortgageIcon } from 'common/otto-ui/icons/mortgage-icon';
import { OnlineShoppingIcon } from 'common/otto-ui/icons/online-shopping-icon';
import { PaycheckIcon } from 'common/otto-ui/icons/paycheck-icon';
import { RentIcon } from 'common/otto-ui/icons/rent-icon';
import { RestaurantsIcon } from 'common/otto-ui/icons/restaurants-icon';
import { ServicesIcon } from 'common/otto-ui/icons/services-icon';
import { TelephoneIcon } from 'common/otto-ui/icons/telephone-icon';
import { TransferIcon } from 'common/otto-ui/icons/transfer-icon';
import { UtilitiesIcon } from 'common/otto-ui/icons/utilities-icon';
import { RefreshIcon } from 'common/otto-ui/icons/refresh-icon';
import { WarningIcon } from 'common/otto-ui/icons/warning-icon';
/**
 * Mapping for JSON object keys
 * TODO: Lowercase all keys
 */
export const iconMap: { [k: string]: React.FC<IconProps> } = {
  Ship: ShipIcon,
  Juggle: AdventuresIcon,
  Flowers: BusinessIcon,
  Shield: ConfidenceIcon,
  Dreaming: DreamingIcon,
  Holiday: FreedomIcon,
  Suitcase: FreedomTravelIcon,
  Health: HealthIcon,
  Home: HomeIcon,
  ShieldCheck: IncomeProtectionIcon,
  Measure: LevelUpIcon,
  Other: OtherIcon,
  Document: RealEstateIcon,
  Tax: TaxEfficiencyIcon,
  University: UniversityIcon,
  Save: WorldIcon,
  Lock: LockIcon,
  Star: StarIcon,
  Target: TargetIcon,
  Trophy: TrophyIcon,
  MoneyBag: MoneyBagIcon,
  Spending: SpendingIcon,
  Gear: GearIcon,
  Check: CheckIcon,
  Question: QuestionIcon,
  Increase: IncreaseIcon,
  Decrease: DecreaseIcon,
  Timer: TimerIcon,
  Close: CloseIcon,
  Ascending: AscendingIcon,
  Descending: DescendingIcon,
  Tick: TickIcon,
  Link: LinkIcon,
  Wallet: WalletIcon,
  Bank: BankIcon,
  Unhappy: UnhappyIcon,
  SparkleCheck: SparkleCheckIcon,
  Lifestyle: LifestyleIcon,
  DoubleChevronRight: DoubleChevronRightIcon,
  ChevronLeft: ChevronLeftIcon,
  Stock: StockIcon,
  CreditCard: CreditCardIcon,
  card: CreditCardIcon,
  StockExchange: StockExchangeIcon,
  Crypto: CryptoIcon,
  Insurance: InsuranceIcon,
  RealEstate: RealEstateIcon,
  Isa: IsaIcon,
  Pension: PensionIcon,
  RealEstateAsset: RealEstateAssetIcon,
  Investment: InvestmentIcon,
  Bin: BinIcon,
  Pram: PramIcon,
  Calendar: CalendarIcon,
  Car: CarIcon,
  Marriage: MarriageIcon,
  TimeOff: TimeOffIcon,
  MovingVan: MovingVanIcon,
  crypto: CryptoIcon,
  moneyBag: MoneyBagIcon,
  moneyHeart: MoneyHeartIcon,
  piggyBank: PiggyBankIcon,
  protectionLock: ProtectionLockIcon,
  calculator: CalculatorIcon,
  cashflow: CashflowIcon,
  liferaft: LiferaftIcon,
  investment: InvestmentIcon,
  realEstateAsset: RealEstateAssetIcon,
  freedom: FreedomIcon,
  tax: TaxEfficiencyIcon,
  home: HomeIcon,
  coach: CoachIcon,
  nft: NftIcon,
  grocery: GroceryIcon,
  gearCheck: GearCheckIcon,
  search: SearchIcon,
  link: LinkIcon,
  pension: PensionIcon,
  wallet: WalletIcon,
  bank: BankIcon,
  creditCard: CreditCardIcon,
  // MoneyHub Category Name icons
  groceries: GroceryIcon,
  insurance: InsuranceIcon,
  utilities: UtilitiesIcon,
  cable: CableIcon,
  atm: AtmIcon,
  entertainment: EntertainmentIcon,
  healthcare: HealthCareIcon,
  electronic: ElectronicIcon,
  paycheck: PaycheckIcon,
  services: ServicesIcon,
  telephone: TelephoneIcon,
  restaurants: RestaurantsIcon,
  onlineShopping: OnlineShoppingIcon,
  online: OnlineShoppingIcon,
  interest: InterestIcon,
  mortgage: MortgageIcon,
  rent: RentIcon,
  loans: LoansIcon,
  loan: LoansIcon,
  travel: ShipIcon,
  gasoline: GasolineIcon,
  automotive: CarIcon,
  transfer: TransferIcon,
  other: OtherIcon,
  charitable: WorldIcon,
  // MoneyHub Category Group icons
  bills: MortgageIcon,
  business: RentIcon,
  cash: AtmIcon,
  gifts: OnlineShoppingIcon,
  health: HealthCareIcon,
  household: RentIcon,
  income: PaycheckIcon,
  repayments: LoansIcon,
  taxes: TaxEfficiencyIcon,
  transfers: TransferIcon,
  transport: CarIcon,
  paychecks: PaycheckIcon,
  service: ServicesIcon,
  hobbies: FreedomTravelIcon,
  dues: MortgageIcon,
  general: OtherIcon,
  clothing: AtmIcon,
  personal: HealthCareIcon,
  otherIcon: OtherIcon,
  mortgages: MortgageIcon,
  credit: CreditCardIcon,
  savings: PiggyBankIcon,
  electronics: ElectronicIcon,
  advertising: FreedomIcon,
  refresh: RefreshIcon,
  warning: WarningIcon,
};
