import { gql } from 'graphql-request';

export const OpenBankingCountriesDocument = gql`
  query OpenBankingCountries {
    openBankingCountries {
      totalCount
      data {
        code
        providers
        authFlow
      }
    }
  }
`;

export const CompleteAuthDocument = gql`
  mutation CompleteAuth($authData: CompleteAuthorisationDto!) {
    completeDataIntegrationAuthorisation(authData: $authData) {
      connection {
        id
        name
        type
        providerConnectionId
        expiresAt
        createdAt
        updatedAt
        actions
      }
    }
  }
`;

export const AvailableConnectionsDocument = gql`
  query AvailableConnections($country: String!, $accountTypes: [String!]) {
    listAvailableConnections(country: $country, accountTypes: $accountTypes) {
      totalCount
      data {
        id
        provider
        authFlow
        name
        type
        country
        iconUrl
        iconBase64
        parentRef
        isBeta
        userTypes
        payments
        bankRef
        accountTypes {
          name
          beta
        }
        status {
          auth
          sync
        }
        singleSyncOnly
      }
    }
  }
`;
