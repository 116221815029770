import { createSlice } from '@reduxjs/toolkit'

interface SkipState {
    enable: boolean;
    hide: boolean;
  }

const initialState: SkipState = {
    enable: true,
    hide: true
}

export const skipButtonSlice = createSlice({
    name: 'skipButton',
    initialState,
    reducers: {
      enableButton: (state) => {
        state.enable = true
      },
      disableButton: (state) => {
        state.enable = false
      },
      hideButton:(state) =>{
        state.hide = true
      },
      unhideButton:(state) =>{
        state.hide = false
      }
    },
  })

  export const {enableButton, disableButton, hideButton, unhideButton} = skipButtonSlice.actions;

  export default skipButtonSlice.reducer;