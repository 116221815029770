export enum AccountTypeEnum {
  CASH = 'CASH',
  SAVINGS = 'SAVINGS',
  CARD = 'CARD',
  INVESTMENT = 'INVESTMENT',
  LOAN = 'LOAN',
  MORTGAGE = 'MORTGAGE',
  PENSION = 'PENSION',
  PROPERTY = 'PROPERTY',
  ASSET = 'ASSET',
  CRYPTO = 'CRYPTO',
  OTHER = 'OTHER',
}
