import { Button } from "@otto-finance/ui";
import { ButtonProps } from "@otto-finance/ui/dist/buttons/button";
import { ButtonHTMLAttributes, ReactEventHandler } from "react";

interface ButtonAccentInterface extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: ReactEventHandler<HTMLButtonElement>;
}

export const ButtonAccent = ({ children, ...rest }: ButtonAccentInterface & ButtonProps) => {
  return (
    <Button {...rest} overrides={{
      BaseButton: {
        style: ({ $theme }) => ({
          color: $theme.colors.primary,
          backgroundColor: $theme.colors.accent,
          ":hover:enabled": {
            backgroundColor: $theme.colors.accent300
          },
          ":active": {
            backgroundColor: $theme.colors.accent200
          },
          ":focus": {
            backgroundColor: $theme.colors.accent200
          }
        })
      }
    }}>
      {children}
    </Button>
  );
};
