import clsx from 'clsx';
import { ForwardedRef, forwardRef, HTMLAttributes, ReactNode } from 'react';

import { Typography } from '../typography';

export interface CardInterface extends HTMLAttributes<HTMLDivElement> {
  /** Background class of the card. */
  bgColor?: string;
  /** Width and Height class of the card. */
  size?: string;
  /** Padding class of the card. */
  padding?: string;
  /** Shadow class of the card. */
  shadow?: string;
  /** The overflow of the card */
  overflow?: string;
  /** The content of the card. */
  children: ReactNode;
  /** The border radius of the card */
  rounded?: string;
  ref?: ForwardedRef<HTMLDivElement>;
  title?: string;
  subTitle?: ReactNode;
  extra?: ReactNode;
  actions?: ReactNode[];
  description?: string | ReactNode;
};

/** Cards contain content and actions about a single subject. */
export const Card = forwardRef<HTMLDivElement, CardInterface>(({
  bgColor,
  size,
  padding,
  shadow,
  className,
  children,
  overflow,
  rounded,
  title,
  subTitle,
  extra,
  actions,
  description,
  ...props
}, ref) => {
  const wrapperClassName = clsx(
    rounded ?? 'rounded-2xl',
    bgColor ?? 'bg-white dark:bg-background-dark-background2',
    size ?? 'min-w-full min-h-full w-full h-full',
    padding ?? 'sm:p-4 p-4',
    shadow ?? 'shadow-shadow1 dark:shadow-none',
    overflow ?? 'overflow-visible',
    className,
  );
  let head: ReactNode;
  if (title || extra) {
    head = (
      <div className="flex justify-between items-baseline mb-4">
        {title && (
          <div>
            <div className="flex items-baseline gap-2">
              <Typography variant="subtitle" semiBold className="mb-2">{title}</Typography>
              {subTitle && subTitle}
            </div>
            {description && <p className="leading-tight">{description}</p>}
          </div>
        )}
        {extra && <div>{extra}</div>}
      </div>
    );
  }
  return (
    <div ref={ref} className={wrapperClassName} {...props}>
      {head}
      {children}
      {actions && (actions.map(a => a))}
    </div>
  );
});

Card.displayName = 'Card';