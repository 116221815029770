import { InvestmentOperation } from 'common/interfaces';
import { caching } from 'configuration/data';
import { AddInvestmentOperationDocument, DeleteHoldingDocument, FetchTransactionsDocument, FinanceHoldingsDocument, FinanceSearchHoldingsDocument } from 'configuration/graphql/documents';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'enums';
import { AssetsTransactionInterface, FinanceHoldings, FinanceHoldingSecurity } from 'slices/assets';

import { API_TAGS, baseApi } from './base.api';

export interface FinanceHoldingsReturnInterface {
  financeHoldings: { data: FinanceHoldings[]; };
}

interface InvestmentOperationArgs {
  userId: string;
  accountId: string;
  symbol: string;
  type: InvestmentOperation;
  amount: number;
  date: string;
  price?: number;
  fees?: number;
}

export const holdingsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchHoldings: builder.query<FinanceHoldings[] | null, { userId: string; }>({
      query: (variables) => ({
        variables,
        document: FinanceHoldingsDocument,
      }),
      keepUnusedDataFor: caching['10mins'],
      providesTags: [API_TAGS.HOLDINGS],
      transformResponse: (response: FinanceHoldingsReturnInterface) => response.financeHoldings.data,
    }),
    searchHoldings: builder.query<FinanceHoldingSecurity[], { key: string; }>({
      query: ({ key }) => ({
        variables: { key: key.toLowerCase() },
        document: FinanceSearchHoldingsDocument,
      }),
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.HOLDINGS_SEARCH],
      transformResponse: ({ financeSecuritiesSearch }: { financeSecuritiesSearch: FinanceHoldingSecurity[]; }) => financeSecuritiesSearch || [],
    }),
    investmentOperation: builder.mutation<string, InvestmentOperationArgs>({
      query: (variables) => ({
        variables: { operation: { ...variables, price: null, fees: 0 } },
        document: AddInvestmentOperationDocument,
      })
    }),
    deleteHolding: builder.mutation<string, { account: string; holding: string; }>({
      query: (variables) => ({
        variables,
        document: DeleteHoldingDocument,
      }),
      invalidatesTags: [
        {
          type: API_TAGS.ACCOUNTS,
          id: 'ALL'
        },
        {
          type: API_TAGS.HISTORICAL_BALANCES,
          id: 'ALL'
        }
      ]
    }),
    holdingTransactions: builder.query<AssetsTransactionInterface[], { from?: string; to?: string; userId: string; accountId?: string; holdingId?: string; holdingIds?: string[]; convertTo: string; }>({
      query: (variables) => ({
        variables,
        document: FetchTransactionsDocument,
      }),
      transformResponse: ({ financeTransactions }: { financeTransactions: { data: AssetsTransactionInterface[]; }; }) => financeTransactions.data.filter(f => f.holdingId)
        .map(t => ({
          ...t,
          date: format(new Date(t.date), DATE_FORMAT.DEFAULT)
        }))
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    }),
  }),
});

export const {
  useFetchHoldingsQuery,
  useLazySearchHoldingsQuery,
  useInvestmentOperationMutation,
  useDeleteHoldingMutation,
  useHoldingTransactionsQuery
} = holdingsApi;
