import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const AudioIcon: React.FC<IconProps> = ({ color = 'currentColor', height = 12, width = 12 }) => (
  <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <path d="M5.57882 0.00244141C4.37766 0.00244141 3.40039 0.979708 3.40039 2.18087V5.88293C3.40039 7.08409 4.37766 8.06136 5.57882 8.06136C6.77998 8.06136 7.75725 7.07842 7.75725 5.87033V2.18087C7.75725 0.979708 6.78011 0.00244141 5.57882 0.00244141ZM7.00171 5.87033C7.00171 6.66186 6.36342 7.30583 5.57882 7.30583C4.79422 7.30583 4.15592 6.66742 4.15592 5.88293V2.18087C4.15592 1.39625 4.79422 0.757971 5.57882 0.757971C6.36342 0.757971 7.00171 1.39627 7.00171 2.18087V5.87033Z" />
      <path d="M5.95914 9.15479H5.20361V10.8673H5.95914V9.15479Z" />
      <path d="M7.14024 10.4019H4.0174C3.80876 10.4019 3.63965 10.571 3.63965 10.7796C3.63965 10.9883 3.80876 11.1574 4.0174 11.1574H7.14024C7.34888 11.1574 7.51799 10.9883 7.51799 10.7796C7.51799 10.571 7.34888 10.4019 7.14024 10.4019Z" />
      <path d="M8.76544 4.01855C8.5568 4.01855 8.38768 4.18767 8.38768 4.39631V5.88218C8.38768 7.4305 7.1281 8.69021 5.57965 8.69021C4.0312 8.69021 2.77162 7.4305 2.77162 5.88218V4.39631C2.77162 4.18765 2.60251 4.01855 2.39387 4.01855C2.18523 4.01855 2.01611 4.18767 2.01611 4.39631V5.88218C2.01611 7.84717 3.61468 9.44572 5.57965 9.44572C7.54462 9.44572 9.14319 7.84715 9.14319 5.88218V4.39631C9.14319 4.18767 8.9741 4.01855 8.76544 4.01855Z" />
    </g>
  </svg>
);
