import { currencyFormatter } from 'utils';

/**
 * Either show the symbol ('£') or the lettering ('GBP') for the currency
 */
type CurrencyType = 'symbol' | 'letter';

export const convertLargeValue = (
  value: number,
  showPositiveSign = false,
  currency = 'GBP',
  currencyType: CurrencyType = 'symbol',
  decimalPlaces = 2,
): string => {
  /**
   * Convert value if large e.g. 1000000 to 1M
   */
  const absVal = Math.abs(value);

  const currencySymbol = currencyFormatter(absVal).replace(/( )|(,)|([0-9])|(\.)/g, '');
  const sign = value > 0 ? (showPositiveSign ? '+' : '') : '-';
  let formattedValue = '';

  if (absVal >= 1e9) {
    // Greater than a Billion
    formattedValue = (absVal / 1e9).toFixed(decimalPlaces);
    if (currencyType === 'letter') return `${currency} ${sign}${formattedValue}B`;
    return `${sign}${currencySymbol}${formattedValue}B`;
  }

  if (absVal >= 1e6 && absVal < 1e9) {
    // Between a Million and a Billion
    formattedValue = (absVal / 1e6).toFixed(decimalPlaces);
    if (currencyType === 'letter') return `${currency} ${sign}${formattedValue}M`;
    return `${sign}${currencySymbol}${formattedValue}M`;
  }

  if (currencyType === 'letter') {
    return `${currency} ${value}`;
  }

  return `${currencyFormatter(value)}`;
};
