import { gql } from 'graphql-request';

export const GetCustomerProfileDocument = gql`
  query GetCustomerProfile($userId: String!) {
    getCustomerProfile(userId: $userId) {
      nickname
      hasPartner
      isMarried
      hasChildren
      currency
      birthday
      country
      hasSharedFinancialInformation
      country
    }
  }
`;

export const UpdateCustomerProfileDocument = gql`
  mutation UpdateCustomerProfile($data: CustomerProfileUpdateDto!, $userId: String!) {
    updateCustomerProfile(data: $data, userId: $userId)
  }
`;
