import { Button, CloseIcon } from '@otto-finance/ui';
import clsx from 'clsx';
import { HTMLAttributes, ReactNode } from 'react';

export enum ChipColorEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DEFAULT = 'default',
  CUSTOM = 'custom',
}

export interface ChipInterface extends HTMLAttributes<HTMLElement> {
  /** Avatar componment will be added inside chip  */
  avatar?: ReactNode;
  /** Background chip color */
  color?: Lowercase<keyof typeof ChipColorEnum>;
  /** Outlined border for chip */
  outline?: boolean;
  /** Compact size for chip */
  compact?: boolean;
  /** Disabled status of chip (has no effect on user events) */
  disabled?: boolean;
  /** Click event listener function on close icon */
  onDelete?: () => void;
}
// TODO: Remove
/** Chip provide users some little information with any actions */
export const Chip = ({
  avatar,
  color = ChipColorEnum.DEFAULT,
  onClick,
  outline,
  compact,
  disabled,
  className,
  children,
  onDelete,
  ...props
}: ChipInterface) => {
  const chipClassName = clsx(
    'px-3 inline-flex justify-center items-center space-x-2.5 rounded-lg border-2 text-2sm font-medium',
    {
      'hover:opacity-80 cursor-pointer': onClick,
      'pointer-events-none opacity-30': disabled,
    },
    color === ChipColorEnum.PRIMARY && {
      'border-primary dark:border-primary-light': true,
      'bg-primary dark:bg-primary-light text-offWhite dark:text-title': !outline,
      'text-primary dark:text-primary-light': outline,
    },
    color === ChipColorEnum.SECONDARY && {
      'border-secondary dark:border-secondary-lightest': true,
      'bg-secondary dark:bg-secondary-lightest text-offWhite dark:text-title': !outline,
      'text-secondary dark:text-secondary-lightest': outline,
    },
    color === ChipColorEnum.DEFAULT && {
      'border-line text-title': true,
      'bg-line dark:text-offWhite': !outline,
      'dark:text-line': outline,
    },
    {
      'h-8': !compact,
      'h-6': compact,
    },
    className,
  );

  return (
    <div className={chipClassName} {...props}>
      {avatar}
      {children}
      {onDelete && <Button onClick={onDelete} shape="circle" size="mini" kind="tertiary"><CloseIcon size="16" /></Button>}
    </div>
  );
};
