import { gql } from 'graphql-request';

export const ListConnectionsDocument = gql`
  query listAvailableConnections($provider: String!) {
    listAvailableConnections(provider: $provider) {
      data {
        name
        id
        iconUrl
        type
        country
        accountTypes {
          name
          beta
        }
      }
    }
  }
`;
