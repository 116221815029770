import { RegionEnum, RISK_LEVEL } from "enums";

export const distributionByRegion = {
  [RISK_LEVEL.CAUTIOUS]: {
    [RegionEnum.EUROPE]: 0.45,
    [RegionEnum.NORTH_AMERICA]: 0.35,
    [RegionEnum.SOUTH_AMERICA]: 0.1,
    [RegionEnum.AUSTRALIA]: 0.15,
    [RegionEnum.ASIA]: 0.15,
    [RegionEnum.AFRICA]: 0.1,
    [RegionEnum.OTHER]: 1
  },
  [RISK_LEVEL.MODERATELY_CAUTIOUS]: {
    [RegionEnum.EUROPE]: 0.35,
    [RegionEnum.NORTH_AMERICA]: 0.3,
    [RegionEnum.SOUTH_AMERICA]: 0.25,
    [RegionEnum.AUSTRALIA]: 0.15,
    [RegionEnum.ASIA]: 0.15,
    [RegionEnum.AFRICA]: 0.12,
    [RegionEnum.OTHER]: 1
  },
  [RISK_LEVEL.BALANCED]: {
    [RegionEnum.EUROPE]: 0.3,
    [RegionEnum.NORTH_AMERICA]: 0.25,
    [RegionEnum.SOUTH_AMERICA]: 0.15,
    [RegionEnum.AUSTRALIA]: 0.2,
    [RegionEnum.ASIA]: 0.25,
    [RegionEnum.AFRICA]: 0.15,
    [RegionEnum.OTHER]: 1
  },
  [RISK_LEVEL.MODERATELY_ADVENTUROUS]: {
    [RegionEnum.EUROPE]: 0.25,
    [RegionEnum.NORTH_AMERICA]: 0.4,
    [RegionEnum.SOUTH_AMERICA]: 0.1,
    [RegionEnum.AUSTRALIA]: 0.15,
    [RegionEnum.ASIA]: 0.30,
    [RegionEnum.AFRICA]: 0.1,
    [RegionEnum.OTHER]: 1
  },
  [RISK_LEVEL.ADVENTUROUS]: {
    [RegionEnum.EUROPE]: 0.25,
    [RegionEnum.NORTH_AMERICA]: 0.3,
    [RegionEnum.SOUTH_AMERICA]: 0.15,
    [RegionEnum.AUSTRALIA]: 0.15,
    [RegionEnum.ASIA]: 0.35,
    [RegionEnum.AFRICA]: 0.1,
    [RegionEnum.OTHER]: 1
  },
};
