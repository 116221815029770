import { gql } from 'graphql-request';

export const FetchMessages = gql`
  query messages($conversationId: String!, $limit: Int, $offset: Int) {
    messages(conversationId: $conversationId, limit: $limit, offset: $offset) {
      totalCount
      data {
        id
        body
        conversationId
        authorId
        createdAt
        messageStatus {
          data {
            userId
            read
          }
        }
      }
    }
  }
`;

export const CreateMessage = gql`
  mutation createMessage($message: MessageCreateDto!) {
    createMessage(message: $message) {
      id
      body
      conversationId
      authorId
      createdAt
      messageStatus {
        data {
          userId
          read
        }
      }
    }
  }
`;