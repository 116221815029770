import { QueryOptions } from '@apollo/client';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { SessionsInterface } from 'slices/sessions';
import { requestGql } from 'utils/request-gql';

export interface FetchSessionsVariablesInterface {
  participants?: string[];
  appointmentParticipantId?: string;
  participantUserId?: string;
}

export interface FetchSessionsActionRequestInterface extends QueryOptions {
  variables: FetchSessionsVariablesInterface;
}

export interface FetchSessionsActionResponseInterface {
  appointments: {
    data: any[];
  };
}

export const fetchSessionsAction = createAsyncThunk(
  'appointments',
  async (request: FetchSessionsActionRequestInterface, thunkApi): Promise<SessionsInterface[] | SerializedError> =>
    await requestGql<SessionsInterface[]>(request, thunkApi, 'appointments'),
);
