import { ChevronLeftIcon, ChevronRightIcon } from '@otto-finance/ui';

export enum PaginationActionIconTypeEnum {
  FIRST = 'first',
  PREV = 'prev',
  NEXT = 'next',
  LAST = 'last',
}

export interface PaginationActionIconInterface {
  type: Lowercase<keyof typeof PaginationActionIconTypeEnum>;
  children?: never;
}

export const PaginationActionIcon = ({ type }: PaginationActionIconInterface) => {
  if (type === PaginationActionIconTypeEnum.FIRST) {
    return (
      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9786 1.19621C11.3691 1.58674 11.3691 2.2199 10.9786 2.61043L6.9357 6.65332L10.9786 10.6962C11.3691 11.0867 11.3691 11.7199 10.9786 12.1104C10.5881 12.501 9.9549 12.501 9.56438 12.1104L4.81438 7.36043C4.42385 6.9699 4.42385 6.33674 4.81438 5.94621L9.56438 1.19621C9.9549 0.805689 10.5881 0.805689 10.9786 1.19621Z"
          fill="currentColor"
        />
        <rect
          x="2.52148"
          y="12.4033"
          width="2"
          height="11.5"
          rx="1"
          transform="rotate(-180 2.52148 12.4033)"
          fill="currentColor"
        />
      </svg>
    );
  } else if (type === PaginationActionIconTypeEnum.PREV) {
    return <ChevronLeftIcon />;
  } else if (type === PaginationActionIconTypeEnum.NEXT) {
    return <ChevronRightIcon />;
  } else if (type === PaginationActionIconTypeEnum.LAST) {
    return (
      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.814378 12.1104C0.423853 11.7199 0.423853 11.0867 0.814378 10.6962L4.85727 6.65332L0.814379 2.61043C0.423855 2.2199 0.423855 1.58674 0.814379 1.19621C1.2049 0.805689 1.83807 0.805689 2.22859 1.19621L6.97859 5.94621C7.36912 6.33674 7.36912 6.9699 6.97859 7.36043L2.22859 12.1104C1.83807 12.501 1.2049 12.501 0.814378 12.1104Z"
          fill="currentColor"
        />
        <rect x="9.27148" y="0.90332" width="2" height="11.5" rx="1" fill="currentColor" />
      </svg>
    );
  }

  return null;
};
