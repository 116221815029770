import clsx from 'clsx';
import { ToggleButtonItemSizeEnum } from 'common/otto-ui/toggle-button/toggle-button-item';
import {
  Children,
  cloneElement,
  FormEvent,
  InputHTMLAttributes,
  isValidElement,
  ReactNode,
  ReactText,
  useMemo,
} from 'react';

export type ToggleButtonValueType = ReactText | ReactText[] | null;

export interface ToggleButtonInterface extends Omit<InputHTMLAttributes<HTMLDivElement>, 'size' | 'value'> {
  /**
   * The currently selected value within the group or an array of selected values when exclusive is false.
   * The value must have reference equality with the option in order to be selected.
   * */
  value?: ToggleButtonValueType;
  /** If true, group orientation is vertical. */
  vertical?: boolean;
  /** If true, only allow one of the child ToggleButton values to be selected. */
  exclusive?: boolean;
  /** The size of the buttons. */
  size?: Lowercase<keyof typeof ToggleButtonItemSizeEnum>;
  /** The content of the button. */
  children?: ReactNode;
}

/** Toggle buttons can be used to group related options. */
export const ToggleButton = ({
  value,
  vertical,
  exclusive,
  size = ToggleButtonItemSizeEnum.DEFAULT,
  className,
  children,
  onChange,
  ...props
}:ToggleButtonInterface) => {
  const classes = {
    wrapper: clsx(
      'z-0 relative box-border inline-flex justify-start items-stretch border border-solid border-line dark:border-placeholder overflow-hidden',
      {
        'flex-row': !vertical,
        'flex-col': vertical,
        'rounded-md': size === ToggleButtonItemSizeEnum.SMALL,
        'rounded-lg': size === ToggleButtonItemSizeEnum.DEFAULT || size === ToggleButtonItemSizeEnum.LARGE,
      },
      className,
    ),
    divider: clsx('border-l border-t border-solid border-label dark:border-grey2', {
      'w-0': !vertical,
      'h-0': vertical,
    }),
    dividerHighlight: clsx('border-l border-t border-solid border-line dark:border-placeholder', {
      'w-0': !vertical,
      'h-0': vertical,
    }),
  };

  const selected: ReactText[] = useMemo(() => (Array.isArray(value) ? value : [value].filter((item) => item)), [value]);

  const handleChange = (newValue: ReactText) => (event: FormEvent<HTMLInputElement>) => {
    if (onChange) {
      let val: any;
      if (selected.includes(newValue)) {
        if (exclusive) {
          val = null;
        } else {
          val = selected.filter((item: ReactText) => item !== newValue);
        }
      } else {
        if (exclusive) {
          val = newValue;
        } else {
          val = [...selected, newValue];
        }
      }

      const newEvent = {
        ...new Event('change', { ...event }),
        nativeEvent: event.nativeEvent,
        isDefaultPrevented: event.isDefaultPrevented,
        isPropagationStopped: event.isPropagationStopped,
        persist: event.persist,
        target: {
          ...(event.target as EventTarget & HTMLInputElement),
          value: val,
          id: props.id,
          name: props.name,
        },
        currentTarget: {
          ...(event.currentTarget as EventTarget & HTMLInputElement),
          value: val,
          id: props.id,
          name: props.name,
        },
      };
      onChange(newEvent);
    }
  };

  const slides: ReactNode[] = useMemo(() => {
    const childrenWithDivider: ReactNode[] = [];
    let prevSelected = false;
    Children.forEach(children, (child: ReactNode, index: number) => {
      if (isValidElement(child)) {
        const itemValue = child.props.value;
        const itemSelected = itemValue && selected.includes(itemValue);

        childrenWithDivider.push(
          <div
            key={`divider-${index}`}
            className={prevSelected || itemSelected ? classes.dividerHighlight : classes.divider}
          />,
        );

        childrenWithDivider.push(
          cloneElement<any>(child, {
            key: child.key ?? `button-${index}`,
            selected: itemSelected,
            size,
            onChange: handleChange(itemValue),
          }),
        );

        prevSelected = itemSelected;
      }
    });

    if (childrenWithDivider.length) {
      childrenWithDivider.shift();
    }

    return childrenWithDivider;
  }, [children, selected, handleChange]);

  return (
    <div className={classes.wrapper} {...props}>
      {slides}
    </div>
  );
};
