import clsx from 'clsx';

interface IconsSortInterface {
  children?: never;
  sortAsc?: boolean;
  sortDesc?: boolean;
}

export const IconsSort = ({ sortAsc, sortDesc }: IconsSortInterface) => {
  const arrowUpClassName = clsx('z-0 fill-current', {
    'text-primary dark:text-primary-light': sortDesc,
    'text-inputBg': !sortDesc,
  });

  const arrowDownClassName = clsx('z-0 fill-current', {
    'text-primary dark:text-primary-light': sortAsc,
    'text-inputBg': !sortAsc,
  });

  return (
    <svg width="11" height="16" xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 11 17">
      <defs />
      <g clipPath="url(#clip0)">
        <path
          className={arrowUpClassName}
          d="M.41 5.847L4.788.392c.39-.504 1.031-.504 1.421 0l4.378 5.455c.39.505.207.917-.458.917H.868c-.665 0-.848-.412-.458-.917zm10.177 4.31L6.21 15.613c-.39.504-1.032.504-1.421 0L.41 10.157C.02 9.653.203 9.24.868 9.24h9.26c.665 0 .849.413.46.917zM13.814 5.847L18.193.392c.39-.504 1.031-.504 1.42 0l4.38 5.455c.389.505.205.917-.46.917h-9.26c-.665 0-.848-.412-.459-.917zm10.178 4.31l-4.378 5.456c-.39.504-1.032.504-1.421 0l-4.379-5.456c-.39-.504-.206-.917.459-.917h9.26c.665 0 .849.413.46.917z"
        />
        <path
          className={arrowDownClassName}
          d="M10.591 10.169l-4.378 5.455c-.39.505-1.032.505-1.421 0L.413 10.17c-.39-.504-.206-.917.459-.917h9.26c.665 0 .849.413.46.917z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h10.59v16H0z" transform="translate(.203 .002)" />
        </clipPath>
      </defs>
    </svg>
  );
};
