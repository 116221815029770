import { gql } from 'graphql-request';

const article = `
  entryId
  title
  slug
  description
  topic {
    entryId
    title
    icon
    slug
  }
  createdAt
  image {
    fileUrl
  }
`;

export const GetArticleDocument = gql`
  query GetArticle($entryId: String!) {
    article(entryId: $entryId) {
      ${article}
      content {
        content
      }
    }
  }
`;

export const GetArticlesDocument = gql`
  query GetArticles($entryId: String, $entryIds: [String!], $following: Boolean, $limit: Float, $offset: Float, $order: [ContentOrderArgType!], $popular: Boolean, $topicsIn: [String!], $excludeIds: [String!]) {
    articles(entryId: $entryId, entryIds: $entryIds, following: $following, limit: $limit, offset: $offset, order: $order, popular: $popular, topicsIn: $topicsIn, excludeIds: $excludeIds) {
      totalCount
      data {
        ${article}
      }
    }
  }
`;

export const GetArticleTopicDocument = gql`
  query GetArticleTopic($entryId: String!) {
    articleTopic(entryId: $entryId) {
      entryId
      title
      slug
      description
      icon
      following
    }
  }
`;

export const GetArticleTopicsDocument = gql`
  query GetArticleTopics {
    articleTopics {
      totalCount
      data {
        entryId
        title
        slug
        description
        icon
        following
      }
    }
  }
`;

export const FollowTopicDocument = gql`
  mutation FollowTopic($topic: String!) {
    followTopic(topic: $topic)
  }
`;
