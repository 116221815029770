import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const ActionStatusIcon: React.FC<IconProps & { status: number; }> = ({ height = 24, width = 24, color = 'currentColor', status = 1 }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      {status === 1 ? ( // todo
        <circle r="11" cx="12" cy="12" strokeWidth="2" stroke={color} strokeLinecap="square" strokeLinejoin="round" strokeDasharray="1 8" />
      ) : status === 2 ? ( // in progress
        <>
          <circle r="11" stroke={color} cx="12" cy="12" strokeWidth="2" />
          <path fill={color} d="M12 25C15.1826 25 18.2348 23.7357 20.4853 21.4853C22.7357 19.2348 24 16.1826 24 13C24 9.8174 22.7357 6.76516 20.4853 4.51472C18.2348 2.26428 15.1826 1 12 1L12 13L12 25Z" />
        </>
      ) : status === 3 ? ( // done
        <>
          <circle r="11" fill={color} stroke={color} cx="12" cy="12" strokeWidth="2" />
          <path d="M17 9L11 15L8 12.0001" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </>
      ) : null}
  </svg>
);
