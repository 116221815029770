import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const PaycheckIcon: React.FC<IconProps> = ({ color = 'currentColor', height = 17, width = 20 }) => (
  <svg width={width} height={height} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2147 11.623H6.24648C6.178 11.623 6.11233 11.6503 6.06391 11.6987C6.01548 11.7471 5.98828 11.8128 5.98828 11.8812C5.98828 11.9497 6.01548 12.0154 6.06391 12.0638C6.11233 12.1122 6.178 12.1394 6.24648 12.1394H13.2147C13.2832 12.1394 13.3488 12.1122 13.3973 12.0638C13.4457 12.0154 13.4729 11.9497 13.4729 11.8812C13.4729 11.8128 13.4457 11.7471 13.3973 11.6987C13.3488 11.6503 13.2832 11.623 13.2147 11.623V11.623Z"
      fill={color}
      stroke={color}
      strokeWidth="0.18"
    />
    <path
      d="M6.1996 6.60624H10.0767C10.1452 6.60624 10.2109 6.57903 10.2593 6.53061C10.3077 6.48219 10.3349 6.41652 10.3349 6.34804C10.3349 6.27956 10.3077 6.21389 10.2593 6.16547C10.2109 6.11705 10.1452 6.08984 10.0767 6.08984H6.1996C6.13112 6.08984 6.06545 6.11705 6.01703 6.16547C5.96861 6.21389 5.94141 6.27956 5.94141 6.34804C5.94141 6.41652 5.96861 6.48219 6.01703 6.53061C6.06545 6.57903 6.13112 6.60624 6.1996 6.60624Z"
      fill={color}
      stroke={color}
      strokeWidth="0.18"
    />
    <path
      d="M6.1996 8.44999H10.0767C10.1452 8.44999 10.2109 8.42278 10.2593 8.37436C10.3077 8.32594 10.3349 8.26027 10.3349 8.19179C10.3349 8.12331 10.3077 8.05764 10.2593 8.00922C10.2109 7.9608 10.1452 7.93359 10.0767 7.93359H6.1996C6.13112 7.93359 6.06545 7.9608 6.01703 8.00922C5.96861 8.05764 5.94141 8.12331 5.94141 8.19179C5.94141 8.26027 5.96861 8.32594 6.01703 8.37436C6.06545 8.42278 6.13112 8.44999 6.1996 8.44999V8.44999Z"
      fill={color}
      stroke={color}
      strokeWidth="0.18"
    />
    <path
      d="M13.2367 9.7793H6.24648C6.178 9.7793 6.11233 9.8065 6.06391 9.85492C6.01548 9.90334 5.98828 9.96902 5.98828 10.0375C5.98828 10.106 6.01548 10.1716 6.06391 10.2201C6.11233 10.2685 6.178 10.2957 6.24648 10.2957H13.2367C13.3052 10.2957 13.3709 10.2685 13.4193 10.2201C13.4677 10.1716 13.4949 10.106 13.4949 10.0375C13.4949 9.96902 13.4677 9.90334 13.4193 9.85492C13.3709 9.8065 13.3052 9.7793 13.2367 9.7793V9.7793Z"
      fill={color}
      stroke={color}
      strokeWidth="0.18"
    />
    <path
      d="M18.5036 4.39344H17.7828V3.28832C17.7831 3.25447 17.7767 3.22089 17.764 3.1895C17.7513 3.15812 17.7325 3.12955 17.7088 3.10543C17.685 3.08131 17.6567 3.06212 17.6256 3.04895C17.5944 3.03578 17.5609 3.0289 17.527 3.02869H16.9344V2.16189C16.9364 2.08105 16.9063 2.00271 16.8508 1.94397C16.7952 1.88523 16.7187 1.85086 16.6378 1.84836H16.418V1.30858C16.418 1.14629 16.2898 1 16.1277 1H15.3742C15.2119 1 15.0902 1.14643 15.0902 1.30858V1.84836H14.8641C14.6963 1.84836 14.5738 1.9942 14.5738 2.16189V4.39344H1.49644C1.2267 4.39344 1 4.59911 1 4.86889V13.28C1.00206 13.4097 1.05547 13.5333 1.14853 13.6237C1.24159 13.7142 1.3667 13.764 1.49644 13.7623H14.5738V14.5213C14.5738 14.523 14.5669 14.5247 14.567 14.5264C14.5647 14.571 14.5743 14.6155 14.5948 14.6553L14.5958 14.6603C14.5966 14.6617 14.5967 14.663 14.5975 14.6644C14.5983 14.6658 14.5983 14.6672 14.5991 14.6685L14.6067 14.6817L15.4959 16.2636C15.5214 16.3092 15.5583 16.3474 15.603 16.3745C15.6477 16.4016 15.6987 16.4166 15.7509 16.418H15.7531C15.8054 16.4164 15.8563 16.4011 15.9008 16.3737C15.9453 16.3462 15.9818 16.3076 16.0067 16.2616L16.8953 14.6855L16.9033 14.6703C16.9041 14.669 16.9045 14.6666 16.9053 14.6652C16.9061 14.6638 16.9071 14.6622 16.9079 14.6608L16.9106 14.6555C16.9311 14.6156 16.9406 14.5711 16.9381 14.5264C16.9381 14.5247 16.9344 14.5229 16.9344 14.5212V13.7623H18.5036C18.6333 13.764 18.7584 13.7142 18.8515 13.6237C18.9445 13.5333 18.9979 13.4097 19 13.28V4.86889C19 4.59911 18.7733 4.39344 18.5036 4.39344ZM15.6066 1.51639H15.9016V1.84836H15.6066V1.51639ZM15.0902 2.36475H16.418V14.3156H15.0902V2.36475ZM1.51639 13.2459V4.90984H14.5738V13.2459H1.51639ZM15.7508 15.6638L15.2809 14.832H16.2209L15.7508 15.6638ZM18.4836 13.2459H16.9344V3.54508H17.2664V6.43973C17.2664 6.5082 17.2936 6.57388 17.342 6.6223C17.3904 6.67072 17.4561 6.69792 17.5246 6.69792C17.5931 6.69792 17.6587 6.67072 17.7072 6.6223C17.7556 6.57388 17.7828 6.5082 17.7828 6.43973V4.90984H18.4836V13.2459Z"
      fill={color}
      stroke={color}
      strokeWidth="0.18"
    />
    <path
      d="M4.90968 10.6265H3.0334C3.21277 10.3683 3.38724 10.0025 3.37761 9.62644L3.37717 9.59369H4.39613C4.4646 9.59369 4.53028 9.56648 4.5787 9.51806C4.62712 9.46964 4.65432 9.40397 4.65432 9.33549C4.65432 9.26701 4.62712 9.20134 4.5787 9.15292C4.53028 9.1045 4.4646 9.07729 4.39613 9.07729H3.35939V8.18662C3.35939 7.82496 3.67011 7.52811 4.03177 7.52811H4.44087C4.50935 7.52811 4.57502 7.50091 4.62344 7.45249C4.67186 7.40407 4.69907 7.33839 4.69907 7.26992C4.69907 7.20144 4.67186 7.13576 4.62344 7.08734C4.57502 7.03892 4.50935 7.01172 4.44087 7.01172H4.03177C3.3854 7.01172 2.843 7.54025 2.843 8.18662V9.07729H2.47304C2.40456 9.07729 2.33889 9.1045 2.29047 9.15292C2.24205 9.20134 2.21484 9.26701 2.21484 9.33549C2.21484 9.40397 2.24205 9.46964 2.29047 9.51806C2.33889 9.56648 2.40456 9.59369 2.47304 9.59369H2.86074L2.86133 9.63533C2.8724 10.0639 2.46507 10.5444 2.30754 10.6928C2.2697 10.7285 2.24341 10.7746 2.23203 10.8254C2.22065 10.8761 2.2247 10.9291 2.24368 10.9775C2.26265 11.0259 2.29567 11.0675 2.33849 11.097C2.38131 11.1264 2.43197 11.1424 2.48396 11.1429H4.90968C4.97816 11.1429 5.04383 11.1157 5.09225 11.0672C5.14067 11.0188 5.16788 10.9531 5.16788 10.8847C5.16788 10.8162 5.14067 10.7505 5.09225 10.7021C5.04383 10.6537 4.97816 10.6265 4.90968 10.6265V10.6265Z"
      fill={color}
      stroke={color}
      strokeWidth="0.18"
    />
  </svg>
);
