import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';


export const WarningIcon: React.FC<IconProps> = ({ color = 'currentColor', size = 51 }) => (
    <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12.5" r="11" stroke="currentColor" strokeWidth="2" />
    <path
      d="M12 14.5V13.7295C12 12.7364 12.7627 11.9167 13.6133 11.4041C14.4344 10.9092 15.2185 10.0295 15 8.49999C14.5714 5.49997 10.5 7 10.5 7"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 17.5V18" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    
);
