export const benchmarkIndexesMockData = [
  {
    "symbol": "^FTSE",
    "1D": 0.325,
    "5D": 2.19367,
    "1M": -2.03547,
    "3M": -5.1405,
    "6M": -5.14897,
    "ytd": -1.50766,
    "1Y": 3.96582,
    "3Y": 20.46582,
    "5Y": -2.89341,
    "10Y": 12.96837,
    "max": 645.88571
  },
  {
    "symbol": "^SP500TR",
    "1D": 0.8577,
    "5D": 2.41085,
    "1M": 3.36598,
    "3M": 9.21228,
    "6M": 13.72681,
    "ytd": 19.00044,
    "1Y": 20.68713,
    "3Y": 49.8692,
    "5Y": 75.6564,
    "10Y": 224.36962,
    "max": 3686.14968
  },
  {
    "symbol": "^SPGNRUP",
    "1D": 1.2329,
    "5D": 6.62514,
    "1M": 3.5538,
    "3M": -3.60872,
    "6M": -6.88811,
    "ytd": 0.1289733,
    "1Y": 5.44224,
    "3Y": 5.44224,
    "5Y": 5.44224,
    "10Y": 5.44224,
    "max": 5.44224
  },
  {
    "symbol": "^NDX",
    "1D": 1.7296,
    "5D": 3.32754,
    "1M": 4.50395,
    "3M": 18.78491,
    "6M": 34.92183,
    "ytd": 43.35353,
    "1Y": 32.77006,
    "3Y": 46.87482,
    "5Y": 111.12198,
    "10Y": 405.60828,
    "max": 13786.19328
  },
  {
    "symbol": "^DJI",
    "1D": 0.1389,
    "5D": 1.64929,
    "1M": 0.53495662,
    "3M": 1.07391,
    "6M": 0.26974806,
    "ytd": 3.79875,
    "1Y": 11.77128,
    "3Y": 31.85388,
    "5Y": 37.47382,
    "10Y": 122.1297,
    "max": 2560.88565
  },
  {
    "symbol": "^FVX",
    "1D": -3.317,
    "5D": -10.56818,
    "1M": -2.11443,
    "3M": 12.10826,
    "6M": 8.88213,
    "ytd": -0.25348542,
    "1Y": 30.34117,
    "3Y": 1220.4698,
    "5Y": 44.29776,
    "10Y": 182.48385,
    "max": -51.9536
  },
  {
    "symbol": "^TNX",
    "1D": -2.59,
    "5D": -7.81862,
    "1M": -2.05729,
    "3M": 9.0145,
    "6M": 7.151,
    "ytd": -0.76516623,
    "1Y": 29.68966,
    "3Y": 487.65628,
    "5Y": 32.85059,
    "10Y": 47.14398,
    "max": -52.15012
  },
  {
    "symbol": "^TYX",
    "1D": -1.4174,
    "5D": -3.34988,
    "1M": -1.14213,
    "3M": 5.64144,
    "6M": 7.50759,
    "ytd": 0.05137426,
    "1Y": 26.9143,
    "3Y": 191.32386,
    "5Y": 32.79918,
    "10Y": 7.86485,
    "max": -49.41558
  },
  {
    "symbol": "^N100",
    "1D": 0.6748,
    "5D": 3.96384,
    "1M": 1.36686,
    "3M": 0.27682287,
    "6M": 4.01085,
    "ytd": 10.23147,
    "1Y": 18.48761,
    "3Y": 36.92075,
    "5Y": 29.44652,
    "10Y": 87.64633,
    "max": 38.012
  },
  {
    "symbol": "^HSI",
    "1D": 2.5962,
    "5D": 4.96413,
    "1M": -0.87493635,
    "3M": -4.88516,
    "6M": -10.98522,
    "ytd": -3.94469,
    "1Y": -6.959,
    "3Y": -24.91646,
    "5Y": -32.16364,
    "10Y": -9.16614,
    "max": 653.44078
  },
  {
    "symbol": "^N225",
    "1D": 1.4882,
    "5D": -0.096485,
    "1M": -1.8151,
    "3M": 15.13785,
    "6M": 24.11916,
    "ytd": 26.06255,
    "1Y": 22.43518,
    "3Y": 42.28528,
    "5Y": 43.46519,
    "10Y": 122.06359,
    "max": 1249.20318
  },
  {
    "symbol": "^GDAXI",
    "1D": 0.7366,
    "5D": 4.035,
    "1M": -0.55234901,
    "3M": 2.61656,
    "6M": 6.98975,
    "ytd": 14.72551,
    "1Y": 26.53359,
    "3Y": 26.1021,
    "5Y": 28.70885,
    "10Y": 96.00975,
    "max": 1505.76905
  },
  {
    "symbol": "^OVX",
    "1D": -8.1064,
    "5D": -13.40444,
    "1M": -7.22668,
    "3M": -8.35875,
    "6M": -21.34067,
    "ytd": -31.94382,
    "1Y": -41.9629,
    "3Y": -43.75585,
    "5Y": 9.0777,
    "10Y": 26.64419,
    "max": 10.88963
  },
  {
    "symbol": "^SPGSCI",
    "1D": 1.1082,
    "5D": 3.91096,
    "1M": 5.3632,
    "3M": -4.76637,
    "6M": -6.66384,
    "ytd": -6.78878,
    "1Y": -14.29248,
    "3Y": 68.15998,
    "5Y": 22.02368,
    "10Y": -12.2562,
    "max": 166.87172
  },
  {
    "symbol": "^VIX",
    "1D": 0.517,
    "5D": -14.77771,
    "1M": -6.84463,
    "3M": -23.53933,
    "6M": -25.83106,
    "ytd": -40.56769,
    "1Y": -49.25429,
    "3Y": -57.71979,
    "5Y": 11.74056,
    "10Y": -1.30529,
    "max": -21.05568
  }
];
