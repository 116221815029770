import { useEffect, useState } from "react";

type Position = Partial<DOMRect>;

export const usePosition = (el?: HTMLElement): Position => {
  const [pos, setPos] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
    bottom: 0,
    left: 0,
    right: 0,
    top: 0
  });
  useEffect(() => {
    if (el) {
      setPos(el.getBoundingClientRect());
    }
  }, [el]);
  return pos;
};
