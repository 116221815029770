import { MutationOptions } from '@apollo/client';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { requestGql } from 'utils/request-gql';

interface CreateAdminRequestInterface extends MutationOptions {
  variables: {
    user: { type: string; firstName: string; lastName: string; email: string; timezone: string };
  };
}

export const createAdminAction = createAsyncThunk(
  'admin/create',
  async (request: CreateAdminRequestInterface, thunkApi): Promise<CreateAdminRequestInterface | SerializedError> =>
    await requestGql<CreateAdminRequestInterface>(request, thunkApi, 'createAndInviteUser'),
);
