export const arrayToObject = (a: any[], key: string | number, f?: string | number): unknown =>
  a.reduce((acc, curr) => {
    acc[curr[key]] = f ? curr[f] : curr;
    return acc;
  }, {});

/**
 * Groups array by month
 * Expects a field `date` with format `YYYY-MM-DD`
 * @param arr 
 * @returns 
 */
export const groupByMonth = <T extends { date: string; }>(arr: T[]): { [key: string]: T[]; } => arr.reduce((p, c) => {
  const ar = c.date.split("-");
  const k = `${ar[0]}-${ar[1]}-01`;
  if (!p[k]) p[k] = [];
  p[k].push(c);
  return p;
}, {});


export const SumArray = <T extends unknown>(a: T[], f: string, i = 0): number => a.reduce((p, c) => p + c[f], i);

/**
 * Calculates the weighted average
 * 
 * @param nums 
 * @param weights 
 * @returns number
 */
export const weightedAverage = (tuple: [number[], number[]]): number => {
  const v = tuple[1].reduce(
    (acc, w, i) => {
      acc[0] = acc[0] + tuple[0][i] * w;
      acc[1] = acc[1] + w;
      return acc;
    }, [0, 0]);
  return v[0] === 0 && v[1] === 0 ? 0 : v[0] / v[1];
};

export const getArrayDifferences = (arr1: string[], arr2: string[]) => arr2.filter(e => !arr1.includes(e));

export const arrayToTree = <T extends { id: string; parentId: string; }>(arr: T[], parent = null): T[] =>
  arr.filter(item => item.parentId === parent)
    .map(child => ({
      ...child, children: arrayToTree(arr,
        child.id)
    }));
    