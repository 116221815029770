import { gql } from 'graphql-request';

const roadmapFields = `
input {
  user {
    riskProfile
    dateOfBirth
    currency
    married
    partner {
      dateOfBirth
    }
  }
  goals {
    type
    deadline
    age
    totalValue
    depositPercentage
    spending
  }
  assets {
    name
    type
    balance
  }
  liabilities {
    name
    monthlyPayment
    endDate
    type
  }
  income {
    netAnnualSalary
    partnerNetAnnualSalary
    otherNetAnnualIncome
    monthlyEmployerPension
  }
  expenses {
    monthlyRent
    monthlyEssential {
      breakdown {
        category
        amount
      }
      total
    }
    monthlyAdditional {
      total
    }
  }
}
output {
  overview {
      emergencyFund {
          currentMonths
          currentTotal
          expectedDate
      }
      debt {
          total
          liabilities {
              totalOwed
              initialEndDate
              expectedEndDate
              type
          }
      }
  }
  plan {   
      period
      actions {
          total
          from
          to
          needs {
              id
              type
              description
              policy
              product
              completed
              amount
          }
      }
    }
    success {
      isGoal
      origin
      totalAllocated
      totalRequired
      percentage
    }
    netWorth {
      date
      amount
      currency
    }
    cashflow {
        date
        currency
        income
        expenses
        needs
    }
}
`;

export const GetRoadmapDocument = gql`
  query GetRoadmap($userId: String!) {
    getRoadmap(userId: $userId) {
      ${roadmapFields}
    }
  }
`;

export const SaveRoadmapDocument = gql`
  mutation SaveRoadmap($roadmap: RoadmapSaveDto!) {
    saveRoadmap(input: $roadmap) {
      id
      ${roadmapFields}
    }
  }
`;

export const DeleteRoadmapDocument = gql`
  mutation DeleteRoadmap($userId: String!) {
    deleteRoadmap(userId: $userId)
  }
`;
