import { RegionEnum, SectorStabilityEnum, StockSectorEnum, WealthProviderEnum } from 'enums';
import { AssetsInterface } from 'slices/assets';

export enum SecurityTypeEnum {
  ETF = 'ETF',
  FUND = 'FUND',
  EQUITY = 'EQUITY',
  UNKNOWN = 'UNKNOWN',
}

export enum SecurityClassStatus {
  UNDERWEIGHT = 0,
  BALANCED = 1,
  OVERWEIGHT = 2,
}

export enum SecurityClassEnum {
  OVERSEAS_EQUITY = 'OVERSEAS_EQUITY',
  UK_EQUITY = 'UK_EQUITY',
  BONDS = 'BONDS',
  CASH = 'CASH',
}

export interface InvestmentHoldingSecurity {
  symbol: string;
  codes: {
    figi: string;
    isin: any;
  };
  name: string;
  description: string;
  exchange: string;
  iconUrl: string;
  price: number;
  convertedPrice: number;
  vendor: string;
  sector: {
    value: string;
    percentage: number;
  }[];
  country: {
    value: string;
    percentage: number;
  }[];
  type: SecurityTypeEnum;
  class: SecurityClassEnum;
  risk: InvestmentHoldingRisk;
}

export interface InvestmentHoldingRisk {
  assetClass: SecurityClassEnum;
  countries: {
    value: string;
    percentage: number;
  }[];
  regions: {
    value: RegionEnum;
    percentage: number;
  }[];
  sectors: {
    value: StockSectorEnum;
    percentage: number;
  }[];
  sectorsStability: {
    value: SectorStabilityEnum;
    percentage: number;
  }[];
}

export interface InvestmentHoldingInterface {
  id?: string;
  accountId: string;
  vendor: WealthProviderEnum;
  amount: number;
  convertedAmount: number;
  balance: {
    amount: number;
    convertedAmount: number;
    convertedCurrency: string;
    currency: string;
  };
  security: InvestmentHoldingSecurity;
}

export interface InvestmentsGrowRateInterface {
  values: {
    value: number;
    growthValue: number;
    growthPercentage: number;
    weight: number;
  };
}

export enum InvestmentOrderBy {
  WEIGHT_DESC = 'weight_desc',
  WEIGHT_ASC = 'weight_asc',
  PERF_DESC = 'perf_desc',
  PERF_ASC = 'perf_asc',
  NAME_DESC = 'name_desc',
  NAME_ASC = 'name_asc',
}

export type InvestmentHolding = InvestmentHoldingInterface & Partial<InvestmentsGrowRateInterface>;

export interface InvestmentAccountInterface extends AssetsInterface, InvestmentsGrowRateInterface {
  holdings: InvestmentHolding[];
  color?: string;
}

export type InvestmentPortfolioType = Record<
  'isa' | 'other' | 'pension',
  {
    total: number;
    growth: number;
    accounts: Partial<InvestmentAccountInterface>[];
  }
>;

export enum InvestmentOperation {
  SELL = 'SELL',
  BUY = 'BUY',
}

export enum InvestmentMapType {
  LINE = 'line',
  LINE_STACKED = 'lineStacked',
  HOLDINGS = 'holdings',
}
