import { createSelector, createSlice } from '@reduxjs/toolkit';
import { StoreInterface } from 'configuration/redux/store';
import { fetchCoachNotepadAction, saveCoachNotepadAction } from 'slices/coach-notepad/actions';

export interface CoachNotepadInterface {
  id: string;
  updatedAt: Date;
  note: string;
}

export interface CoachNotepadStateInterface {
  isLoading: boolean;
  isSaving: boolean;
  error: Error;
  notepad: CoachNotepadInterface;
}

const initialState: CoachNotepadStateInterface = {
  isLoading: true,
  isSaving: false,
  error: null,
  notepad: null
};

const coachNotepadSlice = createSlice({
  initialState,
  name: 'coachNotepad',
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCoachNotepadAction.fulfilled as any, (state, { payload }) => {
      state.notepad = payload;
      state.isLoading = false;
      state.error = null;
    }).addCase(fetchCoachNotepadAction.pending as any, (state) => {
      state.isLoading = true;
    }).addCase(fetchCoachNotepadAction.rejected as any, (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    }).addCase(saveCoachNotepadAction.fulfilled as any, (state) => {
      state.isSaving = false;
      state.error = null;
    }).addCase(saveCoachNotepadAction.pending as any, (state) => {
      state.isSaving = true;
    }).addCase(saveCoachNotepadAction.rejected as any, (state, { payload }) => {
      state.error = payload;
      state.isSaving = false;
    });
  },
});

export default coachNotepadSlice.reducer;

export const coachNotepadSelector = createSelector(
  (state: StoreInterface) => state.coachNotepad,
  (values) => values,
);
