import { caching } from 'configuration/data';
import { CoachDocument, CreateMultipleUsersDocument, CurrentUserDocument, GetChatUsersDocument, GetCoachesDocument, GetTotalUsersDocument, GetUserDocument, GetUserProfileDocument, GetUsersDocument, RequestFinancialInformationDocument, ResendInvitationsDocument, SimpleUsersDocument } from 'configuration/graphql/documents';
import { API_TAGS, baseApi } from './base.api';
import { UserTypeEnum } from 'enums';
import { CustomerProfileInterface, UserInterface } from 'common/interfaces';

/**
 * (WIP)
 *
 * TODO:
 * - Messaging uses existing fetch users action
 */
export interface GetUsersVariables {
  multiColumnTextSearch?: string;
  type?: UserTypeEnum;
  limit?: number;
  offset?: number;
  order?: {
    sort: string;
    order: string;
  };
  ids?: string[];
  couponCode?: string;
  channels?: string[];
  coachIds?: string[];
  coachMetaInfoIds?: string[];
  companyIds?: string[];
  excludeIds?: string[];
  hasSharedFinancialInformation?: boolean;
  includeIds?: string[];
  isRecipient?: boolean;
  optedIn?: boolean;
  search?: {
    key: string;
    value: string;
  };
  source?: string[];
  status?: string;
  text?: string;
  tierIds?: string[];
  lastLogin?: {
    fromDate?: string;
    toDate?: string;
  };
  partnerIds?: string[];
  partnerId?: string;
  coachAssigned?: {
    fromDate?: string;
    toDate?: string;
  };
}

export interface CreateMultipleUsers {
  csv: File;
  preview: boolean;
}
export type CreateMulUserRep = {
  email: string;
  firstName:string;
  lastName:string;
}
export interface CreateMultipleUsersResponse {
  data?: CreateMulUserRep[];
  totalCount?: number;
}

export interface GetUsersReponse {
  data?: UserInterface[];
  totalCount?: number;
  limit?: number;
  offset?: number;
  order?: {
    sort: string;
    order: string;
  };
  ids?: string[];
}

export const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<UserInterface, { id?: string, email?: string }>({
      query: (variables) => ({
        variables,
        document: GetUserDocument,
      }),
      transformResponse: (response: { user: UserInterface | Promise<UserInterface> }) => response.user,
      keepUnusedDataFor: caching.oneHour,
      providesTags: [API_TAGS.USER],
    }),
    getUserProfile: builder.query<CustomerProfileInterface, { userId: string; }>({
      query: (variables) => ({
        variables,
        document: GetUserProfileDocument,
      }),
      transformResponse: (response: { getCustomerProfile }) => response.getCustomerProfile,
      keepUnusedDataFor: caching.oneHour,
      providesTags: [API_TAGS.USER_PROFILE],
    }),
    currentUser: builder.query<UserInterface, { id?: string; }>({
      query: (variables) => ({
        variables,
        document: CurrentUserDocument,
      }),
      transformResponse: (response: { user: UserInterface | Promise<UserInterface> }) => response.user,
      keepUnusedDataFor: caching.oneHour,
      providesTags: [API_TAGS.USER],
    }),
    coach: builder.query<UserInterface, { id?: string; }>({
      query: (variables) => ({
        variables,
        document: CoachDocument,
      }),
      transformResponse: (response: { user: UserInterface | Promise<UserInterface> }) => response.user,
      keepUnusedDataFor: caching.oneHour,
      providesTags: [API_TAGS.USER],
    }),
    getUsers: builder.query<GetUsersReponse, GetUsersVariables & { key?: string; }>({
      query: ({ key, ...rest }) => ({
        variables: rest,
        document: GetUsersDocument,
      }),
      transformResponse: (response: { users }) => response.users,
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.USERS],
    }),
    searchUsers: builder.query<GetUsersReponse, GetUsersVariables>({
      query: (variables) => ({
        variables,
        document: SimpleUsersDocument
      }),
      transformResponse: (response: { users }) => response.users,
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.USERS],
    }),
    getTotalUsers: builder.query<number, GetUsersVariables>({
      query: (variables) => ({
        variables,
        document: GetTotalUsersDocument,
      }),
      transformResponse: (response: { users: { totalCount } }) => response.users.totalCount,
      keepUnusedDataFor: caching.day,
      providesTags: () => [{ type: API_TAGS.USERS, id: 'total' }],
    }),
    getCoaches: builder.query<GetUsersReponse, GetUsersVariables>({
      query: (variables) => ({
        variables: {
          type: 'coach',
          ...variables
        },
        document: GetCoachesDocument,
      }),
      transformResponse: (response: { users }) => response.users,
    }),
    getChatUsers: builder.query<GetUsersReponse, GetUsersVariables>({
      query: (variables) => ({
        variables,
        document: GetChatUsersDocument,
      }),
      transformResponse: (response: { users }) => response.users,
    }),
    createAndInviteMultipleCustomers: builder.mutation<CreateMultipleUsersResponse, CreateMultipleUsers>({
      query: (variables) => ({
        variables,
        document: CreateMultipleUsersDocument,
      }),
      transformResponse: (response: { createAndInviteMultipleCustomers}) => response.createAndInviteMultipleCustomers,
    }),
    resendInvitations: builder.mutation<string, { userIds: string[] }>({
      query: ({ userIds }) => ({
        variables: { input: { userIds }},
        document: ResendInvitationsDocument,
      }),
      transformResponse: (response: { resendInvitations: { message} }) => response.resendInvitations.message,
    }),
    requestAccess: builder.mutation<string, { userId: string; }>({
      query: (variables) => ({
        variables,
        document: RequestFinancialInformationDocument,
      }),
      transformResponse: (response: { requestFinancialInformation }) => response.requestFinancialInformation,
      invalidatesTags: [API_TAGS.USER_PROFILE]
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetChatUsersQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  useCreateAndInviteMultipleCustomersMutation,
  useGetCoachesQuery,
  useLazyCurrentUserQuery,
  useCoachQuery,
  useResendInvitationsMutation,
  useRequestAccessMutation,
  useGetUserProfileQuery,
  useLazyGetUsersQuery,
  useGetTotalUsersQuery,
  useLazySearchUsersQuery,
  useSearchUsersQuery
} = usersApi;
