import { caching } from 'configuration/data';
import { FetchTransactionsDocument, financeSpendingAnalysisDocument, UpdateTransactionDocument } from 'configuration/graphql/documents';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';

import { API_TAGS, baseApi } from './base.api';
import { TransactionInterface } from 'common/interfaces';

export interface TransactionsApiRequestInterface {
  userId: string;
  to?: string;
  from?: string;
  limit?: number;
  offset?: number;
  convertTo?: string;
  categories?: string[];
  direction?: string[];
  matching?: boolean;
  accountId?: string;
  accountIds?: string[];
  order?: {
    sort?: string;
    order?: string;
  }[];
}

export interface TransactionsApiResponseInterface {
  totalCount: number;
  data: TransactionInterface[];
}

export interface SpendingAnalysisCategory {
  category: string;
  group: string;
  values: {
    name: string;
    value: number;
  }[];
}

export interface SpendingAnalysisGrouped {
  group: string;
  items: SpendingAnalysisCategory[];
  values: [number, number];
}

export interface SpendingAnalysisApiResponse {
  totals: [number, number];
  categories: SpendingAnalysisCategory[];
  grouped: SpendingAnalysisGrouped[];
}

interface SpendingAnalysisApiArgs {
  userId: string;
  convertTo: string;
  ranges: {
    from: string;
    to: string;
    name: string;
  }[];
}

export const transactionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    transactions: builder.query<TransactionsApiResponseInterface, TransactionsApiRequestInterface>({
      query: (variables) => ({
        variables,
        document: FetchTransactionsDocument,
      }),
      transformResponse: ({ financeTransactions }: { financeTransactions: TransactionsApiResponseInterface; }) => financeTransactions,
      keepUnusedDataFor: caching.day,
      providesTags: [{
        type: API_TAGS.TRANSACTIONS_TABLE,
        id: 'ALL'
      }],
    }),
    fetchTransactions: builder.query<TransactionsApiResponseInterface, TransactionsApiRequestInterface>({
      query: (variables) => ({
        variables,
        document: FetchTransactionsDocument,
      }),
      transformResponse: ({ financeTransactions }: { financeTransactions: TransactionsApiResponseInterface; }) => {
        if (financeTransactions?.totalCount) {
          return {
            totalCount: financeTransactions.totalCount,
            data: financeTransactions.data.filter(f => f.categoryId !== null)
          };
        }
        return financeTransactions;
      },
      keepUnusedDataFor: caching.day,
      providesTags: [{
        type: API_TAGS.TRANSACTIONS,
        id: 'ALL'
      }],
    }),
    financeSpendingAnalysis: builder.query<SpendingAnalysisApiResponse, SpendingAnalysisApiArgs>({
      query: (variables) => ({
        variables,
        document: financeSpendingAnalysisDocument,
      }),
      transformResponse: ({ financeSpendingAnalysis }: { financeSpendingAnalysis: SpendingAnalysisApiResponse; }) => {
        if (financeSpendingAnalysis) {
          financeSpendingAnalysis.categories = financeSpendingAnalysis.categories
            .map(c => {
              c.values[0].value = Math.abs(c.values[0].value);
              c.values[1].value = Math.abs(c.values[1].value);
              return c;
            });
          financeSpendingAnalysis.categories.sort((a, b) => b.values[1].value - a.values[0].value);
          const grouped = Object.values<SpendingAnalysisCategory[]>(groupBy(financeSpendingAnalysis.categories, c => c.group));
          financeSpendingAnalysis.grouped = sortBy(grouped.reduce<SpendingAnalysisGrouped[]>((acc, curr) => {
              acc.push({
                group: curr[0].group,
                items: curr,
                values: curr.reduce((a, c) => {
                  a[0] += c.values[0].value;
                  a[1] += c.values[1].value;
                  return a;
                }, [0, 0])
              });
              return acc;
            }, []), s => s.group);
          financeSpendingAnalysis.totals = financeSpendingAnalysis.grouped.reduce((a, c) => {
            a[0] += c.values[0];
            a[1] += c.values[1];
            return a;
          }, [0, 0]);
        }
        return financeSpendingAnalysis;
      },
      keepUnusedDataFor: caching.day,
      providesTags: [
        {
          type: API_TAGS.SPENDING_ANALYSIS,
          id: 'ALL'
        }
      ],
    }),
    updateTransaction: builder.mutation<string, { id: string; transaction: { category: string; }; }>({
      query: variables => ({
        variables,
        document: UpdateTransactionDocument,
      }),
      invalidatesTags: [API_TAGS.BUDGETS, API_TAGS.SPENDING_ANALYSIS]
    })
  })
});

export const {
  useTransactionsQuery,
  useFetchTransactionsQuery,
  useFinanceSpendingAnalysisQuery,
  useLazyFinanceSpendingAnalysisQuery,
  useUpdateTransactionMutation
} = transactionsApi;
