import { Typography } from 'common/otto-ui';
import React from 'react';

interface Props {
  label?: string;
}

export const NewFeatureBadge = ({ label = 'New' }: Props): JSX.Element => {
  const classes = {
    wrapper: 'px-2 rounded-xl bg-gradient-to-r to-GradStart from-GradEnd text-background-light-background1',
    text: 'my-0',
  };

  return (
    <div className={classes.wrapper}>
      <Typography variant="caption-2" color="inherit" className={classes.text}>
        {label}
      </Typography>
    </div>
  );
};
