import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const CalendarCheckIcon = ({ color = 'currentColor', size = 24 }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color} fillRule="evenodd" clipRule="evenodd">
      <path d="M5 5C4.44772 5 4 5.44772 4 6V20C4 20.5523 4.44772 21 5 21H11C11.5523 21 12 21.4477 12 22C12 22.5523 11.5523 23 11 23H5C3.34315 23 2 21.6569 2 20V6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V10C22 10.5523 21.5523 11 21 11C20.4477 11 20 10.5523 20 10V6C20 5.44772 19.5523 5 19 5H5Z" />
      <path d="M16 1C16.5523 1 17 1.44772 17 2V6C17 6.55228 16.5523 7 16 7C15.4477 7 15 6.55228 15 6V2C15 1.44772 15.4477 1 16 1Z" />
      <path d="M8 1C8.55228 1 9 1.44772 9 2V6C9 6.55228 8.55228 7 8 7C7.44772 7 7 6.55228 7 6V2C7 1.44772 7.44772 1 8 1Z" />
      <path d="M13.3492 17.2407C13.7686 16.8813 14.3999 16.9299 14.7593 17.3492L16.939 19.8922L21.6936 13.4086C22.0202 12.9633 22.646 12.867 23.0914 13.1936C23.5367 13.5202 23.633 14.146 23.3064 14.5914L17.8064 22.0914C17.6259 22.3375 17.3429 22.4877 17.0379 22.4993C16.733 22.5109 16.4394 22.3825 16.2408 22.1508L13.2408 18.6508C12.8813 18.2315 12.9299 17.6002 13.3492 17.2407Z" />
    </g>
  </svg>
);
