import clsx from "clsx";
import { HTMLAttributes, ReactNode } from "react";

interface ButtonTextProps extends HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  onClick?: () => void;
  children: ReactNode;
}

export const ButtonText: React.FC<ButtonTextProps> = ({ onClick, children, ...rest }) => (
  <button
    className={clsx(
      "flex items-center gap-2 py-[6px] px-[10px] rounded-md transition-background duration-150",
      {
        "hover:bg-background-light-background8 dark:hover:bg-background-dark-background4": !rest.disabled,
        "text-background-light-background7 cursor-default": rest.disabled,
      },
    )}
    type="button"
    onClick={onClick}
    {...rest}>{children}</button>
);
