export enum AlertIconSeverityEnum {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  ALERT= 'alert',
}

export interface AlertIconInterface {
  severity: Lowercase<keyof typeof AlertIconSeverityEnum>;
  size?: number;
  color?: string;
}

export const AlertIcon = ({ severity, size = 24, color = 'currentColor' }) => {
  switch (severity) {
    case AlertIconSeverityEnum.SUCCESS: {
      return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="11" stroke={color} strokeWidth="2" />
          <path
            d="M9 11.9492L11.1213 14.0705L15.364 9.8279"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      );
    }

    case AlertIconSeverityEnum.WARNING: {
      return (
        <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12.5" r="11" stroke={color} strokeWidth="2" />
          <path
            d="M12 14.5V13.7295C12 12.7364 12.7627 11.9167 13.6133 11.4041C14.4344 10.9092 15.2185 10.0295 15 8.49999C14.5714 5.49997 10.5 7 10.5 7"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M12 17.5V18" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      );
    }

    case AlertIconSeverityEnum.ERROR: {
      return (
        <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12.5" r="11" stroke={color} strokeWidth="2" />
          <path d="M12 7.5V12.5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12 16.5V17" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      );
    }

    case AlertIconSeverityEnum.ALERT: {
      return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M10.2908 3.86002L1.82075 18C1.64612 18.3024 1.55372 18.6453 1.55274 18.9945C1.55176 19.3438 1.64224 19.6872 1.81518 19.9905C1.98812 20.2939 2.23748 20.5468 2.53846 20.7239C2.83944 20.901 3.18155 20.9962 3.53075 21H20.4708C20.82 20.9962 21.1621 20.901 21.463 20.7239C21.764 20.5468 22.0134 20.2939 22.1863 19.9905C22.3593 19.6872 22.4497 19.3438 22.4488 18.9945C22.4478 18.6453 22.3554 18.3024 22.1808 18L13.7108 3.86002C13.5325 3.56613 13.2815 3.32314 12.9819 3.15451C12.6824 2.98587 12.3445 2.89728 12.0008 2.89728C11.657 2.89728 11.3191 2.98587 11.0196 3.15451C10.72 3.32314 10.469 3.56613 10.2908 3.86002V3.86002Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 9V13" />
            <path d="M12 17H12.01" />
          </g>
        </svg>
      );
    }

    default: {
      return (
        <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2 23.5V1.5H11V7.5"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2 14.5H11V17.5H22L17 11L22 4.5H11"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
  }
};
