

import { Radio, RadioGroup } from "common/otto-ui";
import { RadioFormFieldProps } from "components/form/types";
import { useField } from 'formik';
import React from "react";
import { capitalizeFirstLetter } from "utils";

export const FormRadioField = ({ label, options, ...props }: RadioFormFieldProps) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <RadioGroup
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        label={label}
        helperText={meta.touched && capitalizeFirstLetter(meta.error)}
        status={!!meta.error ? 'error' : 'default'}
      >
        {options.map(o => <Radio key={`${field.name}_o_${o.value}`} label={o.label} value={o.value} />)}
      </RadioGroup>
    </div>
  );
};
