import { QueryOptions } from '@apollo/client';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { SessionInterface } from 'slices/session';
import { requestGql } from 'utils/request-gql';

export interface FetchSessionsVariablesInterface {
  limit: number;
  offset: number;
}

export interface FetchSessionsActionRequestInterface extends QueryOptions {
  variables: FetchSessionsVariablesInterface;
}

export interface FetchSessionsActionResponseInterface {
  data: SessionInterface[];
  totalCount: number;
}

export const fetchSessionsAction = createAsyncThunk(
  'sessions/fetch',
  async (request: FetchSessionsActionRequestInterface, thunkApi): Promise<SessionInterface[] | SerializedError> => await requestGql<SessionInterface[]>(request, thunkApi, 'appointments'),
);

