import clsx from 'clsx';
import React, { FC, HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  direction?: 'row' | 'column';
  align?: 'start' | 'end' | 'center' | 'base' | 'stretch';
  justify?: 'start' | 'end' | 'center' | 'between' | 'evenly' | 'around' | 'stretch';
  className?: string;
}

export const Flex: FC<Props> = (props) => {
  const { direction, className, align, justify } = props;

  const classes = {
    wrapper: clsx(
      'flex',
      {
        'flex-col': direction === 'column',
        [`items-${align}`]: align,
        [`justify-${justify}`]: justify,
      },
      className,
    ),
  };

  return (
    <div {...props} className={classes.wrapper}>
      {props.children}
    </div>
  );
};
