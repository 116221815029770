import clsx from 'clsx';
import { ButtonColorEnum, ButtonInterface, ButtonSizeEnum, ButtonVariantEnum } from 'common/otto-ui/button';
import { Children, cloneElement, HTMLAttributes, isValidElement, ReactNode, useMemo } from 'react';

export enum ButtonGroupOrientationEnum {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export interface ButtonGroupInterface extends HTMLAttributes<HTMLDivElement> {
  /** The group orientation (layout flow direction) */
  orientation?: Lowercase<keyof typeof ButtonGroupOrientationEnum>;
  /** The variant to use */
  variant?: Lowercase<keyof typeof ButtonVariantEnum>;
  /** The size of the button */
  size?: Lowercase<keyof typeof ButtonSizeEnum>;
  /** The color of the component */
  color?: Lowercase<keyof typeof ButtonColorEnum>;
  /** If true, the buttons will take up the full width of its container */
  fullWidth?: boolean;
  /** If true, no elevation is used */
  disableElevation?: boolean;
  rounded?: boolean;
  /** The content of the button group */
  children?: ReactNode;
}

/** The ButtonGroup component can be used to group related buttons */
export const ButtonGroup = ({
  orientation = ButtonGroupOrientationEnum.HORIZONTAL,
  variant = ButtonVariantEnum.OUTLINED,
  size = ButtonSizeEnum.MEDIUM,
  fullWidth,
  disableElevation,
  className,
  rounded,
  children,
  ...props
}: ButtonGroupInterface) => {
  const classes = {
    wrapper: clsx(
      'z-0 relative box-content inline-flex justify-start items-stretch overflow-hidden',
      {
        'rounded-lg': !rounded,
        'rounded-full': !!rounded,
        'flex-row': orientation === ButtonGroupOrientationEnum.HORIZONTAL,
        'flex-col': orientation === ButtonGroupOrientationEnum.VERTICAL,
        'w-full': fullWidth,
        'h-7.5': size === ButtonSizeEnum.SMALL && orientation === ButtonGroupOrientationEnum.HORIZONTAL,
        'h-9': size === ButtonSizeEnum.MEDIUM && orientation === ButtonGroupOrientationEnum.HORIZONTAL,
        'h-10.5': size === ButtonSizeEnum.LARGE && orientation === ButtonGroupOrientationEnum.HORIZONTAL,
        'shadow-general': variant === ButtonVariantEnum.FILLED && !disableElevation,
      },
      variant === ButtonVariantEnum.OUTLINED && {
        'shadow-line': true,
      },
      className,
    ),
    button: clsx('rounded-l-none rounded-r-none', {
      'flex-grow': fullWidth && orientation === ButtonGroupOrientationEnum.HORIZONTAL,
    }),
    divider: clsx({
      'w-0': orientation === ButtonGroupOrientationEnum.HORIZONTAL,
      'h-0': orientation === ButtonGroupOrientationEnum.VERTICAL,
    }),
  };

  const slides: ReactNode[] = useMemo(() => {
    const childrenWithDivider: ReactNode[] = [];
    Children.forEach(children, (child: ReactNode, index: number) => {
      if (isValidElement(child)) {
        childrenWithDivider.push(
          cloneElement<ButtonInterface>(child, {
            key: child.key ?? `button-${index}`,
            variant,
            size,
            disableElevation: true,
            className: classes.button,
          }),
        );
        childrenWithDivider.push(<div key={`divider-${index}`} className={classes.divider} />);
      }
    });

    if (childrenWithDivider.length) {
      childrenWithDivider.splice(-1, 1);
    }

    return childrenWithDivider;
  }, [children]);

  return (
    <div className={classes.wrapper} {...props}>
      {slides}
    </div>
  );
};
