// source: https://johncabot.libguides.com/c.php?g=404136&p=3814772
export const marketSectors = {
  'govt': 'Government securities',
  'corp': 'Corporate debt',
  'mtge': 'Mortgage securities',
  'm-Mkt': 'Money market, commercial paper',
  'muni': 'Municipal debt',
  'pfd': 'Preferred shares',
  'equity': 'Common shares',
  'comdty': 'Commodity markets ',
  'index': 'Indices',
  'curncy': 'Currency markets',
  'port': 'Portfolio and risk analysis'
};
export const getMarketSectorLabel = (k: string): string => marketSectors[k] ?? 'Unknown';
