import clsx from 'clsx';
import { Typography } from 'common/otto-ui';
import { iconMap } from 'common/otto-ui/icons';
import { TypographyVariantEnum } from 'common/otto-ui/typography';
import React from 'react';

interface Props {
  label: string;
  icon?: string;
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  className?: string;
  textClassName?: string;
  /**
   * Allow forcing the use of the "light" or "dark" theme
   * irrespective of the user's selected theme.
   */
  theme?: 'dark' | 'light';
}

export const ExpertiseBadge: React.FC<Props> = ({ label, icon, size = 'medium', className, textClassName, theme }) => {
  const classes = {
    wrapper: clsx(
      className,
      'flex items-center gap-2 py-2 px-4 rounded-xl bg-background-light-background6',
      'dark:bg-background-dark-background4',
      theme && (theme === 'dark' ? 'bg-background-dark-background4' : 'bg-background-light-background6'),
    ),
    text: clsx(textClassName),
  };

  const Icon = iconMap[icon] || null;

  const textVariation: { [key: string]: Lowercase<keyof typeof TypographyVariantEnum> } = {
    xsmall: 'caption',
    small: 'body-2',
    medium: 'body-1',
    large: 'subtitle',
  };

  return (
    <div className={classes.wrapper}>
      {Icon ? <Icon height={16} width={16} /> : null}
      <Typography variant={textVariation[size]} className={classes.text}>
        {label}
      </Typography>
    </div>
  );
};
