import clsx from 'clsx';
import { useRipple } from 'common/roq-hooks/use-ripple/use-ripple.hook';
import { ButtonHTMLAttributes, MouseEventHandler, ReactNode, ReactText, useMemo } from 'react';

export enum ToggleButtonItemSizeEnum {
  SMALL = 'small',
  DEFAULT = 'default',
  LARGE = 'large',
}

export interface ToggleButtonItemInterface extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onChange'> {
  value?: ReactText;
  selected?: boolean;
  disabled?: boolean;
  size?: Lowercase<keyof typeof ToggleButtonItemSizeEnum>;
  children?: ReactNode;
  onChange?: (value: ReactText) => void;
}

export const ToggleButtonItem = ({
  value,
  selected,
  disabled,
  size,
  className,
  children,
  onMouseDown,
  onClick,
  onChange,
  ...props
}:ToggleButtonItemInterface) => {
  const wrapperClass = useMemo(
    () =>
      clsx(
        'z-0 relative flex justify-center items-center font-semibold overflow-hidden transition duration-150 focus:outline-none',
        {
          'cursor-not-allowed text-label dark:text-grey2 bg-white dark:bg-body': disabled,
          'cursor-pointer': !disabled,
          'text-title dark:text-white bg-label dark:bg-grey2': selected && !disabled,
          'text-placeholder dark:text-line bg-white dark:bg-body hover:bg-offWhite dark:hover-bg-grey1 active:bg-offWhite dark:active:bg-grey1':
            !selected && !disabled,
          'h-9 p-2 text-sm': size === ToggleButtonItemSizeEnum.SMALL,
          'h-12 p-3 text-xl': size === ToggleButtonItemSizeEnum.DEFAULT,
          'h-14 p-3.5 text-2xl': size === ToggleButtonItemSizeEnum.LARGE,
        },
        className,
      ),
    [size, selected, disabled],
  );

  const { addRipple, ripples } = useRipple();

  const handleMouseDown: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (!disabled) {
      addRipple(event);

      if (onMouseDown) {
        onMouseDown(event);
      }
    }
  };

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (!disabled) {
      if (onClick) {
        onClick(event);
      }
      if (onChange) {
        onChange(value);
      }
    }
  };

  return (
    <button className={wrapperClass} disabled={disabled} onMouseDown={handleMouseDown} onClick={handleClick} {...props}>
      {ripples}
      {children}
    </button>
  );
};
