import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const CalendarIcon: React.FC<IconProps> = ({ color = "currentColor", size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color} stroke={color} strokeWidth="0.4">
    <path d="M17.435 6.06738H15.3803V5.48032C15.3803 5.21348 15.1668 5 14.8999 5C14.6331 5 14.4196 5.21348 14.4196 5.48032V6.06738H9.58973V5.48032C9.58973 5.21348 9.37625 5 9.10941 5C8.84256 5 8.62909 5.21348 8.62909 5.48032V6.06738H6.57438C5.6938 6.06738 5 6.78786 5 7.64176V16.4476C5 17.3282 5.72048 18.022 6.57438 18.022H17.4616C18.3422 18.022 19.036 17.3015 19.036 16.4476V7.64176C19.0093 6.78786 18.3155 6.06738 17.435 6.06738ZM18.0487 16.4743C18.0487 16.7945 17.7819 17.0614 17.4616 17.0614H6.5477C6.22749 17.0614 5.96064 16.7945 5.96064 16.4743V10.2035H18.0487V16.4743ZM18.0487 9.21615H5.93396V7.66845C5.93396 7.34823 6.2008 7.08139 6.52101 7.08139H8.57572V7.66845C8.57572 7.93529 8.78919 8.14877 9.05604 8.14877C9.32288 8.14877 9.53636 7.93529 9.53636 7.66845V7.0547H14.3396V7.64176C14.3396 7.90861 14.553 8.12208 14.8199 8.12208C15.0867 8.12208 15.3002 7.90861 15.3002 7.64176V7.0547H17.3549C17.6751 7.0547 17.942 7.32155 17.942 7.64176V9.21615H18.0487Z" />
      <path d="M8.41621 13.1643H9.21674C9.4569 13.1643 9.64369 12.9775 9.64369 12.7373V12.257C9.64369 12.0169 9.4569 11.8301 9.21674 11.8301H8.41621C8.17605 11.8301 7.98926 12.0169 7.98926 12.257V12.7373C7.98926 12.9775 8.17605 13.1643 8.41621 13.1643Z" />
      <path d="M11.591 13.1643H12.3915C12.6317 13.1643 12.8185 12.9775 12.8185 12.7373V12.257C12.8185 12.0169 12.6317 11.8301 12.3915 11.8301H11.591C11.3509 11.8301 11.1641 12.0169 11.1641 12.257V12.7373C11.1641 12.9775 11.3509 13.1643 11.591 13.1643Z" />
      <path d="M14.7668 13.1643H15.5673C15.8075 13.1643 15.9943 12.9775 15.9943 12.7373V12.257C15.9943 12.0169 15.8075 11.8301 15.5673 11.8301H14.7668C14.5266 11.8301 14.3398 12.0169 14.3398 12.257V12.7373C14.3398 12.9775 14.5266 13.1643 14.7668 13.1643Z" />
      <path d="M8.41621 15.4592H9.21674C9.4569 15.4592 9.64369 15.2724 9.64369 15.0323V14.552C9.64369 14.3118 9.4569 14.125 9.21674 14.125H8.41621C8.17605 14.125 7.98926 14.3118 7.98926 14.552V15.0323C7.98926 15.2724 8.17605 15.4592 8.41621 15.4592Z" />
      <path d="M11.591 15.4592H12.3915C12.6317 15.4592 12.8185 15.2724 12.8185 15.0323V14.552C12.8185 14.3118 12.6317 14.125 12.3915 14.125H11.591C11.3509 14.125 11.1641 14.3118 11.1641 14.552V15.0323C11.1641 15.2724 11.3509 15.4592 11.591 15.4592Z" />
      <path d="M14.7668 15.4592H15.5673C15.8075 15.4592 15.9943 15.2724 15.9943 15.0323V14.552C15.9943 14.3118 15.8075 14.125 15.5673 14.125H14.7668C14.5266 14.125 14.3398 14.3118 14.3398 14.552V15.0323C14.3398 15.2724 14.5266 15.4592 14.7668 15.4592Z" />
    </g>
  </svg>
);
