import { differenceInDays } from 'date-fns';

export const calculateConnectionExpiry = (
  expiryDate: string,
): {
  showExpiry: boolean;
  daysToExpire?: number;
} => {
  if (!expiryDate) return { showExpiry: false };
  const now = new Date();
  const expiresAt = new Date(expiryDate);
  const daysToExpire = differenceInDays(expiresAt, now);
  return {
    showExpiry: daysToExpire < 0,
    daysToExpire,
  };
};
