import clsx from 'clsx';
import { HTMLAttributes } from 'react';

export enum BadgeAnchorOriginHorizontalEnum {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum BadgeAnchorOriginVerticalEnum {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum BadgeColorEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ERROR = 'error',
  WARNING = 'warning',
}

export interface BadgeAnchorOriginInterface {
  horizontal: Lowercase<keyof typeof BadgeAnchorOriginHorizontalEnum>;
  vertical: Lowercase<keyof typeof BadgeAnchorOriginVerticalEnum>;
}

export interface BadgeInterface extends HTMLAttributes<HTMLElement> {
  /** Position of badge related to child element */
  anchorOrigin?: BadgeAnchorOriginInterface;
  /** Background color of badge */
  color?: Lowercase<keyof typeof BadgeColorEnum>;
  /** Content inside a badge */
  content?: string;
  /** Enable dotted badge style */
  dot?: boolean;
  badgeClassName?: string;
}

/** Badge show to users some little addition information */
export const Badge = ({
  anchorOrigin = {
    horizontal: BadgeAnchorOriginHorizontalEnum.RIGHT,
    vertical: BadgeAnchorOriginVerticalEnum.TOP,
  },
  color = BadgeColorEnum.PRIMARY,
  children,
  className,
  badgeClassName,
  content,
  dot = false,
}) => {
  const classes = clsx('inline-flex justify-center items-center font-medium absolute rounded-full transform', badgeClassName, {
    'h-4 w-auto min-w-4': !dot,
    'h-1.5 w-1.5': dot,
    'left-0 -translate-x-1/2': anchorOrigin.horizontal === BadgeAnchorOriginHorizontalEnum.LEFT,
    'right-0 translate-x-1/2': anchorOrigin.horizontal === BadgeAnchorOriginHorizontalEnum.RIGHT,
    'top-0 -translate-y-1/2': anchorOrigin.vertical === BadgeAnchorOriginVerticalEnum.TOP,
    'bottom-0 translate-y-1/2': anchorOrigin.vertical === BadgeAnchorOriginVerticalEnum.BOTTOM,
    'bg-primary text-offWhite': color === BadgeColorEnum.PRIMARY,
    'bg-secondary text-offWhite': color === BadgeColorEnum.SECONDARY,
    'bg-error text-offWhite': color === BadgeColorEnum.ERROR,
    'bg-amber-300 text-body': color === BadgeColorEnum.WARNING,
  });
  const contentClassName = clsx('text-2xs', {
    'px-1': content,
    'px-1.5': !content,
  });
  return (
    <div className={`inline-flex relative ${className}`}>
      {children}
      <div className={classes}>
        <span className={contentClassName}>{content}</span>
      </div>
    </div>
  );
};
