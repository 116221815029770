import clsx from 'clsx';
import { HTMLAttributes } from 'react';

const SIZE = 44;

export interface ProgressCircularInterface extends HTMLAttributes<HTMLElement> {
  /** Size of circle */
  size?: number;
  /** Circle border width */
  width?: number;
  /** Value for progress */
  value?: number;
}

/** Circular progress bar with determinate or indeterminate status */
export const ProgressCircular = ({
  value,
  width = 4,
  size = 44,
  className,
}: ProgressCircularInterface) => {
  const classes = {
    circle: clsx({
      'animate-circular-dash': !value,
    }),
    root: clsx(
      {
        'animate-spin': !value,
        'transform -rotate-90': value,
      },
      className,
    ),
  };

  const circumference = 2 * Math.PI * ((SIZE - width) / 2);

  return (
    <div className={classes.root} style={{ width: size, height: size, animationDuration: '1.4s' }}>
      <svg viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}>
        <circle
          className={classes.circle}
          cx={SIZE}
          cy={SIZE}
          r={(SIZE - width) / 2}
          fill="none"
          stroke="currentColor"
          strokeWidth={width}
          strokeDasharray={value ? circumference.toFixed(3) : '80px, 200px'}
          strokeDashoffset={`${value ? (((100 - value) / 100) * circumference).toFixed(3) : 0}px`}
          strokeLinecap={value ? 'inherit' : 'round'}
        />
      </svg>
    </div>
  );
};
