import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const LinkIcon: React.FC<IconProps> = ({ height = 44, width = 48, color = 'currentColor' }) => (
  <svg width={width} height={height} viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.8949 11.5977C48.6365 21.6603 45.174 34.4828 35.1289 40.2824C28.0589 44.3643 19.6065 43.8827 13.1825 39.7517L15.0742 38.6595C20.7955 41.9292 28.0528 42.1562 34.1712 38.6237C43.2684 33.3715 46.4268 21.7833 41.2906 12.6514L40.1744 16.8173L38.3244 16.3216L40.3072 8.92143L47.7074 10.9043L47.2117 12.7543L42.8949 11.5977ZM5.41485 30.7583C0.962149 20.9969 4.57891 9.26539 14.0606 3.79112C20.4562 0.0986137 27.9831 0.140482 34.1164 3.24018L32.09 4.41012C26.7442 2.14172 20.4349 2.32252 15.0183 5.44982C6.48516 10.3764 3.17719 20.8777 7.03866 29.6974L8.1518 25.5431L10.0018 26.0388L8.01897 33.4389L0.618833 31.4561L1.11455 29.6061L5.41485 30.7583Z"
      fill={color}
    />
  </svg>
);
