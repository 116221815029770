import { FinanceHistoricalPrices, FinanceProfileInvestment, FinanceSecurityDetails, FinanceSecurityProfiles, FinanceStockPriceChange } from 'common/interfaces';
import { caching } from 'configuration/data';
import { FinanceHistoricalStockPricesDocument, FinanceHoldingsDocument, FinanceLatestNewsDocument, FinanceMainIndexesDocument, FinanceOverviewDocument, FinanceProfileDocument, FinanceStockPriceChangeDocument, SecurityDetailsDocument, SecurityProfilesDocument, UpdateFinanceProfileDocument } from 'configuration/graphql/documents';
import { CURRENCY } from 'enums';

import { API_TAGS, baseApi } from './base.api';

export interface FinanceOverviewInterface {
  id: string;
  dateAdded: string;
  dateModified: string;
  income: {
    employment: {
      amount: number;
      times: number;
      lastPaycheck: string;
      description: string;
      category: string;
    };
    totalLastMonth: number;
    totalCurrentMonth: number;
  };
  spending: {
    currentMonth: number;
    lastMonth: number;
    last3MonthsAvg: number;
  };
  emergencyFund: {
    averageSpending: number;
    totalCash: number;
    months: number;
    accountIds: string[];
  };
  allowance: {
    totalGlobalIsa: number;
    percentageGlobalIsa: number;
    totalLisa: number;
    percentageLisa: number;
  };
  savings: {
    percentageCurrentMonth: number;
    percentageLastMonth: number;
    totalCurrentMonth: number;
    totalLastMonth: number;
  };
  debt: {
    totalDebt: number;
    totalNetWorth: number;
    ratio: number;
  };
  investing: {
    totalInvested: number;
    totalLiquidAssets: number;
    percentage: number;
  };
}

export interface FinanceHoldingsInterface {
  accountId: string;
  dateOfValuation: string;
  sedol: string;
  quantity: number;
  description: string;
  total: {
    value: number;
    currency: CURRENCY;
  },
  unitPrice: {
    value: number;
    currency: CURRENCY;
  },
  currency: CURRENCY;
  ticker: string;
  securityType: string;
  marketSector: string;
}

export interface FinanceIndexesInterface {
  symbol: string;
  name: string;
  price: number;
  changesPercentage: number;
  change: number;
  dayLow: number;
  dayHigh: number;
  yearHigh: number;
  yearLow: number;
  marketCap: any;
  priceAvg50: number;
  priceAvg200: number;
  volume: number;
  avgVolume: number;
  exchange: string;
  open: number;
  previousClose: number;
  eps: any;
  pe: any;
  earningsAnnouncement: any;
  sharesOutstanding: any;
  timestamp: number;
}

interface FinanceNewsInterface {
  symbol: string;
  publishedDate: string;
  title: string;
  image: string;
  site: string;
  text: string;
  url: string;
  category: string;
}

export const financeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    financeOverview: builder.query<FinanceOverviewInterface, { userId: string; convertTo?: string; }>({
      query: variables => ({
        variables,
        document: FinanceOverviewDocument
      }),
      transformResponse: (response: { financeOverview; }) => response.financeOverview,
      keepUnusedDataFor: caching.oneHour,
      providesTags: [API_TAGS.FINANCE_OVERVIEW]
    }),
    financeHoldings: builder.query<FinanceHoldingsInterface[], { userId: string; }>({
      query: variables => ({
        variables,
        document: FinanceHoldingsDocument
      }),
      transformResponse: (response: { financeHoldings; }) => response.financeHoldings?.data || [],
      keepUnusedDataFor: caching.oneHour,
      providesTags: [API_TAGS.FINANCE_HOLDINGS]
    }),
    financeIndexes: builder.query<FinanceIndexesInterface[], unknown>({
      query: () => ({
        document: FinanceMainIndexesDocument
      }),
      transformResponse: (response: { financeMainIndexes; }) => response.financeMainIndexes || [],
    }),
    financeNews: builder.query<FinanceNewsInterface[], unknown>({
      query: () => ({
        document: FinanceLatestNewsDocument
      }),
      transformResponse: (response: { financeLatestNews; }) => response.financeLatestNews || [],
      keepUnusedDataFor: caching.oneHour,
      providesTags: [API_TAGS.FINANCE_NEWS]
    }),
    financeHistoricalStockPrices: builder.query<FinanceHistoricalPrices[], { from: string; to: string; symbols: string[]; convertTo: string; }>({
      query: (variables) => ({
        variables,
        document: FinanceHistoricalStockPricesDocument
      }),
      transformResponse: (response: { financeHistoricalStockPrices; }) => response.financeHistoricalStockPrices,
      keepUnusedDataFor: caching.day,
      providesTags: [{
        type: API_TAGS.FINANCE_HISTORICAL_PRICES,
        id: 'ALL'
      }]
    }),
    financeStockPriceChange: builder.query<FinanceStockPriceChange[], { symbols: string[]; }>({
      query: (variables) => ({
        variables,
        document: FinanceStockPriceChangeDocument
      }),
      transformResponse: (response: { financeStockPriceChange; }) => response.financeStockPriceChange,
      keepUnusedDataFor: caching.day,
    }),
    financeProfile: builder.query<FinanceProfileInvestment, { userId: string; }>({
      query: (variables) => ({
        variables,
        document: FinanceProfileDocument
      }),
      transformResponse: ({ fetchFinanceProfile }: { fetchFinanceProfile: { investment: FinanceProfileInvestment; }; }) => fetchFinanceProfile?.investment || null,
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.FINANCE_PROFILE]
    }),
    updateFinanceProfile: builder.mutation<any, { userId: string; investment: { excludeRiskAssessment?: string[]; risk?: number} }>({
      query: (variables) => ({
        variables,
        document: UpdateFinanceProfileDocument
      }),
      invalidatesTags: [API_TAGS.FINANCE_PROFILE]
    }),
    securityProfiles: builder.query<FinanceSecurityProfiles[], { symbols: string[]; convertTo: string; }>({
      query: (variables) => ({
        variables,
        document: SecurityProfilesDocument
      }),
      transformResponse: (response: { fetchSecurityProfiles; }) => response.fetchSecurityProfiles,
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.FINANCE_SECURITY_PROFILES]
    }),
    securityDetails: builder.query<FinanceSecurityDetails, { symbol: string; }>({
      query: (variables) => ({
        variables,
        document: SecurityDetailsDocument
      }),
      transformResponse: (response: { fetchSecurityDetails; }) => response.fetchSecurityDetails,
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.FINANCE_SECURITY_DETAILS]
    }),
  }),
});

export const {
  useFinanceOverviewQuery,
  useLazyFinanceOverviewQuery,
  useFinanceHoldingsQuery,
  useFinanceIndexesQuery,
  useFinanceNewsQuery,
  useFinanceHistoricalStockPricesQuery,
  useFinanceStockPriceChangeQuery,
  useFinanceProfileQuery,
  useUpdateFinanceProfileMutation,
  useSecurityProfilesQuery,
  useLazyFinanceStockPriceChangeQuery,
  useLazySecurityProfilesQuery,
  useSecurityDetailsQuery
} = financeApi;
