import { createElement, ReactNode, useEffect, useRef, useState } from 'react';

export enum ClickAwayListenerMouseEventEnum {
  CLICK = 'click',
  MOUSEUP = 'mouseup',
  MOUSEDOWN = 'mousedown',
}

export enum ClickAwayListenerTouchEventEnum {
  TOUCHSTART = 'touchstart',
  TOUCHEND = 'touchend',
}

export interface ClickAwayListenerInterface {
  mouseEvent?: Lowercase<keyof typeof ClickAwayListenerMouseEventEnum>;
  touchEvent?: Lowercase<keyof typeof ClickAwayListenerTouchEventEnum>;
  children: ReactNode;
  onClickAway: (target: Node) => void;
}

export const ClickAwayListener = ({
  mouseEvent = ClickAwayListenerMouseEventEnum.CLICK,
  touchEvent = ClickAwayListenerTouchEventEnum.TOUCHEND,
  children,
  onClickAway,
}: ClickAwayListenerInterface) => {
  const ref = useRef(null);
  const [clickDetected, detectClick] = useState<boolean>(false);

  useEffect(() => {
    let currentEvent = window.event;

    const handleEvents = (event: MouseEvent | TouchEvent): void => {
      if (event === currentEvent) {
        currentEvent = null;
        return;
      }

      if (!clickDetected) {
        onClickAway(event.target as Node);
      }
      detectClick(false);
    };

    document.addEventListener(mouseEvent, handleEvents);
    document.addEventListener(touchEvent, handleEvents);

    return () => {
      document.removeEventListener(mouseEvent, handleEvents);
      document.removeEventListener(touchEvent, handleEvents);
    };
  }, [clickDetected, onClickAway, mouseEvent, touchEvent]);

  useEffect(() => {
    const handleEvents = (): void => {
      detectClick(true);
    };

    if (ref && ref.current) {
      ref.current.addEventListener(mouseEvent, handleEvents);
      ref.current.addEventListener(touchEvent, handleEvents);
    }

    return () => {
      if (ref && ref.current) {
        ref.current.removeEventListener(mouseEvent, handleEvents);
        ref.current.removeEventListener(touchEvent, handleEvents);
      }
    };
  }, [ref, mouseEvent, touchEvent]);

  return createElement('span', { ref }, children);
};
