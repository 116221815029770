import { MutationOptions } from '@apollo/client';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { requestGql } from 'utils/request-gql';

export interface DeleteAdminsVariablesInterface {
  id: string;
}

export interface DeleteAdminsActionRequestInterface extends MutationOptions {
  variables: DeleteAdminsVariablesInterface;
}

export const deleteAdminsAction = createAsyncThunk(
  'admins/deleteOne',
  async (request: DeleteAdminsActionRequestInterface, thunkApi): Promise<void | SerializedError> =>
    await requestGql<void>(request, thunkApi),
);
