import { IconProps } from 'common/otto-ui/icons';

export const InvestmentIcon = ({ color = 'currentColor', size = 24 }: IconProps): JSX.Element => (
  <svg width={size} height={size} viewBox="0,0,256,256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color} fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10">
      <g transform="scale(2,2)">
        <path d="M86,1c-1.66,0 -3,1.34 -3,3c0,1.66 1.34,3 3,3h15.75c-10.19,11.87 -41.07992,42.88008 -94.41992,55.08008c-1.61,0.36 -2.62,1.96984 -2.25,3.58984c0.31,1.39 1.54992,2.33008 2.91992,2.33008c0.22,0 0.44992,-0.02008 0.66992,-0.08008c53.4,-12.21 84.52008,-41.93016 96.33008,-55.41016v13.49023c0,1.66 1.34,3 3,3c1.66,0 3,-1.34 3,-3v-22c0,-1.66 -1.34,-3 -3,-3zM89,51c-21.66,0 -38,7.74 -38,18c0,10.26 16.34,18 38,18c21.66,0 38,-7.74 38,-18c0,-10.26 -16.34,-18 -38,-18zM89,57c18.31,0 32,6.34 32,12c0,5.66 -13.69,12 -32,12c-18.31,0 -32,-6.34 -32,-12c0,-5.66 13.69,-12 32,-12zM54,86c-1.66,0 -3,1.34 -3,3c0,10.26 16.34,18 38,18c21.66,0 38,-7.74 38,-18c0,-1.66 -1.34,-3 -3,-3c-1.66,0 -3,1.34 -3,3c0,5.66 -13.69,12 -32,12c-18.31,0 -32,-6.34 -32,-12c0,-1.66 -1.34,-3 -3,-3zM54,106c-1.66,0 -3,1.34 -3,3c0,10.26 16.34,18 38,18c21.66,0 38,-7.74 38,-18c0,-1.66 -1.34,-3 -3,-3c-1.66,0 -3,1.34 -3,3c0,5.66 -13.69,12 -32,12c-18.31,0 -32,-6.34 -32,-12c0,-1.66 -1.34,-3 -3,-3z" />
      </g>
    </g>
  </svg>
);
