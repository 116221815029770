import { gql } from 'graphql-request';

export const ListActivityDocument = gql`
  query ListActivity($limit: Int, $offset: Int, $userId: String!) {
    listActivity(limit: $limit, offset: $offset, userId: $userId) {
      data {
        authorId
        date
        id
        name
        response
        targetId
        variables
      }
      limit
      offset
      totalCount
    }
  }
`;
