import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { conversationApi, messageApi } from 'api';
import { ConversationInterface, UserSimpleInterface, ChatMessageInterface } from 'common/interfaces';
import { groupChatMessages, GroupedChatMessages } from './chat.utils';
import format from 'date-fns/format';
import { DATE_FORMAT } from 'enums';
import { stripTags } from 'utils';

interface MessagesInterface {
  [conversationId: string]: {
    totalCount: number;
    limit: number;
    items: ChatMessageInterface[];
    chat: GroupedChatMessages[];
  };
}

export interface ChatStateInterface {
  users: {
    ids: string[];
    entities: { [userId: string]: UserSimpleInterface; };
  };
  conversations: {
    limit: number;
    ids: string[];
    totalCount: number;
    entities: ConversationInterface[];
  };
  messages: MessagesInterface;
  activeConversation: string;
}

const initialState: ChatStateInterface = {
  users: {
    ids: [],
    entities: {}
  },
  conversations: {
    limit: 20,
    totalCount: 0,
    entities: [],
    ids: []
  },
  messages: {},
  activeConversation: null
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setActiveConversation: (state, { payload }: PayloadAction<string|null>) => {
      state.activeConversation = payload;
    },
    addChatUsers: (state, { payload }: PayloadAction<UserSimpleInterface[]>) => {
      state.users.ids = [...state.users.ids, ...payload.map(u => u.id)];
      payload.forEach(u => {
        state.users.entities[u.id] = u;
      });
    },
    addConversation: (state, { payload }: PayloadAction<ConversationInterface>) => {
      if (!state.conversations.ids.includes(payload.id)) {
        state.conversations.entities.unshift(payload);
        if (!payload.isPreselected) {
          state.conversations.ids.push(payload.id);
        }
      }
    },
    addChatMessage: (state, { payload }: PayloadAction<ChatMessageInterface>) => {
      if (state.messages[payload.conversationId]) {
        state.messages[payload.conversationId].totalCount += 1;
        state.messages[payload.conversationId].items.push(payload);
        state.messages[payload.conversationId].chat = groupChatMessages(state.messages[payload.conversationId].items);
      }
      const index = state.conversations.entities.findIndex(f => f.id === payload.conversationId);
      if (index > 0) {
        const c = state.conversations.entities.splice(index, 1);
        state.conversations.entities.unshift(c[0]);
      } else if (index === 0 && state.conversations.entities[index].isPreselected) {
        state.conversations.entities[index].isPreselected = false; 
      }
    },
    addChatMessageRemote: (state, { payload }: PayloadAction<string>) => {
      const index = state.conversations.entities.findIndex(f => f.id === payload);
      if (index !== -1) {
        state.conversations.entities[index].unreadCount += 1;

        if (index !== -1) {
          const c = state.conversations.entities.splice(index, 1);
          state.conversations.entities.unshift(c[0]);
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(conversationApi.endpoints.conversations.matchFulfilled, (state, { payload, meta: { arg: { originalArgs } } }) => {
        const filterOut = state.conversations.ids.filter(f => f !== state.activeConversation);
        const entities = payload.data.filter(f => !filterOut.includes(f.id));
        const preSelected = state.conversations.entities.findIndex(f => f.isPreselected);
        if (preSelected !== -1) {
          state.conversations.entities.splice(preSelected, 1);
        }
        if (entities.length) {
          state.conversations = {
            limit: originalArgs.limit,
            totalCount: payload.totalCount,
            entities: [
              ...state.conversations.entities, 
              ...entities
            ],
            ids: [...state.conversations.ids, ...entities.map(d => d.id)]
          };
        }
      })
      .addMatcher(conversationApi.endpoints.markConversationAsRead.matchFulfilled, (state, { payload, meta: { arg: { originalArgs } } }) => {
        const index = state.conversations.entities.findIndex(f => f.id === originalArgs.conversationId);
        if (index !== -1 && state.conversations.entities[index].unreadCount > 0) {
          state.conversations.entities[index].unreadCount = 0;
        }
      })
      .addMatcher(messageApi.endpoints.fetchMessages.matchFulfilled, (state, { payload, meta: { arg: { originalArgs } } }) => {
        const newMessages = payload.data.map(m => ({ ...m, date: format(new Date(m.createdAt), DATE_FORMAT.DEFAULT), body: stripTags(m.body) }));
        const hasMessages = !!state.messages[originalArgs.conversationId];
        state.messages[originalArgs.conversationId] = {
          limit: originalArgs.limit,
          totalCount: payload.totalCount,
          ...(hasMessages ? {
            items: [
              ...newMessages,
              ...state.messages[originalArgs.conversationId].items
            ],
            chat: groupChatMessages([...state.messages[originalArgs.conversationId].items, ...newMessages])
          } : {
            items: newMessages,
            chat: groupChatMessages(newMessages)
          })
        };
      });
  }
});

export const { addChatUsers, addConversation, addChatMessage, addChatMessageRemote, setActiveConversation } = slice.actions;

const chat = ({ chat }: { chat: ChatStateInterface; }) => chat;
const chatUsers = ({ chat }: { chat: ChatStateInterface; }) => chat.users;
const chatConversations = ({ chat }: { chat: ChatStateInterface; }) => chat.conversations;
const chatMessage = ({ chat }: { chat: ChatStateInterface; }) => chat.messages;

export const chatUsersSelector = createSelector(chat, v => v.users);
export const chatConversationsSelector = createSelector(chat, v => v.conversations);
export const chatActiveConversationSelector = createSelector(chat, v => v.activeConversation);
export const chatUserSelector = (userId: string) => createSelector(chatUsers, v => v.entities[userId] ?? { id: '', firstName: '', lastName: '', avatar: null });
export const chatMessageSelector = (conversationId: string) => createSelector(chatMessage, v => v[conversationId] ?? { totalCount: 0, items: [], chat: [], limit: 0 });
export const chatConversationSelector = (conversationId: string) => createSelector(chatConversations, v => v.entities.find(f => f.id === conversationId));

export default slice.reducer;
