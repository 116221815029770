export const PREMIUM_ACCESS_FEATURES = [
  'Your own personal financial coach',
  'A dynamic, personal financial plan for the short and long term',
  '1-to-1 video or audio calls with your personal financial coach to keep on top of your finances',
  'Unlimited messaging with your coach',
  'Objective and action tracking and reminders',
  'Live asset tracking and performance analysis',
  'Link and manage your financial world under one roof',
  'Investment guidance in line with your goals',
  'Optimise your pension plan',
  'Guidance on employee stock options',
  'Live masterclasses with Otto experts & access to all previous masterclasses',
  'Access to Otto\'s handpicked financial experts to work on specific problems',
  'Access to Otto Learn, our how-to finance guide',
  'Portfolio simulation',
];

export const FREEMIUM_ACCESS_FEATURES = [
  'Live masterclasses with Otto experts',
  'Live asset tracking and performance analysis',
  'Link and manage your financial world under one roof',
  'Access to Otto\'s handpicked financial experts to work on specific problems',
  'Access to Otto Learn, our how-to finance guide',
  'Portfolio simulation'
];

// TODO: Fetch these Price values directly from our BE

// Standard Premium Prices
export const MONTHLY_PRICE = 10;
export const MONTHLY_PRICE_PREV_VALUE = 20;

export const ANNUAL_PRICE = 8;
export const ANNUAL_PRICE_PREV_VALUE = 16;

// Company Specific Prices
export const STUART_MONTHLY_PRICE = 8;
export const STUART_QUARTLERY_PRICE = 7;
export const STUART_ANNUAL_PRICE = 6;


// TODO: Need to determine the features here
export const ADDITIONAL_FEATURES = [
  {
    name: 'goalsActions',
    description: 'The ability to create and manage goals and actions',
  },
  {
    name: 'calendar',
    description: 'The ability to create and manage calendar bookings with your coach',
  },
  {
    name: 'messaging',
    description: 'The ability to send and receive messages with your coach',
  },
];

/**
 * Limit the coaches access to users on specific tiers.
 *
 * e.g. this is to prevent coaches from messaging a free user who doesn't have
 * access to the messaging functionality
 */
export const COACH_HIDDEN_TIERS = ['FREE', 'CHURNED'];
