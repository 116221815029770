import { MutationOptions } from '@apollo/client';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { requestGql } from 'utils/request-gql';

interface ContactUsFormValues {
  message: string;
  subject?: string;
}

interface ContactUsVariablesInterface {
  data: ContactUsFormValues;
}

export interface ContactUsActionRequestInterface extends MutationOptions {
  variables: ContactUsVariablesInterface;
}

export const contactUsAction = createAsyncThunk(
  'settings/contactUs',
  async (request: ContactUsActionRequestInterface, thunkApi): Promise<void | SerializedError> =>
    await requestGql<void>(request, thunkApi),
);
