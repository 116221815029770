export enum StockSectorEnum {
  // CYCLICAL
  CONSUMER_CYCLICAL = 'CONSUMER_CYCLICAL',
  FINANCIAL_SERVICES = 'FINANCIAL_SERVICES',
  REAL_ESTATE = 'REAL_ESTATE',
  BASIC_MATERIALS = 'BASIC_MATERIALS',
  INDUSTRIALS = 'INDUSTRIALS',
  ENERGY = 'ENERGY',
  TECHNOLOGY = 'TECHNOLOGY',

  // DEFENSIVE
  COMMUNICATION_SERVICES = 'COMMUNICATION_SERVICES',
  HEALTHCARE = 'HEALTHCARE',
  CONSUMER_DEFENSIVE = 'CONSUMER_DEFENSIVE',
  UTILITIES = 'UTILITIES',

  // OTHER
  OTHER = 'OTHER',
}
