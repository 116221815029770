import { MutationOptions } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestGql } from 'utils/request-gql';

export interface RegisterToWebinarInterface {
    webinarId: string;
    sessionId?: string;
}

export interface RegisterToWebinarRequestInterface extends MutationOptions {
    variables: RegisterToWebinarInterface;
}

export const registerToWebinarAction = createAsyncThunk(
    'events/registerToWebinar',
    async (request: RegisterToWebinarRequestInterface, thunkApi) => requestGql(request, thunkApi, 'registerToWebinar'),
);
