export enum FinanceStockDatePeriod {
  ONE_DAY = "1D",
  FIVE_DAYS = "5D",
  ONE_MONTH = "1M",
  THREE_MONTHS = "3M",
  SIX_MONTHS = "6M",
  YEAR_TO_DATE = "ytd",
  ONE_YEAR = "1Y",
  THREE_YEARS = "3Y",
  FIVE_YEARS = "5Y",
  TEN_YEARS = "10Y",
  MAX = "max"
}

export interface FinanceStockPriceChange {
  symbol: string;
  [FinanceStockDatePeriod.ONE_DAY]: number;
  [FinanceStockDatePeriod.FIVE_DAYS]: number;
  [FinanceStockDatePeriod.ONE_MONTH]: number;
  [FinanceStockDatePeriod.THREE_MONTHS]: number;
  [FinanceStockDatePeriod.SIX_MONTHS]: number;
  [FinanceStockDatePeriod.YEAR_TO_DATE]: number;
  [FinanceStockDatePeriod.ONE_YEAR]: number;
  [FinanceStockDatePeriod.THREE_YEARS]: number;
  [FinanceStockDatePeriod.FIVE_YEARS]: number;
  [FinanceStockDatePeriod.TEN_YEARS]: number;
  [FinanceStockDatePeriod.MAX]: number;
}
