import clsx from 'clsx';
import { ReactNode } from 'react';

export enum DialogActionsAlignEnum {
  START = 'start',
  END = 'end',
  CENTER = 'center',
  BETWEEN = 'between',
  AROUND = 'around',
  EVENLY = 'evenly',
}

export interface DialogActionsInterface {
  align?: Lowercase<keyof typeof DialogActionsAlignEnum>;
  space?: 0 | 0.5 | 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  children?: ReactNode;
}

export const DialogActions = ({
  align = DialogActionsAlignEnum.END,
  space = 1,
  children,
}: DialogActionsInterface) => {
  const className = clsx('flex gap-2', {
    'justify-start': align === DialogActionsAlignEnum.START,
    'justify-end': align === DialogActionsAlignEnum.END,
    'justify-center': align === DialogActionsAlignEnum.CENTER,
    'justify-between': align === DialogActionsAlignEnum.BETWEEN,
    'justify-around': align === DialogActionsAlignEnum.AROUND,
    'justify-evenly': align === DialogActionsAlignEnum.EVENLY,
    'space-x-0': space === 0,
    'space-x-0.5': space === 0.5,
    'space-x-1': space === 1,
    'space-x-1.5': space === 1.5,
    'space-x-2': space === 2,
    'space-x-2.5': space === 2.5,
    'space-x-3': space === 3,
    'space-x-3.5': space === 3.5,
    'space-x-4': space === 4,
    'space-x-5': space === 5,
    'space-x-6': space === 6,
    'space-x-7': space === 7,
    'space-x-8': space === 8,
    'space-x-9': space === 9,
    'space-x-10': space === 10,
  });

  return <div className={className}>{children}</div>;
};
