import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const FullscreenIcon: React.FC<IconProps> = ({ height = 24, width = 24, color = 'currentColor' }) => (
  <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color} stroke={color} strokeWidth="0.76">
      <path d="M7.05467 18.86H4.344C3.052 18.86 2.01333 17.8214 2.01333 16.5294V13.7174C2.01333 13.4387 1.78533 13.2107 1.50667 13.2107C1.228 13.2107 1 13.4387 1 13.7174V16.5294C1 18.3787 2.49467 19.8734 4.344 19.8734H7.05467C7.33333 19.8734 7.56133 19.6454 7.56133 19.3667C7.56133 19.088 7.33333 18.86 7.05467 18.86Z" />
      <path d="M19.4933 13.4133C19.2147 13.4133 18.9867 13.6413 18.9867 13.92V16.6813C18.9867 17.948 17.9227 18.9867 16.6307 18.9867H13.92C13.6413 18.9867 13.4133 19.2147 13.4133 19.4933C13.4133 19.772 13.6413 20 13.92 20H16.6307C18.48 20 20 18.5053 20 16.6813V13.92C20 13.6413 19.772 13.4133 19.4933 13.4133Z" />
      <path d="M16.2759 2.01333C17.4919 2.01333 18.4799 3.02667 18.4799 4.268V7.08C18.4799 7.35867 18.7079 7.58667 18.9866 7.58667C19.2653 7.58667 19.4933 7.35867 19.4933 7.08V4.268C19.4933 2.46933 18.0493 1 16.2759 1H13.4133C13.1346 1 12.9066 1.228 12.9066 1.50667C12.9066 1.78533 13.1346 2.01333 13.4133 2.01333H16.2759Z" />
      <path d="M7.08 1H4.24267C2.444 1 1 2.46933 1 4.29333V7.08C1 7.35867 1.228 7.58667 1.50667 7.58667C1.78533 7.58667 2.01333 7.35867 2.01333 7.08V4.29333C2.01333 3.02667 3.00133 2.01333 4.24267 2.01333H7.08C7.35867 2.01333 7.58667 1.78533 7.58667 1.50667C7.58667 1.228 7.35867 1 7.08 1Z" />
    </g>
  </svg>
);
