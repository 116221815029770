import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';


export const OtherIcon: React.FC<IconProps> = ({ color = 'currentColor', width = 50, height = 52 }) => (
  <div>
    <svg width={width} height={height} viewBox="0 0 50 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5391 27.0498H1.19836L0.0512695 25.9027V5.56249L1.19836 4.41541H21.5391L22.6862 5.56249V25.9027L21.5391 27.0498ZM2.34545 24.7556H20.392V6.70958H2.34545V24.7556Z"
        fill={color}
      />
      <path
        d="M48.8531 22.6344H28.5123L27.3652 21.4873V1.14709L28.5123 0H48.8531L50.0002 1.14709V21.4873L48.8531 22.6344ZM29.6594 20.3402H47.706V2.29418H29.6594V20.3402Z"
        fill={color}
      />
      <path
        d="M35.2407 11.4671C35.3647 11.3354 35.5723 11.3292 35.7041 11.4535L35.2407 11.4671ZM35.2407 11.4671L35.1248 11.3581C35.3092 11.1623 35.6175 11.1533 35.8133 11.3378L35.8133 11.3378L37.829 13.2385M35.2407 11.4671L35.125 11.358C34.9405 11.5536 34.9495 11.8619 35.1452 12.0463L37.829 13.2385M37.829 13.2385L42.4382 8.7861L42.4382 8.78609C42.6315 8.59944 42.9399 8.60445 43.1268 8.79807M37.829 13.2385L35.1452 12.0464L37.499 14.2658C37.499 14.2658 37.499 14.2658 37.499 14.2658C37.5928 14.3543 37.7132 14.3984 37.833 14.3984C37.9548 14.3984 38.0771 14.3527 38.1713 14.2619L38.1715 14.2618L43.1149 9.48655C43.1149 9.48655 43.1149 9.48655 43.1149 9.48655C43.3083 9.29973 43.3137 8.99152 43.1268 8.79807M43.1268 8.79807C43.1268 8.79808 43.1269 8.7981 43.1269 8.79811L43.0124 8.90857L43.1268 8.79807Z"
        fill={color}
        stroke={color}
        strokeWidth="0.318124"
      />
      <path
        d="M21.4879 51.2H1.14709L0 50.0529V29.7122L1.14709 28.5651H21.4879L22.6349 29.7122V50.0529L21.4879 51.2ZM2.29418 48.9058H20.3408V30.8592H2.29418V48.9058Z"
        fill={color}
      />
      <path
        d="M46.6846 51.2H26.3439L25.1968 50.0529V29.7122L26.3439 28.5651H46.6846L47.8317 29.7122V50.0529L46.6846 51.2ZM27.491 48.9058H45.5375V30.8592H27.491V48.9058Z"
        fill={color}
      />
    </svg>
  </div>
);
