import clsx from 'clsx';
import { Fragment, HTMLAttributes, ReactNode } from 'react';

export interface ListItemInterface extends HTMLAttributes<HTMLElement> {
  /** Dividing line under list item */
  divider?: boolean;
  /** Icon of start list item */
  icon?: ReactNode;
  /** Avatar component of start list item */
  avatar?: ReactNode;
  /** Primary style of list item */
  primary?: ReactNode;
  /** Secondary style of list item */
  secondary?: ReactNode;
  /** Scondary action of right element for list item */
  secondaryAction?: ReactNode;
}

export interface ListItemTextInterface {
  primary?: ReactNode;
  secondary?: ReactNode;
}

export const ListItem = ({
  children,
  className,
  divider,
  icon,
  avatar,
  primary,
  secondary,
  secondaryAction,
  onClick,
}: ListItemInterface) => {
  const mainClassName = clsx('w-full flex flex-row justify-start items-center px-6 min-h-14 cursor-pointer', {
    'border-t-2 border-line dark:border-grey1 first:border-t-0': divider,
  });
  return (
    <Fragment>
      <li onClick={onClick} className={`${mainClassName} ${className}`}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
        {primary || secondary ? <ListItemText primary={primary} secondary={secondary} /> : children}
        {secondaryAction && <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>}
      </li>
    </Fragment>
  );
};

export const ListItemIcon: React.FC<{ children: ReactNode;}> = ({ children }) => <div className="mr-8">{children}</div>;

export const ListItemAvatar: React.FC<{ children: ReactNode;}> = ({ children }) => <div className="mr-2">{children}</div>;

export const ListItemText = ({ primary, secondary }: ListItemTextInterface) => {
  const renderTextPrimary = (value: ReactNode) => {
    if (typeof value === 'string') {
      return <div className="text-">{value}</div>;
    } else {
      return value;
    }
  };

  const renderTextSecondary = (value: ReactNode) => {
    if (typeof value === 'string') {
      return <div className="text-sm text-inputBg dark:text-grey2">{value}</div>;
    } else {
      return value;
    }
  };

  return (
    <div className="flex flex-col">
      {renderTextPrimary(primary)}
      {renderTextSecondary(secondary)}
    </div>
  );
};

export const ListItemSecondaryAction: React.FC<{ children: ReactNode;}> = ({ children }) => <div className="ml-auto">{children}</div>;
