import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { switchTheme, uiSelector, UiThemeEnum } from 'slices/ui';
import { useAnalytics } from 'use-analytics';

const themeClassName = {
  [UiThemeEnum.LIGHT]: 'light',
  [UiThemeEnum.DARK]: 'dark',
};

interface UseThemeInterface {
  currentTheme: UiThemeEnum;
  isDark: boolean;
  isLight: boolean;
  setTheme: (t: UiThemeEnum) => void;
  setStoreTheme: () => void;
  toggleTheme: () => void;
}

export const useTheme = (): UseThemeInterface => {
  const dispatch = useDispatch();
  const { track } = useAnalytics();
  const { theme } = useSelector(uiSelector);
  const setTheme = useCallback((t: UiThemeEnum) => {
    dispatch(switchTheme(t));
    /**
     * We keep a class name on the DOM to allow both SCSS module components and
     * Tailwinds components the active theme
     */
    if (t === UiThemeEnum.LIGHT) {
      document.documentElement.classList.remove(themeClassName[UiThemeEnum.DARK]);
      document.documentElement.classList.add(themeClassName[UiThemeEnum.LIGHT]);
    } else if (t === UiThemeEnum.DARK) {
      document.documentElement.classList.remove(themeClassName[UiThemeEnum.LIGHT]);
      document.documentElement.classList.add(themeClassName[UiThemeEnum.DARK]);
    }
    document.documentElement.classList.add(themeClassName[t]);
  }, []);

  const toggleTheme = () => {
    if (theme === UiThemeEnum.LIGHT) {
      setTheme(UiThemeEnum.DARK);
      void track('darkThemeSelected');
    } else {
      setTheme(UiThemeEnum.LIGHT);
      void track('lightThemeSelected');
    }
  };

  const setStoreTheme = () => {
    document.documentElement.classList.add(themeClassName[theme]);
  };
  return {
    currentTheme: theme,
    isDark: theme === UiThemeEnum.DARK,
    isLight: theme === UiThemeEnum.LIGHT,
    setTheme,
    setStoreTheme,
    toggleTheme,
  };
};
