import { IconProps } from 'common/otto-ui/icons/icon.type';
import { ColorTheme } from 'configuration/ui';
import React from 'react';

interface CustomIconProps extends IconProps {
  /**
   * Allocate secondary colour to this increase icon
   */
  isSecondaryColor?: boolean;
}

export const IncreaseIcon: React.FC<CustomIconProps> = ({
  height = 21,
  width = 20,
  isSecondaryColor,
  color = 'url(#paint0_linear_1956:315)',
}) => (
  <svg width={width} height={height} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.44327 0.964285C7.69071 0.535714 8.30929 0.535714 8.55673 0.964286L15.2375 12.5357C15.4849 12.9643 15.1756 13.5 14.6808 13.5H1.31923C0.82436 13.5 0.515066 12.9643 0.762502 12.5357L7.44327 0.964285Z"
      fill={isSecondaryColor ? ColorTheme.secondaryColor: color}
    />
    {isSecondaryColor ? null : (
      <defs>
        <linearGradient
          id="paint0_linear_1956:315"
          x1="-1"
          y1="9.00005"
          x2="16.208"
          y2="9.00005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={ColorTheme.primaryColorGradient[0]} />
          <stop offset="1" stopColor={ColorTheme.primaryColorGradient[1]} />
        </linearGradient>
      </defs>
    )}
  </svg>
);
