import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const RentIcon: React.FC<IconProps> = ({ color = 'currentColor', width = 24, height = 23 }) => (
  <svg width={width} height={height} viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.8839 10.7668L21.1683 9.1194V2.3363C21.1683 2.2822 21.1577 2.22863 21.137 2.17864C21.1163 2.12865 21.086 2.08323 21.0477 2.04497C21.0095 2.00671 20.9641 1.97637 20.9141 1.95569C20.8641 1.935 20.8105 1.92436 20.7564 1.92439H17.7013C17.6472 1.92436 17.5936 1.935 17.5436 1.95569C17.4936 1.97637 17.4482 2.00671 17.4099 2.04497C17.3717 2.08323 17.3413 2.12865 17.3206 2.17864C17.3 2.22863 17.2893 2.2822 17.2894 2.3363V5.39474L11.7905 0.114641C11.7138 0.0410743 11.6116 0 11.5053 0C11.399 0 11.2968 0.0410743 11.2201 0.114641L0.126695 10.7668C0.0673973 10.8237 0.0264794 10.8971 0.00920328 10.9774C-0.00807284 11.0578 -0.00091723 11.1415 0.0297498 11.2177C0.0604168 11.294 0.113193 11.3593 0.181293 11.4053C0.249392 11.4514 0.329702 11.476 0.411894 11.476H1.99075V22.5175C1.99072 22.5716 2.00135 22.6252 2.02204 22.6752C2.04273 22.7252 2.07307 22.7706 2.11132 22.8088C2.14958 22.8471 2.195 22.8774 2.24499 22.8981C2.29498 22.9188 2.34856 22.9294 2.40266 22.9294H20.608C20.6621 22.9294 20.7156 22.9188 20.7656 22.8981C20.8156 22.8774 20.861 22.8471 20.8993 22.8088C20.9376 22.7706 20.9679 22.7252 20.9886 22.6752C21.0093 22.6252 21.0199 22.5716 21.0199 22.5175V11.476H22.5987C22.6809 11.476 22.7612 11.4514 22.8293 11.4053C22.8974 11.3593 22.9502 11.294 22.9809 11.2177C23.0115 11.1415 23.0187 11.0578 23.0014 10.9774C22.9841 10.8971 22.9432 10.8237 22.8839 10.7668ZM18.1132 2.74822H20.3445V8.32837L18.1132 6.1858V2.74822ZM20.608 10.6521C20.5539 10.6521 20.5003 10.6627 20.4503 10.6834C20.4003 10.7041 20.3549 10.7345 20.3166 10.7727C20.2784 10.811 20.248 10.8564 20.2273 10.9064C20.2067 10.9564 20.196 11.01 20.1961 11.0641V22.1056H2.81456V11.0641C2.81459 11.01 2.80396 10.9564 2.78327 10.9064C2.76258 10.8564 2.73224 10.811 2.69399 10.7727C2.65573 10.7345 2.61031 10.7041 2.56032 10.6834C2.51033 10.6627 2.45675 10.6521 2.40265 10.6521H1.43563L11.5053 0.983113L21.575 10.6521H20.608Z"
      fill={color}
    />
    <path
      d="M10.6907 13.4871C10.8252 13.4871 10.9343 13.3781 10.9343 13.2436C10.9343 13.109 10.8252 13 10.6907 13H8.94278C8.80824 13 8.69922 13.109 8.69922 13.2436V16.7395C8.69922 16.874 8.80824 16.983 8.94278 16.983H10.6907C10.8252 16.983 10.9343 16.874 10.9343 16.7395C10.9343 16.6049 10.8252 16.4959 10.6907 16.4959H9.18635V15.2351H10.6907C10.8252 15.2351 10.9343 15.1261 10.9343 14.9916C10.9343 14.857 10.8252 14.748 10.6907 14.748H9.18635V13.4871H10.6907Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
    />
    <path
      d="M14.3939 13C14.2594 13 14.1503 13.109 14.1503 13.2436V15.7202L12.864 13.1351C12.8137 13.0339 12.7007 12.9805 12.5902 13.0065C12.4802 13.0325 12.4023 13.1306 12.4023 13.2437V16.7396C12.4023 16.8741 12.5114 16.9831 12.6459 16.9831C12.7805 16.9831 12.8895 16.8741 12.8895 16.7396V14.2797L14.1758 16.8648C14.2177 16.9489 14.303 16.9999 14.3938 16.9999C14.4122 16.9999 14.4309 16.9979 14.4496 16.9935C14.5596 16.9676 14.6375 16.8695 14.6375 16.7563V13.2436C14.6375 13.109 14.5285 13 14.3939 13Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
    />
    <path
      d="M6.65272 15.235H6.99147C7.12601 15.235 7.23503 15.126 7.23503 14.9915V13.2436C7.23503 13.109 7.12601 13 6.99147 13H5.24356C5.10902 13 5 13.109 5 13.2436V16.7395C5 16.874 5.10902 16.983 5.24356 16.983C5.37811 16.983 5.48713 16.874 5.48713 16.7395V15.2351H6.12809L6.76525 16.8299C6.80334 16.9252 6.89482 16.9831 6.99157 16.9831C7.02167 16.9831 7.05226 16.9775 7.08188 16.9656C7.20678 16.9157 7.26757 16.774 7.21769 16.6491L6.65272 15.235ZM5.48713 13.4871H6.74791V14.7479H5.48713V13.4871Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
    />
    <path
      d="M18.093 13H16.3451C16.2106 13 16.1016 13.109 16.1016 13.2436C16.1016 13.3781 16.2106 13.4871 16.3451 13.4871H16.9756V16.7564C16.9756 16.891 17.0846 17 17.2191 17C17.3537 17 17.4627 16.891 17.4627 16.7564V13.4871H18.0931C18.2277 13.4871 18.3367 13.3781 18.3367 13.2436C18.3367 13.109 18.2276 13 18.093 13Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
    />
  </svg>
);
