import { EntityId } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { Button, ButtonIcon } from 'common/otto-ui/button';
import { CheckBox } from 'common/otto-ui/check-box';
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'common/otto-ui/dialog';
import { tableMapFieldToComponent } from 'common/otto-ui/table';
import { MapDataToTableConfigItemInterface } from 'common/otto-ui/table/map-data-to-table.mapper';
import { SyntheticEvent, useState } from 'react';
import { Edit2Icon } from '../icons';
import { DeleteIcon } from '@otto-finance/ui';

export interface TableBodyInterface {
  data: any[];
  headKeys: MapDataToTableConfigItemInterface[];
  selectionMode?: boolean;
  selectedItems?: EntityId[];
  onSetSelectedItems?: (event: SyntheticEvent<HTMLInputElement>) => void;
  children?: never;
  editMode?: boolean;
  deleteMode?: boolean;
  onEditEntity?: (id: string) => void;
  onDeleteEntity?: (id: string) => void;
  sort: string;
}

export const TableBody = ({
  data,
  headKeys,
  onSetSelectedItems,
  selectedItems,
  selectionMode,
  editMode,
  deleteMode,
  onEditEntity,
  onDeleteEntity,
  sort,
}:TableBodyInterface) => {
  const [deleteEntityId, setDeleteEntityId] = useState<string>(null);
  const handleCloseDeleteDialog = () => {
    setDeleteEntityId(null);
  };

  const handleDeleteEntity = () => {
    if (deleteEntityId) {
      onDeleteEntity(deleteEntityId);
      setDeleteEntityId(null);
    }
  };

  const renderCells = (item: any, index: number) =>
    headKeys.map((headKey, cellIndex) => {
      const className = clsx('border-b border-solid border-line px-2 py-6.5', {
        'bg-transparentBlue700 dark:bg-transparentBlue300':
          selectedItems?.includes(item.id) || headKey.dataTarget && headKey.dataTarget === sort,
      });
      return (
        <td key={`table-body-td-${item?.id}-${index}-${cellIndex}`} className={className}>
          {tableMapFieldToComponent(headKey, item)}
        </td>
      );
    });

  return (
    <tbody>
      {data.map((item, index) => (
        <tr key={`table-body-tr-${item?.id}-${index}`} className="bg-white dark:bg-background-dark-background3 dark:even:bg-background-dark-background2">
          {selectionMode && (
            <td className={clsx(
              'border-b border-solid border-line px-8 py-6.5 text-center sticky left-0 bg-white shadow-general dark:shadow-general-lg z-10',
              {
                'bg-transparentBlue700': selectedItems?.includes(item.id),
              },
            )}>
              <div className="flex align-items justify-center">
                <CheckBox
                  checked={selectedItems?.includes(item.id)}
                  onChange={onSetSelectedItems}
                  size="small"
                  data-row-id={item.id}
                />
              </div>
            </td>
          )}
          {renderCells(item, index)}
          {(editMode || deleteMode) && (
            <td className="border-b border-solid border-line px-8 py-6.5 sticky right-0 bg-white shadow-general dark:shadow-general-lg">
              <div className="flex">
                {editMode && (
                  <ButtonIcon onClick={() => onEditEntity(item.id)}>
                    <Edit2Icon className="text-grey2" />
                  </ButtonIcon>
                )}
                {deleteMode && (
                  <ButtonIcon onClick={() => setDeleteEntityId(item.id)}>
                    <DeleteIcon />
                  </ButtonIcon>
                )}
              </div>
            </td>
          )}
        </tr>
      ))}
      <Dialog open={!!deleteEntityId} onClose={handleCloseDeleteDialog} maxWidth={'md'} closeIcon disableBackdropClick>
        <DialogTitle>Delete row</DialogTitle>
        <DialogContent>Are you sure you want to delete this row?</DialogContent>
        <DialogActions>
          <Button variant="filled" color="secondary" onClick={handleDeleteEntity} className="mr-2">
            Delete
          </Button>
          <Button variant="filled" onClick={handleCloseDeleteDialog}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </tbody>
  );
};
