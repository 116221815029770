import { useRouter } from "next/router";
import { useEffect } from "react";

type PageEvent = 'routeChangeStart' | 'routeChangeComplete' | 'beforeHistoryChange' | 'routeChangeError' | 'hashChangeStart' | 'hashChangeComplete';

export const usePageChange = (event: PageEvent, cb: (e: string) => void) : void => {
  const router = useRouter();
  useEffect(() => {
    router.events.on(event, cb);
    return () => {
      router.events.off(event, cb);
    };
  },[]);
}