import clsx from 'clsx';
import { Portal } from 'common/otto-ui/portal/portal';
import { ProgressCircular } from 'common/otto-ui/progress/progress-circular';
import { HTMLAttributes, useEffect } from 'react';

export interface BackdropInterface extends HTMLAttributes<HTMLDivElement> {
  /** If true, the backdrop is open. */
  open?: boolean;
  /** If true, the backdrop cover full screen */
  fullScreen?: boolean;
  /** Size of spinner */
  size?: number;
  /** border width of spinner */
  width?: number;
}

/** The backdrop component is used to provide emphasis on a particular element or parts of it. */
export const Backdrop = ({
  open,
  fullScreen,
  width = 2,
  size = 72,
  className,
  children,
  style,
  ...props
}:BackdropInterface) => {
  const classes = {
    frame: 'z-[200] fixed left-0 top-0 w-screen h-screen pointer-events-auto',
    backdrop: clsx(
      'bg-title dark:bg-grey2 bg-opacity-60 dark:bg-opacity-40 text-white flex flex-row justify-center items-center',
      {
        'z-[200] absolute left-0 top-0 w-full h-full': fullScreen,
      },
      className,
    ),
  };

  useEffect(() => {
    if (fullScreen) {
      if (open) {
        document.body.classList.add('prevent-body-scroll');
      } else {
        document.body.classList.remove('prevent-body-scroll');
      }
    }

    return () => {
      document.body.classList.remove('prevent-body-scroll');
    };
  }, [fullScreen, open]);

  if (!open) {
    return null;
  }


  return fullScreen ? (
    <Portal>
      <div className={classes.frame}>
        <div className={classes.backdrop} {...props}>
          {children ? children : <ProgressCircular size={size} width={width} />}
        </div>
      </div>
    </Portal>
  ) : (
    <div className={classes.backdrop} style={style} {...props}>
      <ProgressCircular width={width * 0.75} size={size * 0.75} />
    </div>
  );
};
