import Analytics from 'analytics';
import { hotjar, LinkedInTag, plugins } from 'configuration/analytics';
import React, { ReactNode, useEffect } from 'react';
import { AnalyticsProvider as AnalyticsWrapper } from 'use-analytics';
import { useLoginHook } from 'views/login/hooks';

// Initialize the analytics library
const analytics = Analytics({
  app: 'otto-finance',
  plugins,
});

export const AnalyticsProvider: React.FC<{ children: ReactNode; }> = ({ children }) => {
  const { userData } = useLoginHook();

  useEffect(() => {
    // Initialise the hotjar instance
    hotjar.initialize();
    if (userData?.id) {
      hotjar.identify(userData.id, {});
    }
  }, [userData]);

  return (
    <AnalyticsWrapper instance={analytics}>
      <LinkedInTag />
      {children}
    </AnalyticsWrapper>
  );
};
