import clsx from 'clsx';
import styles from 'common/otto-ui/tabs/tabs-line.module.scss';
import React, { ReactNode, useEffect, useState } from 'react';

type TabsLineItem = {
  id: string;
  label: string | ReactNode;
  onClick?: () => void;
};

interface TabsLineProps {
  id?: string;
  data: TabsLineItem[];
  defaultItem?: number;
  small?: boolean;
  onChange?: (id: string, k: number) => void;
}

export const TabsLine: React.FC<TabsLineProps> = ({
  id = 'tln',
  data,
  defaultItem = 0,
  small,
  onChange
}) => {
  const [selected, setSelected] = useState(defaultItem);
  useEffect(() => {
    setSelected(defaultItem);
  }, [defaultItem]);
  const onSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, i: TabsLineItem, k: number) => {
    if (i.onClick) {
      i.onClick();
    }
    if (onChange) {
      onChange(i.id, k);
    }
    setSelected(k);
  };
  return (
  <div className={clsx(styles.root, small && styles.small)}>
      <div className={styles.tabs} role="tablist">
        {data.map((item, k) => (
          <div
            key={k}
            onClick={(e) => onSelect(e, item, k)}
            role="tab"
            aria-controls={`${id}_tab_${k}`}
            aria-selected={k === selected}
            tabIndex={k}
          >
            <div role="presentation">
              {item.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
