import { createSlice } from '@reduxjs/toolkit';
import { CompanyInterface } from 'api/events.api';
import { isAfter, isBefore } from 'date-fns';
import { fetchWebinarsAction, listCategoriesAction } from 'slices/events/actions';

interface ImageInterface {
  title: string | null;
  description: string | null;
  contentType: string | null;
  fileName: string | null;
  fileUrl: string | null;
}

interface ContentInterface {
  data: { [key: string]: string; } | null;
  content: { [key: string]: string; } | null;
  nodeType: string | null;
}

export interface SpeakerInterface {
  name: string;
  avatar: ImageInterface | null;
  role: string | null;
}

interface TopicInterface {
  title: string | null;
  slug: string | null;
  image: ImageInterface | null;
  content: ContentInterface;
  icon: string;
}

export interface CategoryInterface {
  title: string;
  entryId: string;
  slug: string;
  url: string;
  score: number | null;
}

export interface WebinarInterface {
  webinarId: string;
  entryId: string;
  sessionId: string | null;
  title: string;
  slug: string;
  registration: {
    registered: boolean;
    joinLink: string | null;
  };
  video: {
    fileName: string;
    url: string;
  };
  description: string | null;
  videoId: string | null;
  videoUrl: string | null;
  date: string | null;
  thumbnail: ImageInterface | null;
  banner: ImageInterface | null;
  host: SpeakerInterface;
  speakers: SpeakerInterface[];
  categories: CategoryInterface[];
  topics: TopicInterface[];
  companies: CompanyInterface[];
  isUpcoming?: boolean;
}

export interface EventsStateInterface {
  webinars: WebinarInterface[];
  events: {
    upcomingEvents: WebinarInterface[];
    previousEvents: WebinarInterface[];
  };
  categories: CategoryInterface[];
}

export const eventsInitialState: EventsStateInterface = {
  webinars: [],
  events: {
    upcomingEvents: [],
    previousEvents: [],
  },
  categories: [],
};

export const eventsSlice = createSlice({
  name: 'events',
  initialState: eventsInitialState,
  reducers: {
    updateRegistrationState: (state, action) => {
      const { webinarId, registered } = action.payload;
      const webinar = state.webinars.find((w) => w.webinarId === webinarId);
      if (webinar) {
        webinar.registration.registered = registered;
      }
    },
    setUpcomingEvents: (state, action) => {
      const { payload } = action;
      const upcomingEvents = payload.filter((webinar) => isAfter(new Date(webinar.date), new Date())).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      state.events.upcomingEvents = upcomingEvents;
    },
    setPreviousEvents: (state, action) => {
      const { payload } = action;
      const previousEvents = payload.filter((webinar) => isBefore(new Date(webinar.date), new Date())).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      state.events.previousEvents = previousEvents;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWebinarsAction.fulfilled as any, (state, { payload }) => {
      state.webinars = payload.data.map(w => ({ ...w, isUpcoming: isAfter(new Date(w.date), new Date()) }));
    }).addCase(listCategoriesAction.fulfilled as any, (state, { payload }) => {
      state.categories = payload.data;
    });
  },
});

export const { updateRegistrationState, setUpcomingEvents, setPreviousEvents } = eventsSlice.actions;
export default eventsSlice.reducer;
