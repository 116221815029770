import { gql } from 'graphql-request';

const fields = `
  id
  title
  amount
  currentAmount
  progression
  type
  repeat
  expires
  expired
  createdAt
`;

const GetTargetsDocument = gql`
  query GetTargets {
    getTargets {
      ${fields}
    }
  }
`;

const GetTargetDocument = gql`
  query GetTarget {
    getTarget {
      ${fields}
    }
  }
`;

const CreateTargetDocument = gql`
  mutation CreateTarget($data: CreateTargetDto!) {
    createTarget(data: $data)
  }
`;

const DeleteTargetDocument = gql`
  mutation DeleteTarget($targetId: String!) {
    deleteTarget(targetId: $targetId)
  }
`;

const UpdateTargetDocument = gql`
  mutation UpdateTarget($targetId: String!, $data: CreateTargetDto!) {
    updateTarget(targetId: $targetId, data: $data)
  }
`;

export {
  GetTargetsDocument,
  GetTargetDocument,
  CreateTargetDocument,
  DeleteTargetDocument,
  UpdateTargetDocument
};
