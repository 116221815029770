import { gql } from 'graphql-request';

export const FinanceHoldingsDocument = gql`
  query financeHoldings($userId: String!) {
    financeHoldings(userId: $userId) {
      data {
        accountId
        dateOfValuation
        sedol
        quantity
        description
        total {
          value
          currency
        }
        unitPrice {
          value
          currency
        }
        currency
        ticker
        securityType
        marketSector
      }
    }
  }
`;

export const FinanceMainIndexesDocument = gql`
  query financeMainIndexes {
    financeMainIndexes
  }
`;

export const FinanceLatestNewsDocument = gql`
  query FinanceLatestNews {
    financeLatestNews
  }
`;

export const FinanceSearchHoldingsDocument = gql`
  query FinanceSecuritiesSearch($key: String!){
    financeSecuritiesSearch(key: $key)
  }
`;

export const AddInvestmentOperationDocument = gql`
  mutation AddInvestmentOperation($operation: InvestmentOperationDto!){
    addInvestmentOperation(operation: $operation) 
  }
`;

export const DeleteHoldingDocument = gql`
  mutation DeleteHoldingDocument($account: String!, $holding: String){
    deleteAccountHoldings(account: $account, holding: $holding) 
  }
`;

export const FinanceHistoricalStockPricesDocument = gql`
  query FinanceHistoricalStockPrices($convertTo: String!,$from: String!,$symbols: [String!]!,$to: String!){
  financeHistoricalStockPrices(convertTo: $convertTo,from: $from, symbols: $symbols,to: $to) {
    symbol
    historical {
      close
      date
      volume
    }
  }
}
`;

export const FinanceStockPriceChangeDocument = gql`
  query FinanceStockPriceChange($symbols: [String!]!){
    financeStockPriceChange(symbols: $symbols)
  }
`;

export const FinanceProfileDocument = gql`
  query FinanceProfile($userId: String!){
    fetchFinanceProfile(userId: $userId) {
      userId
      investment {
        excludeRiskAssessment
        risk
        watchlist {
          symbol
          idea
          tags
        }
      }
    }
  }
`;

export const UpdateFinanceProfileDocument = gql`
  mutation UpdateFinanceProfile($investment: InvestmentProfileDto!, $userId: String!){
    updateFinanceProfile(investment: $investment, userId: $userId) {
      investment {
        excludeRiskAssessment
        risk
        watchlist {
          symbol
          idea
          tags
        }
      }
    }
  }
`;

const securityProfileFields = `
  symbol
  name
  description
  iconUrl
  exchange
  exchangeShortName
  price
  changes
  currency
  convertedPrice
  convertedCurrency
  vendor
  country {
    percentage
    value
  }
  industry
  type
  class
  ceo
  fullTimeEmployees
  ipoDate
  exchangeShortName
`;

export const SecurityProfilesDocument = gql`
  query SecurityProfilesQuery(
  $symbols: [String!]!
  $convertTo: String
) {
  fetchSecurityProfiles(
    symbols: $symbols
    convertTo: $convertTo
  ) {
      ${securityProfileFields}
    }
}
`;

export const SecurityDetailsDocument = gql`
query SecurityDetailsQuery($symbol: String!){
  fetchSecurityDetails(symbol: $symbol) {
    profile {
      ${securityProfileFields}
    }
    indicators {
      open
      close
      high
      low
      marketCap
      beta
      volume
      lastDiv
      priceEarningsRatioTTM
      grossProfitMarginTTM
      netProfitMarginTTM
      currentRatioTTM
      returnOnEquityTTM
      lastIncomeStatement
      epsLastStatement
      revenueGrowthLastStatement
    }
    grading {
      newGrade
      previousGrade
      gradingCompanies
      from
      to
      count
    }
  }
}
`;
