import { IconProps } from 'common/otto-ui/icons/icon.type';
import { useTheme } from 'common/roq-hooks/use-theme';
import React from 'react';


export const RealEstateIcon: React.FC<IconProps> = ({ color = 'black', width = 56, height = 56, darkColor = 'white' }) => {

  const { isDark } = useTheme();

  return (
  <div>
    <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.4922 25.8462H4.30758C4.07909 25.8462 3.85995 25.937 3.69838 26.0985C3.53681 26.2601 3.44604 26.4792 3.44604 26.7077V50.8308C3.44604 51.0593 3.53681 51.2784 3.69838 51.44C3.85995 51.6016 4.07909 51.6923 4.30758 51.6923H40.4922C40.7207 51.6923 40.9398 51.6016 41.1014 51.44C41.263 51.2784 41.3537 51.0593 41.3537 50.8308V26.7077C41.3537 26.4792 41.263 26.2601 41.1014 26.0985C40.9398 25.937 40.7207 25.8462 40.4922 25.8462ZM39.6307 33.6H24.9845V27.5693H39.6307V33.6ZM16.3691 27.5693V41.3539C16.3691 41.5824 16.4599 41.8015 16.6215 41.9631C16.783 42.1247 17.0022 42.2154 17.2307 42.2154H27.5691V49.9693H12.923V38.7693C12.923 38.5408 12.8322 38.3216 12.6706 38.1601C12.5091 37.9985 12.2899 37.9077 12.0614 37.9077H5.16912V27.5693H16.3691ZM5.16912 39.6308H11.1999V49.9693H5.16912V39.6308ZM29.2922 49.9693V41.3539C29.2922 41.1254 29.2014 40.9063 29.0399 40.7447C28.8783 40.5831 28.6592 40.4923 28.4307 40.4923H18.0922V27.5693H23.2614V34.4616C23.2614 34.6901 23.3522 34.9092 23.5138 35.0708C23.6753 35.2323 23.8945 35.3231 24.123 35.3231H39.6307V49.9693H29.2922Z"
        fill={isDark ? darkColor : color}
      />
      <path
        d="M34.4615 23.2607C34.6385 23.2607 34.8113 23.206 34.9562 23.1043C35.1011 23.0025 35.2111 22.8586 35.2713 22.6921C35.3319 22.5255 35.3395 22.3442 35.2933 22.1731C35.247 22.002 35.1491 21.8492 35.0129 21.7358L14.336 4.50504C14.24 4.42532 14.128 4.36737 14.0075 4.3352C13.887 4.30302 13.761 4.29739 13.6381 4.31869C13.5152 4.33999 13.3984 4.38771 13.2958 4.45856C13.1932 4.52941 13.1071 4.62169 13.0436 4.72904L2.70518 21.9598C2.62763 22.0906 2.58608 22.2396 2.58475 22.3917C2.58342 22.5437 2.62236 22.6934 2.69762 22.8256C2.77288 22.9577 2.88177 23.0676 3.01323 23.144C3.14469 23.2204 3.29404 23.2607 3.4461 23.2607H34.4615ZM14.0086 6.47796L32.0836 21.5377H4.97103L14.0086 6.47796Z"
        fill={isDark ? darkColor : color}
      />
      <path
        d="M24.6643 18.2906L15.1874 10.5367C15.0948 10.46 14.9871 10.4035 14.8714 10.3709C14.7557 10.3382 14.6344 10.3302 14.5154 10.3472C14.3967 10.366 14.2832 10.4095 14.1823 10.4748C14.0814 10.5401 13.9953 10.6257 13.9295 10.7263L8.76027 18.4801C8.67801 18.6073 8.63071 18.7539 8.62315 18.9051C8.61558 19.0564 8.64802 19.207 8.7172 19.3417C8.78812 19.4825 8.89632 19.6011 9.03 19.6846C9.16368 19.7682 9.31772 19.8135 9.47535 19.8155H24.1215C24.2986 19.8154 24.4713 19.7608 24.6162 19.6591C24.7611 19.5573 24.8712 19.4134 24.9314 19.2469C24.9909 19.0794 24.9972 18.8976 24.9494 18.7264C24.9016 18.5552 24.802 18.403 24.6643 18.2906V18.2906ZM11.0864 18.0924L14.8341 12.4666L21.7264 18.0924H11.0864Z"
        fill={isDark ? darkColor : color}
      />
      <path
        d="M32.9969 0.766769C32.7568 0.524112 32.4711 0.331417 32.1561 0.199811C31.8411 0.0682043 31.5032 0.00029376 31.1618 0H2.58462C1.89913 0 1.24173 0.272307 0.757016 0.757016C0.272307 1.24173 0 1.89913 0 2.58462V52.5538C0 53.2393 0.272307 53.8967 0.757016 54.3814C1.24173 54.8662 1.89913 55.1385 2.58462 55.1385H42.2154C42.9009 55.1385 43.5583 54.8662 44.043 54.3814C44.5277 53.8967 44.8 53.2393 44.8 52.5538V13.7329C44.8017 13.0489 44.5322 12.3921 44.0505 11.9065L32.9969 0.766769ZM32.7385 2.95508L41.776 12.0615H32.7385V2.95508ZM42.2154 53.4154H2.58462C2.35612 53.4154 2.13699 53.3246 1.97542 53.163C1.81385 53.0015 1.72308 52.7823 1.72308 52.5538V2.58462C1.72308 2.35612 1.81385 2.13699 1.97542 1.97542C2.13699 1.81385 2.35612 1.72308 2.58462 1.72308H31.0154V12.9231C31.0154 13.1516 31.1062 13.3707 31.2677 13.5323C31.4293 13.6938 31.6484 13.7846 31.8769 13.7846H43.0769V52.5538C43.0769 52.7823 42.9862 53.0015 42.8246 53.163C42.663 53.3246 42.4439 53.4154 42.2154 53.4154Z"
        fill={isDark ? darkColor : color}
      />
      <path
        d="M52.5584 0C51.6456 0 50.7702 0.50504 50.1248 1.40402C49.4794 2.30299 49.1168 3.52226 49.1168 4.79361V41.9441C49.1046 42.0593 49.1046 42.1764 49.1168 42.2916L51.698 54.2756C51.7501 54.5245 51.8588 54.7432 52.0077 54.8988C52.1566 55.0544 52.3378 55.1385 52.5239 55.1385C52.7101 55.1385 52.8913 55.0544 53.0402 54.8988C53.1891 54.7432 53.2978 54.5245 53.3499 54.2756L55.9311 42.2916C55.9665 42.1812 55.9897 42.0641 56 41.9441V4.79361C56 3.52226 55.6374 2.30299 54.9919 1.40402C54.3465 0.50504 53.4711 0 52.5584 0ZM52.5584 2.3968C53.0148 2.3968 53.4524 2.64932 53.7752 3.09881C54.0979 3.5483 54.2792 4.15793 54.2792 4.79361V8.38881H50.8376V4.79361C50.8376 4.15793 51.0189 3.5483 51.3416 3.09881C51.6643 2.64932 52.102 2.3968 52.5584 2.3968ZM50.8376 40.7457V10.7856H54.2792V40.7457H50.8376ZM52.5584 49.7576L51.1301 43.1425H53.9866L52.5584 49.7576Z"
        fill={isDark ? darkColor : color}
      />
    </svg>
  </div>

  )
};
