import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const DescendingIcon: React.FC<IconProps> = ({ color = 'black', height = 12, width = 15 }) => (
  <div>
    <svg width={width} height={height} viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9263 8.20368L11.8091 10.2655V1.37803C11.8091 1.27776 11.7692 1.18159 11.6983 1.11069C11.6274 1.03979 11.5312 0.999952 11.431 0.999952C11.3307 0.999952 11.2345 1.03979 11.1636 1.11069C11.0927 1.18159 11.0529 1.27776 11.0529 1.37803V5.82176V10.2655L8.93563 8.20368C8.86775 8.13819 8.77785 8.10043 8.68357 8.09782C8.63295 8.0978 8.58284 8.10782 8.53611 8.12729C8.48939 8.14676 8.44698 8.17529 8.41135 8.21124C8.34055 8.28213 8.30078 8.37823 8.30078 8.47842C8.30078 8.57861 8.34055 8.67471 8.41135 8.7456L11.184 11.4325C11.219 11.466 11.26 11.4925 11.3049 11.5106C11.3447 11.5245 11.3864 11.5321 11.4284 11.5333H11.4461C11.4874 11.5324 11.5282 11.5247 11.5671 11.5106H11.5847C11.6264 11.4917 11.6647 11.4662 11.6981 11.435L14.4708 8.74812C14.5416 8.67723 14.5813 8.58113 14.5813 8.48094C14.5813 8.38075 14.5416 8.28465 14.4708 8.21376C14.4358 8.17766 14.3938 8.149 14.3475 8.12951C14.3011 8.11001 14.2513 8.10009 14.2011 8.10034C14.1507 8.09852 14.1005 8.10672 14.0533 8.12446C14.0062 8.1422 13.963 8.16913 13.9263 8.20368Z"
        fill={color}
        stroke="#252926"
        strokeWidth="0.252055"
      />
      <path
        d="M6.9227 6.63592H1.37749C1.27722 6.63592 1.18105 6.67575 1.11015 6.74665C1.03924 6.81756 0.999409 6.91372 0.999409 7.014C0.999409 7.11427 1.03924 7.21044 1.11015 7.28134C1.18105 7.35225 1.27722 7.39208 1.37749 7.39208H6.9227C6.97235 7.39208 7.02151 7.3823 7.06738 7.3633C7.11326 7.3443 7.15493 7.31645 7.19004 7.28134C7.22515 7.24623 7.253 7.20455 7.272 7.15868C7.291 7.11281 7.30078 7.06365 7.30078 7.014C7.30078 6.96435 7.291 6.91518 7.272 6.86931C7.253 6.82344 7.22515 6.78176 7.19004 6.74665C7.15493 6.71154 7.11326 6.6837 7.06738 6.6647C7.02151 6.64569 6.97235 6.63592 6.9227 6.63592Z"
        fill={color}
      />
      <path
        d="M5.91448 4.41783H1.37749C1.27722 4.41783 1.18105 4.45767 1.11015 4.52857C1.03924 4.59947 0.999409 4.69564 0.999409 4.79592C0.999409 4.89619 1.03924 4.99236 1.11015 5.06326C1.18105 5.13416 1.27722 5.174 1.37749 5.174H5.91448C6.01475 5.174 6.11092 5.13416 6.18182 5.06326C6.25273 4.99236 6.29256 4.89619 6.29256 4.79592C6.29256 4.69564 6.25273 4.59947 6.18182 4.52857C6.11092 4.45767 6.01475 4.41783 5.91448 4.41783Z"
        fill={color}
      />
      <path
        d="M1.37749 2.95844H4.90626C5.00653 2.95844 5.1027 2.9186 5.1736 2.8477C5.24451 2.77679 5.28434 2.68063 5.28434 2.58035C5.28434 2.48008 5.24451 2.38391 5.1736 2.31301C5.1027 2.2421 5.00653 2.20227 4.90626 2.20227H1.37749C1.27722 2.20227 1.18105 2.2421 1.11015 2.31301C1.03924 2.38391 0.999409 2.48008 0.999409 2.58035C0.999409 2.68063 1.03924 2.77679 1.11015 2.8477C1.18105 2.9186 1.27722 2.95844 1.37749 2.95844Z"
        fill={color}
      />
      <path
        d="M6.9227 6.63592H1.37749C1.27722 6.63592 1.18105 6.67575 1.11015 6.74665C1.03924 6.81756 0.999409 6.91372 0.999409 7.014C0.999409 7.11427 1.03924 7.21044 1.11015 7.28134C1.18105 7.35225 1.27722 7.39208 1.37749 7.39208H6.9227C6.97235 7.39208 7.02151 7.3823 7.06738 7.3633C7.11326 7.3443 7.15493 7.31645 7.19004 7.28134C7.22515 7.24623 7.253 7.20455 7.272 7.15868C7.291 7.11281 7.30078 7.06365 7.30078 7.014C7.30078 6.96435 7.291 6.91518 7.272 6.86931C7.253 6.82344 7.22515 6.78176 7.19004 6.74665C7.15493 6.71154 7.11326 6.6837 7.06738 6.6647C7.02151 6.64569 6.97235 6.63592 6.9227 6.63592Z"
        stroke="#252926"
        strokeWidth="0.252055"
      />
      <path
        d="M5.91448 4.41783H1.37749C1.27722 4.41783 1.18105 4.45767 1.11015 4.52857C1.03924 4.59947 0.999409 4.69564 0.999409 4.79592C0.999409 4.89619 1.03924 4.99236 1.11015 5.06326C1.18105 5.13416 1.27722 5.174 1.37749 5.174H5.91448C6.01475 5.174 6.11092 5.13416 6.18182 5.06326C6.25273 4.99236 6.29256 4.89619 6.29256 4.79592C6.29256 4.69564 6.25273 4.59947 6.18182 4.52857C6.11092 4.45767 6.01475 4.41783 5.91448 4.41783Z"
        stroke="#252926"
        strokeWidth="0.252055"
      />
      <path
        d="M1.37749 2.95844H4.90626C5.00653 2.95844 5.1027 2.9186 5.1736 2.8477C5.24451 2.77679 5.28434 2.68063 5.28434 2.58035C5.28434 2.48008 5.24451 2.38391 5.1736 2.31301C5.1027 2.2421 5.00653 2.20227 4.90626 2.20227H1.37749C1.27722 2.20227 1.18105 2.2421 1.11015 2.31301C1.03924 2.38391 0.999409 2.48008 0.999409 2.58035C0.999409 2.68063 1.03924 2.77679 1.11015 2.8477C1.18105 2.9186 1.27722 2.95844 1.37749 2.95844Z"
        stroke="#252926"
        strokeWidth="0.252055"
      />
    </svg>
  </div>
);
