import { gql } from 'graphql-request';

const speakerHostModel = `
  name
  avatar {
    fileUrl
  }
  role
`;

const defaultFields = `
  entryId
  webinarId
  sessionId
  title
  slug
  description
  date
  registration {
    registered
    joinLink
  }
  thumbnail {
    fileUrl
  }
  banner {
    title
    description
    contentType
    fileName
    fileUrl
  }
  host {
    ${speakerHostModel}
  }
  speakers {
    ${speakerHostModel}
  }
  categories {
    title
    slug
    score
  }
  topics {
    title
    slug
    icon
    image {
      fileUrl
    }
  }
  companies {
    name
    logo {
      fileUrl
    }
  }
  video{
    fileName
    url
  }
`;

export const GetEventsDocument = gql`
  query GetEvents($limit: Float, $offset: Float, $order: [ContentOrderArgType!], $entryId: String, $entryIds: [String!], $categoriesIn: [String!], $categoriesNotIn: [String!],) {
    fetchWebinars(limit: $limit, offset: $offset, order: $order, categoriesIn: $categoriesIn, categoriesNotIn: $categoriesNotIn,entryId: $entryId, entryIds: $entryIds) {
      totalCount
      data {
        ${defaultFields}
      }
    }
  }
`;
