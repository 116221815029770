export { 
  default,
  foldersSelector,
  folderSelector,
  folderTreeSelector,
  documentOwnerSelector,
  updateDocumentOwners,
  documentOwnerByIdSelector
} from 'slices/documents/documents.slice';
export type {
  DocumentsStateInterface
} from 'slices/documents/documents.slice';
