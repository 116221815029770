import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { fetchAdminsAction } from 'slices/admins/actions';

export interface AdminsInterface {
  email: string;
  firstName: string;
  lastName: string;
  type: string;
  lastLogin: string;
  id: string;
  status: string;
}

export interface AdminsStateInterface extends EntityState<AdminsInterface, string> {
  isLoading: boolean;
  error: Error;
  isCreateOrUpdate: boolean;
  isDeleting: boolean | string;
  updatingData: AdminsInterface;
  totalCount: number;
  limit: number;
  offset: number;
  pageCount: number;
  currentPage: number;
  order: {
    sort: string;
    order: string;
  };
}

const entityAdapter = createEntityAdapter<AdminsInterface>();

const initialState: AdminsStateInterface = {
  isLoading: false,
  error: null,
  isCreateOrUpdate: false,
  isDeleting: false,
  updatingData: {
    email: '',
    firstName: '',
    lastName: '',
    type: '',
    lastLogin: '',
    id: '',
    status: '',
  },
  limit: 10,
  offset: 0,
  totalCount: 0,
  currentPage: 1,
  pageCount: 0,
  order: {
    sort: null,
    order: null,
  },
  ...entityAdapter.getInitialState(),
};

const userSlice = createSlice({
  initialState,
  name: 'coaches',
  reducers: {
    setAdminsPaginationAction: (state, { payload }) => {
      const { offset, limit } = payload;
      state.offset = offset;
      state.limit = limit;
      state.currentPage = Math.ceil(offset / limit + 1);
    },
    clearUsersErrorAction: (state) => {
      state.error = null;
    },
    setIsCreateOrUpdate: (state, { payload }) => {
      state.isCreateOrUpdate = payload;
    },
    setIsDeleting: (state, { payload }) => {
      state.isDeleting = payload;
    },
    setUpdatingData: (state, { payload }) => {
      if (payload) {
        state.updatingData = Object.values(state.entities).find((item) => item.id === payload);
      } else {
        state.updatingData = initialState.updatingData;
      }
    },
    setAdminsOrderAction: (state, { payload }) => {
      let { sort: newSort } = payload;
      if(newSort === 'fullName'){
        newSort = 'firstName'
      }
      const { order: sortInOrder, sort: sortInState } = state.order;
      if (!sortInState) {
        state.order.sort = newSort;
        state.order.order = 'ASC';
      }
      if (sortInState && sortInState === newSort && sortInOrder === 'ASC') {
        state.order.order = 'DESC';
      }
      if (sortInState && sortInState === newSort && sortInOrder === 'DESC') {
        state.order.sort = null;
        state.order.order = null;
      }
      if (sortInState && sortInState !== newSort) {
        state.order.sort = newSort;
        state.order.order = 'ASC';
      }
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminsAction.fulfilled as any, (state, { payload: { data, totalCount } }) => {
      const { limit } = state;
      entityAdapter.setAll(state, data);
      state.isLoading = false;
      state.error = null;
      state.totalCount = totalCount;
      state.pageCount = Math.ceil(totalCount / limit);
    })
    .addCase(fetchAdminsAction.pending as any, (state) => {
      state.isLoading = true;
    })
    .addCase(fetchAdminsAction.rejected as any, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    });
  },
});

export default userSlice.reducer;
export const {
  clearUsersErrorAction,
  setIsCreateOrUpdate,
  setUpdatingData,
  setAdminsPaginationAction,
  setAdminsOrderAction,
  setError,
  setIsDeleting,
} = userSlice.actions;
export { fetchAdminsAction };
