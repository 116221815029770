import { DatePicker, FormControl } from "@otto-finance/ui";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { DATE_FORMAT } from "enums";
import { useField } from 'formik';

interface DatePickerProps {
  clearable?: boolean;
  maxDate?: Date;
  minDate?: Date;
  autoFocusCalendar?: boolean;
}

interface FormDatePickerFieldProps extends DatePickerProps {
  label: string;
  name: string;
  caption?: JSX.Element | string;
  formatString?: string;
  placeholder?: string;
  required?: boolean;
}

export const FormDatePickerField = ({ label, name, caption, formatString = DATE_FORMAT.DEFAULT_UK, placeholder = DATE_FORMAT.DEFAULT_UK.toLowerCase(), formatValue, ...props }: FormDatePickerFieldProps & { formatValue?: boolean;}) => {
  const [field, _, helper] = useField({ name });
  return (
    <FormControl caption={caption} label={`${label}${props.required ? '*' :''}`}>
      <DatePicker
        {...props}
        value={field.value ? [formatValue ? parse(field.value, formatString, new Date()): new Date(field.value)] : []}
        onChange={({ date }) => {
          if (!date) {
            helper.setValue(null);
          } else {
            helper.setValue(formatValue ? format(date, formatString) : date);
          }
        }}
        formatString={formatString}
        placeholder={placeholder}
      />
    </FormControl>
  );
};
