import { gql } from 'graphql-request';

const GetGlossariesDocument = gql`
  query GetGlossaries($value: String!) {
    glossaries(value: $value) {
      data {
        glossaryValues {
          data {
            value
            glossaryId
          }
        }
      }
    }
  }
`;

const GetGlossaryDocument = gql`
  query GetGlossary($id: String!) {
    glossary(id: $id) {
      id 
      glossaryValues {
        data {
          value
          glossaryId
        }
      }
    }
  }
`;

const CreateAcceptedTermDocument = gql`
  mutation CreateAcceptedTerm($acceptedTermData: CreateAcceptedTermDto!) {
    createAcceptedTerm(acceptedTermData: $acceptedTermData) {
      id
    }
  }
`;

export {
  GetGlossariesDocument,
  CreateAcceptedTermDocument,
  GetGlossaryDocument
};
