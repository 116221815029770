import { AcceptInviteDocument, ChangePasswordDocument, CommunityConnectDocument, ForgotPasswordDocument, LoginDocument, LoginGuestDocument, LogoutDocument, ResetPasswordDocument, SignupDocument, TokenRefreshDocument, ValidateTokenDocument } from 'configuration/graphql/documents';

import { baseApi } from './base.api';
import { ResetPasswordArgsInterface, SignupArgsInterface, UserInterface } from 'common/interfaces';
import { UserTypeEnum } from 'enums';

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    communityConnect: builder.mutation<string, { sso: string; sig: string; }>({
      query: (variables) => ({
        variables,
        document: CommunityConnectDocument
      }),
      transformResponse: (response: { discourseConnect; }) => response.discourseConnect,
    }),
    validateToken: builder.query<{ message: string; userId: string; }, { token: string }>({
      query: ({ token }) => ({
        variables: { input: { token }},
        document: ValidateTokenDocument
      }),
      transformResponse: (response: { validateToken; }) => response.validateToken,
    }),
    acceptInvite: builder.mutation<{ accessToken: string; refreshToken: string; user: UserInterface; }, { token: string; password: string; timezone: string; }>({
      query: (args) => ({
        variables: { input: args },
        document: AcceptInviteDocument
      }),
      transformResponse: (response: { acceptInvite; }) => response.acceptInvite,
    }),
    tokenRefresh: builder.query<{ accessToken: string; }, {}>({
      query: () => ({
        document: TokenRefreshDocument,
      }),
      transformResponse: (response: { tokenRefresh; }) => response?.tokenRefresh,
    }),
    login: builder.mutation<{ accessToken: string; refreshToken: string; user: UserInterface; calendarConnected?: boolean; }, { email: string; password: string; }>({
      query: (args) => ({
        variables: { data: args },
        document: LoginDocument
      }),
      transformResponse: (response: { login; }) => response.login,
    }),
    loginGuest: builder.mutation<{ accessToken: string; refreshToken: string; user: UserInterface; }, { token: string; }>({
      query: (variables) => ({
        variables,
        document: LoginGuestDocument
      }),
      transformResponse: (response: { loginGuest; }) => response.loginGuest,
    }),
    signup: builder.mutation<{ id: string; firstName: string; lastName: string; email: string;  }, SignupArgsInterface>({
      query: (args) => ({
        variables: { input: {...args, type: 'coach' } },
        document: SignupDocument
      }),
      transformResponse: (response: { createAndInviteCoach; }) => response.createAndInviteCoach,
    }),
    resetPassword: builder.mutation<string, ResetPasswordArgsInterface>({
      query: (args) => ({
        variables: { data: args },
        document: ResetPasswordDocument
      })
    }),
    changePassword: builder.mutation<string, { password: string; }>({
      query: (args) => ({
        variables: { data: args },
        document: ChangePasswordDocument
      })
    }),
    forgotPassword: builder.mutation<string, { email: string; }>({
      query: (args) => ({
        variables: { data: args },
        document: ForgotPasswordDocument
      })
    }),
    logout: builder.mutation<string, unknown>({
      query: () => ({
        document: LogoutDocument
      })
    })
  })
});

export const {
  useCommunityConnectMutation,
  useLazyValidateTokenQuery,
  useValidateTokenQuery,
  useAcceptInviteMutation,
  useLazyTokenRefreshQuery,
  useLoginMutation,
  useSignupMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useForgotPasswordMutation,
  useLogoutMutation,
  useLoginGuestMutation
} = authApi;
