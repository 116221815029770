import { IconProps } from 'common/otto-ui/icons/icon.type';
import { useTheme } from 'common/roq-hooks/use-theme';
import React from 'react';

export const ArrowDownIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  height = 12,
  width = 14,
  darkColor = 'white',
}) => {
  const { isDark } = useTheme();
  return (
    <svg width={width} height={height} viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 0.999998L10.5 10L20 1"
        stroke={isDark ? darkColor : color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
