export const createDownloadLink = (data: any | string, extension = 'csv', fileName = 'download'): any => {
  const url = window.URL.createObjectURL(new Blob(['\ufeff', data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.${extension}`);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};
