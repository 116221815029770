import { IconProps } from 'common/otto-ui/icons/icon.type';

export const QuestionIcon = ({ height = 21, width = 20 }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="9.80834" cy="10.1247" rx="9.80834" ry="10.1247" fill="url(#paint0_linear_5:8661)" />
    <path
      d="M8.92431 11.6329C8.92431 11.7522 9.02101 11.8489 9.1403 11.8489H10.1469C10.2662 11.8489 10.3629 11.7522 10.3629 11.6329C10.3629 10.9985 10.6244 10.7555 11.1998 10.2965L11.7491 9.85099C12.3768 9.3515 12.743 8.67652 12.743 7.78554C12.743 6.31408 11.6052 5.26111 9.83975 5.26111C8.16063 5.26111 7.21784 6.15256 6.95075 7.35653C6.86002 7.7655 7.21069 8.10953 7.6296 8.10953C8.04852 8.10953 8.3573 7.75301 8.52294 7.36823C8.72608 6.89635 9.16067 6.62457 9.83975 6.62457C10.7813 6.62457 11.1998 7.20506 11.1998 7.85304C11.1868 8.42003 10.9514 8.74402 10.5198 9.09501L10.1013 9.4325C9.35587 10.04 8.92431 10.4855 8.92431 11.6329ZM9.66974 14.9674C10.2713 14.9674 10.6113 14.5759 10.6113 13.9819C10.6113 13.3879 10.2713 12.9964 9.66974 12.9964C9.04201 12.9964 8.72814 13.3879 8.72814 13.9819C8.72814 14.5759 9.04201 14.9674 9.66974 14.9674Z"
      fill="#0C0C0F"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5:8661"
        x1="1.88398e-08"
        y1="10.1248"
        x2="18.7535"
        y2="10.1248"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#53DADA" />
        <stop offset="1" stopColor="#61D57F" />
      </linearGradient>
    </defs>
  </svg>
);
