export type AccessType = 'fullAccess' | 'restrictedAccess' | 'noAccess';

export enum FeatureFlagEnum {
  DASHBOARD_DATA_VIEW = 'dashboard/data_view',
  DASHBOARD_GOALS = 'dashboard/goals',
  WEALTH = 'wealth',
  GOALS = 'goals',
  MESSAGING = 'messaging',
  CALENDAR = 'calendar',
  SETTINGS_BILLING = 'settings/billing',
  COACH = 'coach',
  ROADMAP = 'roadmap',
  WEALTH_IDEAS = 'wealth/investment_ideas',
  WEALTH_ANALYSIS = 'wealth/investment_analysis',
  DOCUMENTS = 'documents'
}

export enum TierEnum {
  FREE = 'FREE',
  PREMIUM = 'PREMIUM',
  CHURNED = 'CHURNED',
  COMPANY = 'COMPANY',
  BENEFITS = 'BENEFITS',
  BENEFIT_TRIAL = 'BENEFIT_TRIAL',
  OTTO = 'OTTO',
  LITE = 'LITE',
  LEGACY_LITE = 'LEGACY_LITE',
  NOVA = 'NOVA'
}