import React, { useEffect } from 'react';
import LinkedIn from 'react-linkedin-insight';
import { __DEV__ } from 'utils';

declare global {
  interface Window {
    lintrk: any;
  }
}

interface Props {}

const partnerId = process.env.NEXT_PUBLIC_LINKED_IN_PARTNER_ID;

export const linkedInConversion = {
  /**
   * The ID of the LinkedIn Conversion to track. Can
   * be found on the LinkedIn pixel.
   */
  COMPLETE_SIGNUP: '7584105'
}

export const LinkedInTag: React.FC<Props> = () => {
  useEffect(() => {
    if (!__DEV__) {
      LinkedIn.init(partnerId, 'dc');
    }
  }, []);

  return <></>;
};
