import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const MissingVideoIcon: React.FC<IconProps> = ({
  height = 135,
  width = 162,
  color = '#CDD3CF',
  secondaryColor = '#F8F9F8',
}) => (
  <svg width={width} height={height} viewBox="0 0 162 135" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M111 40.111C110.289 39.7731 109.495 39.6531 108.716 39.7662C107.937 39.8793 107.21 40.2204 106.624 40.7466L93.8889 51.8443V45.171C93.8824 43.1827 93.0897 41.2776 91.6838 39.8717C90.2778 38.4657 88.3728 37.673 86.3844 37.6666H80.6156L73.9911 26.6421C73.7748 26.2772 73.4672 25.9749 73.0986 25.7649C72.73 25.5549 72.3131 25.4444 71.8889 25.4443H32.7778C32.1295 25.4443 31.5077 25.7019 31.0493 26.1603C30.5909 26.6187 30.3333 27.2405 30.3333 27.8888C30.3333 28.5371 30.5909 29.1588 31.0493 29.6173C31.5077 30.0757 32.1295 30.3332 32.7778 30.3332H70.4956L74.8956 37.6666H32.9489C30.9606 37.673 29.0555 38.4657 27.6496 39.8717C26.2436 41.2776 25.4509 43.1827 25.4445 45.171V93.7177C25.4509 95.706 26.2436 97.611 27.6496 99.017C29.0555 100.423 30.9606 101.216 32.9489 101.222H86.3844C88.3728 101.216 90.2778 100.423 91.6838 99.017C93.0897 97.611 93.8824 95.706 93.8889 93.7177V87.0443L106.673 98.2399C107.411 98.8848 108.358 99.2407 109.338 99.2421C109.918 99.2032 110.484 99.0451 111 98.7777C111.725 98.4635 112.342 97.9446 112.777 97.2846C113.211 96.6246 113.443 95.8522 113.444 95.0621V43.7288C113.424 42.9555 113.183 42.2041 112.75 41.5631C112.317 40.9221 111.71 40.4183 111 40.111V40.111ZM89 93.8888C88.9565 94.5524 88.6618 95.1745 88.176 95.6286C87.6901 96.0827 87.0495 96.3346 86.3844 96.3332H32.9489C32.2552 96.3332 31.5899 96.0576 31.0994 95.5671C30.6089 95.0766 30.3333 94.4114 30.3333 93.7177V45.171C30.3333 44.4773 30.6089 43.812 31.0994 43.3215C31.5899 42.831 32.2552 42.5554 32.9489 42.5554H86.3844C87.0781 42.5554 87.7434 42.831 88.2339 43.3215C88.7244 43.812 89 44.4773 89 45.171V93.8888ZM108.556 93.571L93.8889 80.7377V58.4932L108.556 45.6599V93.571Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M67 1C53.9465 1 41.186 4.87083 30.3324 12.123C19.4787 19.3752 11.0194 29.683 6.02398 41.7429C1.0286 53.8028 -0.278423 67.0732 2.2682 79.8759C4.81483 92.6787 11.1007 104.439 20.331 113.669C29.5612 122.899 41.3213 129.185 54.1241 131.732C66.9268 134.278 80.1972 132.971 92.2571 127.976C104.317 122.981 114.625 114.521 121.877 103.668C129.129 92.814 133 80.0536 133 67C133 49.4957 126.046 32.7084 113.669 20.331C101.292 7.95355 84.5043 1 67 1V1ZM67 128.111C54.9134 128.111 43.0982 124.527 33.0485 117.812C22.9988 111.097 15.1661 101.553 10.5407 90.3862C5.91537 79.2196 4.70517 66.9322 7.06315 55.0778C9.42114 43.2234 15.2414 32.3345 23.7879 23.7879C32.3345 15.2414 43.2234 9.42111 55.0778 7.06312C66.9322 4.70514 79.2196 5.91534 90.3862 10.5407C101.553 15.166 111.097 22.9988 117.812 33.0485C124.527 43.0982 128.111 54.9134 128.111 67C128.111 83.2077 121.673 98.7515 110.212 110.212C98.7515 121.673 83.2077 128.111 67 128.111Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M113.777 129.776C118.875 133.182 124.869 135 131 135C139.222 135 147.107 131.734 152.92 125.92C158.734 120.107 162 112.222 162 104C162 97.8688 160.182 91.8753 156.776 86.7773C153.369 81.6794 148.528 77.7061 142.863 75.3597C137.199 73.0134 130.966 72.3995 124.952 73.5957C118.939 74.7918 113.415 77.7443 109.08 82.0797C104.744 86.4151 101.792 91.9388 100.596 97.9522C99.3995 103.966 100.013 110.199 102.36 115.863C104.706 121.528 108.679 126.369 113.777 129.776Z"
      fill={secondaryColor}
    />
    <path
      d="M131 135C124.869 135 118.875 133.182 113.777 129.776C108.679 126.369 104.706 121.528 102.36 115.863C100.013 110.199 99.3995 103.966 100.596 97.9522C101.792 91.9388 104.744 86.4151 109.08 82.0797C113.415 77.7443 118.939 74.7918 124.952 73.5957C130.966 72.3995 137.199 73.0134 142.863 75.3597C148.528 77.7061 153.369 81.6794 156.776 86.7773C160.182 91.8753 162 97.8688 162 104C162 112.222 158.734 120.107 152.92 125.92C147.107 131.734 139.222 135 131 135ZM131 79.2C126.095 79.2 121.3 80.6545 117.222 83.3796C113.144 86.1046 109.965 89.9779 108.088 94.5095C106.211 99.0411 105.72 104.028 106.677 108.838C107.633 113.649 109.995 118.068 113.464 121.536C116.932 125.005 121.351 127.367 126.162 128.323C130.972 129.28 135.959 128.789 140.491 126.912C145.022 125.035 148.895 121.856 151.62 117.778C154.346 113.7 155.8 108.905 155.8 104C155.8 97.4226 153.187 91.1147 148.536 86.4638C143.885 81.8129 137.577 79.2 131 79.2Z"
      fill={color}
    />
    <path
      d="M137.202 113.293C136.794 113.296 136.39 113.217 136.012 113.063C135.635 112.909 135.291 112.682 135.001 112.394L128.801 106.194C128.514 105.905 128.287 105.561 128.132 105.183C127.978 104.806 127.9 104.401 127.902 103.993V94.6933C127.902 93.8711 128.229 93.0826 128.81 92.5012C129.392 91.9199 130.18 91.5933 131.002 91.5933C131.825 91.5933 132.613 91.9199 133.194 92.5012C133.776 93.0826 134.102 93.8711 134.102 94.6933V102.722L139.403 107.992C139.694 108.28 139.925 108.623 140.082 109.001C140.239 109.379 140.32 109.784 140.32 110.193C140.32 110.602 140.239 111.008 140.082 111.385C139.925 111.763 139.694 112.106 139.403 112.394C139.114 112.682 138.77 112.909 138.392 113.063C138.015 113.217 137.61 113.296 137.202 113.293Z"
      fill={color}
    />
  </svg>
);
