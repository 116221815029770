import { gql } from 'graphql-request';

export const PartnersDocument = gql`
  query Partners {
    partners {
      data {
        id
        name
      }
      totalCount
    }
  }
`;
