import _upperCase from 'lodash/fp/upperCase';
import { ReactElement, ReactNode } from 'react';
export interface MapDataToTableInterface {
  dataToHead: any[];
  dataToBody: any[];
}

type MapDataToTableConfigCommon = {
  label: string;
  type?: string;
  link?: string;
  getCellData?: (row: any) => string | ReactNode;
  /**
   * Render function for rendering custom component
   */
  disableSorting?: boolean;
};

export type MapDataToTableConfigItemInterface =
  | (MapDataToTableConfigCommon & {
    /**
     * Render function for rendering custom component will be optional
     */
    dataTarget: string;
    render?: (item: Record<string, any>) => ReactElement;
  })
  | (MapDataToTableConfigCommon & {
    /**
     * Render function for rendering custom component will be required
     */
    dataTarget?: string;
    render: (item: Record<string, any>) => ReactElement;
  });

export const mapDataToTable = (
  data: { [key: string]: string; }[],
  config?: MapDataToTableConfigItemInterface[],
): MapDataToTableInterface => {
  const [item = {}] = data;
  let dataToHead = Object.keys(item).map(
    (entry): MapDataToTableConfigItemInterface => ({
      dataTarget: entry,
      label: _upperCase(entry),
    }),
  );

  if (config?.length > 0) {
    dataToHead = config;
  }

  return {
    dataToHead,
    dataToBody: data,
  };
};
