import { createContext, useContext } from 'react';

export interface AppContextProps {};

export const AppContext = createContext<AppContextProps | null>(null);

export const useAppContext = (): AppContextProps => {
  const value = useContext(AppContext);
  return value;
};
