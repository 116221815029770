import { MutableRefObject, useEffect, useState } from "react";

export const useDataset = <T extends HTMLElement>(ref: MutableRefObject<T>, attr: string) : number => {
  const [index, setIndex] = useState(-1)
  useEffect(() => {
    if (!ref.current) return
    const dataIndex = Number(ref.current.dataset[attr]);
    if (!Number.isNaN(dataIndex)) {
      setIndex(dataIndex);
    }
  }, []);
  return index;
}
