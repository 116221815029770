import clsx from 'clsx';
import { ReactNode } from 'react';

export enum DialogTitleTextAlignEnum {
  START = 'start',
  CENTER = 'center',
  END = 'end',
  JUSTIFY = 'justify',
}

export enum DialogTitleFontSizeEnum {
  XS = 'xs',
  SM = 'sm',
  BASE = 'base',
  LG = 'lg',
  XL = 'xl',
  '2XL' = '2xl',
  '3XL' = '3xl',
  '4XL' = '4xl',
  '5XL' = '5xl',
  '6XL' = '6xl',
  '7XL' = '7xl',
  '8XL' = '8xl',
  '9XL' = '9xl',
}

export interface DialogTitleInterface {
  align?: Lowercase<keyof typeof DialogTitleTextAlignEnum>;
  fontSize?: Lowercase<keyof typeof DialogTitleFontSizeEnum>;
  children?: ReactNode;
}

export const DialogTitle = ({
  align = DialogTitleTextAlignEnum.START,
  fontSize = DialogTitleFontSizeEnum.LG,
  children,
}) => {
  const className = clsx('text-bold', {
    'text-start': align === DialogTitleTextAlignEnum.START,
    'text-center': align === DialogTitleTextAlignEnum.CENTER,
    'text-end': align === DialogTitleTextAlignEnum.END,
    'text-justify': align === DialogTitleTextAlignEnum.JUSTIFY,
    'text-xs': fontSize === DialogTitleFontSizeEnum.XS,
    'text-sm': fontSize === DialogTitleFontSizeEnum.SM,
    'text-base': fontSize === DialogTitleFontSizeEnum.BASE,
    'text-lg': fontSize === DialogTitleFontSizeEnum.LG,
    'text-xl': fontSize === DialogTitleFontSizeEnum.XL,
    'text-2xl': fontSize === DialogTitleFontSizeEnum['2XL'],
    'text-3xl': fontSize === DialogTitleFontSizeEnum['3XL'],
    'text-4xl': fontSize === DialogTitleFontSizeEnum['4XL'],
    'text-5xl': fontSize === DialogTitleFontSizeEnum['5XL'],
    'text-6xl': fontSize === DialogTitleFontSizeEnum['6XL'],
    'text-7xl': fontSize === DialogTitleFontSizeEnum['7XL'],
    'text-8xl': fontSize === DialogTitleFontSizeEnum['8XL'],
    'text-9xl': fontSize === DialogTitleFontSizeEnum['9XL'],
  });
  return <div className={className}>{children}</div>;
};
