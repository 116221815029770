import { CompleteAuthDocument, CompleteManualKeyAuthDocument } from 'configuration/graphql/documents';
import { CompleteAuthInterface, SaveApiKeysInterface } from 'slices/assets/actions';

import { baseApi } from './base.api';

interface ConnectionInterface {
  id: string;
  name: string;
  providerConnectionId: string;
}

export interface CompleteAuthResponseInterface {
  completeDataIntegrationAuthorisation: { connection: ConnectionInterface };
}

interface CompleteAuthReturnInterface {
  connection?: ConnectionInterface;
  error?: {
    [key: string]: string;
  }
}

export const completeAuthApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    completeAuth: builder.mutation<CompleteAuthReturnInterface, { authData: CompleteAuthInterface }>({
      query: (variables) => ({
        variables,
        document: CompleteAuthDocument,
      }),
      transformResponse: (response: CompleteAuthResponseInterface) => response.completeDataIntegrationAuthorisation,
    }),
    completeManualKeyAuth: builder.mutation<boolean, { authData: SaveApiKeysInterface }>({
        query: (variables) => ({
          variables,
          document: CompleteManualKeyAuthDocument,
        }),
        transformResponse: () => true
      }),
  }),
});

export const { useCompleteAuthMutation, useCompleteManualKeyAuthMutation } = completeAuthApi;
