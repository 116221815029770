import { IconProps } from 'common/otto-ui/icons/icon.type';
import { useTheme } from 'common/roq-hooks/use-theme';
import React from 'react';

export const MoneyHeartIcon: React.FC<IconProps> = ({
  color = '#252926',
  height = 19,
  width = 19,
  darkColor = 'white',
}) => {
  const { isDark } = useTheme();
  return (
    <svg width={width} height={height} viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2835 2.27697C15.8788 1.87213 15.3984 1.55099 14.8696 1.33188C14.3408 1.11277 13.774 1 13.2016 1C12.6292 1 12.0625 1.11277 11.5337 1.33188C11.0049 1.55099 10.5244 1.87213 10.1198 2.27697L9.28001 3.11675L8.44023 2.27697C7.62287 1.45961 6.5143 1.00042 5.35839 1.00042C4.20247 1.00042 3.0939 1.45961 2.27654 2.27697C1.45919 3.09432 1 4.20289 1 5.35881C1 6.51472 1.45919 7.6233 2.27654 8.44065L3.11632 9.28043L9.28001 15.4441L15.4437 9.28043L16.2835 8.44065C16.6883 8.03601 17.0095 7.55556 17.2286 7.02677C17.4477 6.49798 17.5604 5.9312 17.5604 5.35881C17.5604 4.78642 17.4477 4.21964 17.2286 3.69085C17.0095 3.16205 16.6883 2.68161 16.2835 2.27697V2.27697Z"
        stroke={isDark ? darkColor : color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.2854 8.51446H7.99851L8.00092 8.6864C8.02281 9.53825 7.11616 10.3998 7.10735 10.4082C7.06541 10.4491 7.03638 10.5015 7.02386 10.5587C7.01133 10.6159 7.01585 10.6756 7.03686 10.7303C7.05743 10.7846 7.09362 10.8316 7.14087 10.8653C7.18811 10.8991 7.2443 10.9181 7.30233 10.9199H11.0581C11.0956 10.9199 11.1327 10.9125 11.1673 10.8982C11.202 10.8838 11.2334 10.8628 11.2599 10.8363C11.2864 10.8098 11.3075 10.7783 11.3218 10.7437C11.3362 10.7091 11.3435 10.672 11.3435 10.6345C11.3435 10.597 11.3362 10.5599 11.3218 10.5253C11.3075 10.4906 11.2864 10.4592 11.2599 10.4327C11.2334 10.4062 11.202 10.3852 11.1673 10.3708C11.1327 10.3565 11.0956 10.3491 11.0581 10.3491H7.9314C8.2369 9.94136 8.58816 9.32819 8.57152 8.68448L8.56932 8.51446H10.263C10.3387 8.51446 10.4113 8.48439 10.4648 8.43087C10.5183 8.37735 10.5484 8.30475 10.5484 8.22906C10.5484 8.15336 10.5183 8.08077 10.4648 8.02725C10.4113 7.97372 10.3387 7.94365 10.263 7.94365H8.56019V6.4423C8.56019 5.81943 9.07583 5.29347 9.69883 5.29347H10.3322C10.4079 5.29347 10.4805 5.2634 10.534 5.20987C10.5876 5.15635 10.6176 5.08376 10.6176 5.00806C10.6176 4.93237 10.5876 4.85977 10.534 4.80625C10.4805 4.75273 10.4079 4.72266 10.3322 4.72266H9.69883C8.76107 4.72266 7.98938 5.50454 7.98938 6.4423V7.94365H7.2854C7.20971 7.94365 7.13712 7.97372 7.08359 8.02725C7.03007 8.08077 7 8.15336 7 8.22906C7 8.30475 7.03007 8.37735 7.08359 8.43087C7.13712 8.48439 7.20971 8.51446 7.2854 8.51446Z"
        fill={isDark ? darkColor : color}
        stroke={isDark ? darkColor : color}
        strokeWidth="0.419707"
      />
    </svg>
  );
};
