import { ConnectionInterface, DataIntegrationAuthUrl, SaveApiKeysResponse } from 'common/interfaces';
import { caching, COUNTRIES } from 'configuration/data';
import {
  AvailableConnectionsDocument,
  CompleteAuthDocument,
  DataIntegrationAuthUrlDocument,
  FinanceSaveApiKeysDocument,
  OpenBankingCountriesDocument,
  RenewDataIntegrationAuthDocument,
  RevokeDataIntegrationAuthDocument,
} from 'configuration/graphql/documents';
import { WealthProviderEnum } from 'enums';

import { accountsCacheTags, API_TAGS, baseApi } from './base.api';
import { ProviderStatusEnum } from 'slices/assets/assets.slice';

export enum COUNTRY_AUTH_FLOW {
  BANK_CONNECTIONS = 'listAvailableBankConnections',
  AUTH_URL = 'dataIntegrationAuthorisationUrl',
}

export interface OpenBankingCountry {
  code: string;
  providers: string[];
  authFlow: COUNTRY_AUTH_FLOW;
  flag: string;
  name: string;
}

interface CompleteAuthArgsInterface {
  authRequestId?: string;
  code: string;
  provider: WealthProviderEnum;
  idToken?: string;
  state?: string;
  userId?: string;
}

interface RenewDataIntegrationAuthArgs {
  provider: WealthProviderEnum;
  userId: string;
  connectionId?: string;
  providerConnectionId?: string;
  useTppConsent?: boolean;
}

interface RevokeAuthorisationArgs {
  connectionId?: string;
  provider: string;
  providerConnectionId?: string;
  userId: string;
}

interface SaveApiKeysArgs {
  apiKey: string;
  apiSecret: string;
  provider: WealthProviderEnum;
  userId: string;
}

const DISABLED_PROVIDERS = [WealthProviderEnum.Coinbase];
const UNAVAILABLE_PROVIDERS = [ProviderStatusEnum.PERMANENTLY_UNAVAILABLE, ProviderStatusEnum.TEMPORARILY_UNAVAILABLE];

export const financeAuthApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    openBankingCountries: builder.query({
      query: () => ({
        document: OpenBankingCountriesDocument,
      }),
      transformResponse: (response: { openBankingCountries: { data: OpenBankingCountry[] } }) => {
        const countries = response.openBankingCountries.data;
        return countries.map((c) => ({
          ...c,
          name: COUNTRIES[c.code],
          flag: `/static/countries/${c.code.toLowerCase()}.webp`,
        }));
      },
      keepUnusedDataFor: caching.oneHour,
      providesTags: [API_TAGS.ACCOUNTS],
    }),
    completeAuthorisation: builder.mutation<any, CompleteAuthArgsInterface>({
      query: (args) => ({
        variables: { authData: args },
        document: CompleteAuthDocument,
      }),
      invalidatesTags: accountsCacheTags,
    }),
    availableConnections: builder.query<ConnectionInterface[], { country: string; accountTypes?: string[] }>({
      query: (args) => ({
        variables: args,
        document: AvailableConnectionsDocument,
      }),
      transformResponse: (response: {
        listAvailableConnections: { data: ConnectionInterface[]; totalCount: number };
      }) => {
        if (response.listAvailableConnections?.totalCount) {
          return response.listAvailableConnections.data.map((c) => {
            c.iconUrl =
              !c.iconBase64 && !c.iconUrl
                ? `/static/assets/${c.id}.png`
                : `${c.provider === WealthProviderEnum.Plaid ? 'data:image/png;base64,' + c.iconBase64 : c.iconUrl}`;
            c.isDisabled = DISABLED_PROVIDERS.includes(c.provider) || c?.isBeta || UNAVAILABLE_PROVIDERS.includes(c.status.auth);
            return c;
          });
        }
        return [];
      },
      keepUnusedDataFor: caching.oneHour,
      providesTags: [API_TAGS.FINANCE_CONNECTIONS],
    }),
    dataIntegrationAuthUrl: builder.mutation<
      DataIntegrationAuthUrl,
      { userId: string; bankId?: string; country?: string; provider: WealthProviderEnum }
    >({
      query: (args) => ({
        variables: { authData: args },
        document: DataIntegrationAuthUrlDocument,
      }),
      transformResponse: (response: { dataIntegrationAuthorisationUrl: DataIntegrationAuthUrl }) =>
        response.dataIntegrationAuthorisationUrl,
    }),
    renewDataIntegrationAuth: builder.mutation<DataIntegrationAuthUrl, RenewDataIntegrationAuthArgs>({
      query: (args) => ({
        variables: { authData: args },
        document: RenewDataIntegrationAuthDocument,
      }),
      transformResponse: (response: { renewDataIntegrationAuthorisation: DataIntegrationAuthUrl }) =>
        response.renewDataIntegrationAuthorisation,
      invalidatesTags: accountsCacheTags,
    }),
    revokeDataIntegrationAuth: builder.mutation<string, RevokeAuthorisationArgs>({
      query: (args) => ({
        variables: { authData: args },
        document: RevokeDataIntegrationAuthDocument,
      }),
      invalidatesTags: accountsCacheTags,
    }),
    financeSaveApiKeys: builder.mutation<SaveApiKeysResponse, SaveApiKeysArgs>({
      query: (args) => ({
        variables: { authData: args },
        document: FinanceSaveApiKeysDocument,
      }),
      transformResponse: (response: { saveApiKeys: SaveApiKeysResponse }) => response.saveApiKeys,
    }),
  }),
});

export const {
  useOpenBankingCountriesQuery,
  useAvailableConnectionsQuery,
  useCompleteAuthorisationMutation,
  useDataIntegrationAuthUrlMutation,
  useRenewDataIntegrationAuthMutation,
  useRevokeDataIntegrationAuthMutation,
  useFinanceSaveApiKeysMutation,
} = financeAuthApi;
