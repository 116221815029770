import { styled } from "@otto-finance/ui";
import { uiAlign } from "./ui.constants";

export const Column = styled<'div', { gap?: string; crossalign?: 'center', justify?: 'start' | 'end'| 'center' | 'stretch'; align?: AlignContent; }>(
  'div',
  ({ $theme, gap, crossalign, justify, align }) => ({
    display: 'grid',
    gap: gap ?? $theme.sizing.scale400,
    ...(crossalign && {
      alignItems: uiAlign[crossalign]
    }),
    ...(justify && {
      justifyItems: justify
    }),
    ...(align && {
      alignContent: align
    }),
  }),
);
