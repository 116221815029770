import { MutationOptions } from '@apollo/client';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { requestGql } from 'utils/request-gql';

export interface CreateNoteVariablesInterface {
  input: { appointmentId: string; createdBy: string; note: string };
}

export interface CreateNoteActionRequestInterface extends MutationOptions {
  variables: CreateNoteVariablesInterface;
}

export const createNoteAction = createAsyncThunk(
  'sessions/createNote',
  async (request: CreateNoteActionRequestInterface, thunkApi): Promise<void | SerializedError> =>
    await requestGql<void>(request, thunkApi, 'createAppointmentNote'),
);
