import { useCallback } from "react";
import { useAnalytics } from "use-analytics";
import { useUser } from "common/roq-hooks";
import { setUser } from "@sentry/nextjs";

export const useIdentifyUser = () => {
  const { identify: idntf } = useAnalytics();
  const user = useUser();

  const identifyUser = useCallback(() => {
    if (user) {
      idntf(user.id, {
        name: user?.fullName,
        email: user.email,
        tier: user.tier,
        channel: user.channel,
        avatar: user.avatar,
        birthday: user?.birthday,
        firstName: user.firstName,
        lastName: user.lastName,
        isMarried: user?.isMarried,
        userType: user.type,
        company: user.customerMetaInfo?.customerCompany?.name
      });
      setUser({ id: user.id });
    }
  }, [user]);

  return {
    identifyUser
  };
};
