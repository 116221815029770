import { QueryOptions } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestGql } from 'utils/request-gql';

export interface FetchWebinarsVariablesInterface {
  limit?: number;
  offset?: number;
  entryId?: string | null;
  category?: string | null;
  categoriesIn?: string[];
  categoriesAll?: string[];
  order?: {
    sort: string;
    order: string;
  }[];
}

export interface FetchWebinarsActionRequestInterface extends QueryOptions {
  variables: FetchWebinarsVariablesInterface;
}

export const fetchWebinarsAction = createAsyncThunk(
  'events/fetchWebinars',
  async (request: FetchWebinarsActionRequestInterface, thunkApi) =>
    requestGql(request, thunkApi, 'fetchWebinars'),
);
