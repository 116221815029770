import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

interface CustomIconProps extends IconProps {
  inverted?: boolean;
}

export const ThumbIcon: React.FC<CustomIconProps> = ({
  color = 'currentColor',
  width = 13,
  height = 12,
  inverted,
}) => {
  if (inverted) {
    return (
      <svg width={width} height={height} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.550498 -5.72205e-05H3.89044C4.19449 -5.72205e-05 4.44098 0.246436 4.44098 0.550481V1.10102H4.80254C5.29572 0.731096 5.84736 0.448412 6.43023 0.261999L7.52375 0.0310297C7.71571 0.0105133 7.90913 -5.72205e-05 8.10318 -5.72205e-05H10.0267C10.706 -5.72205e-05 11.3079 0.40749 11.5602 1.03819L11.9514 2.0161C12.2657 2.86034 12.3617 3.04168 12.3617 3.04168L12.8816 4.34146C13.0854 4.85097 13.0234 5.42643 12.7158 5.88081C12.4081 6.33519 11.8968 6.60646 11.3481 6.60646H9.94643L9.94646 8.25808C9.94646 8.99323 9.66169 9.68945 9.14462 10.2184C8.62201 10.7531 7.92917 11.0475 7.19376 11.0475C6.88972 11.0475 6.64322 10.801 6.64322 10.4969L6.64318 8.97521C6.64318 8.07736 6.13713 7.2442 5.35396 6.85262C5.03182 6.69153 4.67118 6.60642 4.31102 6.60642H0.550535C0.24649 6.60642 4.20846e-09 6.35993 4.20846e-09 6.05588V0.550481C-3.71891e-05 0.246436 0.246452 -5.72205e-05 0.550498 -5.72205e-05ZM7.78021 8.95314C7.78017 7.64189 7.03522 6.42215 5.88231 5.84574C5.44684 5.62802 4.96361 5.50488 4.47693 5.48612V2.18006H5.02747C5.15351 2.18006 5.27573 2.13682 5.37373 2.05754C6.15377 1.42651 7.13586 1.07898 8.13913 1.07898H10.0627C10.2891 1.07898 10.4898 1.21481 10.5739 1.42508L11.8952 4.72832C11.9641 4.90068 11.9439 5.08772 11.8399 5.24143C11.7358 5.39514 11.5697 5.4833 11.384 5.4833H9.43176C9.28572 5.4833 9.1457 5.54132 9.04246 5.64457C8.93921 5.74781 8.88122 5.88784 8.88122 6.03388L8.88129 8.236C8.88129 8.95501 8.41451 9.58633 7.78021 9.82394V8.95314ZM3.37592 5.4833H1.13706V1.07898H3.37592V5.4833Z"
          fill={color}
        />
      </svg>
    );
  }

  return (
    <svg width={width} height={height} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.550498 11.0475H3.89044C4.19449 11.0475 4.44098 10.801 4.44098 10.497V9.94646H4.80254C5.29572 10.3164 5.84736 10.5991 6.43023 10.7855L7.52375 11.0165C7.71571 11.037 7.90913 11.0475 8.10318 11.0475H10.0267C10.706 11.0475 11.3079 10.64 11.5602 10.0093L11.9514 9.03139C12.2657 8.18715 12.3617 8.00581 12.3617 8.00581L12.8816 6.70602C13.0854 6.19651 13.0234 5.62106 12.7158 5.16668C12.4081 4.7123 11.8968 4.44103 11.3481 4.44103H9.94643L9.94646 2.7894C9.94646 2.05425 9.66169 1.35804 9.14462 0.829041C8.62201 0.29443 7.92917 0 7.19376 0C6.88972 0 6.64322 0.24646 6.64322 0.550542L6.64318 2.07227C6.64318 2.97013 6.13713 3.80328 5.35396 4.19486C5.03182 4.35595 4.67118 4.44106 4.31102 4.44106H0.550535C0.24649 4.44106 4.20846e-09 4.68756 4.20846e-09 4.9916V10.497C-3.71891e-05 10.801 0.246452 11.0475 0.550498 11.0475ZM7.78021 2.09435C7.78017 3.40559 7.03522 4.62533 5.88231 5.20175C5.44684 5.41947 4.96361 5.54261 4.47693 5.56136V8.86743H5.02747C5.15351 8.86743 5.27573 8.91067 5.37373 8.98994C6.15377 9.62097 7.13586 9.96851 8.13913 9.96851H10.0627C10.2891 9.96851 10.4898 9.83267 10.5739 9.6224L11.8952 6.31916C11.9641 6.14681 11.9439 5.95977 11.8399 5.80606C11.7358 5.65235 11.5697 5.56419 11.384 5.56419H9.43176C9.28572 5.56419 9.1457 5.50616 9.04246 5.40292C8.93921 5.29967 8.88122 5.15965 8.88122 5.01361L8.88129 2.81149C8.88129 2.09248 8.41451 1.46116 7.78021 1.22354V2.09435ZM3.37592 5.56419H1.13706V9.96851H3.37592V5.56419Z"
        fill={color}
      />
    </svg>
  );
};
