import debounce from 'lodash/debounce';
import { useEffect,useState } from 'react'

export const useWindowSize = (size: number, debounceValue = 200): boolean => {
  const [isMatch, setMatch] = useState<boolean>(window.innerWidth >= size);

  const onResize = debounce(() => {
    getSize();
  }, debounceValue);

  const getSize = () => {
    setMatch(window.innerWidth >= size);
  };

  useEffect(() => {
    getSize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [])

  return isMatch;
}
