import { MutationDataOptions } from '@apollo/client';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { requestGql } from 'utils/request-gql';

export interface SaveCoachNotepadActionRequestInterface extends MutationDataOptions {
  variables: {
    note: string;
    userId: string;
  }
}

export const saveCoachNotepadAction = createAsyncThunk(
  'coachNotepad/save',
  async (request: SaveCoachNotepadActionRequestInterface, thunkApi): Promise<boolean | SerializedError> =>
    await requestGql<boolean>(request, thunkApi, 'saveCoachNotepad')
);
