import { StockSectorEnum } from "enums";

export const securitySectorMap = {
  [StockSectorEnum.CONSUMER_CYCLICAL]: "Consumer Cyclical",
  [StockSectorEnum.FINANCIAL_SERVICES]: "Financial Services",
  [StockSectorEnum.REAL_ESTATE]: "Real Estate",
  [StockSectorEnum.BASIC_MATERIALS]: "Basic Materials",
  [StockSectorEnum.INDUSTRIALS]: "Industrials",
  [StockSectorEnum.ENERGY]: "Energy",
  [StockSectorEnum.TECHNOLOGY]: "Technology",
  [StockSectorEnum.COMMUNICATION_SERVICES]: "Communication Services",
  [StockSectorEnum.HEALTHCARE]: "Healthcare",
  [StockSectorEnum.CONSUMER_DEFENSIVE]: "Consumer Defensive",
  [StockSectorEnum.UTILITIES]: "Utilities",
  [StockSectorEnum.OTHER]: "Unknown",
};
