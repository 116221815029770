/**
 * The maximum length of an action name
 */
export const ACTION_NAME_MAX_LENGTH = parseInt(process.env.NEXT_PUBLIC_ACTION_NAME_MAX_LENGTH, 10) || 45;

/**
 * The maximum length of a note
 */
export const NOTE_MAX_LENGTH = parseInt(process.env.NEXT_PUBLIC_NOTE_MAX_LENGTH, 10) || 500;

// An edge case but if values are over a billion we need to make the text smaller to prevent overflow
// TODO: From a UX perspective we should use agreed notation e.g. (£100B)
export const valueSizeThreshold = 1000000000;

export const truncateText = (lines = 2, orient = 'vertical') => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': lines,
  '-webkit-box-orient': orient
});
