import { MutationOptions } from "@apollo/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestGql } from "utils/request-gql";

export interface StopSessionVariablesInterface {
    userId: string;
  }
  
  export interface StopSessionActionRequestInterface extends MutationOptions  {
    variables: StopSessionVariablesInterface;
  }
  
  export const stopSessionAction = createAsyncThunk(
    'session/Stop',
    async (
      request: StopSessionActionRequestInterface,
      thunkApi,
    ): Promise<any> =>
      await requestGql<any>(request, thunkApi, 'updateSession'),
  );
  