import { RISK_LEVEL, SectorStabilityEnum, StockSectorEnum } from "enums";

export const maxBySectorStability = {
  [RISK_LEVEL.CAUTIOUS]: {
    [SectorStabilityEnum.CYCLICAL]: 0.05,
    [SectorStabilityEnum.DEFENSIVE]: 0.95,
    [SectorStabilityEnum.OTHER]: 1,
  },
  [RISK_LEVEL.MODERATELY_CAUTIOUS]: {
    [SectorStabilityEnum.CYCLICAL]: 0.2,
    [SectorStabilityEnum.DEFENSIVE]: 0.8,
    [SectorStabilityEnum.OTHER]: 1,
  },
  [RISK_LEVEL.BALANCED]: {
    [SectorStabilityEnum.CYCLICAL]: 0.4,
    [SectorStabilityEnum.DEFENSIVE]: 0.6,
    [SectorStabilityEnum.OTHER]: 1,
  },
  [RISK_LEVEL.MODERATELY_ADVENTUROUS]: {
    [SectorStabilityEnum.CYCLICAL]: 0.6,
    [SectorStabilityEnum.DEFENSIVE]: 0.4,
    [SectorStabilityEnum.OTHER]: 1,
  },
  [RISK_LEVEL.ADVENTUROUS]: {
    [SectorStabilityEnum.CYCLICAL]: 0.8,
    [SectorStabilityEnum.DEFENSIVE]: 0.2,
    [SectorStabilityEnum.OTHER]: 1,
  },
};

export const maxBySector = {
  [RISK_LEVEL.CAUTIOUS]: {
    [StockSectorEnum.CONSUMER_CYCLICAL]: 0.1,
    [StockSectorEnum.FINANCIAL_SERVICES]: 0.1,
    [StockSectorEnum.REAL_ESTATE]: 0.05,
    [StockSectorEnum.BASIC_MATERIALS]: 0.1,
    [StockSectorEnum.INDUSTRIALS]: 0.05,
    [StockSectorEnum.ENERGY]: 0.05,
    [StockSectorEnum.TECHNOLOGY]: 0.05,
    [StockSectorEnum.COMMUNICATION_SERVICES]: 0.15,
    [StockSectorEnum.HEALTHCARE]: 0.1,
    [StockSectorEnum.CONSUMER_DEFENSIVE]: 0.15,
    [StockSectorEnum.UTILITIES]: 0.15,
    [StockSectorEnum.OTHER]: 1,
  },
  [RISK_LEVEL.MODERATELY_CAUTIOUS]: {
    [StockSectorEnum.CONSUMER_CYCLICAL]: 0.1,
    [StockSectorEnum.FINANCIAL_SERVICES]: 0.1,
    [StockSectorEnum.REAL_ESTATE]: 0.05,
    [StockSectorEnum.BASIC_MATERIALS]: 0.1,
    [StockSectorEnum.INDUSTRIALS]: 0.05,
    [StockSectorEnum.ENERGY]: 0.05,
    [StockSectorEnum.TECHNOLOGY]: 0.1,
    [StockSectorEnum.COMMUNICATION_SERVICES]: 0.15,
    [StockSectorEnum.HEALTHCARE]: 0.1,
    [StockSectorEnum.CONSUMER_DEFENSIVE]: 0.1,
    [StockSectorEnum.UTILITIES]: 0.1,
    [StockSectorEnum.OTHER]: 1,
  },
  [RISK_LEVEL.BALANCED]: {
    [StockSectorEnum.CONSUMER_CYCLICAL]: 0.15,
    [StockSectorEnum.FINANCIAL_SERVICES]: 0.1,
    [StockSectorEnum.REAL_ESTATE]: 0.05,
    [StockSectorEnum.BASIC_MATERIALS]: 0.1,
    [StockSectorEnum.INDUSTRIALS]: 0.1,
    [StockSectorEnum.ENERGY]: 0.05,
    [StockSectorEnum.TECHNOLOGY]: 0.1,
    [StockSectorEnum.COMMUNICATION_SERVICES]: 0.05,
    [StockSectorEnum.HEALTHCARE]: 0.1,
    [StockSectorEnum.CONSUMER_DEFENSIVE]: 0.1,
    [StockSectorEnum.UTILITIES]: 0.05,
    [StockSectorEnum.OTHER]: 1,
  },
  [RISK_LEVEL.MODERATELY_ADVENTUROUS]: {
    [StockSectorEnum.CONSUMER_CYCLICAL]: 0.2,
    [StockSectorEnum.FINANCIAL_SERVICES]: 0.1,
    [StockSectorEnum.REAL_ESTATE]: 0.05,
    [StockSectorEnum.BASIC_MATERIALS]: 0.1,
    [StockSectorEnum.INDUSTRIALS]: 0.15,
    [StockSectorEnum.ENERGY]: 0.05,
    [StockSectorEnum.TECHNOLOGY]: 0.1,
    [StockSectorEnum.COMMUNICATION_SERVICES]: 0.15,
    [StockSectorEnum.HEALTHCARE]: 0.1,
    [StockSectorEnum.CONSUMER_DEFENSIVE]: 0.05,
    [StockSectorEnum.UTILITIES]: 0,
    [StockSectorEnum.OTHER]: 1,
  },
  [RISK_LEVEL.ADVENTUROUS]: {
    [StockSectorEnum.CONSUMER_CYCLICAL]: 0.2,
    [StockSectorEnum.FINANCIAL_SERVICES]: 0.1,
    [StockSectorEnum.REAL_ESTATE]: 0.05,
    [StockSectorEnum.BASIC_MATERIALS]: 0.1,
    [StockSectorEnum.INDUSTRIALS]: 0.15,
    [StockSectorEnum.ENERGY]: 0.05,
    [StockSectorEnum.TECHNOLOGY]: 0.1,
    [StockSectorEnum.COMMUNICATION_SERVICES]: 0.2,
    [StockSectorEnum.HEALTHCARE]: 0.1,
    [StockSectorEnum.CONSUMER_DEFENSIVE]: 0.05,
    [StockSectorEnum.UTILITIES]: 0,
    [StockSectorEnum.OTHER]: 1,
  },
};
