import { MutationOptions } from '@apollo/client';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { PlatformAccessTokenInterface } from 'slices/platform';
import { requestGql } from 'utils/request-gql';

interface CreateAccessTokenActionRequestInterface extends MutationOptions {}

export const createAccessTokenAction = createAsyncThunk(
  'createAccessToken',
  async (
    request: CreateAccessTokenActionRequestInterface,
    thunkApi,
  ): Promise<PlatformAccessTokenInterface | SerializedError> =>
    await requestGql<PlatformAccessTokenInterface>(request, thunkApi, 'createPlatformToken'),
);
