import { FormControl, Input, Slider, SliderProps } from "@otto-finance/ui";
import { useField } from 'formik';
import { ChangeEvent, useCallback } from "react";

export const FormSliderField = ({ label, name, caption, suffix, showInput, ...props }: Omit<SliderProps, 'value'> & { name: string; suffix?: string; caption?: string; label: string; showInput?: boolean; }) => {
  const [field, _, helper] = useField({ name });
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const v = Number(e.target.value);
    if (isNaN(v)) return;

    if (v >= props.min && v <= props.max) {
      helper.setValue(v);
    }
  }, []);
  return (
    <>
      <FormControl label={label} caption={caption}>
        <Slider
          {...props}
          value={[field.value]}
          onChange={({ value }) => helper.setValue(value[0])}
          valueToLabel={value => `${value}${suffix}`}
        />
      </FormControl>
      {showInput && (
        <Input
          size="compact"
          onChange={onChange}
          endEnhancer={suffix}
          value={field.value}
          min={props.min}
          max={props.max}
          type="number"
          overrides={{
            Root: {
              style: {
                width: "180px"
              },
            },
          }} />
      )}
    </>
  );
};
