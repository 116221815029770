import { gql } from 'graphql-request';

export const SurveyDocument = gql`
  query Configuration($name: String!) {
    configuration(name: $name) {
      id
      name
      config
    }
  }
`;
export const CreateSurveyDocument = gql`
  mutation createResult(
    $sectionName: String!
    $pageName: String!
    $elementName: String!
    $result: JsonObject!
    $sessionId: ID!) {
    createResult(
      result: {
        sectionName: $sectionName
        pageName: $pageName
        elementName: $elementName
        result: $result
        sessionId: $sessionId
      }
    ) {
      sectionName
      pageName
      elementName
      result
      session {
        id
        userId
        configurationId
      }
    }
  }
`;

export const CreateSurveySessionDocument = gql`
  mutation CreateSession($session: SessionCreateDto!) {
    createSession(session: $session) {
      id
      isCompleted
      configurationId
    }
  }
`;
