import { NextRouter, useRouter } from "next/router";
import { useEffect, useState } from "react";

export const useRouteReady = (): {
  isReady: boolean,
  router: NextRouter
  
} => {
  const [isReady, setIsReady] = useState(false);
  const router = useRouter();
  useEffect(() => {
    if (router.isReady) {
      setIsReady(true);
    }
  }, [router.isReady]);
  return {
    isReady,
    router
  };
};
