import { gql } from 'graphql-request';

export const ListBudgetsDocument = gql`
  query ListBudgets($userId: String!) {
    listBudgets(userId: $userId) {
      data {
        id
        category
        amount
      }
    }
  }
`;

export const DeleteBudgetDocument = gql`
  mutation DeleteBudget($id: String!) {
    deleteBudget(id: $id)
  }
`;

export const CreateBudgetDocument = gql`
  mutation CreateBudget($budget: BudgetCreateDto!) {
    createBudget(budget: $budget) {
      id
      category
      amount
    }
  }
`;
