import { MutationOptions } from "@apollo/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestGql } from "utils/request-gql";

export interface StartSessionVariablesInterface {
    userId: string;
    configurationId: string;
  }
  
  export interface StartSessionActionRequestInterface extends MutationOptions  {
    variables: StartSessionVariablesInterface;
  }
  
  export const startSessionAction = createAsyncThunk(
    'session/start',
    async (
      request: StartSessionActionRequestInterface,
      thunkApi,
    ): Promise<any> =>
      await requestGql<any>(request, thunkApi, 'createSession'),
  );
  