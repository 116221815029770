import { LabelSmall, ParagraphSmall, toaster, useTheme } from "@otto-finance/ui";
import { ReactNode, useCallback } from "react";

type IToaster = typeof toaster;

export enum TOAST_VARIANT {
  DEFAULT = 'default',
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export interface ToastPropsInterface {
    id?: React.Key;
    /** The number of milliseconds to wait before closing */
    closeAfter?: number;
    /** The variant to use. */
    variant?: Lowercase<keyof typeof TOAST_VARIANT>;
    /** Displayed above the content */
    title?: string | ReactNode;
    /** The content of the Toast. Unless provided, children props will be used to show content. */
    message?: string;
    footer?: ReactNode;
}

interface UseToastInterface {
  toaster: IToaster;
  toast: (props: ToastPropsInterface) => React.Key | undefined | null,
  closeToast: (id: React.Key) => void;
}

type ToasterKind = "info" | "negative" | "positive" | "warning";

const toastMethods = {
  default: 'info',
  error: 'negative',
  info: 'info',
  success: 'positive',
  warning: 'warning',
};

export const useToast = (): UseToastInterface => {
  const [css, theme] = useTheme();
  const toast = useCallback(({ id, title, message, footer, variant = TOAST_VARIANT.DEFAULT, closeAfter = 5000 }: ToastPropsInterface) => {
    return toaster.show(
      <div className={css({ display: 'grid', gap: theme.sizing.scale400 })}>
        {title && <LabelSmall color="white">{title}</LabelSmall>}
        {message && <ParagraphSmall color="white">{message}</ParagraphSmall>}
        {footer && <div className={css({ display: 'flex', gap: theme.sizing.scale400 })}>{footer}</div>}
      </div>, {
      kind: toastMethods[variant] as ToasterKind,
      autoHideDuration: closeAfter !== undefined ? closeAfter : 5000,
      key: id
    });
  }, []);
  const closeToast = useCallback((id: React.Key) => {
    toaster.clear(id);
  }, []);
  return {
    toaster,
    toast,
    closeToast
  };
};
