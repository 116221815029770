import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

interface CustomProps extends IconProps {
  filled?: boolean;
}

export const TimerIcon: React.FC<CustomProps> = ({ color = '#D69A07', height = '11', width = '11', filled = true }) => {
  if (filled) {
    return (
      <svg width={width} height={height} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.68232 0.778442C8.2683 0.778442 10.3646 2.77496 10.3646 5.2378C10.3646 7.70063 8.2683 9.69715 5.68232 9.69715C3.09635 9.69715 1 7.70063 1 5.2378C1 2.77496 3.09635 0.778442 5.68232 0.778442Z"
          fill="white"
          stroke={color}
          strokeWidth="1.24862"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.58115 5.68295H5.45961C5.21332 5.68295 5.01367 5.4833 5.01367 5.23701V2.5614"
          stroke={color}
          strokeWidth="1.24862"
          strokeLinecap="round"
        />
      </svg>
    );
  }

  return (
    <svg width={width} height={height} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.68757 1.23691C9.6492 1.23691 12.0501 3.52384 12.0501 6.34492C12.0501 9.16599 9.6492 11.4529 6.68757 11.4529C3.72595 11.4529 1.32507 9.16599 1.32507 6.34492C1.32507 3.52384 3.72595 1.23691 6.68757 1.23691Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0055 7.40368C10.3921 7.40368 10.7055 7.09028 10.7055 6.70368C10.7055 6.31708 10.3921 6.00368 10.0055 6.00368V7.40368ZM6.97505 3.59446C6.97505 3.20786 6.66165 2.89446 6.27505 2.89446C5.88846 2.89446 5.57505 3.20786 5.57505 3.59446H6.97505ZM6.84027 7.40368H10.0055V6.00368H6.84027V7.40368ZM6.97505 6.13847V3.59446H5.57505V6.13847H6.97505ZM6.84027 6.00368C6.91471 6.00368 6.97505 6.06403 6.97505 6.13847H5.57505C5.57505 6.83723 6.14151 7.40368 6.84027 7.40368V6.00368Z"
        fill="white"
      />
    </svg>
  );
};
