import { gql } from 'graphql-request';

export const CreateSuitabilityReportDocument = gql`
  mutation CreateSuitabilityReport($input: SuitabilityReportCreateDto!){
    createSuitabilityReport(input: $input) {
      id
      token
      dates {
        createdAt
        updatedAt
      }
      status
    }
  }
`;

export const ListSuitabilityReportsDocument = gql`
  query ListSuitabilityReports($ids: [String!]!, $tokens: [String!]!) {
    listSuitabilityReports(ids: $ids, tokens: $tokens) {
      id
      advisor {
        firstName
        lastName
        email
        index
      }
      customer {
        firstName
        lastName
        email
        index
      }
      dates {
        createdAt
        updatedAt
      }
      status
      paragraphs {
        text
        speaker
        start
        end
      }
      sections {
        index
        title
        text
      }
      audioUrl
    }
  }
`;

export const GetReportsDocument = gql`
  query GetSuitabilityReport($id: String!, $token: String!) {
    fetchSuitabilityReport(id: $id, token: $token) {
      id
      advisor {
        firstName
        lastName
        email
      }
      customer {
        firstName
        lastName
        email
      }
      dates {
        createdAt
        updatedAt
      }
      status
      paragraphs {
        text
        speaker
        start
        end
      }
    }
  }
`;

export const CreateInstantRoomDocument = gql`
  mutation createInstantRoom($id: String!, $role: RoleEnum!) {
    createInstantRoom(id: $id, role: $role) {
      token
      recordingUrl
    }
  }
`;

export const GenerateSuitabilityReportDocument = gql`
  mutation GenerateSuitabilityReport($id: String!, $token: String!) {
    generateSuitabilityReport(id: $id, token: $token)
  }
`;

export const GenerateAppointmentReportDocument = gql`
  mutation generateSuitabilityReportFromAppointment($appointmentId: String!) {
    generateSuitabilityReportFromAppointment(appointmentId: $appointmentId)
  }
`;

export const GetReportsByCoachDocument = gql`
  query ListSuitabilityReportsByCoachId($coachId: String!){
    listSuitabilityReportsByCoachId(coachId: $coachId) {
      id
      userId
      appointmentId
      sections {
        index
        title
        text
      }
      paragraphs {
          text
          speaker
          start
          end
      }
      appointment {
          id
          start
      }
      user {
          id
          firstName
          lastName
          avatar
      }
      customer {
          firstName
          lastName
          index
      }
      advisor {
          firstName
          lastName
          index
      }
      dates {
        createdAt
        updatedAt
      }
      audioUrl
    }
  }
`;
