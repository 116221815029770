import { ROUTE_ENUM } from 'configuration/data/routers';

export const deepLinkCheck = (deepLink: string): boolean => {
  /**
   * Checks the route is a valid route based on our ROUTE_ENUMS
   * and allowing for dynamic routes e.g. /users/:id
   */

  if (!deepLink || typeof deepLink !== 'string') {
    return false;
  };

  let isMatch = false;

  const routes = Object.values(ROUTE_ENUM);

  routes.forEach((route: string) => {
    const standardRegex = new RegExp(`^${route}$`);
    const dynamicRegex = new RegExp(`^${route}(/[a-z]*)$`);
    const queryParamRegex = new RegExp(`^${route}([?a-z=&]*)$`);

    if (standardRegex.test(deepLink) || dynamicRegex.test(deepLink) || queryParamRegex.test(deepLink)) {
      isMatch = true;
    }
  });

  return isMatch;
};
