import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const CableIcon: React.FC<IconProps> = ({ color = 'currentColor', height = 16, width = 22 }) => (
  <svg width={width} height={height} viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5169 11.7551H20.4712V13.7745C20.4712 14.1605 20.1683 14.4634 19.7823 14.4634H17.2086C16.8214 14.4634 16.5169 14.1605 16.5169 13.7745V11.7551ZM17.2061 1.52942H19.7795C20.1667 1.52942 20.4712 1.83107 20.4712 2.21702V11.2267H16.5169V2.21702C16.5169 1.83082 16.8186 1.52942 17.2061 1.52942ZM17.2061 1C16.5355 1 15.9883 1.54643 15.9883 2.21702L15.9893 13.7745C15.9893 14.4451 16.5385 14.9926 17.2089 14.9926H19.7826C20.4516 14.9926 21.0003 14.4451 21.0003 13.7745L20.9975 2.21702C20.9975 1.54643 20.4501 1 19.7798 1H17.2061Z"
      fill={color}
      stroke={color}
      strokeWidth="0.2"
    />
    <path
      d="M18.4923 7.6084C19.0001 7.6084 19.4048 8.01467 19.4048 8.52352C19.4048 9.03135 19.0001 9.43711 18.4923 9.43711C17.9844 9.43711 17.5799 9.03135 17.5799 8.52352C17.5799 8.01441 17.9844 7.6084 18.4923 7.6084ZM18.4923 7.08203C17.6988 7.08203 17.0508 7.72977 17.0508 8.52326C17.0508 9.31574 17.6988 9.96348 18.4923 9.96348C19.286 9.96348 19.934 9.31574 19.934 8.52326C19.9338 7.72977 19.286 7.08203 18.4923 7.08203Z"
      fill="#444945"
      stroke="#444945"
      strokeWidth="0.2"
    />
    <path
      d="M17.4612 2.92773C17.3144 2.92773 17.1953 3.04504 17.1953 3.19206C17.1953 3.33883 17.3144 3.45664 17.4612 3.45664H17.9253C18.0723 3.45664 18.1912 3.33883 18.1912 3.19206C18.1912 3.04504 18.0723 2.92773 17.9253 2.92773H17.4612Z"
      fill="#444945"
      stroke="#444945"
      strokeWidth="0.2"
    />
    <path
      d="M19.0617 2.92773C18.916 2.92773 18.7969 3.04504 18.7969 3.19206C18.7969 3.33883 18.916 3.45664 19.0617 3.45664H19.5284C19.6752 3.45664 19.794 3.33883 19.794 3.19206C19.794 3.04504 19.6749 2.92773 19.5284 2.92773H19.0617Z"
      fill="#444945"
      stroke="#444945"
      strokeWidth="0.2"
    />
    <path
      d="M17.4346 4.10938C17.0827 4.12791 17.1093 4.65403 17.461 4.63854H17.9252C18.0722 4.63854 18.19 4.52072 18.19 4.37396C18.19 4.22719 18.0719 4.10938 17.9249 4.10938H17.4608C17.4516 4.10938 17.4427 4.10938 17.4346 4.10938Z"
      fill="#444945"
      stroke="#444945"
      strokeWidth="0.2"
    />
    <path
      d="M19.0361 4.10938C18.6844 4.12791 18.7111 4.65403 19.0617 4.63854H19.5284C19.6752 4.63854 19.794 4.52072 19.794 4.37396C19.794 4.22719 19.6752 4.10938 19.5284 4.10938H19.0617C19.0536 4.10938 19.0445 4.10938 19.0361 4.10938Z"
      fill="#444945"
      stroke="#444945"
      strokeWidth="0.2"
    />
    <path
      d="M17.4344 5.29164C17.0997 5.32338 17.1236 5.8208 17.4608 5.81776H17.925C18.0773 5.82867 18.2066 5.70806 18.2066 5.55495C18.2066 5.40209 18.0773 5.28123 17.925 5.29164H17.4608C17.4514 5.29164 17.4425 5.29164 17.4344 5.29164Z"
      fill="#444945"
      stroke="#444945"
      strokeWidth="0.2"
    />
    <path
      d="M19.0365 5.29164C18.7005 5.32338 18.7259 5.8208 19.0621 5.81776H19.5288C19.6822 5.82867 19.8104 5.70806 19.8104 5.55495C19.8104 5.40209 19.6822 5.28123 19.5288 5.29164H19.0621C19.054 5.29164 19.0448 5.29164 19.0365 5.29164Z"
      fill="#444945"
      stroke="#444945"
      strokeWidth="0.2"
    />
    <path
      d="M2.46916 10.4777H13.4287C13.9553 10.4777 14.3715 10.8944 14.3715 11.421V13.5199C14.3715 14.047 13.9551 14.4627 13.4287 14.4627H2.46916C1.94279 14.4627 1.52891 14.047 1.52891 13.5199V11.421C1.52916 10.8944 1.94279 10.4777 2.46916 10.4777ZM3.53256 7.00514H12.3666C12.6025 7.00514 12.7439 7.08944 12.8841 7.25372C13.0255 7.41877 13.1431 7.67142 13.2393 7.95199L13.9696 10.0547C13.8015 9.98714 13.6202 9.94905 13.4285 9.94905H2.46916C2.27872 9.94905 2.09616 9.98714 1.92959 10.0547L2.65808 7.95199C2.75634 7.67142 2.8739 7.41877 3.01534 7.25372C3.1555 7.08944 3.29693 7.00514 3.53256 7.00514ZM3.53256 6.47852C3.14509 6.47852 2.82744 6.66108 2.61339 6.91094C2.39908 7.16079 2.26679 7.4693 2.15964 7.7773L1.08455 10.8799C1.07135 10.9132 1.06576 10.9474 1.06729 10.983C1.02361 11.1219 1 11.2681 1 11.421V13.5199C1 14.3301 1.65993 14.9918 2.46942 14.9918H13.4287C14.2395 14.9918 14.8981 14.3304 14.8981 13.5199V11.421C14.8981 11.2681 14.8755 11.1204 14.8321 10.9817C14.8321 10.9474 14.8268 10.9119 14.8148 10.8802L13.7382 7.77755C13.6313 7.46955 13.4988 7.16104 13.2845 6.91119C13.0702 6.66134 12.7528 6.47877 12.3666 6.47877H3.53256V6.47852Z"
      fill="#444945"
      stroke="#444945"
      strokeWidth="0.2"
    />
    <path
      d="M10.3011 11.4429C10.232 11.4444 10.1675 11.4736 10.1195 11.5231C9.72417 11.9218 9.60661 12.521 9.81964 13.0393C10.0324 13.559 10.5403 13.9003 11.0996 13.9003C11.6588 13.9003 12.1643 13.559 12.3784 13.0393C12.5914 12.521 12.4738 11.922 12.0785 11.5231C11.9767 11.4124 11.8045 11.4086 11.6976 11.5143C11.5917 11.6189 11.5943 11.792 11.7029 11.8941C11.949 12.1412 12.0241 12.5154 11.8906 12.8394C11.757 13.1637 11.4462 13.3711 11.0999 13.3711C10.7535 13.3711 10.4428 13.1639 10.3089 12.8394C10.1756 12.5157 10.2493 12.1412 10.494 11.8941C10.6644 11.726 10.5405 11.435 10.3011 11.4429Z"
      fill="#444945"
      stroke="#444945"
      strokeWidth="0.2"
    />
    <path
      d="M11.0975 11.084C10.9525 11.0861 10.8375 11.2069 10.84 11.3524L10.8359 12.4593C10.8347 12.606 10.9535 12.7251 11.1005 12.7251C11.2463 12.7251 11.3649 12.606 11.3636 12.4593L11.3664 11.3524C11.3687 11.2031 11.2473 11.081 11.0975 11.084Z"
      fill="#444945"
      stroke="#444945"
      strokeWidth="0.2"
    />
    <path
      d="M4.00192 12.4712C4.00192 12.7642 3.76374 13.0029 3.46895 13.0029C3.17542 13.0029 2.9375 12.7644 2.9375 12.4712C2.9375 12.1776 3.17567 11.9395 3.46895 11.9395C3.76374 11.9395 4.00192 12.1776 4.00192 12.4712Z"
      fill="#444945"
      stroke="#444945"
      strokeWidth="0.2"
    />
    <path
      d="M5.39108 13.0029C5.68473 13.0029 5.92278 12.7648 5.92278 12.4712C5.92278 12.1775 5.68473 11.9395 5.39108 11.9395C5.09743 11.9395 4.85938 12.1775 4.85938 12.4712C4.85938 12.7648 5.09743 13.0029 5.39108 13.0029Z"
      fill="#444945"
      stroke="#444945"
      strokeWidth="0.2"
    />
  </svg>
);
