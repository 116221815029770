export enum TRACK_EVENT {
  ASSET_ADDED = 'asset_added',
  ASSET_REMOVED = 'asset_removed',
  ASSET_TYPE_SELECTED = 'accountTypeSelected',
  AUTOMATION_CREATED = 'automation_created',
  BUDGET_CREATED = 'budget_created',
  BUDGET_DELETED = 'budget_deleted',
  COACH_VIDEO_VIEWED = 'coachVideoViewed',
  COACH_CONSENT_CANCEL = 'cancelledCoachingConsent',
  USER_LOGIN = 'user_login',
  USER_ACCOUNT_CREATED = 'user_account_created',
  ONBOARDING_COMPLETED = 'onboarding_completed',
  SESSION_BOOKED = 'sessionBooked',
  SESSION_NOTE_CREATED = 'session_note_created',
  SESSION_CANCELED = 'sessionCanceled',
  ROADMAP_CREATED = 'roadmap_created',
  ROADMAP_DELETED = 'roadmap_deleted',
  GOAL_CREATED = 'goal_created',
  GOAL_NOTE_CREATED = 'newNoteCreated',
  TASK_CREATED = 'taskCreated',
  TASK_DELETED = 'taskDeleted',
  TASK_ORDER = 'task_order',
  TASK_UPDATED = 'task_updated',
  TASKS_SURVEY_OPEN = 'tasks_survey_open',
  TASKS_AUTO_POPULATED = 'tasks_auto_populated',
  TRANSACTION_UPDATED = 'transaction_updated',
  TRANSACTIONS_DOWNLOADED = 'transactions_downloaded',
  EVENT_REGISTER = 'registerToWebinar',
  MANUAL_ASSET_ADDED = 'manual_asset_added',
  FEEDBACK_SUBMIT = 'feedbackSubmitted',
  MESSAGE_SENT = 'messageSent',
  PASSWORD_SET = 'passwordSet',
  UPGRADE_GOALS_BUTTON = 'upgrade_goals_actions_button_clicked',
  OTTOMATE_FEEDBACK = 'ruleFeedback',
  INVEST_ADD_HOLDING_BTN = "invest_add_holding_btn",
  INVEST_HOLDING_ADDED = "invest_holding_added",
  INVEST_HOLDING_DELETED = "invest_holding_deleted",
  INVEST_HOLDING_BUY = "invest_holding_buy",
  INVEST_HOLDING_SELL = "invest_holding_sell",
  INVEST_FILTER_CHANGE = "invest_filter_change",
  INVEST_SORT_CHANGE = "invest_sort_change",
  INVEST_CHART_TYPE = "invest_chart_type",
  AI_SUMMARY_TASK_CREATED = 'ai_summary_task_created',
  AI_SUMMARY_REPORT = 'ai_summary_report',
  REPORT_DEMO = 'report_demo',
  REPORT_LOGIN = 'report_login',
  REPORT_START_BTN = 'report_start_btn',
  REPORT_START_BTN_SUBMIT = 'report_start_btn_submit',
  REPORT_PLAY_AUDIO = 'report_play_audio',
  REPORT_VIEW = 'report_view',
  REPORT_JOINED = 'report_joined',
  REPORT_COMPLETE = 'report_complete'
}
