import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const RoadmapIcon: React.FC<IconProps> = ({ height = 24, width = 26, color = 'currentColor' }) => (
  <svg width={width} height={height} viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color} stroke={color} strokeWidth="1.24889">
      <path d="M24.9644 23.048H1.44639C1.27324 23.048 1.13281 22.9076 1.13281 22.7345C1.13281 22.5613 1.27324 22.4209 1.44639 22.4209H24.9644C25.1375 22.4209 25.2779 22.5613 25.2779 22.7345C25.2779 22.9076 25.1375 23.048 24.9644 23.048Z" />
      <path d="M13.1783 18.8677H13.1782C13.0699 18.8677 12.9693 18.8118 12.9121 18.7199C12.1873 17.5558 11.188 16.3389 10.2215 15.1622C10.0914 15.0037 9.9612 14.8453 9.83164 14.6868H1.44639C1.27324 14.6868 1.13281 14.5463 1.13281 14.3732C1.13281 14.2001 1.27324 14.0596 1.44639 14.0596H9.98033C10.0745 14.0596 10.1637 14.102 10.2232 14.1749C10.3835 14.3713 10.5448 14.5678 10.7061 14.7641C11.5734 15.8202 12.4669 16.908 13.1784 17.9759C13.9118 16.8771 14.8295 15.7741 15.7203 14.7035C15.8675 14.5266 16.0146 14.3498 16.1608 14.1732C16.2203 14.1012 16.3089 14.0596 16.4023 14.0596H24.9644C25.1375 14.0596 25.2779 14.2 25.2779 14.3731C25.2779 14.5463 25.1375 14.6867 24.9644 14.6867H16.5497C16.4343 14.8259 16.3184 14.9652 16.2024 15.1046C15.2136 16.2929 14.1912 17.5218 13.4444 18.72C13.3871 18.8119 13.2865 18.8677 13.1783 18.8677Z" />
      <path d="M13.1793 18.8671H13.1793C13.071 18.8671 12.9704 18.8112 12.9131 18.7193C12.1885 17.5554 11.1894 16.3388 10.2232 15.1623C10.0613 14.9651 9.89935 14.7679 9.73848 14.5708C9.30842 14.0439 8.80654 13.4209 8.34554 12.796C7.3617 11.4622 6.23242 9.7086 6.23242 7.97256C6.23242 4.12789 9.36031 1 13.205 1C17.0512 1 20.1804 4.12784 20.1804 7.97251C20.1804 9.83456 18.9793 11.6989 17.1758 13.9251C17.0227 14.1142 16.859 14.3138 16.6449 14.5725C16.4988 14.7491 16.3517 14.9258 16.2046 15.1027C15.2154 16.2915 14.1925 17.5209 13.4454 18.7194C13.3882 18.8113 13.2876 18.8671 13.1793 18.8671ZM13.205 1.62715C9.70608 1.62715 6.85957 4.47371 6.85957 7.97256C6.85957 9.18452 7.47349 10.5573 8.85018 12.4237C9.30314 13.0378 9.799 13.6532 10.2243 14.1743C10.3848 14.371 10.5463 14.5676 10.7078 14.7643C11.5748 15.82 12.468 16.9077 13.1794 17.9753C13.9131 16.876 14.8312 15.7726 15.7224 14.7015C15.8693 14.5251 16.0159 14.3488 16.1617 14.1726C16.3743 13.9157 16.5367 13.7176 16.6884 13.5304C18.4079 11.4076 19.5532 9.65012 19.5532 7.97245C19.5532 4.47366 16.7054 1.62715 13.205 1.62715Z" />
      <path d="M13.2077 11.7218C11.1999 11.7218 9.56641 10.0458 9.56641 7.98577C9.56641 5.92696 11.1999 4.25195 13.2077 4.25195C15.2142 4.25195 16.8467 5.92696 16.8467 7.98577C16.8467 10.0458 15.2142 11.7218 13.2077 11.7218ZM13.2077 4.8791C11.5457 4.8791 10.1936 6.27277 10.1936 7.98577C10.1936 9.70002 11.5457 11.0947 13.2077 11.0947C14.8685 11.0947 16.2195 9.70002 16.2195 7.98577C16.2195 6.27277 14.8685 4.8791 13.2077 4.8791Z" />
      <path d="M13.2077 11.7218C11.1999 11.7218 9.56641 10.0458 9.56641 7.98577C9.56641 5.92696 11.1999 4.25195 13.2077 4.25195C15.2142 4.25195 16.8467 5.92696 16.8467 7.98577C16.8467 10.0458 15.2142 11.7218 13.2077 11.7218ZM13.2077 4.8791C11.5457 4.8791 10.1936 6.27277 10.1936 7.98577C10.1936 9.70002 11.5457 11.0947 13.2077 11.0947C14.8685 11.0947 16.2195 9.70002 16.2195 7.98577C16.2195 6.27277 14.8685 4.8791 13.2077 4.8791Z" />
      <path d="M2.86536 18.8675H1.31362C1.14045 18.8675 1 18.727 1 18.5539C1 18.3807 1.14045 18.2402 1.31362 18.2402H2.86536C3.03854 18.2402 3.17898 18.3807 3.17898 18.5539C3.17898 18.727 3.03854 18.8675 2.86536 18.8675Z" />
      <path d="M6.84979 18.8675H5.298C5.12482 18.8675 4.98438 18.727 4.98438 18.5539C4.98438 18.3807 5.12482 18.2402 5.298 18.2402H6.84979C7.02296 18.2402 7.16341 18.3807 7.16341 18.5539C7.16341 18.727 7.02296 18.8675 6.84979 18.8675Z" />
      <path d="M10.8029 18.8675H9.25112C9.07795 18.8675 8.9375 18.727 8.9375 18.5539C8.9375 18.3807 9.07795 18.2402 9.25112 18.2402H10.8029C10.976 18.2402 11.1165 18.3807 11.1165 18.5539C11.1165 18.727 10.976 18.8675 10.8029 18.8675Z" />
      <path d="M17.0568 18.8675H15.505C15.3319 18.8675 15.1914 18.727 15.1914 18.5539C15.1914 18.3807 15.3319 18.2402 15.505 18.2402H17.0568C17.23 18.2402 17.3704 18.3807 17.3704 18.5539C17.3704 18.727 17.23 18.8675 17.0568 18.8675Z" />
      <path d="M21.0412 18.8675H19.4894C19.3162 18.8675 19.1758 18.727 19.1758 18.5539C19.1758 18.3807 19.3162 18.2402 19.4894 18.2402H21.0412C21.2144 18.2402 21.3548 18.3807 21.3548 18.5539C21.3548 18.727 21.2144 18.8675 21.0412 18.8675Z" />
      <path d="M24.9943 18.8675H23.4425C23.2694 18.8675 23.1289 18.727 23.1289 18.5539C23.1289 18.3807 23.2694 18.2402 23.4425 18.2402H24.9943C25.1675 18.2402 25.3079 18.3807 25.3079 18.5539C25.3079 18.727 25.1675 18.8675 24.9943 18.8675Z" />
    </g>
  </svg>
);
