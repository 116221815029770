import { gql } from 'graphql-request';

export const InvestmentIdeasDocument = gql`
  query fetchInvestmentIdeas(
    $limit: Int,
    $after: String,
    $assetClassesIn: [String!], 
    $contentTypeIn: [String!], 
    $featured: Boolean, 
    $regionsIn: [String!], 
    $regionsNotIn: [String!], 
    $sectorsIn: [String!], 
    $sectorsNotIn: [String!]
    ) {
    fetchInvestmentIdeas(
      limit: $limit,
      after: $after,
      assetClassesIn: $assetClassesIn, 
      contentTypeIn: $contentTypeIn, 
      featured: $featured, 
      regionsIn: $regionsIn, 
      regionsNotIn: $regionsNotIn, 
      sectorsIn: $sectorsIn, 
      sectorsNotIn: $sectorsNotIn
    ) {
      endCursor
      hasNextPage
      data {
        date
        assetClasses
        content {
          imageCaption
          imageUrl
          quote
          quoteCaption
          textHtml
          type
        }
        featured
        id
        regions
        sectors
        slug
        subheader
        symbols
        thumbnail
        title
        type
        tags
      }
    }
  }
`;
