import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const HomeIcon:React.FC<IconProps> = ({ color = 'currentColor', height = 56, width = 56}) => (
  <div>
    <svg width={width} height={height} viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
      <g fill={color}>
        <path d="M55.7416 28.251L49.9723 22.9907V6.95548C49.9723 6.44643 49.6329 6.10706 49.1239 6.10706H43.5243C43.0152 6.10706 42.6758 6.44643 42.6758 6.95548V16.2882L34.7855 9.07655C34.4461 8.82202 33.9371 8.82202 33.6825 9.07655L22.9924 18.8335C21.8046 16.7972 19.7683 15.3549 17.3927 15.3549H6.53288C2.96949 15.3549 0 18.6638 0 22.6514V40.7229C0 41.2319 0.339371 41.5713 0.848426 41.5713H4.75119V53.025C4.75119 54.637 6.10867 55.9945 7.72068 55.9945C8.22974 55.9945 51.1601 55.9945 51.4995 55.9945C52.0085 55.9945 52.3479 55.6552 52.3479 55.1461V29.6933H55.1477C55.4871 29.6933 55.8265 29.4388 55.9113 29.1843C56.081 28.8449 55.9961 28.4207 55.7416 28.251ZM44.3727 7.80391H48.2755V21.3787L44.3727 17.8153V7.80391ZM6.44804 53.025V30.6266C6.44804 30.1175 6.10867 29.7782 5.59961 29.7782C5.09056 29.7782 4.75119 30.1175 4.75119 30.6266V39.8744H1.69685V22.6514C1.69685 19.597 3.90276 17.1366 6.53288 17.1366H17.3927C19.429 17.1366 21.1258 18.5789 21.8894 20.5303C22.1439 21.209 22.2288 21.8878 22.2288 22.5665V28.8449V39.8744H19.1744V30.6266C19.1744 30.1175 18.8351 29.7782 18.326 29.7782C17.817 29.7782 17.4776 30.1175 17.4776 30.6266V53.025C17.4776 53.7038 16.8837 54.2977 16.2049 54.2977H12.8112V41.3168C12.8112 40.8077 12.4719 40.4683 11.9628 40.4683C11.4538 40.4683 11.1144 40.8077 11.1144 41.3168V54.2977H7.72068C7.04194 54.2977 6.44804 53.7038 6.44804 53.025ZM28.8465 54.2977V37.7534H39.5367V54.2977H28.8465ZM50.6511 54.2977H41.3184V36.9049C41.3184 36.3959 40.979 36.0565 40.4699 36.0565H27.9981C27.489 36.0565 27.1496 36.3959 27.1496 36.9049V54.2977H18.9199C19.0896 53.9583 19.1744 53.5341 19.1744 53.025V41.5713H23.0772C23.5863 41.5713 23.9256 41.2319 23.9256 40.7229V29.6933H50.5662V54.2977H50.6511ZM51.4995 27.9965H24.0105V22.6514C24.0105 21.8878 23.9256 21.209 23.7559 20.5303L34.2764 10.8582C47.1725 22.6514 52.9418 27.9965 52.9418 27.9965H51.4995Z" />
        <path d="M34.5308 16.7997C31.9005 16.7997 29.7793 18.9209 29.7793 21.5512C29.7793 24.1814 31.9005 26.3026 34.5308 26.3026C37.1611 26.3026 39.2823 24.1814 39.2823 21.5512C39.1974 19.0057 37.0762 16.7997 34.5308 16.7997ZM34.5308 24.6905C32.8338 24.6905 31.4763 23.333 31.4763 21.636C31.4763 19.9391 32.8338 18.5815 34.5308 18.5815C36.2277 18.5815 37.5853 19.9391 37.5853 21.636C37.5853 23.333 36.1429 24.6905 34.5308 24.6905Z" />
        <path d="M11.8794 13.9999C15.6976 13.9999 18.8369 10.8605 18.8369 7.04237C18.8369 3.13937 15.6976 0 11.8794 0C8.06125 0 4.92188 3.13937 4.92188 6.95752C4.92188 10.8605 8.06125 13.9999 11.8794 13.9999ZM11.8794 1.69696C14.7642 1.69696 17.14 4.0727 17.14 6.95752C17.14 9.84235 14.7642 12.2181 11.8794 12.2181C8.99457 12.2181 6.61883 9.84235 6.61883 6.95752C6.61883 4.0727 8.99457 1.69696 11.8794 1.69696Z" />
        <path d="M43.8629 33.6817H48.275C48.7841 33.6817 49.1235 33.3423 49.1235 32.8332C49.1235 32.3241 48.7841 31.9847 48.275 31.9847H43.8629C43.3538 31.9847 43.0144 32.3241 43.0144 32.8332C43.0144 33.3423 43.4386 33.6817 43.8629 33.6817Z" />
        <path d="M43.8629 38.2639H48.275C48.7841 38.2639 49.1235 37.9245 49.1235 37.4154C49.1235 36.9063 48.7841 36.5669 48.275 36.5669H43.8629C43.3538 36.5669 43.0144 36.9063 43.0144 37.4154C43.0144 37.9245 43.4386 38.2639 43.8629 38.2639Z" />
        <path d="M43.8629 42.8459H48.275C48.7841 42.8459 49.1235 42.5065 49.1235 41.9974C49.1235 41.4883 48.7841 41.1489 48.275 41.1489H43.8629C43.3538 41.1489 43.0144 41.4883 43.0144 41.9974C43.0144 42.5065 43.4386 42.8459 43.8629 42.8459Z" />
        <path d="M43.8629 47.4271H48.275C48.7841 47.4271 49.1235 47.0877 49.1235 46.5786C49.1235 46.0695 48.7841 45.7301 48.275 45.7301H43.8629C43.3538 45.7301 43.0144 46.0695 43.0144 46.5786C43.0144 47.0877 43.4386 47.4271 43.8629 47.4271Z" />
        <path d="M43.8629 51.923H48.275C48.7841 51.923 49.1235 51.5836 49.1235 51.0746C49.1235 50.5655 48.7841 50.2261 48.275 50.2261H43.8629C43.3538 50.2261 43.0144 50.5655 43.0144 51.0746C43.0144 51.5836 43.4386 51.923 43.8629 51.923Z"  />
      </g>
    </svg>
  </div>
);
