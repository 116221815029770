import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const NoVideoIcon: React.FC<IconProps> = ({ color = 'currentColor', height = 12, width = 12 }) => (
  <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color}>
      <path d="M0.575098 9.35013C0.767489 9.35013 0.923633 9.19399 0.923633 9.0016V3.42503C0.923633 3.23264 1.07978 3.0765 1.27217 3.0765H7.21121C7.4036 3.0765 7.55975 2.92035 7.55975 2.72796C7.55975 2.53557 7.4036 2.37943 7.21121 2.37943H1.27217C0.695691 2.37943 0.226562 2.84855 0.226562 3.42503V9.0016C0.226562 9.19399 0.382706 9.35013 0.575098 9.35013Z" />
      <path d="M10.8745 3.11285L8.59089 4.25465V3.56873L11.2774 0.882221C11.4133 0.746292 11.4133 0.52532 11.2774 0.389392C11.1415 0.253463 10.9205 0.253463 10.7846 0.389392L0.328509 10.8455C0.19258 10.9814 0.19258 11.2024 0.328509 11.3383C0.464438 11.4742 0.685409 11.4742 0.821338 11.3383L2.81008 9.34954H7.54528C8.12176 9.34954 8.59089 8.88041 8.59089 8.30393V7.47372L10.8745 8.61552C10.9846 8.67059 11.1129 8.66292 11.2133 8.60019C11.3164 8.53675 11.3792 8.42453 11.3792 8.30393V3.42444C11.3792 3.30384 11.3164 3.19162 11.214 3.12818C11.1108 3.06335 10.9825 3.05848 10.8745 3.11285ZM7.54528 8.65247H3.50715L7.89382 4.2658V8.30393C7.89382 8.49632 7.73767 8.65247 7.54528 8.65247ZM10.6821 7.74L8.59089 6.6944V5.03397L10.6821 3.98837V7.74Z" />
    </g>
  </svg>
);
