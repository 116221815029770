import { Button, CloseIcon } from '@otto-finance/ui';
import clsx from 'clsx';
import { AlertIcon, AlertIconSeverityEnum } from 'common/otto-ui/alert/alert-icon';
import { HTMLAttributes, MouseEventHandler, ReactNode, SyntheticEvent } from 'react';

export enum AlertVariantEnum {
  DEFAULT = 'default',
  FILLED = 'filled',
  OUTLINED = 'outlined',
  ALERT='alert'
}

export interface AlertInterface extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  /** The severity of the alert. This defines the color and icon used. */
  severity?: Lowercase<keyof typeof AlertIconSeverityEnum>;
  /** The main color for the alert. Unless provided, the value is taken from the severity prop. */
  color?: Lowercase<keyof typeof AlertIconSeverityEnum>;
  /** The variant to use. */
  variant?: Lowercase<keyof typeof AlertVariantEnum>;
  /** Override the icon displayed before the children. Unless provided, the icon is mapped to the value of the severity prop. */
  icon?: ReactNode;
  /** Displayed above the content */
  title?: string;
  /** The action to display. It renders after the message, at the end of the alert. */
  action?: ReactNode;
  /** The content of the component. */
  children?: ReactNode;
  onClose?: () => void;
}

/** An alert displays a short, important message in a way that attracts the user's attention without interrupting the user's task. */
export const Alert = ({
  severity = AlertIconSeverityEnum.INFO,
  color: _color,
  variant = AlertVariantEnum.DEFAULT,
  icon,
  title,
  action,
  className,
  children,
  onClose,
  ...props
}:AlertInterface) => {
  const color: Lowercase<keyof typeof AlertIconSeverityEnum> = _color || severity;
  const classes = {
    wrapper: clsx(
      'my-3 p-6 flex flex-row space-x-2 cursor-default border-2 border-solid rounded-lg text-base',
      'dark:bg-background-dark-background3 dark:border-none',
      {
        'font-semibold': !Boolean(title),
      },
      variant === AlertVariantEnum.DEFAULT && {
        'bg-background-light-background8':
          color === AlertIconSeverityEnum.INFO,
        'border-green-100 bg-green-100 text-green-700 dark:border-green-900 dark:bg-green-900 dark:text-green-300':
          color === AlertIconSeverityEnum.SUCCESS,
        'border-amber-100 bg-amber-100 text-amber-700 dark:border-amber-900 dark:bg-amber-900 dark:text-amber-300':
          color === AlertIconSeverityEnum.WARNING,
        'border-red-100 bg-red-100 text-red-700 dark:border-red-900 dark:bg-red-900 dark:text-red-300':
          color === AlertIconSeverityEnum.ERROR,

      },
      variant === AlertVariantEnum.FILLED && {
        'text-blueGray-50': true,
        'border-blue-700 bg-blue-700': color === AlertIconSeverityEnum.INFO,
        'border-green-500 bg-green-500': color === AlertIconSeverityEnum.SUCCESS,
        'border-amber-500 bg-amber-500': color === AlertIconSeverityEnum.WARNING,
        'border-red-500 bg-red-500': color === AlertIconSeverityEnum.ERROR,
      },
      variant === AlertVariantEnum.OUTLINED && {
        'border-blue-700 text-blue-800 dark:text-blue-500': color === AlertIconSeverityEnum.INFO,
        'border-green-500 text-green-700 dark:text-green-500': color === AlertIconSeverityEnum.SUCCESS,
        'border-amber-500 text-amber-700 dark:border-amber-300 dark:text-amber-300':
          color === AlertIconSeverityEnum.WARNING,
        'border-red-500 text-red-700 dark:text-red-500': color === AlertIconSeverityEnum.ERROR,
      },
      className,
    ),
    body: clsx('w-full', { 'space-y-4': Boolean(title) }),
    icon: clsx(
      'cursor-pointer',
      variant === AlertVariantEnum.DEFAULT && {
        'text-blue-700 dark:text-blue-500': color === AlertIconSeverityEnum.INFO,
        'text-green-700 dark:text-green-500': color === AlertIconSeverityEnum.SUCCESS,
        'text-amber-700 dark:text-amber-500': color === AlertIconSeverityEnum.WARNING,
        'text-red-700 dark:text-red-500': color === AlertIconSeverityEnum.ERROR,
      },
      variant === AlertVariantEnum.FILLED && {
        'text-blueGray-50': true,
      },
      variant === AlertVariantEnum.OUTLINED && {
        'text-blue-700 dark:text-blue-500': color === AlertIconSeverityEnum.INFO,
        'text-green-500': color === AlertIconSeverityEnum.SUCCESS,
        'text-amber-500 dark:text-amber-300': color === AlertIconSeverityEnum.WARNING,
        'text-red-500': color === AlertIconSeverityEnum.ERROR,
      },
    ),
    alertIcon: clsx(
      'w-6 h-6 mr-1.5',
      variant === AlertVariantEnum.DEFAULT && {
        'text-blue-700 dark:text-blue-500': color === AlertIconSeverityEnum.INFO,
        'text-green-700 dark:text-green-500': color === AlertIconSeverityEnum.SUCCESS,
        'text-amber-700 dark:text-amber-500': color === AlertIconSeverityEnum.WARNING,
        'text-red-700 dark:text-red-500': color === AlertIconSeverityEnum.ERROR,
      },
      variant === AlertVariantEnum.FILLED && {
        'text-blueGray-50': true,
      },
      variant === AlertVariantEnum.OUTLINED && {
        'text-blue-700 dark:text-blue-500': color === AlertIconSeverityEnum.INFO,
        'text-green-500': color === AlertIconSeverityEnum.SUCCESS,
        'text-amber-500 dark:text-amber-300': color === AlertIconSeverityEnum.WARNING,
        'text-red-500': color === AlertIconSeverityEnum.ERROR,
      },
    ),
    titleWrapper: clsx('flex flex-1 items-center', {
      'min-h-full': !children,
    }),
  };

  return (
    <div className={classes.wrapper} {...props}>
      {title ? (
        <div className={classes.body}>
          <div className={classes.titleWrapper}>
            <div className={classes.alertIcon}>{icon ?? <AlertIcon severity={severity} />}</div>
            <div className="font-bold">{title}</div>
          </div>
          <div className="text-sm pl-7.5">{children}</div>
        </div>
      ) : (
        <div className={classes.body}>{children}</div>
      )}

      {Boolean(onClose) && (
        <Button onClick={onClose} size="mini" kind="tertiary" shape="circle">
          <CloseIcon />
        </Button>
      )}

      {Boolean(action) && <div className={classes.icon}>{action}</div>}
    </div>
  );
};
