import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';


export const StarIcon: React.FC<IconProps> = ({ height = 26, width = 30, color = '#CDD3CF'}) => (
  <svg width={width} height={height} viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.0261 21.8478C18.9763 21.848 18.9272 21.8362 18.8828 21.8135L15.2618 19.9205L11.6408 21.8104C11.5892 21.8375 11.5311 21.8496 11.473 21.8454C11.4149 21.8411 11.3591 21.8206 11.3121 21.7862C11.2651 21.7518 11.2286 21.7049 11.2069 21.6509C11.1852 21.5968 11.1791 21.5377 11.1893 21.4804L11.8805 17.4795L8.9538 14.6462C8.91183 14.6058 8.88204 14.5544 8.86782 14.4979C8.85359 14.4414 8.85551 14.382 8.87335 14.3266C8.89119 14.2711 8.92424 14.2218 8.96873 14.1841C9.01323 14.1465 9.06738 14.1221 9.12504 14.1138L13.1726 13.5284L14.9816 9.8856C15.0074 9.83379 15.0472 9.79021 15.0964 9.75974C15.1456 9.72927 15.2024 9.71313 15.2602 9.71313C15.3181 9.71313 15.3749 9.72927 15.4241 9.75974C15.4733 9.79021 15.5131 9.83379 15.5389 9.8856L17.3479 13.5284L21.3955 14.1138C21.4531 14.1221 21.5073 14.1465 21.5518 14.1841C21.5963 14.2218 21.6293 14.2711 21.6471 14.3266C21.665 14.382 21.6669 14.4414 21.6527 14.4979C21.6385 14.5544 21.6087 14.6058 21.5667 14.6462L18.64 17.4795L19.3312 21.4804C19.339 21.5255 19.3367 21.5717 19.3246 21.6158C19.3125 21.66 19.2908 21.7009 19.2611 21.7356C19.2313 21.7704 19.1943 21.7982 19.1526 21.8171C19.1109 21.8359 19.0656 21.8453 19.0198 21.8447L19.0261 21.8478ZM15.2618 19.2542C15.3116 19.254 15.3607 19.2658 15.405 19.2885L18.612 20.9635L17.9893 17.4203C17.9806 17.3703 17.9842 17.3189 18 17.2706C18.0157 17.2223 18.043 17.1786 18.0795 17.1432L20.67 14.6368L17.0988 14.1231C17.0492 14.1159 17.0021 14.0969 16.9614 14.0676C16.9208 14.0384 16.8878 13.9997 16.8653 13.955L15.2618 10.7294L13.6583 13.9612C13.6358 14.006 13.6028 14.0446 13.5622 14.0739C13.5215 14.1031 13.4744 14.1222 13.4248 14.1293L9.84427 14.6462L12.4347 17.1526C12.4712 17.1879 12.4986 17.2316 12.5143 17.2799C12.53 17.3282 12.5337 17.3796 12.525 17.4297L11.9023 20.9729L15.1061 19.2947C15.1536 19.2678 15.2073 19.2538 15.2618 19.2542Z"
      fill={color}
      stroke={color}
      strokeWidth="0.48571"
    />
    <path
      d="M15.2615 3.99745C15.179 3.99745 15.0998 3.96465 15.0414 3.90626C14.983 3.84787 14.9502 3.76867 14.9502 3.6861V0.883924C14.9502 0.801348 14.983 0.722154 15.0414 0.663764C15.0998 0.605374 15.179 0.572571 15.2615 0.572571C15.3441 0.572571 15.4233 0.605374 15.4817 0.663764C15.5401 0.722154 15.5729 0.801348 15.5729 0.883924V3.6861C15.5729 3.76867 15.5401 3.84787 15.4817 3.90626C15.4233 3.96465 15.3441 3.99745 15.2615 3.99745Z"
      fill={color}
      stroke={color}
      strokeWidth="0.48571"
    />
    <path
      d="M29.1165 15.044H26.3143C26.2317 15.044 26.1525 15.0112 26.0941 14.9528C26.0357 14.8944 26.0029 14.8153 26.0029 14.7327C26.0029 14.6501 26.0357 14.5709 26.0941 14.5125C26.1525 14.4541 26.2317 14.4213 26.3143 14.4213H29.1165C29.199 14.4213 29.2782 14.4541 29.3366 14.5125C29.395 14.5709 29.4278 14.6501 29.4278 14.7327C29.4278 14.8153 29.395 14.8944 29.3366 14.9528C29.2782 15.0112 29.199 15.044 29.1165 15.044Z"
      fill={color}
      stroke={color}
      strokeWidth="0.48571"
    />
    <path
      d="M4.20825 15.044H1.40608C1.3235 15.044 1.24431 15.0112 1.18592 14.9528C1.12753 14.8944 1.09473 14.8153 1.09473 14.7327C1.09473 14.6501 1.12753 14.5709 1.18592 14.5125C1.24431 14.4541 1.3235 14.4213 1.40608 14.4213H4.20825C4.29083 14.4213 4.37002 14.4541 4.42841 14.5125C4.4868 14.5709 4.51961 14.6501 4.51961 14.7327C4.51961 14.8153 4.4868 14.8944 4.42841 14.9528C4.37002 15.0112 4.29083 15.044 4.20825 15.044Z"
      fill={color}
      stroke={color}
      strokeWidth="0.48571"
    />
    <path
      d="M7.45228 7.24196C7.4113 7.2422 7.37068 7.23435 7.33275 7.21885C7.29481 7.20336 7.26031 7.18053 7.23122 7.15167L5.24479 5.16524C5.19378 5.10568 5.16713 5.02906 5.17015 4.9507C5.17318 4.87234 5.20566 4.79801 5.26112 4.74256C5.31657 4.68711 5.3909 4.65463 5.46926 4.6516C5.54762 4.64857 5.62423 4.67523 5.6838 4.72623L7.67023 6.71266C7.71414 6.75621 7.74408 6.81185 7.75625 6.87249C7.76841 6.93312 7.76224 6.99601 7.73853 7.05312C7.71481 7.11024 7.67463 7.159 7.62309 7.19319C7.57156 7.22737 7.51101 7.24544 7.44917 7.24508L7.45228 7.24196Z"
      fill={color}
      stroke={color}
      strokeWidth="0.48571"
    />
    <path
      d="M25.0685 24.8582C25.0275 24.8585 24.9869 24.8506 24.949 24.8351C24.911 24.8196 24.8765 24.7968 24.8474 24.7679L22.861 22.7815C22.81 22.722 22.7833 22.6453 22.7864 22.567C22.7894 22.4886 22.8219 22.4143 22.8773 22.3588C22.9328 22.3034 23.0071 22.2709 23.0855 22.2679C23.1638 22.2648 23.2404 22.2915 23.3 22.3425L25.2864 24.3289C25.3291 24.3725 25.3581 24.4275 25.3697 24.4874C25.3814 24.5472 25.3752 24.6091 25.352 24.6655C25.3288 24.7218 25.2896 24.7701 25.2392 24.8044C25.1888 24.8387 25.1294 24.8574 25.0685 24.8582Z"
      fill={color}
      stroke={color}
      strokeWidth="0.48571"
    />
    <path
      d="M23.07 7.24123C23.0082 7.24159 22.9476 7.22353 22.8961 7.18934C22.8445 7.15515 22.8044 7.10639 22.7806 7.04928C22.7569 6.99216 22.7508 6.92928 22.7629 6.86864C22.7751 6.80801 22.805 6.75237 22.8489 6.70882L24.8354 4.72239C24.8949 4.67138 24.9715 4.64473 25.0499 4.64775C25.1283 4.65078 25.2026 4.68326 25.2581 4.73872C25.3135 4.79417 25.346 4.8685 25.349 4.94686C25.352 5.02522 25.3254 5.10183 25.2744 5.1614L23.2879 7.14783C23.2305 7.20649 23.1521 7.24006 23.07 7.24123Z"
      fill={color}
      stroke={color}
      strokeWidth="0.48571"
    />
    <path
      d="M5.45379 24.8585C5.39195 24.8588 5.3314 24.8408 5.27986 24.8066C5.22833 24.7724 5.18814 24.7236 5.16443 24.6665C5.14071 24.6094 5.13454 24.5465 5.14671 24.4859C5.15887 24.4253 5.18882 24.3696 5.23273 24.3261L7.21916 22.3396C7.27872 22.2886 7.35534 22.262 7.4337 22.265C7.51206 22.268 7.58639 22.3005 7.64184 22.356C7.69729 22.4114 7.72977 22.4857 7.7328 22.5641C7.73583 22.6425 7.70917 22.7191 7.65817 22.7786L5.67174 24.7651C5.61425 24.8237 5.53591 24.8573 5.45379 24.8585Z"
      fill={color}
      stroke={color}
      strokeWidth="0.48571"
    />
  </svg>
);
