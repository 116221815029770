import clsx from 'clsx';
import { PaginationActionIcon } from 'common/otto-ui/pagination/pagination-action-icon';
import { ReactEventHandler } from 'react';

export enum PaginationItemTypeEnum {
  FIRST = 'first',
  PREV = 'prev',
  ELLIPSE = 'ellipse',
  PAGE = 'page',
  NEXT = 'next',
  LAST = 'last',
}

export enum PaginationItemColorEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
}

export enum PaginationItemShapeEnum {
  SQUARE = 'square',
  CIRCLE = 'circle',
}

export enum PaginationItemSizeEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum PaginationItemVariantEnum {
  TEXT = 'text',
  OUTLINED = 'outlined',
}

export interface PaginationItemInterface {
  type?: Lowercase<keyof typeof PaginationItemTypeEnum>;
  page?: number;
  selected?: boolean;
  color?: Lowercase<keyof typeof PaginationItemColorEnum>;
  shape?: Lowercase<keyof typeof PaginationItemShapeEnum>;
  size?: Lowercase<keyof typeof PaginationItemSizeEnum>;
  variant?: Lowercase<keyof typeof PaginationItemVariantEnum>;
  onClick?: ReactEventHandler<HTMLDivElement>;
  children?: never;
}

export const PaginationItem = ({
  type = PaginationItemTypeEnum.PAGE,
  page = 1,
  selected,
  color = PaginationItemColorEnum.PRIMARY,
  shape = PaginationItemShapeEnum.SQUARE,
  size = PaginationItemSizeEnum.MEDIUM,
  variant = PaginationItemVariantEnum.OUTLINED,
  onClick,
}: PaginationItemInterface) => {
  const className = clsx(
    'flex justify-center items-center',
    {
      'w-6.5 h-6.5 text-sm':
        (variant === PaginationItemVariantEnum.OUTLINED || selected) && size === PaginationItemSizeEnum.SMALL,
      'w-8 h-8 text-base':
        (variant === PaginationItemVariantEnum.OUTLINED || selected) && size === PaginationItemSizeEnum.MEDIUM,
      'w-10 h-10 text-base':
        (variant === PaginationItemVariantEnum.OUTLINED || selected) && size === PaginationItemSizeEnum.LARGE,
      rounded: (variant === PaginationItemVariantEnum.OUTLINED || selected) && shape === PaginationItemShapeEnum.SQUARE,
      'rounded-full':
        (variant === PaginationItemVariantEnum.OUTLINED || selected) && shape === PaginationItemShapeEnum.CIRCLE,
    },
    {
      'm-1 bg-offWhite dark:bg-body border-solid': variant === PaginationItemVariantEnum.OUTLINED,
      'border-2 border-primary text-primary dark:border-primary-light dark:text-primary-light':
        variant === PaginationItemVariantEnum.OUTLINED && color === PaginationItemColorEnum.PRIMARY && selected,
      'border-2 border-secondary text-secondary dark:border-secondary-lightest dark:text-secondary-lightest':
        variant === PaginationItemVariantEnum.OUTLINED && color === PaginationItemColorEnum.SECONDARY && selected,
      'border-2 border-success text-success dark:border-success-light dark:text-success-light':
        variant === PaginationItemVariantEnum.OUTLINED && color === PaginationItemColorEnum.SUCCESS && selected,
      'border border-label dark:border-grey2': variant === PaginationItemVariantEnum.OUTLINED && !selected,
    },
    {
      'mx-1.5 my-1': variant === PaginationItemVariantEnum.TEXT,
      'bg-primary dark:bg-primary-light text-offWhite dark:text-title':
        variant === PaginationItemVariantEnum.TEXT && color === PaginationItemColorEnum.PRIMARY && selected,
      'bg-secondary dark:bg-secondary-lightest text-offWhite dark:text-title':
        variant === PaginationItemVariantEnum.TEXT && color === PaginationItemColorEnum.SECONDARY && selected,
      'bg-success dark:bg-success-light text-offWhite dark:text-title':
        variant === PaginationItemVariantEnum.TEXT && color === PaginationItemColorEnum.SUCCESS && selected,
    },
    {
      'text-grey2 dark:text-placeholder':
        type === PaginationItemTypeEnum.FIRST ||
        type === PaginationItemTypeEnum.PREV ||
        type === PaginationItemTypeEnum.NEXT ||
        type === PaginationItemTypeEnum.LAST,
      'text-title dark:text-offWhite':
        type === PaginationItemTypeEnum.ELLIPSE || (type === PaginationItemTypeEnum.PAGE && !selected),
    },
    { 'cursor-pointer': type !== PaginationItemTypeEnum.ELLIPSE },
  );

  return (
    <div className={className} onClick={onClick}>
      {type === PaginationItemTypeEnum.ELLIPSE && '…'}
      {type === PaginationItemTypeEnum.PAGE && page}
      {(type === PaginationItemTypeEnum.FIRST ||
        type === PaginationItemTypeEnum.PREV ||
        type === PaginationItemTypeEnum.NEXT ||
        type === PaginationItemTypeEnum.LAST) && <PaginationActionIcon type={type} />}
    </div>
  );
};
