import clsx from 'clsx';
import { HTMLAttributes, MouseEventHandler, ReactNode } from 'react';

export interface MenuItemInterface extends HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  highlightText?: boolean;
  selected?: boolean;
  onClick?: MouseEventHandler;
  children?: ReactNode;
}

export const MenuItem = ({
  disabled,
  highlightText,
  onClick,
  children,
  className: classNameProp,
  selected,
  ...props
}: MenuItemInterface) => {
  const className = clsx('p-3 flex space-x-4 rounded-lg', {
    'cursor-pointer text-body dark:text-placeholder': !disabled && !selected,
    'cursor-default text-grey2 dark:text-grey1': disabled,
    'hover:bg-background-light-background8 dark:hover:text-title dark:hover:bg-background-dark-background8':
      !disabled && !highlightText,
    'dark:hover:text-secondary-light': !disabled && highlightText,
    'text-offWhite bg-secondary dark:text-title dark:bg-secondary-light': selected && !highlightText,
    'text-secondary dark:text-secondary-light': selected && highlightText,
    [classNameProp]: classNameProp,
  });

  const onClickHandler: MouseEventHandler = disabled ? null : onClick;

  return (
    <div className={className} onClick={onClickHandler} {...props}>
      {children}
    </div>
  );
};
