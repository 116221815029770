import clsx from 'clsx';
import { CSSProperties } from 'react';
import styles from './progress.module.scss';

interface Props {
  /**
   * Percentage value 0 - 100
   */
  value: number;
  color?: 'default' | 'alert' | 'danger' | 'blue'
}

export const Progress: React.FC<Props> = ({ value, color = 'default' }) => (
  <div
    className={clsx(styles.root, styles[color])}
    style={{ '--p': `${value > 100 ? 100 : value}%` } as CSSProperties}>
    {/* bars */}
    <div>
      {/* primary bar */}
      <div />
      {/* shadow bar */}
      <div />
    </div>
    {/* bg */}
    <div />
  </div>
);