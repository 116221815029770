import React from 'react';

import { IconProps } from './icon.type';

interface Props extends IconProps {
  status?: 'error' | 'warning';
}

const classes = {
  main: 'border flex items-center justify-center w-5 h-5 rounded-full',
  error: 'border-red-500 text-error',
  warning: 'border-yellow-500 text-warning',
};

export const ExclamationIcon: React.FC<Props> = ({ height, width, status = 'error' }) => {
  if (status === 'warning') {
    return (
      <div
        className={`${classes.main} ${classes.warning}`}
        style={{
          height,
          width,
        }}
      >
        !
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div
        className={`${classes.main} ${classes.error}`}
        style={{
          height,
          width,
        }}
      >
        !
      </div>
    );
  }

  return null;
};
