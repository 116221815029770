import { IconProps } from 'common/otto-ui/icons/icon.type';
import { useTheme } from 'common/roq-hooks/use-theme';
import React from 'react';

export const CryptoIcon: React.FC<IconProps> = ({ height = 41, width = 40, color = 'black', darkColor = 'white' }) => {
  const { isDark } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.418 0C7.34422 0 0 7.34297 0 16.418C0 25.4918 7.34297 32.8361 16.418 32.8361C25.4916 32.8361 32.8361 25.4928 32.8361 16.418C32.8361 7.34422 25.4931 0 16.418 0ZM16.418 30.8447C8.46329 30.8447 1.99138 24.3728 1.99138 16.418C1.99138 8.46304 8.46329 1.99138 16.418 1.99138C24.3728 1.99138 30.8447 8.46304 30.8447 16.418C30.8447 24.3728 24.3728 30.8447 16.418 30.8447Z"
        fill={isDark ? darkColor : color}
      />
      <path
        d="M28.0409 18.0712C27.5048 17.9485 26.9706 18.2834 26.8479 18.8195C25.7281 23.7069 21.4387 27.1203 16.417 27.1203C11.3954 27.1203 7.10597 23.7067 5.98615 18.8195C5.86315 18.2834 5.32954 17.9485 4.79318 18.0712C4.25707 18.194 3.92213 18.7281 4.04488 19.2642C5.34808 24.951 10.4409 29.1116 16.417 29.1116C22.3922 29.1116 27.4857 24.9515 28.7889 19.2642C28.9119 18.7281 28.577 18.1943 28.0409 18.0712Z"
        fill={isDark ? darkColor : color}
      />
      <path
        d="M5.01644 14.7893C5.47038 14.7893 5.88048 14.4769 5.9862 14.0157C7.10627 9.12833 11.3954 5.71501 16.4171 5.71501C21.4387 5.71501 25.7281 9.12859 26.8479 14.0157C26.9709 14.5518 27.5053 14.8868 28.0409 14.764C28.577 14.6413 28.912 14.1072 28.7892 13.571C27.4863 7.88501 22.394 3.72363 16.4171 3.72363C10.4419 3.72363 5.34838 7.8835 4.04518 13.571C3.90188 14.1953 4.37736 14.7893 5.01644 14.7893Z"
        fill={isDark ? darkColor : color}
      />
      <path
        d="M16.2295 24.6477C16.7794 24.6477 17.2253 24.202 17.2253 23.6521V22.2359H18.044C19.9229 22.2359 21.4513 20.7073 21.4513 18.8284C21.4513 17.6189 20.8175 16.5554 19.8648 15.9504C20.2503 15.3982 20.4773 14.7279 20.4773 14.0049C20.4773 12.1781 19.0321 10.683 17.2253 10.6013V9.18111C17.2253 8.63122 16.7794 8.18555 16.2295 8.18555C15.6796 8.18555 15.2339 8.63122 15.2339 9.18111V10.5973H13.3315C12.7816 10.5973 12.3359 11.0432 12.3359 11.5931C12.3359 12.1292 12.3359 20.704 12.3359 21.2401C12.3359 21.79 12.7816 22.2359 13.3315 22.2359H15.2339V23.6521C15.2339 24.202 15.6796 24.6477 16.2295 24.6477ZM14.3273 12.5887H17.0698C17.8506 12.5887 18.4859 13.224 18.4859 14.0049C18.4859 14.7857 17.8506 15.4208 17.0698 15.4208H14.3273V12.5887ZM14.3273 17.4122C14.8223 17.4122 17.6459 17.4122 18.044 17.4122C18.8249 17.4122 19.46 18.0475 19.46 18.8284C19.46 19.6092 18.8249 20.2445 18.044 20.2445H14.3273V17.4122Z"
        fill={isDark ? darkColor : color}
      />
    </svg>
  );
};
