import { Layer } from '@otto-finance/ui';
import clsx from 'clsx';
import { ModalManager } from 'common/otto-ui/modal/modal-manager';
import {
  HTMLAttributes,
  ReactEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

const manager = new ModalManager();

export interface ModalInterface extends HTMLAttributes<HTMLDivElement> {
  /** If true, the Modal is open. */
  open: boolean;
  /** If true, clicking the backdrop will not fire the onClose callback. */
  disableBackdropClick?: boolean;
  /** If true, hitting escape will not fire the onClose callback. */
  disableEscapeKeyDown?: boolean;
  /** If true, the backdrop is not rendered. */
  hideBackdrop?: boolean;
  /** Callback fired when the backdrop is clicked. */
  onBackdropClick?: ReactEventHandler;
  /** Callback fired when the escape key is pressed, disableEscapeKeyDown is false and the modal is in focus. */
  onEscapeKeyDown?: (event: KeyboardEvent) => void;
  /**
   * Callback fired when the component requests to be closed.
   * function() => void;
   */
  onClose?: () => void;
  modalManager?: ModalManager;
  /** A single child content element. */
  children: ReactNode;
  /**
   * Position of the modal body
   */
  position?: 'top' | 'center' | 'bottom' | 'bottom-right';
}

export const Modal = ({
  open,
  disableBackdropClick,
  disableEscapeKeyDown,
  hideBackdrop,
  onBackdropClick,
  onEscapeKeyDown,
  onClose,
  modalManager = manager,
  className,
  children,
  position = 'center',
  ...props
}: ModalInterface) => {
  // TODO: Fix tailwind to extend zIndex
  const classes = {
    wrapper: clsx('backdrop-blur-sm backdrop-contrast-50 backdrop-brightness-25 fixed left-0 top-0 w-screen h-screen animate-fadeIn'),
    backdrop: clsx('fixed left-0 top-0 w-full h-screen bg-title opacity-60', 'dark:bg-black dark:opacity-70'),
    wrapperContent: clsx('fixed left-0 top-0 overflow-auto w-screen h-screen flex flex-row justify-center p-4', className, {
      'items-center': position === 'center',
      'items-end': position === 'bottom',
      'items-start': position === 'top',
      '!justify-end items-end': position === 'bottom-right'
    })
  };

  const [wrapperElement, setWrapperElement] = useState<HTMLElement>(null);
  const [backdropElement, setBackdropElement] = useState<HTMLElement>(null);

  const handleBackdropClick: ReactEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (!disableBackdropClick && event.target === backdropElement) {
        if (onBackdropClick) {
          onBackdropClick(event);
        }
        if (onClose) {
          onClose();
        }
      }
    },
    [disableBackdropClick, backdropElement, disableBackdropClick, onClose],
  );

  useEffect(() => {
    const handleKeyup = (event: KeyboardEvent) => {
      if (!disableEscapeKeyDown && event.key === 'Escape' && modalManager.isTopModal(wrapperElement)) {
        if (onEscapeKeyDown) {
          onEscapeKeyDown(event);
        }
        if (onClose) {
          onClose();
        }
      }
    };

    document.addEventListener('keyup', handleKeyup);
    modalManager.add(wrapperElement);

    return () => {
      document.removeEventListener('keyup', handleKeyup);
      modalManager.remove(wrapperElement);
    };
  }, [wrapperElement, disableEscapeKeyDown, onEscapeKeyDown, onClose]);

  return open ? (
    <Layer>
      <div ref={setWrapperElement}
        className={classes.wrapper}
        role="presentation"
        {...props}>
        {!hideBackdrop && <div className={classes.backdrop} />}
        <div ref={setBackdropElement} className={classes.wrapperContent} onMouseDown={handleBackdropClick}>
          {children}
        </div>
      </div>
    </Layer>
  ) : null;
};
