import { InvestmentHoldingInterface, InvestmentHoldingSecurity } from "common/interfaces";
import { COUNTRIES } from "configuration/data";
import get from "lodash/get";
import has from "lodash/has";
import { AssetsInterface } from "slices/assets";
import { getPercentage } from "utils/numbers";
import { securityRegionMap, securitySectorMap, securitySectorStabilityMap } from "views/wealth/data";

export type SecurityAlloc = {
  id: string;
  name: string;
  value: number;
  percentage: number;
  security: InvestmentHoldingSecurity;
};

export enum SecuritySelector {
  SECTOR = 'sector',
  COUNTRY = 'country',
  RISK_SECTORS = 'risk.sectors',
  RISK_STABILITIES = 'risk.sectorsStability',
  RISK_REGIONS = 'risk.regions'
}

export const securityMaps = {
  [SecuritySelector.SECTOR]: securitySectorMap,
  [SecuritySelector.COUNTRY]: COUNTRIES,
  [SecuritySelector.RISK_SECTORS]: securitySectorMap,
  [SecuritySelector.RISK_STABILITIES]: securitySectorStabilityMap,
  [SecuritySelector.RISK_REGIONS]: securityRegionMap
};

const aggrSectors = (holdings: InvestmentHoldingInterface[], security: SecuritySelector) =>
  holdings.reduce<SecurityAlloc[]>((acc, curr) => {
    if (has(curr.security, security)) {
      get(curr.security, security).forEach(i => {
        const id = i.value === 'Unknown' ? 'OTHER' : i.value;
        const v = curr.balance.convertedAmount * (i.percentage / 100);
        const fI = acc.findIndex(f => f.id === id);
        if (fI >= 0) {
          acc[fI].value += v;
        } else {
          acc.push({
            id,
            name: !id || id === 'null' ? 'Unknown' : securityMaps[security][id.toUpperCase()] || 'Unknown',
            value: v,
            percentage: 0,
            security: curr.security
          });
        }
      });
    }
    return acc;
  }, [])
    .filter(f => f.value > 0)
    .sort((a, b) => b.value - a.value);

export const getAllocation = (allocations: InvestmentHoldingInterface[], securityName: SecuritySelector): SecurityAlloc[] => {
  const alloc = aggrSectors(allocations, securityName);
  const sumSectors = alloc.reduce((a, c) => a + c.value, 0);
  return alloc.map(s => {
    s.percentage = getPercentage(sumSectors, s.value, 1);
    return s;
  });
};

export const getAccountsAllocations = (accounts: AssetsInterface[], accountId?: string): {
  sectors: SecurityAlloc[];
  countries: SecurityAlloc[];
} => {
  const allocations = (accountId ? accounts.find(f => f.id === accountId).holdings : accounts.map(a => a.holdings)).flat();
  return {
    sectors: getAllocation(allocations, SecuritySelector.SECTOR),
    countries: getAllocation(allocations, SecuritySelector.COUNTRY)
  };
};
