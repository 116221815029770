import { IconProps } from 'common/otto-ui/icons/icon.type';
import { useTheme } from 'common/roq-hooks/use-theme';
import React from 'react';

export const ChevronLeftIcon: React.FC<IconProps> = ({
  color = 'currentColor',
  height = 14,
  width = 8,
  darkColor = 'white',
}) => {
  const { isDark } = useTheme();
  const themeColor = isDark ? darkColor : color;

  return (
    <svg width={width} height={height} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.60937 1.66671L1.29238 7.00004L6.60938 12.3334"
        stroke={themeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
