import { QueryOptions } from '@apollo/client';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { SessionInterface } from 'slices/session';
import { requestGql } from 'utils/request-gql';

export interface FetchSessionVariablesInterface {
  id: string;
}

export interface FetchSessionActionRequestInterface extends QueryOptions {
  variables: FetchSessionVariablesInterface;
}

export const fetchSessionAction = createAsyncThunk(
  'session/fetch',
  async (request: FetchSessionActionRequestInterface, thunkApi): Promise<SessionInterface[] | SerializedError> =>
    await requestGql<SessionInterface[]>(request, thunkApi, 'session'),
);
