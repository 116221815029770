import { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export interface PortalInterface {
  children: ReactNode;
}

export const Portal = ({ children }: PortalInterface) => {
  const container = useRef<HTMLDivElement>(null);
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    container.current = document.createElement('div');
    container.current.classList.add('otto-portal');
    document.getElementById("__next").children[0].insertAdjacentElement('afterend', container.current);
    setMounted(true);
    return () => {
      container.current.remove();
    };
  }, []);

  return mounted ? createPortal(children, container.current) : null;
};
