import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

interface CustomIconProps extends IconProps {
  startColor?: string;
  endColor?: string;
  /**
   * A custom ID to allow this icon to be used multiple times in a single component
   */
  customId?: string;
}

export const GradientCheckIcon: React.FC<CustomIconProps> = ({
  startColor = '#53DADA',
  endColor = '#61D57F',
  height = 7,
  width = 8,
  customId = '3572_1713',
}) => (
  <svg width={width} height={height} viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.19003 5.888C3.09207 5.888 3.00302 5.84347 2.93177 5.77223L0.981482 3.67054C0.838995 3.51915 0.847901 3.2787 0.981482 3.12731C1.12397 2.97592 1.34661 2.98482 1.48909 3.12731L3.18113 4.95293L6.93032 0.705031C7.0639 0.553639 7.29544 0.544733 7.43793 0.68722C7.58041 0.829707 7.58932 1.07015 7.45574 1.23045L3.45719 5.77223C3.37705 5.84347 3.28799 5.888 3.19003 5.888Z"
      fill={`url(#paint0_linear_${customId})`}
      stroke={`url(#paint1_linear_${customId})`}
      strokeWidth="0.556056"
    />
    <defs>
      <linearGradient
        id={`paint0_linear_${customId}`}
        x1="0.87793"
        y1="3.23686"
        x2="7.257"
        y2="3.23686"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={startColor} />
        <stop offset="1" stopColor={endColor} />
      </linearGradient>
      <linearGradient
        id={`paint1_linear_${customId}`}
        x1="0.87793"
        y1="3.23686"
        x2="7.257"
        y2="3.23686"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={startColor} />
        <stop offset="1" stopColor={endColor} />
      </linearGradient>
    </defs>
  </svg>
);
