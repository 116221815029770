import { Input, SearchIcon, useTheme } from '@otto-finance/ui';
import { ExpandedState, flexRender, getCoreRowModel, getExpandedRowModel, getFilteredRowModel, getSortedRowModel, OnChangeFn, PaginationState, RowSelectionState, TableState, useReactTable, SortingState, Row } from '@tanstack/react-table';
import { ExclamationIcon } from 'common/otto-ui/icons';
import { Paginator } from 'common/otto-ui/table-v3';
import { Typography } from 'common/otto-ui/typography';
import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import { Fragment, useCallback, useState } from 'react';
import { TableTh } from './table-th';
import { ColorTheme } from 'configuration/ui';

import styles from './styles.module.scss';
import clsx from 'clsx';

interface TableInterface<T> {
  data: T[];
  columns: any[];
  pagination?: PaginationState;
  totalCount: number;
  enableExpanding?: boolean;
  enablePagination?: boolean;
  headRight?: JSX.Element;
  isLoading?: boolean;
  error?: boolean | string;
  state?: Partial<TableState>;
  enableRowSelection?: boolean;
  enableGlobalFilter?: boolean;
  manualSorting?: boolean;
  stickyPos?: number;
  searchPlaceholder?: string;
  expandedView?: (data: T) => JSX.Element;
  // expandedView?: ({ data }: { data: T; }) => JSX.Element;
  onExpandedChange?: OnChangeFn<ExpandedState>;
  // onExpandedChange?: (e: ExpandedState) => void;
  onRowSelectionChange?: OnChangeFn<RowSelectionState>;
  // onRowSelectionChange?: (p: RowSelectionState) => void;
  onPaginationChange?: OnChangeFn<PaginationState>;
  onSortingChange?: OnChangeFn<SortingState>;
}

// TODO: Implement all features & fix styles before replacing existing table components
export const Table = <T extends unknown>({
  data,
  columns,
  pagination,
  totalCount,
  error = false,
  state = {},
  enableRowSelection,
  headRight,
  enablePagination,
  enableGlobalFilter,
  manualSorting,
  searchPlaceholder = 'Search...',
  stickyPos,
  expandedView,
  onExpandedChange,
  onRowSelectionChange,
  onSortingChange,
  onPaginationChange
}: TableInterface<T>): JSX.Element => {
  const [css, theme] = useTheme();
  const [globalFilter, setGlobalFilter] = useState('');

  const table = useReactTable({
    data,
    columns,
    enableHiding: true,
    pageCount: !enablePagination || !pagination ? 1 : Math.ceil(totalCount / pagination.pageSize),
    manualPagination: enablePagination,
    state: {
      ...state,
      globalFilter
    },
    enableRowSelection,
    enableGlobalFilter,
    manualSorting,
    globalFilterFn: 'includesString',
    onGlobalFilterChange: (s) => setGlobalFilter(s),
    onExpandedChange,
    onRowSelectionChange,
    onPaginationChange,
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const onGlobalSearch = useCallback(debounce(e => table.setGlobalFilter(e.target.value), 200), []);
 
  if (error) {
    return (
      <div className="flex flex-col items-center space-y-3">
        <ExclamationIcon />
        <Typography variant="body-2" color="error">
          {typeof error === 'string' ? error : 'Unable to load table'}
        </Typography>
      </div>
    );
  }

  return (
    <div className={css({ display: 'grid', gap: theme.sizing.scale600 })}>

      {enableGlobalFilter ? (
        <div className={css({
          display: 'grid',
          justifyContent: 'space-between',
          padding: theme.sizing.scale600,
          gap: theme.sizing.scale400,
          [theme.mediaQuery.medium]: {
            display: 'flex',
          }
        })}>
          <div>
            <Input
              onChange={onGlobalSearch}
              endEnhancer={<SearchIcon size="sm" />}
              placeholder={searchPlaceholder}
              clearable={true}
              clearOnEscape={true}
              autoFocus={false}
            />

          </div>
          {headRight}
        </div>
      ) : null}

      <table className={css({
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: '0px',
      })}>
        <TableTh table={table} pos={stickyPos} />
        <tbody>

          {table.getRowModel().rows.map((row) => (
            <Fragment key={`tbody_tr_${row.id}`}>
              <tr
                className={clsx(
                  styles.tableRow,
                  row.getIsExpanded() && styles.tableRowExpanded
                )}
                >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={`tbody_td_${cell.id}`}
                    className={css({
                      padding: theme.sizing.scale400,
                      borderTop: `solid 1px ${ColorTheme.gray50}`
                    })}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
              <AnimatePresence initial={false}>
                {/* Expanded Rows */}
                {row.getIsExpanded() && (
                  <motion.tr
                    key={`tbody_tr_expanded_${row.id}`}
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className={css({ backgroundColor: ColorTheme.background50 })}
                  >
                    <motion.td colSpan={table.getAllColumns().length}>
                      {expandedView(row.original)}
                    </motion.td>
                  </motion.tr>
                )}
              </AnimatePresence>
            </Fragment>
          ))}

        </tbody>
      </table>
      {/* Pagination */}
      {enablePagination && totalCount > pagination.pageSize ? <Paginator table={table} /> : null}
    </div >
  );
};
