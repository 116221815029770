import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const TickIcon: React.FC<IconProps> = ({ color = '#252926', width = 10, height = 9 }) => (
  <div>
    <svg width={width} height={height} viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.74612 1.09692C8.79475 1.13428 8.83553 1.18087 8.86614 1.23401C8.89675 1.28715 8.91659 1.3458 8.92452 1.40661C8.93245 1.46742 8.92832 1.52919 8.91237 1.5884C8.89641 1.64762 8.86894 1.7031 8.83153 1.75169L4.16455 7.81877C4.12406 7.8714 4.0728 7.91478 4.01421 7.94601C3.95562 7.97725 3.89103 7.99562 3.82477 7.99991C3.75851 8.00419 3.6921 7.99429 3.62997 7.97086C3.56784 7.94743 3.51143 7.91101 3.4645 7.86404L1.13101 5.53055C1.046 5.44253 0.998954 5.32464 1.00002 5.20227C1.00108 5.07991 1.05016 4.96285 1.13669 4.87632C1.22322 4.78979 1.34028 4.74071 1.46264 4.73965C1.58501 4.73858 1.7029 4.78562 1.79092 4.87064L3.74872 6.82844L8.09134 1.18232C8.12871 1.1337 8.17529 1.09291 8.22843 1.0623C8.28157 1.03169 8.34022 1.01185 8.40103 1.00392C8.46184 0.995988 8.52362 1.00012 8.58283 1.01608C8.64204 1.03203 8.69753 1.0595 8.74612 1.09692V1.09692Z"
        fill={color}
        stroke={color}
        strokeWidth="0.4"
      />
    </svg>
  </div>
);
