import { IconProps } from 'common/otto-ui/icons/icon.type';
import React from 'react';

export const DownloadIcon: React.FC<IconProps> = ({ color = "currentColor", size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill={color} stroke={color} strokeWidth="0.4">
      <path d="M10.9446 13.2453L9.02921 15.1616V8.30138C9.02921 8.13495 8.89425 8 8.72963 8C8.5632 8 8.42825 8.13495 8.42825 8.30138V15.1616L6.51466 13.248C6.45983 13.1914 6.38241 13.159 6.29958 13.159H6.29867C6.21764 13.159 6.14037 13.1905 6.08811 13.2453C6.03148 13.3028 6 13.3784 6 13.4577C6 13.5405 6.03148 13.616 6.08811 13.67L8.51996 16.1037C8.57659 16.1585 8.65129 16.1882 8.72962 16.1882C8.80613 16.1882 8.8834 16.1567 8.94108 16.1001L11.3684 13.6718C11.426 13.6152 11.4583 13.5396 11.4583 13.4576C11.4583 13.3775 11.4269 13.3021 11.3711 13.2471C11.2622 13.1373 11.0508 13.1355 10.9446 13.2453L10.9446 13.2453Z" />
      <path d="M14.0034 4.52851H13.971C13.7335 2.54293 12.0052 1 9.99876 1C8.35777 1 6.86971 2.01666 6.2731 3.53803C6.16511 3.52629 6.05983 3.52102 5.95816 3.52102C4.64735 3.52102 3.49662 4.43692 3.19526 5.70902C1.95824 5.82153 1 6.86425 1 8.11303C1 9.44374 2.08325 10.5277 3.41469 10.5277L7.45476 10.5276C7.62029 10.5276 7.75433 10.3944 7.75433 10.2289C7.75433 10.0616 7.61938 9.92661 7.45476 9.92661H3.41392C2.41442 9.92661 1.60019 9.11327 1.60019 8.11288C1.60019 7.11608 2.41353 6.30097 3.40324 6.29735L3.44466 6.30096C3.59407 6.30096 3.71908 6.19387 3.74243 6.04551C3.89982 4.94889 4.85174 4.12198 5.9565 4.12198C6.11028 4.12198 6.2615 4.13824 6.41437 4.16882L6.47462 4.17514C6.60505 4.17514 6.71847 4.09065 6.75718 3.96548C7.20977 2.55211 8.51245 1.60111 9.99693 1.60111C11.8242 1.60111 13.315 3.03439 13.3914 4.86423C13.3959 4.94617 13.4328 5.02162 13.4967 5.0775C13.5533 5.12615 13.619 5.14679 13.7279 5.14859C13.8223 5.13594 13.914 5.12977 14.0005 5.12977C15.323 5.12977 16.399 6.20576 16.399 7.52834C16.399 8.85091 15.323 9.92691 14.0005 9.92691H9.97425C9.80872 9.92691 9.67467 10.0619 9.67467 10.2292C9.67467 10.3947 9.80872 10.5279 9.97425 10.5279H14.0005C15.6541 10.5279 17 9.18285 17 7.52833C17 5.87351 15.657 4.52851 14.0034 4.52851Z" />
    </g>
  </svg>
);
