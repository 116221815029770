import { BaseProvider, themeOverrides, getTheme, PRIMITIVES } from "@otto-finance/ui";
import { ReactNode, useMemo } from "react";
import { useSelector } from "react-redux";
import { uiSettingsSelector } from "slices/ui/ui.slice";
import { merge } from "lodash";
import { getThemeOverride } from "slices/ui/ui.util";
import { hslToObj } from "utils/colors";

export const AppTheme = ({ children }: { children: ReactNode; }) => {
  const { site: { theme } } = useSelector(uiSettingsSelector);
  const themeOverride = useMemo(() => getThemeOverride(), []);
  const getStyles = useMemo(() => {
    const colors = themeOverride.colors;
    const accentObj = hslToObj(colors.accent);
    const primaryObj = hslToObj(colors.primary);
    return (
      <style>
        {`
        :root {
          ${`--hsl-primary-h: ${primaryObj[0]}deg;`}
          ${`--hsl-primary-s: ${primaryObj[1]}%;`}
          ${`--hsl-primary-l: ${primaryObj[2]}%;`}
          ${`--hsl-accent-h: ${accentObj[0]}deg;`}
          ${`--hsl-accent-s: ${accentObj[1]}%;`}
          ${`--hsl-accent-l: ${accentObj[2]}%;`}
          ${Object.keys(colors).map(c => {
            return `--color-${c}: ${colors[c]};`;
          }).join('')}
        }
        `}
      </style>
    );
  }, [themeOverride]);
  const overrideTheme = useMemo(() => getTheme(PRIMITIVES, merge(themeOverrides, themeOverride)), [theme]);
  return (
    <>
      {getStyles}
      <BaseProvider theme={overrideTheme}>
        {children}
      </BaseProvider>
    </>
  );
};
