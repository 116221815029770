import { IconProps } from 'common/otto-ui/icons/icon.type';
import { useTheme } from 'common/roq-hooks/use-theme';
import React from 'react';

export const RealEstateAssetIcon: React.FC<IconProps> = ({
  height = 30,
  width = 30,
  color = 'black',
  darkColor = 'white',
}) => {
  const { isDark } = useTheme();

  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0817 16.1666C19.4037 16.1666 19.665 15.9053 19.665 15.5833C19.665 15.2613 19.4037 15 19.0817 15H10.9153C10.5934 15 10.332 15.2613 10.332 15.5833V23.7497C10.332 24.0717 10.5934 24.333 10.9153 24.333H14.4152C14.7372 24.333 14.9985 24.0717 14.9985 23.7497C14.9985 23.4277 14.7372 23.1664 14.4152 23.1664H11.4987V16.1666H19.0817Z"
        fill={isDark ? darkColor : color}
        stroke={isDark ? darkColor : color}
        strokeWidth="0.533333"
      />
      <path
        d="M17.3332 27.8323H5.66701V14.4162C5.66701 14.0942 5.40569 13.8329 5.0837 13.8329H2.99195L14.4167 2.40811C14.7247 2.10013 15.273 2.09896 15.5821 2.40811L19.8368 6.66279C20.0048 6.82962 20.2556 6.88095 20.4726 6.78878C20.6908 6.69895 20.8331 6.48546 20.8331 6.24981V2.16662H24.333V10.9163C24.333 11.0715 24.3948 11.2196 24.5045 11.3293L27.0081 13.8329H24.9163C24.5943 13.8329 24.333 14.0942 24.333 14.4162V14.9995C24.333 15.3215 24.5943 15.5828 24.9163 15.5828C25.2383 15.5828 25.4996 15.3215 25.4996 14.9995H28.4162C28.6518 14.9995 28.8653 14.8572 28.9551 14.6402C29.0461 14.422 28.996 14.1712 28.8292 14.0044L25.4996 10.6748V1.58331C25.4996 1.26132 25.2383 1 24.9163 1H20.2498C19.9278 1 19.6665 1.26132 19.6665 1.58331V4.84169L16.4069 1.58215C15.6568 0.83084 14.3455 0.829673 13.5919 1.58215L1.17085 14.0032C1.00402 14.17 0.953854 14.4208 1.04485 14.639C1.13468 14.8572 1.34817 14.9995 1.58383 14.9995H4.50039V28.4156C4.50039 28.7376 4.76171 28.999 5.0837 28.999H17.3332C17.6552 28.999 17.9166 28.7376 17.9166 28.4156C17.9166 28.0937 17.6552 27.8323 17.3332 27.8323Z"
        fill={isDark ? darkColor : color}
        stroke={isDark ? darkColor : color}
        strokeWidth="0.533333"
      />
      <path
        d="M22.5844 16.167C19.046 16.167 16.168 19.0451 16.168 22.5834C16.168 26.1218 19.046 28.9998 22.5844 28.9998C26.1228 28.9998 29.0008 26.1218 29.0008 22.5834C29.0008 19.0451 26.1228 16.167 22.5844 16.167ZM22.5844 27.8332C19.69 27.8332 17.3346 25.4778 17.3346 22.5834C17.3346 19.689 19.69 17.3336 22.5844 17.3336C25.4788 17.3336 27.8342 19.689 27.8342 22.5834C27.8342 25.4778 25.4788 27.8332 22.5844 27.8332Z"
        fill={isDark ? darkColor : color}
        stroke={isDark ? darkColor : color}
        strokeWidth="0.533333"
      />
      <path
        d="M24.9164 25.4997H21.0596L21.8365 23.1665H22.5832C22.9052 23.1665 23.1665 22.9052 23.1665 22.5832C23.1665 22.2612 22.9052 21.9999 22.5832 21.9999H21.728L20.8496 20.6804C20.9662 20.1169 21.714 19.6666 22.5855 19.6666C23.7113 19.6666 24.3005 19.8789 24.3355 20.2989C24.3623 20.6198 24.6528 20.8717 24.9654 20.8309C25.2862 20.8041 25.5242 20.5218 25.4974 20.2009C25.4029 19.0728 24.4218 18.5 22.5843 18.5C20.9756 18.5 19.6666 19.5465 19.6666 20.8332C19.6666 20.9487 19.7005 21.0607 19.7646 21.1564L20.3269 21.9999H19.0833C18.7613 21.9999 18.5 22.2612 18.5 22.5832C18.5 22.9052 18.7613 23.1665 19.0833 23.1665H20.6069L19.697 25.8976C19.6363 26.0761 19.6666 26.272 19.7763 26.4237C19.8859 26.5765 20.0621 26.6664 20.2499 26.6664H24.9164C25.2384 26.6664 25.4997 26.405 25.4997 26.0831C25.4997 25.7611 25.2384 25.4997 24.9164 25.4997Z"
        fill={isDark ? darkColor : color}
        stroke={isDark ? darkColor : color}
        strokeWidth="0.533333"
      />
    </svg>
  );
};
