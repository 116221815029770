import { Button } from "common/otto-ui/button";
import { CloseIcon } from "common/otto-ui/icons";
import { Typography } from "common/otto-ui/typography";

export const DrawerHeader: React.FC<{ title: string, onClose?: () => void; }> = ({ title, onClose }) => (
  <div className="flex items-center justify-between p-6">
    <Typography variant='subtitle' mediumBold>{title}</Typography>
    {onClose && <Button onClick={onClose} variant="icon"><CloseIcon width={22} /></Button>}
  </div>
);
