import { CheckBox } from "common/otto-ui";
import { FormFieldProps } from "components/form/types";
import { useField } from 'formik';
import React from "react";

export const FormCheckField = ({ label, type, ...props }: FormFieldProps) => {
  const [field] = useField(props);
  return (
    <div className="grid gap-2">
      <label htmlFor={field.name}>{label}</label>
      <div className="px-2">
        <CheckBox
          id={field.name}
          name={field.name}
          value={field.value || ''}
          placeholder={''}
          onChange={field.onChange}
          onBlur={field.onBlur}
          disabled={props.disabled}
          checked={Boolean(field.value)}
        />
      </div>
    </div>
  );
};
