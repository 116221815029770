import clsx from 'clsx';
import { Button } from 'common/otto-ui/button';
import { CloseIcon } from 'common/otto-ui/icons';
import { Modal, ModalInterface } from 'common/otto-ui/modal';
import { Typography } from 'common/otto-ui/typography';

export enum DialogMaxWidthEnum {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  '2XL' = '2xl',
}

export interface DialogInterface extends ModalInterface {
  /** If true, close icon button will be appeared */
  closeIcon?: boolean;
  /** If true, the dialog will be full-screen */
  fullScreen?: boolean;
  /** If true, the dialog stretches to maxWidth. */
  fullWidth?: boolean;
  /** Determine the max-width of the dialog. The dialog width grows with the size of the screen. Set to false to disable maxWidth. */
  maxWidth?: Lowercase<keyof typeof DialogMaxWidthEnum>;
  title?: string;
  compact?: boolean;
}

/** Dialogs inform users about a task and can contain critical information, require decisions, or involve multiple tasks. */
export const Dialog = ({
  open,
  disableBackdropClick,
  disableEscapeKeyDown,
  closeIcon,
  fullScreen,
  fullWidth,
  maxWidth = DialogMaxWidthEnum.MD,
  className,
  children,
  title,
  compact,
  onBackdropClick,
  onEscapeKeyDown,
  onClose,
  ...props
}: DialogInterface) => {
  const frameClassName = clsx(
    'md:h-100 relative p-6 flex gap-6 flex-col bg-white dark:bg-background-dark-background3 text-title dark:text-offWhite',
    {
      'rounded-2xl': !fullScreen,
      'w-screen h-screen': fullScreen,
      'w-full': !fullScreen && fullWidth,
      'max-w-screen-full': !fullScreen && !maxWidth,
      'max-w-screen-sm': maxWidth === DialogMaxWidthEnum.SM && !fullScreen,
      'max-w-screen-md': maxWidth === DialogMaxWidthEnum.MD && !fullScreen,
      'max-w-screen-lg': maxWidth === DialogMaxWidthEnum.LG && !fullScreen,
      'max-w-screen-xl': maxWidth === DialogMaxWidthEnum.XL && !fullScreen,
      'max-w-screen-2xl': maxWidth === DialogMaxWidthEnum['2XL'] && !fullScreen,
      'p-0 pt-6': compact,

    },
    className,
  );
  const dialogTitle = clsx('max-w-[450px]', {
    'p-6 pb-0': compact
  });
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      onBackdropClick={onBackdropClick}
      onEscapeKeyDown={onEscapeKeyDown}
      onClose={onClose}
      {...props}
    >
      <div className={frameClassName}>
        {title && (
          <div className={dialogTitle}>
            <Typography variant="header-3" className="leading-tight" semiBold>{title}</Typography>
          </div>
        )}
        <div className="absolute top-6 right-6">
          {closeIcon && (
            <Button onClick={handleClose} variant="icon"><CloseIcon width={22} /></Button>
          )}
        </div>
        {children}
      </div>
    </Modal>
  );
};
