import clsx from 'clsx';
import React from 'react';

interface Props {
  header?: boolean;
  rowCount?: number;
  colCount?: number;
}

const classes = {
  wrapper: 'animate-pulse flex justify-center items-center p-7',
  table: 'flex-1 space-y-6 py-1',
  header: clsx('h-12 bg-gray-100 rounded', 'dark:bg-background-dark-background2'),
  row: 'flex gap-4',
  rowItem: clsx('h-12 flex-1 bg-gray-100 rounded', 'dark:bg-background-dark-background2'),
};

const SkeletonRow: React.FC<{ colCount: number }> = ({ colCount }) => (
  <div className={classes.row}>
    {Array.from(Array(colCount).keys()).map((_, index) => (
      <div className={classes.rowItem} key={index}></div>
    ))}
  </div>
);

export const TableSkeleton: React.FC<Props> = ({ header = true, rowCount = 3, colCount = 4 }) => (
  <div className={classes.wrapper}>
    <div className={classes.table}>
      {header && <div className={classes.header} />}
      {Array.from(Array(rowCount).keys()).map((_, index) => (
        <SkeletonRow key={index} colCount={colCount} />
      ))}
    </div>
  </div>
);
