import { caching } from 'configuration/data';

import { API_TAGS, baseApi } from './base.api';
import { DocumentModel, FolderModel } from 'common/interfaces';
import { CreateDocumentDocument, CreateFolderDocument, DeleteDocumentDocument, DeleteFolderDocument, GetDocumentDocument, GetDocumentDownloadDocument, GetDocumentsDocument, GetFolderDocument, GetFoldersDocument, ShareDocumentDocument, UpdateDocumentDocument, UpdateFolderDocument } from 'configuration/graphql/documents';
import { OrderInterface } from 'common/interfaces/common.interface';

export interface QueryDocumentsArgs {
  userId?: string;
  sharedWith?: string;
  name?: string;
  folderId?: string;
  label?: string,
  order?: OrderInterface,
  limit?: number;
  offset?: number;
  owner?: string;
}

interface UpdateDocumentArgs {
  name?: string;
  folderId?: string;
  status?: string;
  labels?: string[];
}

export const documentsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    folder: builder.query<FolderModel, { id: string; }>({
      query: (variables) => ({
        variables,
        document: GetFolderDocument,
      }),
      transformResponse: (res: { folder; }) => res.folder,
      keepUnusedDataFor: caching.day,
      providesTags: (_, __, arg) => [{ type: API_TAGS.FOLDERS, id: arg.id }],
    }),
    folders: builder.query<FolderModel[], { userId: string; }>({
      query: (variables) => ({
        variables,
        document: GetFoldersDocument,
      }),
      transformResponse: (res: { folders: { data; }; }) => res?.folders?.data ?? [],
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.FOLDERS],
    }),
    document: builder.query<DocumentModel, { id: string; }>({
      query: (variables) => ({
        variables,
        document: GetDocumentDocument,
      }),
      transformResponse: (res: { document; }) => res?.document,
      keepUnusedDataFor: caching.day,
      providesTags: (_, __, arg) => [{ type: API_TAGS.DOCUMENTS, id: arg.id }],
    }),
    documentDownload: builder.query<DocumentModel, { id: string; }>({
      query: (variables) => ({
        variables,
        document: GetDocumentDownloadDocument,
      }),
      transformResponse: (res: { document; }) => res?.document,
      keepUnusedDataFor: caching.day,
      providesTags: (_, __, arg) => [{ type: API_TAGS.DOCUMENTS, id: arg.id }],
    }),
    documents: builder.query<{ totalCount: number; data: DocumentModel[]; }, QueryDocumentsArgs>({
      query: (variables) => ({
        variables,
        document: GetDocumentsDocument,
      }),
      transformResponse: (res: { documents; }) => res?.documents,
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.DOCUMENTS]
    }),
    updateFolder: builder.mutation<FolderModel, { id: string; name: string; }>({
      query: (variables) => ({
        variables,
        document: UpdateFolderDocument,
      }),
      transformResponse: (res: { updateFolder; }) => res.updateFolder,
      invalidatesTags: (_, __, arg) => [{ type: API_TAGS.FOLDERS, id: arg.id }, API_TAGS.FOLDERS],
    }),
    deleteFolder: builder.mutation<string, { id: string; }>({
      query: (variables) => ({
        variables,
        document: DeleteFolderDocument
      }),
      invalidatesTags: (_, __, arg) => [API_TAGS.FOLDERS],
    }),
    createFolder: builder.mutation<FolderModel, { userId: string; parent: string | null; name: string; }>({
      query: (variables) => ({
        variables,
        document: CreateFolderDocument,
      }),
      transformResponse: (res: { updateFolder; }) => res.updateFolder,
      invalidatesTags: [API_TAGS.FOLDERS],
    }),
    createDocument: builder.mutation<Partial<DocumentModel>, { name: string; folderId?: string; labels?: string[]; contentType: string; userId: string; size: number; replace: boolean;}>({
      query: (args) => ({
        variables: { document: args },
        document: CreateDocumentDocument,
      }),
      transformResponse: (res: { createDocument; }) => res.createDocument
    }),
    updateDocument: builder.mutation<Partial<DocumentModel>, { id: string; document: UpdateDocumentArgs; }>({
      query: (variables) => ({
        variables,
        document: UpdateDocumentDocument,
      }),
      transformResponse: (res: { updateDocument; }) => res.updateDocument,
      invalidatesTags: (_, __, arg) => [API_TAGS.DOCUMENTS, { type: API_TAGS.DOCUMENTS, id: arg.id }],
    }),
    deleteDocument: builder.mutation<string, { id: string; }>({
      query: (variables) => ({
        variables,
        document: DeleteDocumentDocument
      }),
      invalidatesTags: [API_TAGS.DOCUMENTS]
    }),
    shareDocument: builder.mutation<string, { id: string; shareWith?: string[]; stopSharingWith?: string[]; }>({
      query: (variables) => ({
        variables,
        document: ShareDocumentDocument
      }),
      invalidatesTags: (_, __, arg) => [API_TAGS.DOCUMENTS, { type: API_TAGS.DOCUMENTS, id: arg.id }],
    })
  })
});

export const {
  useFoldersQuery,
  useFolderQuery,
  useUpdateFolderMutation,
  useCreateFolderMutation,
  useCreateDocumentMutation,
  useDocumentsQuery,
  useUpdateDocumentMutation,
  useDeleteDocumentMutation,
  useDocumentQuery,
  useLazyDocumentDownloadQuery,
  useShareDocumentMutation,
  useLazyDocumentsQuery,
  useDeleteFolderMutation
} = documentsApi;
