import clsx from 'clsx';
import { HTMLAttributes } from 'react';

export interface ProgressLinearInterface extends HTMLAttributes<HTMLElement> {
  /** Progress value percent */
  value?: number;
  /** Buffer progress value percent */
  buffer?: number;
  /** Classes will be overrided default classes of value view element */
  valueClassName?: string;
  /** Classes will be overrided default classes of buffer view element */
  bufferClassName?: string;
}

/** Circular progress bar with determinate or indeterminate status */
export const ProgressLinear = ({
  value,
  buffer,
  className,
  valueClassName,
  bufferClassName,
}:ProgressLinearInterface) => {
  const classes = {
    value:
      valueClassName ||
      clsx('transition-all duration-500 ease-in-out absolute top-0 bottom-0 left-0 bg-primary dark:bg-primary-light', {
        'w-auto animate-linear-progress': value === undefined,
      }),
    buffer:
      buffer &&
      (bufferClassName ||
        clsx('transition-all duration-500 ease-in-out  absolute top-0 bottom-0 bg-inputBg dark:bg-primary-normal', {
          'left-0': buffer,
        })),
    main: clsx('overflow-hidden relative bg-line dark:bg-grey1', {
      [className]: !!className,
    }),
  };

  return (
    <div className={classes.main}>
      {buffer && (
        <div className={classes.buffer} style={buffer !== undefined ? { width: `${buffer}%` } : undefined}/>
      )}
      <div className={classes.value} style={value !== undefined ? { width: `${value}%` } : undefined}/>
    </div>
  );
};
